import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { Typography, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  EditTwoTone as EditIcon,
  AttachFile as AttachFileIcon,
  DeleteTwoTone as DeleteIcon,
} from '@material-ui/icons';
import TableRow from 'common/core/Table/TableRow';
import TableCell from 'common/core/Table/TableCell';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import { PodcastsEpisode } from 'types/podcasts';
import { useStyles } from './styles';

interface Props {
  template: PodcastsEpisode;
  handleEditMode: (episodeId: string) => void;
  editEpisodeAudio: (episodeId: string, file: any) => void;
  deleteEpisode: (episodeId: string) => void;
}

const PodcastsEpisodesListItem = ({
  template,
  handleEditMode,
  editEpisodeAudio,
  deleteEpisode,
}: Props) => {
  const { id: episodeId, title, podcastTitle, recordUrl, isPublic } = template;
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const fileRef = useRef<any>();
  const classes = useStyles();

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEditAction = () => {
    handleEditMode(episodeId);
    setAnchorEl(null);
  };

  const handleFileForUpload = () => {
    setAnchorEl(null);
    fileRef.current.click();
  };

  const chooseFileInUploader = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      fileRef.current.value = '';
      editEpisodeAudio(episodeId, file);
    }
  };

  const handleDeleteAction = () => {
    toggleOpenConfirmModal();
    setAnchorEl(null);
  };

  const toggleOpenConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  const handleDeleteApproved = () => {
    deleteEpisode(episodeId);
    toggleOpenConfirmModal();
  };

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell>
          <Typography className={classes.text}>{title}</Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.text}>{podcastTitle}</Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.text}>{!!recordUrl && 'Audio'}</Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.text}>{isPublic ? 'Public' : 'Private'}</Typography>
        </TableCell>
        <TableCell>
          <IconButton onClick={toggleMenu}>
            <MoreVertIcon className={classes.menuButton} />
          </IconButton>
        </TableCell>
      </TableRow>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <MenuItem onClick={handleEditAction} className={classes.subMenu} key="edit">
          Редагувати
          <EditIcon />
        </MenuItem>
        <MenuItem onClick={handleFileForUpload} className={classes.subMenu} key="test">
          Нове аудіо
          <AttachFileIcon />
        </MenuItem>
        <MenuItem
          onClick={handleDeleteAction}
          className={classnames(classes.subMenu, classes.subMenuDelete)}
          key="delete"
        >
          Видалити
          <DeleteIcon />
        </MenuItem>
      </Menu>
      <ModalConfirm
        title="Дійсно хочете видалити?"
        note="Ви не зможете повернути назад."
        isOpenConfirmModal={isOpenConfirmModal}
        toggleOpenConfirmModal={toggleOpenConfirmModal}
        handleSumbit={handleDeleteApproved}
      />
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={chooseFileInUploader}
        multiple
        accept="audio/mpeg"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default PodcastsEpisodesListItem;
