import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingTop: 4,
  },
  headerBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  titleBlock: {
    justifyContent: 'flex-start',
    flexGrow: 1,
    marginLeft: 10,
  },
  closeBlock: {
    flexGrow: 0,
    marginRight: 5,
  },
  closeButton: {},
  closeIcon: {},
  titleText: {
    fontSize: 18,
    fontWeight: 400,
  },

  contentBlock: {
    padding: 5,
  },
  actionsBlock: {
    display: 'flex',
    backgroundColor: '#fff',
    padding: 5,
    justifyContent: 'center',
    paddingBottom: 20,
  },
  button: {
    marginLeft: 10,
  },
}));
