import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { Typography, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  EditTwoTone as EditIcon,
  AudiotrackTwoTone as AudioIcon,
  HighlightOffTwoTone as AudioDeleteIcon,
  DeleteTwoTone as DeleteIcon,
} from '@material-ui/icons';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import { PoetryWalksItem } from 'types/projects/poetry';
import { useStyles } from './styles';

interface Props {
  poetry: PoetryWalksItem;
  isLastItem: boolean;
  handleEditMode: (userId: string) => void;
  editPoetryAudio: (poetryId: string, file: any) => void;
  deletePoetryAudio: (poetryId: string) => void;
  deletePoetry: (poetryId: string) => void;
}

const PoetryListItem = ({
  poetry,
  isLastItem,
  handleEditMode,
  editPoetryAudio,
  deletePoetryAudio,
  deletePoetry,
}: Props) => {
  const { id: poetryId, name, authorName, authorPictureUrl, body, isPublic, recordUrl } = poetry;
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const fileRef = useRef<any>();
  const classes = useStyles();

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEditAction = () => {
    handleEditMode(poetryId);
    setAnchorEl(null);
  };

  const handleFileForUpload = () => {
    setAnchorEl(null);
    fileRef.current.click();
  };

  const chooseFileInUploader = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      fileRef.current.value = '';
      editPoetryAudio(poetryId, file);
    }
  };

  const handleDeletePoetryAudio = () => {
    deletePoetryAudio(poetryId);
    setAnchorEl(null);
  };

  const handleDeleteAction = () => {
    toggleOpenConfirmModal();
    setAnchorEl(null);
  };

  const toggleOpenConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  const handleDeleteApproved = () => {
    deletePoetry(poetryId);
    toggleOpenConfirmModal();
  };

  return (
    <>
      <div className={classnames(classes.container, isLastItem ? classes.containerBottom : null)}>
        <div className={classes.titleBlock}>
          <Typography className={classnames(classes.contentText, classes.titleText)}>
            {name}
          </Typography>
          <Typography className={classnames(classes.contentText, classes.authorText)}>
            {authorName}
          </Typography>
          <Typography className={classnames(classes.contentText, classes.poemText)}>
            {body.slice(0, 100)}
          </Typography>
        </div>
        <div className={classes.titleBlock}>
          <Typography className={classnames(classes.contentText, recordUrl && classes.audioText)}>
            {recordUrl ? 'audio' : 'no audio'}
          </Typography>
        </div>
        <div className={classes.statusBlock}>
          <Typography
            className={classnames(classes.contentText, !isPublic && classes.notPublicText)}
          >
            {isPublic ? '' : 'Захований'}
          </Typography>
        </div>
        <div className={classes.actionsBlock}>
          <IconButton onClick={toggleMenu}>
            <MoreVertIcon className={classes.menuButton} />
          </IconButton>
        </div>
      </div>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <MenuItem onClick={handleEditAction} className={classes.subMenu} key="edit">
          Редагувати
          <EditIcon />
        </MenuItem>
        {!recordUrl ? (
          <MenuItem onClick={handleFileForUpload} className={classes.subMenu} key="add_audio">
            Додати аудіо
            <AudioIcon />
          </MenuItem>
        ) : (
          <MenuItem
            onClick={handleDeletePoetryAudio}
            className={classes.subMenu}
            key="delete_audio"
          >
            Видалити аудіо
            <AudioDeleteIcon />
          </MenuItem>
        )}
        {!recordUrl && (
          <MenuItem
            onClick={handleDeleteAction}
            className={classnames(classes.subMenu, classes.subMenuDelete)}
            key="delete"
          >
            Видалити
            <DeleteIcon />
          </MenuItem>
        )}
      </Menu>
      <ModalConfirm
        title="Дійсно хочете видалити?"
        note="Ви не зможете повернути назад."
        isOpenConfirmModal={isOpenConfirmModal}
        toggleOpenConfirmModal={toggleOpenConfirmModal}
        handleSumbit={handleDeleteApproved}
      />
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={chooseFileInUploader}
        multiple
        accept="audio/mpeg"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default PoetryListItem;
