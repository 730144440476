import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';

export const useTaskUrlCheck = (id: string) => {
  const checkUrl = useCallback(async (uid: string) => {
    const details = { id, uid };
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/nature/check_task_url',
      details,
    );

    if (results?.data?.isChecked) {
      return results?.data?.isUnique;
    }

    return false;
  }, []);

  return {
    checkUrl,
  };
};
