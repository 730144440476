import React, { useEffect, useState } from 'react';
import { Context } from './Context';

interface Props {
  children: React.ReactNode;
}

const ContextProvider = ({ children }: Props) => {
  const [isUserAuthed, setIsUserAuthed] = useState<boolean | null>(null);
  const [userData, setUserData] = useState<any | null>(null);
  const [alerts, setAlerts] = useState([]);

  const defaultContext = {
    isUserAuthed,
    setIsUserAuthed,
    userData,
    setUserData,
    alerts,
    setAlerts,
  };

  return <Context.Provider value={defaultContext}>{children}</Context.Provider>;
};

export default ContextProvider;