import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import PlaceHeader from './PlaceHeader';
import PlaceInfo from './PlaceInfo';
import PlaceMap from './PlaceMap';
import PlaceRoutes from './PlaceRoutes';
import { usePlace } from 'hooks/places/usePlace';
import { usePlaceRoutes } from 'hooks/places/usePlaceRoutes';
import { useStyles } from './styles';

const radiusDefault = 2000;

const PlaceContainer = () => {
  const [radius, setRadius] = useState<number>(radiusDefault);
  const location = useLocation();
  const pagePath = location.pathname.split('/');
  const placeId = pagePath[pagePath.length - 1] ?? '';
  const { placeData } = usePlace(placeId);
  const { isLoading: isLoadingRoutes, routesList = [] } = usePlaceRoutes(placeId, radius);
  const classes = useStyles();

  const handleChangeRadius = (value: number) => {
    setRadius(value);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <PlaceHeader place={placeData} />
      <Grid container spacing={1}>
        <Grid item md={6} sm={6} xs={12}>
          <PlaceInfo />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <PlaceMap place={placeData} />
        </Grid>
      </Grid>
      <PlaceRoutes
        isLoadingRoutes={isLoadingRoutes}
        routesList={routesList}
        radius={radius}
        handleChangeRadius={handleChangeRadius}
      />
    </Container>
  );
};

export default PlaceContainer;
