import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { FormControl, Select, InputLabel, Typography, Switch } from '@material-ui/core';
// @ts-ignore
import { slugify } from 'transliter';
import EditIcon from '@material-ui/icons/Edit';
import Input from 'common/core/Input';
import InputCheck from 'common/InputCheck';
import DatePicker from 'common/core/DatePicker';
import InputEditor from 'common/core/InputEditor';
import Button from 'common/core/Button';
import { dateConvertFromDatePicker, dateConvertToDatePicker } from 'utils/datetime';
import { usePostUrlCheck } from 'hooks/blog/usePostUrlCheck';
import { usePodcastsEpisodesCommon } from 'hooks/podcasts/usePodcastsEpisodesCommon';
import { BlogPageMode, BlogPost, BlogAuthor, BlogCategory, BlogPostInput } from 'types/blog';
import { useStyles } from './styles';

interface Props {
  post: BlogPost | null;
  authorsList: BlogAuthor[];
  categoriesList: BlogCategory[];
  createPost: (details: BlogPostInput) => void;
  editPost: (details: BlogPostInput) => void;
  editPostPicture: (postId: string, file: any) => void;
  handlePageMode: (value: string) => void;
}

const BlogEdit = ({
  post,
  authorsList,
  categoriesList,
  createPost,
  editPost,
  editPostPicture,
  handlePageMode,
}: Props) => {
  const [datePost, setDatePost] = useState<string>(new Date().toISOString().substring(0, 10));
  const [title, setTitle] = useState<string>('');
  const [authorId, setAuthorId] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [uid, setUid] = useState<string>('');
  const [categoryId, setCategoryId] = useState<string>('');
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [titleError, setTitleError] = useState<string>('');
  const [pictureUrl, setPictureUrl] = useState<string>('');
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [episodeId, setEpisodeId] = useState<string>('');
  const fileRef = useRef<any>();
  const { checkUrl } = usePostUrlCheck(post?.id ?? '');
  const { data: episodesList } = usePodcastsEpisodesCommon();
  const classes = useStyles();

  useEffect(() => {
    // if (post) {
    setDatePost(
      typeof post?.datePost === 'number' && post?.datePost !== 0
        ? dateConvertToDatePicker(post?.datePost).substring(0, 10)
        : new Date().toISOString().substring(0, 10),
    );
    setTitle(post?.title ?? '');
    setAuthorId(post?.authorId ?? '');
    setBody(post?.body ?? '');
    setUid(post?.uid ?? '');
    setVideoUrl(post?.videoUrl ?? '');
    setIsPublic(post?.isPublic ?? false);
    setCategoryId(post?.categoryId ?? '');
    setEpisodeId(post?.podcastData?.episodeId ?? '');
    // }
  }, [post]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (post?.pictureUrl) {
      timer = setTimeout(() => {
        setPictureUrl(post?.pictureUrl);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [post]);

  useEffect(() => {
    if (!post?.id) {
      setUid(slugify(title, '-'));
    }
  }, [title, post]);

  const handleDatePost = (e: any) => {
    setDatePost(e.target.value as string);
  };

  const handleChangeBody = (value: string) => {
    setBody(value);
  };

  // const handleChangeCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const categoriesNew =
  //     categories.findIndex((categoryId) => categoryId === e.target.name) !== -1
  //       ? categories.filter((categoryId) => categoryId !== e.target.name)
  //       : [...categories, e.target.name];

  //   setCategories(categoriesNew);
  // };

  const handleUpoloadImage = () => {
    fileRef.current.click();
  };

  const chooseFileInUploader = (e: any) => {
    if (e.target.files.length) {
      editPostPicture(post?.id ?? '', e.target.files[0]);
      fileRef.current.value = '';
    }
  };

  const handleSubmit = () => {
    setTitleError(title ? '' : 'Заповніть заголовок поста');

    if (title) {
      const datePostValue = !!dateConvertFromDatePicker(datePost)
        ? dateConvertFromDatePicker(datePost)
        : 0;
      if (!post?.id) {
        createPost({
          datePost: datePostValue,
          title,
          authorId,
          body,
          uid,
          videoUrl,
          categoryId,
          episodeId,
          isPublic,
        });
      } else {
        editPost({
          postId: post?.id ?? '',
          datePost: datePostValue,
          title,
          authorId,
          body,
          uid,
          videoUrl,
          categoryId,
          episodeId,
          isPublic,
        });
      }
    }
  };

  return (
    <>
      <div className={classes.container}>
        {!!post && (
          <div className={classes.pictureBlock}>
            {!!pictureUrl && (
              <img onLoad={() => {}} src={pictureUrl} className={classes.picture} alt="" />
            )}
            <EditIcon onClick={handleUpoloadImage} className={classes.editIcon} />
          </div>
        )}
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Заголовок поста"
            type="text"
            className={classes.input}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            helperText={titleError}
            fullWidth
            required
          />
        </div>
        <div className={classes.inputBlock}>
          <InputCheck
            label="UID"
            value={uid}
            handleChangeValue={(value) => setUid(value)}
            handleCheckValue={checkUrl}
          />
          {/* <Input
            size="medium"
            label="URL (шлях)"
            type="text"
            className={classnames(classes.input, classes.inputLimited)}
            onChange={(e) => setUid(e.target.value)}
            value={uid}
          /> */}
        </div>
        <div className={classes.inputBlock}>
          <DatePicker
            label="Дата виходу"
            value={datePost}
            defaultValue={new Date().toISOString().substring(0, 10)}
            onChange={handleDatePost}
            className={classnames(classes.input, classes.inputDatePicker)}
            fullWidth
          />
        </div>
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Автор</InputLabel>
            <Select
              label="Автор"
              native
              onChange={(e: any) => setAuthorId(e.target.value)}
              value={authorId}
              className={classes.select}
            >
              <option aria-label="None" value="" key={0}></option>
              {authorsList
                .sort((a: BlogAuthor, b: BlogAuthor) => a.name.localeCompare(b.name))
                .map((item: BlogAuthor) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.inputHeaderBlock}>
          <Typography className={classes.inputHeaderText}>Текст поста:</Typography>
        </div>
        <div className={classes.inputEditorBlock}>
          <InputEditor valueInitial={body} handleChangeValue={handleChangeBody} />
        </div>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Video URL"
            type="text"
            className={classes.input}
            onChange={(e) => setVideoUrl(e.target.value)}
            value={videoUrl}
            fullWidth
          />
        </div>
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Категорія</InputLabel>
            <Select
              label="Категорія"
              native
              onChange={(e: any) => setCategoryId(e.target.value)}
              value={categoryId}
              className={classes.select}
            >
              <option aria-label="None" value="" key={0}></option>
              {!!categoriesList &&
                categoriesList.map(({ id, name }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
            </Select>
          </FormControl>
        </div>

        {/* <div className={classes.inputBlock}>
          <div className={classes.optionsListBlock}>
            <div className={classes.optionsListHeaderBlock}>
              <Typography className={classes.optionsListHeaderText}>Категорії:</Typography>
            </div>
            {categoriesList.map((item: any) => {
              const isChecked = categories.findIndex((categoryId) => categoryId === item.id) !== -1;
              return (
                <div className={classes.optionBlock}>
                  <div className={classes.optionCheckboxBlock}>
                    <Checkbox
                      name={item.id}
                      checked={isChecked}
                      onChange={handleChangeCategory}
                      color="primary"
                      className={classes.optionCheckbox}
                    />
                  </div>
                  <div className={classes.optionNameBlock}>
                    <Typography className={classes.optionNameText}>{item?.name}</Typography>
                  </div>
                </div>
              );
            })}
          </div>
        </div> */}

        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Епізод подкасту</InputLabel>
            <Select
              label="Епізод подкасту"
              native
              onChange={(e: any) => setEpisodeId(e.target.value)}
              value={episodeId}
              className={classes.select}
            >
              <option aria-label="None" value="" key={0}></option>
              {!!episodesList &&
                episodesList.map(({ id, title, podcastTitle }) => (
                  <option value={id} key={id}>
                    {title} || {podcastTitle}
                  </option>
                ))}
            </Select>
          </FormControl>
        </div>

        <div className={classes.inputBlock}>
          <Switch
            checked={isPublic}
            onChange={() => setIsPublic(!isPublic)}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography className={classes.switchText}>
            {isPublic ? 'Показувати на сайті' : 'Не показувати на сайті'}
          </Typography>
        </div>
        <div className={classes.buttonBlock}>
          <Button
            onClick={() => handlePageMode(BlogPageMode.LIST)}
            variant="outlined"
            className={classes.button}
          >
            Назад
          </Button>
          <Button onClick={handleSubmit} className={classes.button}>
            Зберегти
          </Button>
        </div>
      </div>
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={chooseFileInUploader}
        multiple
        accept="image/*"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default BlogEdit;
