import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { timeout } from 'utils/timeout';
import { useAlert } from 'hooks/useAlert';
import {
  NatureCategory,
  NatureAge,
  NatureTask,
  NatureTaskInputCreate,
  NatureTaskInputEdit,
} from 'types/projects/nature';

export const useTasks = () => {
  const [categoriesList, setCategoriesList] = useState<NatureCategory[]>([]);
  const [agesList, setAgesList] = useState<NatureAge[]>([]);
  const [tasksList, setTasksList] = useState<NatureTask[]>([]);
  const showAlert = useAlert();

  useEffect(() => {
    const getCategories = async () => {
      const results: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/nature/get_task_categories',
        {},
      );
      if (results?.data) {
        setCategoriesList(results.data.categories);
      }
    };

    getCategories();
  }, []);

  useEffect(() => {
    getTasks();
  }, []);

  const getTasks = useCallback(async () => {
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/nature/get_admin_tasks',
      {},
    );

    if (results?.data?.tasks) {
      setTasksList(results.data.tasks);
      setAgesList(results.data.ages);
    }
  }, []);

  const createTask = useCallback(async (details: NatureTaskInputCreate) => {
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/nature/create_task',
      details,
    );
    if (results?.data?.id) {
      getTasks();
      showAlert('Завдання створено');
      return results?.data?.id;
    }
  }, []);

  const editTask = useCallback(async (details: NatureTaskInputEdit) => {
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/nature/edit_task', details);
    getTasks();
    showAlert('Завдання змінено');
  }, []);

  const editTaskPicture = useCallback(async (taskId: string, file: any) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('taskId', taskId);

    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/nature/edit_task_picture',
      formData,
    );
    await new Promise((resolve) => {
      return setTimeout(resolve, 1500);
    });
    getTasks();
    showAlert('Ілюстрацію завдання змінено');
  }, []);

  const deleteTaskPicture = useCallback(async (taskId: string) => {
    const details = { taskId };
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/nature/delete_task_picture',
      details,
    );
    getTasks();
    showAlert('Завдання видалено');
  }, []);

  const createGalleryPicture = useCallback(async (taskId: string, file: any) => {
    let formData = new FormData();
    formData.append('taskId', taskId);
    formData.append('file', file);
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/nature/create_task_gallery_picture',
      formData,
    );
    await timeout(2000);
    getTasks();
  }, []);

  const deleteGalleryPicture = useCallback(async (taskId: string, id: string) => {
    const details = { taskId, id };
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/nature/delete_task_gallery_picture',
      details,
    );

    getTasks();
  }, []);

  const deleteTask = useCallback(async (taskId: string) => {
    const details = { taskId };
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/nature/delete_task', details);
    getTasks();
    showAlert('Завдання видалено.');
  }, []);

  return {
    categoriesList,
    agesList,
    tasksList,
    createTask,
    editTask,
    editTaskPicture,
    deleteTaskPicture,
    createGalleryPicture,
    deleteGalleryPicture,
    deleteTask,
  };
};
