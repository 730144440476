import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  titleBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginTop: 10,
  },
  noteBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    paddingTop: 10,
  },
  buttonBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: 24,
    marginBottom: 5,
  },
  titleText: {
    fontSize: 22,
    fontWeight: 500,
    lineHeight: '32px',
  },
  noteText: {
    fontSize: 16,
    fontWeight: 400,
  },
  buttonCancel: {
    width: '50%',
    height: 45,
    fontSize: 16,
    fontWeight: 400,
    margin: 4,
  },
  buttonSumbit: {
    width: '50%',
    height: 45,
    fontSize: 16,
    fontWeight: 400,
    backgroundColor: '#FF0000',
    margin: 4,
  },
}));
