import { useState, useEffect, useContext, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { useGoogleLogin } from 'react-google-login';
import { googleClientId } from 'config';
import { Context } from 'context/Context';
import { useAlert } from '../useAlert';

export const useLogin = () => {
  const { setIsUserAuthed, userData, setUserData } = useContext(Context);
  const [answerError, setAnswerError] = useState<string>('');
  const showAlert = useAlert();

  useEffect(() => {
    if (userData) {
      setAnswerError('');
    }
  }, [userData]);

  const login = useCallback(async (email: string, password: string) => {
    const details = {
      email,
      password,
    };

    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/auth/login',
      details,
    );

    if (results?.data) {
      if (results.data?.error) {
        setAnswerError(results.data.error);
      } else {
        const {
          accessToken,
          userId,
          firstName,
          lastName,
          userName,
          email,
          regionId,
          cityId,
          avatarUrl,
          createdAt,
        } = results.data;
        const userDataNew = {
          userId,
          firstName,
          lastName,
          userName,
          email,
          regionId,
          cityId,
          avatarUrl,
          createdAt,
        };
        setIsUserAuthed(true);
        setUserData(userDataNew);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('userData', JSON.stringify(userDataNew));
        showAlert('Вітаємо. Ви успішно зайшли.');
      }
    }
  }, []);

  return { login, answerError };
};
