import { Grid } from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import StarIcon from '@material-ui/icons/Star';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PeopleIcon from '@material-ui/icons/People';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import TextRotateVerticalIcon from '@material-ui/icons/TextRotateVertical';
import Container from 'common/core/Container';
import PageTitle from 'common/core/PageTitle';
import MenuMainItem from 'common/core/MenuMainItem';

const pagesList = [
  {
    id: 'apps',
    title: 'Всі застосунки',
    url: '/apps',
    icon: <AppsIcon />,
  },
  {
    id: 'tvorytelling_templates',
    title: 'Tvorytelling : Шаблони',
    url: '/tvorytelling/templates',
    icon: <ViewQuiltIcon />,
  },
  {
    id: 'nature_achievements',
    title: 'Nature Neighbours : Досягнення',
    url: '/projects/nature/achievements',
    icon: <StarIcon />,
  },
  {
    id: 'poetrywalks_poetry',
    title: 'Poetry Walks : Вірши',
    url: '/projects/poetry/poetry',
    icon: <MenuBookIcon />,
  },
  {
    id: 'poetrywalks_authors',
    title: 'Poetry Walks : Автори',
    url: '/projects/poetry/authors',
    icon: <PeopleIcon />,
  },
  {
    id: 'alphabet_letters',
    title: 'Alphabet : Літери',
    url: '/projects/alphabet/letters',
    icon: <SortByAlphaIcon />,
  },
  {
    id: 'alphabet_sounds',
    title: 'Alphabet Sounds : Слова',
    url: '/projects/alphabet/words',
    icon: <TextRotateVerticalIcon />,
  },
];

const ProjectsMainContainer = () => {
  return (
    <Container>
      <PageTitle title="Застосунки" />
      <Grid container spacing={2}>
        {pagesList.map(({ id, title, url, icon }) => (
          <MenuMainItem key={id} title={title} url={url}>
            {icon}
          </MenuMainItem>
        ))}
      </Grid>
    </Container>
  );
};

export default ProjectsMainContainer;
