import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    // border: '1px solid #EBEBEB',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;',
    borderRadius: 6,
    [theme.breakpoints.down('sm')]: {
      height: 70,
    },
  },
  leftBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
    aspectRatio: '4/3',
    backgroundColor: '#336699',
    borderRadius: '6px 0 0 6px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '20%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '20%',
    },
  },
  isPrivateBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 3,
    left: 3,
    width: 18,
    height: 18,
    backgroundColor: '#9B0000',
  },
  isRecommendBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: 25,
    height: 25,
    borderRadius: '6px 0 0 0',
    backgroundColor: '#9B0000',
  },
  contentBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  contentTopBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '50%',
    paddingTop: 8,
    paddingLeft: 8,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 6,
    },
  },
  contentBottomBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '50%',
    paddingLeft: 8,
    paddingBottom: 8,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 5,
    },
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 2,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 1,
    },
  },
  actionsBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  picture: {
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '4/3',
    borderRadius: 4,
  },
  imgEmptyIcon: {
    width: 60,
    [theme.breakpoints.down('sm')]: {
      width: 40,
    },
    [theme.breakpoints.down('xs')]: {
      width: 40,
    },
    opacity: 0.8,
  },
  isPrivateIcon: {
    fontSize: 14,
    color: '#fff',
  },
  isRecommendIcon: {
    fontSize: 20,
    color: '#fff',
  },
  titleText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '14px',
    marginBottom: 3,
  },
  titleNoText: {
    fontWeight: 350,
    color: '#999',
    fontStyle: 'italic',
    lineHeight: '14px',
  },
  infoText: {
    fontSize: 12,
    fontWeight: 350,
    color: '#666666',
    lineHeight: '14px',
  },
  circleRouteIcon: {
    color: '#660708 !important',
    marginLeft: 6,
  },
  circleRouteText: {
    color: '#660708',
  },
  locationText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '75%',
  },
  infoIcon: {
    fontSize: 12,
    color: '#666666',
    marginRight: 2,
  },
  rating: {
    color: theme.palette.primary.main,
  },
  commentsText: {
    fontSize: 12,
    fontWeight: 350,
    lineHeight: '15px',
    color: '#999',
  },
  ratingText: {
    fontSize: 12,
    fontWeight: 350,
    lineHeight: '15px',
    color: '#666666',
    marginRight: 8,
  },
  commentsIcon: {
    fontSize: 14,
    color: '#666666',
    marginRight: 2,
  },
  loveIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    fontSize: 22,
    color: '#999',
    [theme.breakpoints.down('sm')]: {
      top: 4,
      right: 4,
      fontSize: 20,
    },
  },
  loveIconDisabled: {
    // cursor: 'auto !important',
    color: '#999',
  },
  menuButton: {
    position: 'absolute',
    bottom: 10,
    right: 5,
    padding: 4,
    [theme.breakpoints.down('sm')]: {
      padding: 4,
    },
  },
  menuIcon: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  subMenu: {
    minWidth: 170,
  },
  subMenuDelete: {
    color: '#FF0000 !important',
  },
  subMenuIcon: {},
}));
