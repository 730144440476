import { Grid } from '@material-ui/core';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ViewListIcon from '@material-ui/icons/ViewList';
import Container from 'common/core/Container';
import PageTitle from 'common/core/PageTitle';
import MenuMainItem from 'common/core/MenuMainItem';

const pagesList = [
  {
    id: 'users',
    title: 'Всі користувачі',
    url: '/users',
    icon: <PermContactCalendarIcon />,
  },
  {
    id: 'email-templates',
    title: 'Шаблони листів',
    url: '/email-templates',
    icon: <ViewListIcon />,
  },
];

const PeopleMainContainer = () => {
  return (
    <Container>
      <PageTitle title="Користувачі" />
      <Grid container spacing={2}>
        {pagesList.map(({ id, title, url, icon }) => (
          <MenuMainItem key={id} title={title} url={url}>
            {icon}
          </MenuMainItem>
        ))}
      </Grid>
    </Container>
  );
};

export default PeopleMainContainer;
