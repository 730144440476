import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Login from './Login';
import { Context } from 'context/Context';
import { useStyles } from './styles';

const AuthContainer = () => {
  const { isUserAuthed } = useContext(Context);
  const [isLoginPage, setIsLoginPage] = useState(true);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (isUserAuthed) {
      history.push('/profile/edit');
    }
  }, [isUserAuthed]);

  const togglePage = () => {
    setIsLoginPage(!isLoginPage);
  };

  return <>{isLoginPage ? <Login togglePage={togglePage} /> : <></>}</>;
};

export default AuthContainer;
