import { useState, useCallback, useContext } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { useAlert } from '../useAlert';
import { Context } from 'context/Context';

export const useAvatarEdit = () => {
  const { userData, setUserData } = useContext(Context);
  const [answerError, setAnswerError] = useState<string>('');
  const showAlert = useAlert();

  const editAvatar = useCallback(async (file: any) => {
    let formData = new FormData();
    formData.append('file', file);

    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/auth/edit_avatar',
      formData,
    );

    if (results?.data) {
      if (results.data?.error) {
        setAnswerError(results.data.error);
      } else {
        const { avatarUrl } = results.data;
        const userDataNew = {
          ...userData,
          avatarUrl,
        };
        setUserData(userDataNew);
        localStorage.setItem('userData', JSON.stringify(userDataNew));
        showAlert('Ваш аватар був змінений.');
      }
    }
  }, []);

  return {
    answerError,
    editAvatar,
  };
};
