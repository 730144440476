import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { timeout } from 'utils/timeout';
import { Story } from 'types/projects/tvorytelling';

export const useTemplates = () => {
  const [storiesList, setStoriesList] = useState<Story[]>([]);

  useEffect(() => {
    getStories();
  }, []);

  const getStories = useCallback(async () => {
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/tvorytelling/get_admin_templates',
      {},
    );

    if (results?.data) {
      setStoriesList(results.data.stories);
    }
  }, []);

  const createStory = useCallback(
    async (categoryId: string, name: string, description: string, isPublic: boolean) => {
      const details = {
        categoryId,
        title: name,
        description,
        isPublic,
      };
      await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/tvorytelling/create_template',
        details,
      );
      getStories();
    },
    [],
  );

  const editStory = useCallback(
    async (
      storyId: string,
      categoryId: string,
      name: string,
      description: string,
      isPublic: boolean,
    ) => {
      const details = {
        storyId,
        categoryId,
        title: name,
        description,
        isPublic,
      };
      await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/tvorytelling/edit_template',
        details,
      );
      getStories();
    },
    [],
  );

  const editStoryPicture = useCallback(async (storyId: string, file: any) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('storyId', storyId);

    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/tvorytelling/edit_template_picture',
      formData,
    );

    await timeout(2500);

    getStories();
  }, []);

  const deleteStory = useCallback(async (storyId: string) => {
    const details = { storyId };
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/tvorytelling/delete_template',
      details,
    );
    getStories();
  }, []);

  return {
    storiesList,
    createStory,
    editStory,
    editStoryPicture,
    deleteStory,
  };
};
