import { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { Route } from 'types/places';

export const usePlaceRoutes = (placeId: string, radius: number) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [routesList, setRoutesList] = useState<Route[]>([]);

  useEffect(() => {
    const getRoutes = async () => {
      setIsLoading(true);
      const details = {
        placeId,
        radius,
      };
      const results: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/places/get_routes',
        details,
      );
      if (results?.data) {
        setIsLoading(false);
        setRoutesList(results.data.routes);
      }
    };

    if (placeId && radius) {
      getRoutes();
    }
  }, [placeId, radius]);

  return {
    isLoading,
    routesList,
  };
};
