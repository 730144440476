import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 42,
    fontSize: 16,
    fontWeight: 350,
    color: '#0F0F21',
    borderBottom: '1px solid #E4E7ED',
    paddingLeft: 8,
    paddingRight: 8,
  },
}));
