import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 20,
  },

  titleText: {
    fontSize: 20,
    fontWeight: 500,
  },
  button: {
    minWidth: 130,
  },
}));
