import React from 'react';
import { Marker, Circle } from 'react-leaflet';
import L from 'leaflet';
import markerUsualIcon from 'assets/icons/pointer1.svg';
import markerEditableIcon from 'assets/icons/placeholder-point.svg';
import markerExampleStartIcon from 'assets/icons/flag_point3.svg';
import markerExampleIcon from 'assets/icons/circle7.svg';
import currentPositionIcon from 'assets/icons/pointer_person.svg';
import createPointIcon from 'assets/icons/create_marker.svg';

const markerUsual = new L.Icon({
  iconUrl: markerUsualIcon,
  iconRetinaUrl: markerUsualIcon,
  iconSize: new L.Point(50, 50),
  iconAnchor: [50 / 2, 50],
});

const markerExample = new L.Icon({
  iconUrl: markerExampleIcon,
  iconRetinaUrl: markerExampleIcon,
  iconSize: new L.Point(12, 12),
  iconAnchor: [12 / 2, 12 / 2],
});

const markerExampleStart = new L.Icon({
  iconUrl: markerExampleStartIcon,
  iconRetinaUrl: markerExampleStartIcon,
  iconSize: new L.Point(50, 50),
  iconAnchor: [(50 - 30) / 2, 50],
});

const markerEditable = new L.Icon({
  iconUrl: markerEditableIcon,
  iconRetinaUrl: markerEditableIcon,
  iconSize: new L.Point(40, 40),
  iconAnchor: [40 / 2, 40],
});

const createPointIconMarker = new L.Icon({
  iconUrl: createPointIcon,
  iconRetinaUrl: createPointIcon,
  iconSize: new L.Point(30, 30),
  iconAnchor: [30 / 2, 30],
});

const currentPositionMarker = new L.Icon({
  iconUrl: currentPositionIcon,
  iconRetinaUrl: currentPositionIcon,
  iconSize: new L.Point(50, 50),
  iconAnchor: [50 / 2, 50],
});

const MapMarker = ({
  position,
  type = 'usual',
  size = 'small',
  isStart = false,
  isWithCircle = false,
  isCircle = false,
  radius = 0,
  isEditable = false,
  handleClick = () => {},
}: any) => {
  const onClick = (e: React.SyntheticEvent) => {
    handleClick(e);
  };

  if (isCircle === true) {
    return (
      <Circle
        center={position}
        radius={3.0}
        color="#000066"
        fillColor="#000066"
        opacity={1}
        fillOpacity={1}
        weight={3}
        zIndex={10}
      />
    );
  }

  return (
    <>
      {type === 'usual' && <Marker icon={markerUsual} position={position} onClick={onClick} />}
      {type === 'route_example' && isStart === false && (
        <Marker icon={markerExample} position={position} onClick={onClick} />
      )}
      {type === 'route_example' && isStart === true && (
        <Marker icon={markerExampleStart} position={position} onClick={onClick} />
      )}
      {type === 'editable' && (
        <Marker icon={markerEditable} position={position} onClick={onClick} />
      )}
      {type === 'create' && (
        <Marker icon={createPointIconMarker} position={position} onClick={onClick} />
      )}
      {type === 'current' && (
        <Marker icon={currentPositionMarker} position={position} onClick={onClick} />
      )}
      {isWithCircle === true && (
        <Circle
          center={position}
          radius={15.0}
          color="red"
          fillColor="red"
          opacity={1}
          fillOpacity={0.2}
          weight={0.5}
          zIndex={10}
        />
      )}
    </>
  );
};

export default MapMarker;
