import React, { useState, useEffect } from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import Modal from 'common/core/Modal';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import {
  PoetryWalksItem,
  PoetryWalksAuthor,
  PoetryInputCreate,
  PoetryInputEdit,
} from 'types/projects/poetry';
import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
  authorsList: PoetryWalksAuthor[];
  poetry: PoetryWalksItem | null;
  createPoetry: (details: PoetryInputCreate) => void;
  editPoetry: (details: PoetryInputEdit) => void;
}

const PoetryEdit = ({
  isOpen,
  toggleIsOpen,
  authorsList,
  poetry,
  createPoetry,
  editPoetry,
}: Props) => {
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const [name, setName] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [authorId, setAuthorId] = useState<string>('');
  const [recordAuthorName, setRecordAuthorName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    if (poetry) {
      setName(poetry?.name ?? '');
      setAuthorId(poetry?.authorId ?? '');
      setBody(poetry?.body ?? '');
      setRecordAuthorName(poetry?.recordAuthorName ?? '');
      setIsPublic(poetry?.isPublic ?? true);
    } else {
      setName('');
      setAuthorId('');
      setBody('');
      setRecordAuthorName('');
      setIsPublic(true);
    }
    return () => {
      setName('');
      setIsPublic(true);
    };
  }, [poetry, isOpen]);

  const handleSubmit = () => {
    // setNameError(name ? '' : 'Заповніть назву вірша');

    // if (name) {
    if (!poetry?.id) {
      createPoetry({
        name,
        body,
        authorId,
        isPublic,
      });
    } else {
      editPoetry({
        poetryId: poetry?.id ?? '',
        name,
        body,
        authorId,
        recordAuthorName,
        isPublic,
      });
    }
    toggleIsOpen();
    // }
  };

  return (
    <Modal
      title={poetry?.id ? 'Редагування вірша' : 'Новий вірш'}
      widthMax={550}
      open={isOpen}
      handleClose={toggleIsOpen}
    >
      <div className={classes.container}>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Назва"
            type="text"
            className={classes.input}
            onChange={(e) => setName(e.target.value)}
            value={name}
            // helperText={nameError}
            fullWidth
            required
          />
        </div>
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Автор</InputLabel>
            <Select
              label="Автор"
              native
              onChange={(e: any) => setAuthorId(e.target.value)}
              value={authorId}
              className={classes.select}
            >
              <option aria-label="None" value="" key={0}></option>
              {authorsList.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.inputBlock}>
          <Input
            label="Текст вірша"
            type="text"
            className={classes.input}
            onChange={(e) => setBody(e.target.value)}
            value={body}
            fullWidth
            multiline
            rows={10}
          />
        </div>
        {!!poetry?.recordUrl && (
          <div className={classes.inputBlock}>
            <Input
              size="medium"
              label="Хто озвучував"
              type="text"
              className={classes.input}
              onChange={(e) => setRecordAuthorName(e.target.value)}
              value={recordAuthorName}
              fullWidth
            />
          </div>
        )}
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Доступ</InputLabel>
            <Select
              label="Доступ"
              native
              onChange={(e: any) => setIsPublic(e.target.value === 'true' ? true : false)}
              value={isPublic ? 'true' : 'false'}
              className={classes.select}
            >
              <option value="false" key="private">
                Сховати
              </option>
              <option value="true" key="public">
                Показувати
              </option>
            </Select>
          </FormControl>
        </div>
        <div className={classes.buttonBlock}>
          <Button onClick={handleSubmit}>Зберегти</Button>
        </div>
      </div>
    </Modal>
  );
};

export default PoetryEdit;
