export enum BlogPageMode {
  LIST = 'list',
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
}

export interface BlogAuthor {
  id: string;
  name: string;
}

export interface BlogCategory {
  id: string;
  uid?: string;
  name: string;
}

export interface BlogPost {
  id: string;
  uid: string;
  title: string;
  authorId?: string;
  authorName?: string;
  body: string;
  categoryId?: string;
  categoryName?: string;
  // categories?: BlogCategory[];
  pictureUrl: string;
  thumbnailUrl: string;
  videoUrl: string;
  userId?: string;
  datePost?: number;
  podcastData: {
    podcastId?: string;
    podcastTitle?: string;
    episodeId?: string;
    episodeTitle?: string;
    recordUrl?: string;
    pictureUrl?: string;
  };
  createdAt: number;
  isPublic: boolean;
}

export interface BlogPostInput {
  postId?: string;
  title: string;
  authorId: string;
  body: string;
  datePost?: number;
  uid: string;
  videoUrl: string;
  categoryId: string;
  // categories?: string[];
  episodeId: string;
  isPublic: boolean;
  file?: any;
}
