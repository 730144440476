import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mapBlock: {
    position: 'relative',
    width: '100%',
    height: 650,
    // border: '1px solid #EBEBEB',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;',
  },
  map: {
    cursor: 'crosshair',
  },
  currentLocationIcon: {
    position: 'absolute',
    right: 5,
    top: 5,
    zIndex: 3000,
    color: '#333',
    fontSize: 26,
  },
}));
