import React from 'react';
import ProfilePasswordForm from './ProfilePasswordForm';
import { usePasswordEdit } from 'hooks/auth/usePasswordEdit';

const ProfilePassword = () => {
  const { answerError, editPassword } = usePasswordEdit();

  return <ProfilePasswordForm answerError={answerError} editPassword={editPassword} />;
};

export default ProfilePassword;
