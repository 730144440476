import Button from 'common/core/Button';
import { NatureTasksPageMode } from 'types/projects/nature';
import { useStyles } from './styles';

interface Props {
  pageMode: NatureTasksPageMode;
  handleAddMode: () => void;
  handleListMode: () => void;
}

const TasksActions = ({ pageMode, handleAddMode, handleListMode }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {pageMode === NatureTasksPageMode.LIST && (
        <Button onClick={handleAddMode} className={classes.button}>
          Додати завдання
        </Button>
      )}
      {(pageMode === NatureTasksPageMode.ADD || pageMode === NatureTasksPageMode.EDIT) && (
        <Button onClick={handleListMode} variant="outlined" className={classes.button}>
          Назад
        </Button>
      )}
    </div>
  );
};

export default TasksActions;
