import { useState, useEffect, useContext, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { Context } from 'context/Context';
import { useAlert } from '../useAlert';
import { Region, City } from 'types/places';

export const useProfileEdit = () => {
  const { userData, setUserData } = useContext(Context);
  const [regionsList, setRegionsList] = useState<Region[]>([]);
  const [citiesList, setCitiesList] = useState<City[]>([]);
  const [answerError, setAnswerError] = useState<string>('');
  const showAlert = useAlert();

  useEffect(() => {
    const getRegions = async () => {
      const results: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/places/get_regions',
        {},
      );
      if (results?.data) {
        setRegionsList(results.data.regions);
      }
    };

    getRegions();
  }, []);

  useEffect(() => {
    const getCities = async () => {
      const results: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/places/get_cities',
        {},
      );
      if (results?.data) {
        setCitiesList(results.data.cities);
      }
    };

    getCities();
  }, []);

  const editProfile = useCallback(
    async (firstName: string, lastName: string, regionId: string, cityId: string) => {
      const details = {
        firstName,
        lastName,
        regionId,
        cityId,
      };

      const results: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/auth/edit_profile',
        details,
      );

      if (results?.data) {
        if (results.data?.error) {
          setAnswerError(results.data.error);
        } else {
          setAnswerError('');
          const { firstName, lastName, userName, regionId, cityId } = results.data;
          const userDataNew = {
            ...userData,
            firstName,
            lastName,
            userName,
            regionId,
            cityId,
          };
          setUserData(userDataNew);
          localStorage.setItem('userData', JSON.stringify(userDataNew));
          showAlert('Ваш профіль був змінений.');
        }
      }
    },
    [userData],
  );

  return { regionsList, citiesList, editProfile, answerError };
};
