import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    // height: 30,
    backgroundColor: '#fff',
    margin: 0,
  },
}));

interface Props {
  label: string;
  defaultValue: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  inputProps?: any;
  className: string;
  margin?: 'dense' | 'none' | 'normal';
}

const DatePicker = ({
  label,
  defaultValue,
  value,
  onChange,
  fullWidth = false,
  inputProps = '',
  className = '',
  margin = 'normal',
}: Props) => {
  const classes = useStyles();

  return (
    <TextField
      id="date"
      variant="outlined"
      margin={margin}
      size="medium"
      label={label}
      type="date"
      defaultValue={defaultValue}
      fullWidth={fullWidth}
      value={value}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        ...inputProps,
        classes: { input: classnames(classes.root, className) },
      }}
      className={classnames(classes.root, className)}
    />
  );
};

export default DatePicker;
