import { useState } from 'react';
import Container from 'common/core/Container';
import PageTitle from 'common/core/PageTitle';
import TeamActions from './TeamActions';
import TeamList from './TeamList';
import TeamEdit from './TeamEdit';
import { useTeamMembers } from 'hooks/general/teamMembers/useTeamMembers';
import { useCreateTeamMember } from 'hooks/general/teamMembers/useCreateTeamMember';
import { useEditTeamMember } from 'hooks/general/teamMembers/useEditTeamMember';
import { useEditTeamMemberPicture } from 'hooks/general/teamMembers/useEditTeamMemberPicture';
import { TeamMember } from 'types/general';

const PodcastsContainer = () => {
  const [memberIdCurrent, setMemberIdCurrent] = useState<string>('');
  const { mutate: createMember } = useCreateTeamMember();
  const { mutate: editMember } = useEditTeamMember();
  const { mutate: editMemberPicture } = useEditTeamMemberPicture();
  const { data: membersList } = useTeamMembers();
  const [isOpenEditModal, setIsEditOpenModal] = useState<boolean>(false);

  const handleEditMode = (value: string) => {
    setMemberIdCurrent(value);
    setIsEditOpenModal(true);
  };

  const handleAddMode = () => {
    setMemberIdCurrent('');
    setIsEditOpenModal(true);
  };

  return (
    <>
      <Container>
        <PageTitle title="Команда проекту" />
        <TeamActions handleAddMode={handleAddMode} />
        {!!membersList && (
          <TeamList
            membersList={membersList}
            handleEditMode={handleEditMode}
            editMemberPicture={editMemberPicture}
          />
        )}
      </Container>
      {!!membersList && (
        <TeamEdit
          isOpen={isOpenEditModal}
          toggleIsOpen={() => setIsEditOpenModal(false)}
          member={membersList.find((member: TeamMember) => member.id === memberIdCurrent) ?? null}
          createMember={createMember}
          editMember={editMember}
        />
      )}
    </>
  );
};

export default PodcastsContainer;
