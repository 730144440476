import React, { useState } from 'react';
import Container from 'common/core/Container';
import PageTitle from 'common/core/PageTitle';
import PodcastsActions from './PodcastsActions';
import PodcastsList from './PodcastsList';
import PodcastsEdit from './PodcastsEdit';
// import { useShopCategories } from 'hooks/shop/useShopCategories';
import { usePodcasts } from 'hooks/podcasts/usePodcasts';
import { useCreatePodcast } from 'hooks/podcasts/useCreatePodcast';
import { useEditPodcast } from 'hooks/podcasts/useEditPodcast';
import { useEditPodcastPicture } from 'hooks/podcasts/useEditPodcastPicture';
// import { ShopCategory } from 'types/shop';
import { Podcast } from 'types/podcasts';

const PodcastsContainer = () => {
  const [podcastIdCurrent, setPodcastIdCurrent] = useState<string>('');
  //   const { createCategory, editCategory, editCategoryPicture } = useShopCategories();
  const { mutate: createPodcast } = useCreatePodcast();
  const { mutate: editPodcast } = useEditPodcast();
  const { mutate: editPodcastPicture } = useEditPodcastPicture();
  const { data: podcastsList } = usePodcasts();
  const [isOpenEditModal, setIsEditOpenModal] = useState<boolean>(false);

  const handleEditMode = (authorId: string) => {
    setPodcastIdCurrent(authorId);
    setIsEditOpenModal(true);
  };

  const handleAddMode = () => {
    setPodcastIdCurrent('');
    setIsEditOpenModal(true);
  };

  return (
    <>
      <Container>
        <PageTitle title="Категорії магазину" />
        <PodcastsActions handleAddMode={handleAddMode} />
        {!!podcastsList && (
          <PodcastsList
            podcastsList={podcastsList}
            handleEditMode={handleEditMode}
            editPodcastPicture={editPodcastPicture}
          />
        )}
      </Container>
      {!!podcastsList && (
        <PodcastsEdit
          isOpen={isOpenEditModal}
          toggleIsOpen={() => setIsEditOpenModal(false)}
          podcast={podcastsList.find((podcast: Podcast) => podcast.id === podcastIdCurrent) ?? null}
          createPodcast={createPodcast}
          editPodcast={editPodcast}
        />
      )}
    </>
  );
};

export default PodcastsContainer;
