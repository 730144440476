import React from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import Button from 'common/core/Button';
import { useAuthors } from 'hooks/projects/poetry/useAuthors';
import { useStyles } from './styles';

interface Props {
  handleAddMode: () => void;
  authorIdCurrent: string;
  handleAuthorIdCurrent: (value: string) => void;
}

const PoetryActions = ({ handleAddMode, authorIdCurrent, handleAuthorIdCurrent }: Props) => {
  const { authorsList } = useAuthors();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.filterBlock}>
        {!!authorsList?.length && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Автор</InputLabel>
            <Select
              label="Автор"
              native
              onChange={(e: any) =>
                handleAuthorIdCurrent((e.target.value === 'all' ? '' : e.target.value) as string)
              }
              value={authorIdCurrent === '' ? 'all' : authorIdCurrent}
              className={classes.select}
            >
              <option value="all" key="all">
                Всі автори
              </option>
              {authorsList.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <div className={classes.addBlock}>
        <Button onClick={handleAddMode} className={classes.addButton}>
          Додати вірш
        </Button>
      </div>
    </div>
  );
};

export default PoetryActions;
