import React, { useContext, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Typography, IconButton } from '@material-ui/core';
import ProfileIcon from '@material-ui/icons/AccountCircle';
import ProfileMenuIcon from '@material-ui/icons/AssignmentIndTwoTone';
import LogoutMenuIcon from '@material-ui/icons/MeetingRoomTwoTone';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import { useLogout } from 'hooks/auth/useLogout';
import { Context } from 'context/Context';
import { useStyles } from './styles';
import HeaderButton from './HeaderButton';

const Header = () => {
  const { isUserAuthed } = useContext(Context);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const location = useLocation();
  const history = useHistory();
  const appPath = location.pathname;
  const pagePath = location.pathname.split('/');
  const { logout } = useLogout();
  const classes = useStyles();

  const handleClickProfile = (e: React.MouseEvent<HTMLElement>) => {
    if (isUserAuthed) {
      toggleMenu(e);
    } else {
      history.push('/signin');
    }
  };

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleMyProfile = () => {
    setAnchorEl(null);
    history.push('/profile/edit');
  };

  const handleLogout = () => {
    logout();
    setAnchorEl(null);
    history.push('/');
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.titleBlock}>
          <Typography className={classes.titleText} noWrap onClick={() => history.push('/')}>
            Ivandry Admin
          </Typography>
        </div>
        <div className={classes.menuBlock}>
          <div className={classes.sectionDesktop}>
            {isUserAuthed && (
              <>
                <HeaderButton title="Завдання" route="/tasks" routesList={['/tasks']} />
                <HeaderButton title="Блог" route="/blog" routesList={['/blog', '/post/[uid]']} />
                <HeaderButton title="Застосунки" route="/projects" routesList={['/projects']} />
                <HeaderButton
                  title="Магазин"
                  route="/shop"
                  routesList={['/shop', '/shop/products', '/shop/products/[uid]']}
                />
                {/* <HeaderButton
                  title="Користувачі"
                  route="/people"
                  routesList={['/people', '/users', '/user/[uid]', '/email-templates']}
                /> */}
                <HeaderButton
                  title="Локації"
                  route="/locations"
                  routesList={['/locations', '/routes', '/route/[uid]', '/places', '/place/[uid]']}
                />

                <HeaderButton
                  title="Інше"
                  route="/other"
                  routesList={['/people', '/users', '/user/[uid]', '/email-templates']}
                />

                <IconButton onClick={handleClickProfile} color="inherit">
                  <ProfileIcon
                    className={classnames(
                      classes.menuIcon,
                      ['/profile'].includes(appPath) && classes.menuLinkActive,
                    )}
                  />
                </IconButton>
              </>
            )}
          </div>
          <div className={classes.sectionMobile}>
            {isUserAuthed && (
              <>
                <IconButton onClick={handleClickProfile} color="inherit">
                  <ProfileIcon
                    className={classnames(
                      classes.menuIcon,
                      appPath === '/profile' || pagePath[1] === 'profile'
                        ? classes.menuIconActive
                        : '',
                    )}
                  />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </div>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <MenuItem onClick={handleMyProfile} key="1" className={classes.subMenu}>
          Мій профайл
          <ProfileMenuIcon className={classes.submenuIcon} />
        </MenuItem>
        <MenuItem onClick={handleLogout} key="10" className={classes.subMenu}>
          Вихід
          <LogoutMenuIcon className={classes.submenuIcon} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default Header;
