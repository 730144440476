import React, { useEffect, useState } from 'react';
import Table from 'common/core/Table';
import EmailTemplatesListItem from './EmailTemplatesListItem';
import Pagination from 'common/core/Pagination';
import { EmailTemplate } from 'types/general';
import { useStyles } from './styles';

interface Props {
  templatesList: EmailTemplate[];
  handleEditMode: (templateId: string) => void;
  testTemplate: (templateId: string) => void;
  deleteTemplate: (templateId: string) => void;
}

const NUM_VALUES_ON_PAGE = 40;

const EmailTemplatesList = ({
  templatesList,
  handleEditMode,
  testTemplate,
  deleteTemplate,
}: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (templatesList.length) {
      const pageCountNew = Math.ceil(templatesList.length / NUM_VALUES_ON_PAGE);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [templatesList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Table>
        {templatesList
          .filter(
            (_: EmailTemplate, index: number) =>
              index >= (page - 1) * NUM_VALUES_ON_PAGE && index < page * NUM_VALUES_ON_PAGE,
          )
          .map((template: EmailTemplate) => (
            <EmailTemplatesListItem
              key={template.id}
              template={template}
              handleEditMode={handleEditMode}
              testTemplate={testTemplate}
              deleteTemplate={deleteTemplate}
            />
          ))}
      </Table>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default EmailTemplatesList;
