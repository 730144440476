export enum ProjectsPageMode {
  LIST = 'list',
  ADD = 'add',
  EDIT = 'edit',
  EDIT_GALLERY = 'edit_gallery',
}

export interface Project {
  id: string;
  name: string;
  descriptionBrief?: string;
  description: string;
  rules?: string;
  audience: string;
  path?: string;
  url: string;
  pictureUrl?: string;
  isPublic: boolean;
  isTesting?: boolean;
  skills: string[];
  categories: string[];
  linksData: ApplicationLinksData;
  versionData: ApplicationVersionData;
  pictures: string[];
  order?: number;
}

export interface ApplicationLinksData {
  appStoreUrl?: string;
  googlePlayUrl?: string;
  webUrl?: string;
}

export interface ApplicationVersionData {
  versionAndroid?: number;
  versionIos?: number;
}

export interface ProjectInput {
  projectId?: string;
  name: string;
  description: string;
  rules: string;
  audience: string;
  isPublic: boolean;
  isTesting?: boolean;
  skills: string[];
  linksData: ApplicationLinksData;
  versionData: ApplicationVersionData;
}
