import React, { useState } from 'react';
import Container from 'common/core/Container';
import PageTitle from 'common/core/PageTitle';
import AlphabetWordsActions from './AlphabetWordsActions';
import AlphabetWordsList from './AlphabetWordsList';
import AlphabetWordsEdit from './AlphabetWordsEdit';
import { useWords } from 'hooks/projects/alphabet/useWords';
import { AlphabetWord } from 'types/projects/alphabet';

const AlphabetWordsContainer = () => {
  const [wordIdCurrent, setWordIdCurrent] = useState<string>('');
  const { wordsList, createWord, editWord, editWordPicture, deleteWord } = useWords();
  const [isOpenEditModal, setIsEditOpenModal] = useState<boolean>(false);

  const handleEditMode = (wordId: string) => {
    setWordIdCurrent(wordId);
    setIsEditOpenModal(true);
  };

  const handleAddMode = () => {
    setWordIdCurrent('');
    setIsEditOpenModal(true);
  };

  return (
    <>
      <Container>
        <PageTitle title="Alphabet Words : Слова" />
        <AlphabetWordsActions handleAddMode={handleAddMode} />
        <AlphabetWordsList
          wordsList={wordsList}
          handleEditMode={handleEditMode}
          editWordPicture={editWordPicture}
          deleteWord={deleteWord}
        />
      </Container>
      <AlphabetWordsEdit
        isOpen={isOpenEditModal}
        toggleIsOpen={() => setIsEditOpenModal(false)}
        item={wordsList.find((item: AlphabetWord) => item.id === wordIdCurrent) ?? null}
        createWord={createWord}
        editWord={editWord}
      />
    </>
  );
};

export default AlphabetWordsContainer;
