import { Grid } from '@material-ui/core';
import TeamListItem from './TeamListItem';
import { TeamMember } from 'types/general';

interface Props {
  membersList: TeamMember[];
  handleEditMode: (memberId: string) => void;
  editMemberPicture: (details: { memberId: string; file: any }) => void;
}

const TeamList = ({ membersList, handleEditMode, editMemberPicture }: Props) => {
  return (
    <Grid container spacing={1}>
      {membersList.map((member: TeamMember) => (
        <TeamListItem
          key={member.id}
          member={member}
          handleEditMode={handleEditMode}
          editMemberPicture={editMemberPicture}
        />
      ))}
    </Grid>
  );
};

export default TeamList;
