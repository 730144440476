import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import {
  PoetryWalksAuthor,
  PoetryWalksAuthorInputCreate,
  PoetryWalksAuthorInputEdit,
} from 'types/projects/poetry';

export const useAuthors = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authorsList, setAuthorsList] = useState<PoetryWalksAuthor[]>([]);

  useEffect(() => {
    getAuthors();
  }, []);

  const getAuthors = async () => {
    setIsLoading(true);
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/poetry/get_admin_authors',
      {},
    );
    if (results?.data?.authors) {
      setAuthorsList(results?.data?.authors);
    }
    setIsLoading(false);
  };

  const createAuthor = useCallback(async (details: PoetryWalksAuthorInputCreate) => {
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/poetry/create_author', details);
    getAuthors();
  }, []);

  const editAuthor = useCallback(async (details: PoetryWalksAuthorInputEdit) => {
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/poetry/edit_author', details);
    getAuthors();
  }, []);

  const editAuthorPicture = useCallback(async (authorId: string, file: any) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('authorId', authorId);

    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/poetry/edit_author_picture',
      formData,
    );
    await new Promise((resolve) => {
      return setTimeout(resolve, 2500);
    });
    getAuthors();
  }, []);

  return {
    isLoading,
    authorsList,
    createAuthor,
    editAuthor,
    editAuthorPicture,
  };
};
