import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Typography, IconButton, Grid } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  EditTwoTone as EditIcon,
  DeleteTwoTone as DeleteIcon,
  TimelineTwoTone as RouteIcon,
} from '@material-ui/icons';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import LocationBigIcon from 'assets/icons/location3.svg';
import { Place } from 'types/places';
import { useStyles } from './styles';

interface Props {
  place: Place;
  isLastItem: boolean;
  handleEditMode: (placeId: string) => void;
  handleAreaMode: (placeId: string) => void;
  deletePlace: (placeId: string) => void;
}

const PlacesListItem = ({
  place,
  isLastItem,
  handleEditMode,
  handleAreaMode,
  deletePlace,
}: Props) => {
  const { id: placeId, name, position, location, pictureUrl } = place;
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const pictureRef = useRef<any>();
  const fileRef = useRef<any>();
  const history = useHistory();
  const classes = useStyles();

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEditAction = () => {
    handleEditMode(placeId);
    setAnchorEl(null);
  };

  const handleAreaAction = () => {
    handleAreaMode(placeId);
    setAnchorEl(null);
  };

  const handleDeleteAction = () => {
    toggleOpenConfirmModal();
    setAnchorEl(null);
  };

  const toggleOpenConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  const handleDeleteApproved = () => {
    deletePlace(placeId);
    toggleOpenConfirmModal();
  };

  return (
    <>
      <Grid item md={3} sm={6} xs={12}>
        <div className={classes.container}>
          <div
            className={pictureUrl ? classes.pictureBlock : classes.pictureEmptyBlock}
            onClick={() => history.push(`/places/place/${placeId}`)}
          >
            <img
              src={pictureUrl ? pictureUrl : LocationBigIcon}
              className={pictureUrl ? classes.picture : classes.imgEmptyIcon}
              alt=""
            />
          </div>
          <div className={classes.infoBlock}>
            <div className={classes.leftBlock}>
              <div className={classes.titleBlock}>
                <Typography className={classes.titleText}>{name}</Typography>
              </div>
              {!!location && (
                <div className={classes.locationBlock}>
                  <Typography className={classes.infoText}>{location}</Typography>
                </div>
              )}
            </div>
            <div className={classes.actionsBlock}>
              <IconButton onClick={toggleMenu} className={classes.menuButton}>
                <MoreVertIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </Grid>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <MenuItem onClick={handleEditAction} className={classes.subMenu} key="1">
          Редагувати
          <EditIcon />
        </MenuItem>
        <MenuItem onClick={handleAreaAction} className={classes.subMenu} key="1">
          Набір точок
          <RouteIcon />
        </MenuItem>
        <MenuItem
          onClick={handleDeleteAction}
          className={classnames(classes.subMenu, classes.subMenuDelete)}
          key="2"
        >
          Видалити
          <DeleteIcon />
        </MenuItem>
      </Menu>
      <ModalConfirm
        title="Дійсно хочете видалити?"
        note="Ви не зможете повернути назад."
        isOpenConfirmModal={isOpenConfirmModal}
        toggleOpenConfirmModal={toggleOpenConfirmModal}
        handleSumbit={handleDeleteApproved}
      />
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={() => {}}
        multiple
        accept="image/*"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default PlacesListItem;
