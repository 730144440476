import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import InfoBox from 'common/core/InfoBox';
import BlogListItem from './BlogListItem';
import Pagination from 'common/core/Pagination';
import { BlogPost } from 'types/blog';
import { useStyles } from './styles';

const NUM_VALUES_ON_PAGE = 15;

interface Props {
  isLoadingPosts: boolean;
  postsList: BlogPost[];
  handleEditMode: (placeId: string) => void;
  deletePost: (placeId: string) => void;
}

const BlogList = ({ isLoadingPosts, postsList, handleEditMode, deletePost }: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (postsList.length) {
      const pageCountNew = Math.ceil(postsList.length / NUM_VALUES_ON_PAGE);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [postsList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Grid container spacing={1}>
        {!postsList.length && !isLoadingPosts && (
          <InfoBox label="Ви поки що не створили жодного поста." className={classes.infoBox} />
        )}
        {postsList
          .filter(
            (_: BlogPost, index: number) =>
              index >= (page - 1) * NUM_VALUES_ON_PAGE && index < page * NUM_VALUES_ON_PAGE,
          )
          .map((post: BlogPost, index: number) => (
            <BlogListItem
              key={post.id}
              post={post}
              handleEditMode={handleEditMode}
              deletePost={deletePost}
            />
          ))}
      </Grid>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default BlogList;
