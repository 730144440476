export interface AreaInput {
  name: string;
  description: string;
  pointsList: RoutePoint[];
  location?: string;
  isPublic: boolean;
}
// export interface AreaAddInput extends AreaInput {}
// export interface AreaEditInput extends AreaInput {}

export enum PlacesPageMode {
  LIST = 'list',
  ADD = 'add',
  EDIT = 'edit',
  AREA = 'area',
}

export interface Place {
  id: string;
  categoryId?: string;
  categoryName?: string;
  regionId?: string;
  regionName?: string;
  cityId?: string;
  cityName?: string;
  name: string;
  description?: string;
  pictureUrl?: string;
  position: [number, number];
  latitude?: number;
  longitude?: number;
  category?: string;
  location?: string;
  userId?: string;
  userName?: string;
  createdAt: number;
  isActive?: boolean;
}

export interface PlaceCategory {
  id: string;
  name: string;
}

export interface Region {
  id: string;
  name: string;
}

export interface City {
  id: string;
  regionId: string;
  name: string;
  cityNameWithRegion?: string;
  latitude?: number;
  longitude?: number;
}

export enum RoutesPageMode {
  LIST = 'list',
  LIST_PUBLIC = 'list_public',
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
}

export interface Route {
  id: string;
  categoryId?: string;
  regionId?: string;
  cityId?: string;
  placeId?: string;
  name: string;
  description?: string;
  // points: RoutePoint[];
  points: string;
  latitude?: number;
  longitude?: number;
  numPoints?: number;
  distance: number;
  location?: string;
  userId?: string;
  userName?: string;
  pictureUrl?: string;
  createdAt: number;
  isLoopRoute: boolean;
  isRecommended?: boolean;
  isPublic?: boolean;
  rating: number;
  numRatingVotes: number;
  isRatingVoted: boolean;
  numComments: number;
  numLoves?: number;
  isRouteLoved?: boolean;
}

export interface RoutePathCoords {
  position1: [number, number];
  position2: [number, number];
}

export interface RoutePoint {
  id: string;
  latitude: number;
  longitude: number;
}

export interface RouteEditInput {
  routeId: string;
  name: string;
  isRecommended: boolean;
  isPublic: boolean;
}
