import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import PodcastsListItem from './PodcastsListItem';
import Pagination from 'common/core/Pagination';
import { numValuesOnPage } from 'config';
import { PoetryWalksAuthor } from 'types/projects/poetry';
import { Podcast } from 'types/podcasts';
import { useStyles } from './styles';

interface Props {
  podcastsList: Podcast[];
  handleEditMode: (categoryId: string) => void;
  editPodcastPicture: (details: { podcastId: string; file: any }) => void;
}

const PodcastsList = ({ podcastsList, handleEditMode, editPodcastPicture }: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (podcastsList.length) {
      const pageCountNew = Math.ceil(podcastsList.length / numValuesOnPage);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [podcastsList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Grid container spacing={1}>
        {podcastsList
          .filter(
            (_: Podcast, index: number) =>
              index >= (page - 1) * numValuesOnPage && index < page * numValuesOnPage,
          )
          .map((podcast: Podcast) => (
            <PodcastsListItem
              key={podcast.id}
              podcast={podcast}
              handleEditMode={handleEditMode}
              editPodcastPicture={editPodcastPicture}
            />
          ))}
      </Grid>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default PodcastsList;
