import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { EmailTemplate } from 'types/general';
import { useAlert } from '../useAlert';

export const useEmailTemplates = () => {
  const [templatesList, setTemplatesList] = useState<EmailTemplate[]>([]);
  const showAlert = useAlert();

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = useCallback(async () => {
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/general/get_email_templates',
      {},
    );
    if (results?.data) {
      setTemplatesList(results.data?.templates);
    }
  }, []);

  const createTemplate = useCallback(
    async (name: string, label: string, subject: string, body: string) => {
      const details = {
        name,
        label,
        subject,
        body,
      };
      await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/general/create_email_template',
        details,
      );
      showAlert('Новий шаблон було створено');
      getTemplates();
    },
    [],
  );

  const editTemplate = useCallback(
    async (templateId: string, name: string, label: string, subject: string, body: string) => {
      const details = {
        templateId,
        name,
        label,
        subject,
        body,
      };
      await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/general/edit_email_template',
        details,
      );
      showAlert('Шаблон було змінено');
      getTemplates();
    },
    [],
  );

  const testTemplate = useCallback(async (templateId: string) => {
    const details = { templateId };
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/general/test_email_template',
      details,
    );
    getTemplates();
    showAlert('Тестовий лист висланий');
  }, []);

  const deleteTemplate = useCallback(async (templateId: string) => {
    const details = { templateId };
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/general/delete_email_template',
      details,
    );
    getTemplates();
    showAlert('Шаблон був виделений');
  }, []);

  return {
    templatesList,
    createTemplate,
    editTemplate,
    testTemplate,
    deleteTemplate,
  };
};
