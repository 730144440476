import React, { useState, useEffect } from 'react';
import {} from '@material-ui/core';
import Map from 'common/core/Map';
import MapMarker from 'common/core/Map/MapMarker';
import { useStyles } from './styles';

const PlaceMap = ({ place }: any) => {
  // const { latitude, longitude } = place;
  const [position, setPosition] = useState<[number, number]>([0, 0]);
  const classes = useStyles();

  useEffect(() => {
    if (place?.latitude && place?.longitude) {
      setPosition([place?.latitude, place?.longitude]);
    }
  }, [place]);

  return (
    <div>
      <div className={classes.mapBlock}>
        <Map center={position} zoom={15}>
          <MapMarker position={position} />
        </Map>
      </div>
    </div>
  );
};

export default PlaceMap;
