import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 5px 20px rgba(23, 33, 48, 0.09)',
    borderRadius: 12,
    padding: 10,
    paddingLeft: 12,
    marginBottom: 20,
  },
  titleBlock: {
    display: 'flex',
    marginBottom: 10,
  },
  errorBlock: {
    display: 'flex',
    marginBottom: 10,
  },
  inputBlock: {
    display: 'flex',
    marginBottom: 15,
  },
  mapBlock: {
    position: 'relative',
    width: '100%',
    height: 450,
  },
  buttonBlock: {
    display: 'flex',
    marginTop: 20,
  },
  titleText: {
    fontSize: 18,
    fontWeight: 450,
  },
  errorText: {
    fontSize: 16,
    fontWeight: 300,
    color: '#ff0000',
  },
  select: {
    width: 315,
  },
  selectHelper: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    color: '#000',
    paddingBottom: 8,
  },
  input: {
    width: 450,
  },
  inputTextarea: {
    width: 315,
    height: 100,
  },
}));
