import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import StoryFramesListItem from './StoryFramesListItem';
import InfoBox from 'common/core/InfoBox';
import { StoryFrame } from 'types/projects/tvorytelling';
import { useStyles } from './styles';

interface Props {
  actionMode: string;
  framesList: StoryFrame[];
  frameIdCurrent: string;
  handleStartPlaying: (frameId: string) => void;
  handleStopPlaying: (frameId: string) => void;
}

const StoryFramesList = ({
  actionMode,
  framesList,
  frameIdCurrent,
  handleStartPlaying,
  handleStopPlaying,
}: Props) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={1}>
        {!framesList.length && (
          <InfoBox label="У цій історії поки що немає жодного кадру." className={classes.infoBox} />
        )}
        {framesList.map((frame: StoryFrame, index: number) => (
          <StoryFramesListItem
            key={frame.id}
            index={index}
            actionMode={actionMode}
            frame={frame}
            frameIdCurrent={frameIdCurrent}
            handleStartPlaying={handleStartPlaying}
            handleStopPlaying={handleStopPlaying}
          />
        ))}
      </Grid>
    </>
  );
};

export default StoryFramesList;
