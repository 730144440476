import React, { useState, useEffect, useRef } from 'react';
import { Map as MapContainer, TileLayer } from 'react-leaflet';
import { LatLngBounds, LeafletMouseEvent } from 'leaflet';
import L, { LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import PlacesAreaMapMarker from './PlacesAreaMapMarker';
import { RoutePoint } from 'types/places';
import { useStyles } from './styles';

interface Props {
  placePosition: [number, number];
  pointsList: RoutePoint[];
  addPointer: (point: LatLng) => void;
  deletePointer: (pointId: string) => void;
}

const PlacesAreaMap = ({ placePosition, pointsList, addPointer, deletePointer }: Props) => {
  const [isPointsListConsidered, setIsPointsListConsidered] = useState<boolean>(false);
  const [bounds, setBounds] = useState<LatLngBounds>();
  const [zoom, setZoom] = useState<number>(5);
  const mapRef = useRef<any>();
  const classes = useStyles();

  useEffect(() => {
    // if (pointsList.length && !isPointsListConsidered) {
    //   let boundsList: any[] = [];
    //   pointsList.map((point: any) => boundsList.push([point.latitude, point.longitude]));
    //   if (boundsList.length) {
    //     const bounds = L.latLngBounds(boundsList);
    //     setBounds(bounds);
    //     setIsPointsListConsidered(true);
    //   }
    // }
    if (placePosition) {
      const map = mapRef.current.leafletElement;
      map.setView(placePosition, 18);
    }
  }, [placePosition]);

  const handleAddPointer = (e: LeafletMouseEvent) => {
    addPointer(e.latlng);
  };

  const handleDeletePointer = (e: React.SyntheticEvent, pointId: string) => {
    deletePointer(pointId);
  };

  return (
    <div className={classes.mapBlock}>
      <MapContainer
        ref={mapRef}
        center={placePosition}
        // bounds={bounds}
        zoom={zoom}
        maxZoom={19}
        attributionControl={false}
        zoomControl={true}
        // doubleClickZoom={true}
        // scrollWheelZoom={true}
        dragging={true}
        // animate={true}
        easeLinearity={0.35}
        onClick={handleAddPointer}
        className={classes.map}
      >
        <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
        {pointsList.map((point: RoutePoint, index: number) => (
          <PlacesAreaMapMarker
            key={point.id}
            position={[point.latitude, point.longitude]}
            handleClick={(e: React.SyntheticEvent) => handleDeletePointer(e, point.id)}
            isStart={index === 0}
          />
        ))}
      </MapContainer>
    </div>
  );
};

export default PlacesAreaMap;
