import { Grid } from '@material-ui/core';
import StoreIcon from '@material-ui/icons/Store';
import CategoryIcon from '@material-ui/icons/Category';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Container from 'common/core/Container';
import PageTitle from 'common/core/PageTitle';
import MenuMainItem from 'common/core/MenuMainItem';

const pagesList = [
  {
    id: 'shop-products',
    title: 'Всі товари',
    url: '/shop/products',
    icon: <StoreIcon />,
  },
  {
    id: 'shop-categories',
    title: 'Категорії товарів',
    url: '/shop/categories',
    icon: <CategoryIcon />,
  },
  {
    id: 'shop-orders',
    title: 'Всі замовлення',
    url: '/shop/orders',
    icon: <ShoppingCartIcon />,
  },
];

const ShopMainContainer = () => {
  return (
    <Container>
      <PageTitle title="Магазин" />
      <Grid container spacing={2}>
        {pagesList.map(({ id, title, url, icon }) => (
          <MenuMainItem key={id} title={title} url={url}>
            {icon}
          </MenuMainItem>
        ))}
      </Grid>
    </Container>
  );
};

export default ShopMainContainer;
