import React from 'react';
import { Typography } from '@material-ui/core';
import Button from 'common/core/Button';
import { BlogPageMode } from 'types/blog';
import { useStyles } from './styles';

interface Props {
  pageMode: string;
  handlePageMode: (value: string) => void;
}

const BlogHeader = ({ pageMode, handlePageMode }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.titleText}>
        Блог
        {pageMode === BlogPageMode.ADD && <>: новий пост</>}
        {pageMode === BlogPageMode.EDIT && <>: редагування поста</>}
      </Typography>
      {pageMode === BlogPageMode.LIST ? (
        <Button onClick={() => handlePageMode(BlogPageMode.ADD)} className={classes.button}>
          Додати пост
        </Button>
      ) : (
        <Button
          onClick={() => handlePageMode(BlogPageMode.LIST)}
          variant="outlined"
          className={classes.button}
        >
          Назад
        </Button>
      )}
    </div>
  );
};

export default BlogHeader;
