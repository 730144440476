import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import ProductsHeader from './ProductsHeader';
import ProductsList from './ProductsList';
import ProductsEdit from './ProductsEdit';
import { useShopProducts } from 'hooks/shop/useShopProducts';
import { ShopProductsPageMode, ShopProduct, ShopProductInput } from 'types/shop';
import { useStyles } from './styles';

const ProductsContainer = () => {
  const [pageMode, setPageMode] = useState<string>(ShopProductsPageMode.LIST);
  const [productIdCurrent, setProductIdCurrent] = useState<string>('');
  const {
    isLoading: isLoadingProducts,
    categoriesList,
    skillsList,
    productsList,
    createProduct,
    editProduct,
    editProductPicture,
    createGalleryPicture,
    deleteGalleryPicture,
    deleteProduct,
  } = useShopProducts();
  const classes = useStyles();

  const handleCreateProduct = async (details: ShopProductInput) => {
    const productId = await createProduct(details);
    if (productId) {
      setProductIdCurrent(productId);
      setPageMode(ShopProductsPageMode.EDIT);
    }
  };

  const handlePageMode = (value: string) => {
    setPageMode(value);
  };

  useEffect(() => {
    if (pageMode !== ShopProductsPageMode.EDIT && pageMode !== ShopProductsPageMode.VIEW) {
      setProductIdCurrent('');
    }
  }, [pageMode]);

  const handleEditMode = (productId: string) => {
    setProductIdCurrent(productId);
    setPageMode(ShopProductsPageMode.EDIT);
  };

  const handleViewMode = (productId: string) => {
    setProductIdCurrent(productId);
    setPageMode(ShopProductsPageMode.VIEW);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <ProductsHeader pageMode={pageMode} handlePageMode={handlePageMode} />
      {pageMode === ShopProductsPageMode.LIST && (
        <ProductsList
          isLoadingProducts={isLoadingProducts}
          productsList={productsList}
          handleEditMode={handleEditMode}
          handleViewMode={handleViewMode}
          deleteProduct={deleteProduct}
        />
      )}
      {(pageMode === ShopProductsPageMode.EDIT || pageMode === ShopProductsPageMode.ADD) && (
        <ProductsEdit
          product={
            productsList.find((product: ShopProduct) => product.id === productIdCurrent) ?? null
          }
          categoriesList={categoriesList}
          skillsList={skillsList}
          createProduct={handleCreateProduct}
          editProduct={editProduct}
          editProductPicture={editProductPicture}
          createGalleryPicture={createGalleryPicture}
          deleteGalleryPicture={deleteGalleryPicture}
          handlePageMode={handlePageMode}
        />
      )}
    </Container>
  );
};

export default ProductsContainer;
