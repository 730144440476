import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// @ts-ignore
import { RecordRTCPromisesHandler } from 'recordrtc';
import { Container } from '@material-ui/core';
import MyStoryHeader from './MyStoryHeader';
import MyStoryFramesList from './MyStoryFramesList';
import { useMyStory } from 'hooks/projects/tvorytelling/useMyStory';
import { StoryPageMode, StoryActionMode } from 'types/projects/tvorytelling';
import { useStyles } from './styles';

const MyStoryContainer = () => {
  const [pageMode, setPageMode] = useState<string>(StoryPageMode.LIST_CREATE);
  const [actionMode, setActionMode] = useState<string>(StoryActionMode.WAITING);
  const [isModeRecordingStarted, setIsModeRecordingStarted] = useState<boolean>(false);
  const [frameIdCurrent, setFrameIdCurrent] = useState<string>('');
  const location = useLocation();
  const pagePath = location.pathname.split('/');
  const storyId = pagePath[3] ?? '';
  const [audioInputList, setAudioInputList] = useState<any[]>([]);
  const [audioInputChosen, setAudioInputChosen] = useState<string>('');
  const {
    storyData,
    framesList,
    createFrame,
    editFrame,
    deleteFrame,
    reorderFrames,
    createRecord,
    deleteRecord,
  } = useMyStory(storyId);
  const recorder = useRef<any>(null);
  const recordRef = useRef<any>(null);
  const audioBlob = useRef<any>(null);
  const classes = useStyles();

  useEffect(() => {
    setActionMode(StoryActionMode.WAITING);
  }, [pageMode]);

  // useEffect(() => {
  //   setPageMode(framesList.length ? StoryPageMode.LIST_VIEW : StoryPageMode.LIST_CREATE);
  // }, [framesList]);

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const audioInputListNew = devices
          .filter((device) => device.kind === 'audioinput')
          .map((device) => ({ deviceId: device.deviceId, label: device.label }));
        setAudioInputList(audioInputListNew);
        setAudioInputChosen(audioInputListNew[0].deviceId);
        // console.log(audioInputListNew);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    if (!isModeRecordingStarted && audioInputChosen && pageMode === StoryPageMode.LIST_CREATE) {
      setIsModeRecordingStarted(true);
      showAudioStream(audioInputChosen);
    }
    return () => {
      if (isModeRecordingStarted) {
        recorder.current.reset();
        recorder.current.destroy();
      }
    };
  }, [isModeRecordingStarted, audioInputChosen, pageMode]);

  const showAudioStream = async (audioDeviceId: string) => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: { deviceId: audioDeviceId },
      // audio: true,
    });
    recorder.current = new RecordRTCPromisesHandler(stream, {
      type: 'audio',
      mimeType: 'audio/webm',
      disableLogs: true,
    });
    recordRef.current.muted = true;
    recordRef.current.volume = 0;
    recordRef.current.srcObject = stream;
  };

  const handleStartRecording = (frameId: string) => {
    setActionMode(StoryActionMode.RECORDING);
    setFrameIdCurrent(frameId);
    recorder.current.startRecording();
  };

  const handleStopRecording = async (frameId: string) => {
    setActionMode(StoryActionMode.WAITING);
    setFrameIdCurrent('');
    await recorder.current.stopRecording();
    audioBlob.current = await recorder.current.getBlob();
    await createRecord(frameId, audioBlob.current);
  };

  const handleStartPlaying = (frameId: string) => {
    setActionMode(StoryActionMode.PLAYING);
    setFrameIdCurrent(frameId);
  };

  const handleStopPlaying = (frameId: string) => {
    setActionMode(StoryActionMode.WAITING);
    setFrameIdCurrent('');
  };

  const handleStartEditing = (frameId: string) => {
    setActionMode(StoryActionMode.EDITING);
    setFrameIdCurrent(frameId);
  };

  const handleStopEditing = async (frameId: string, titleValue: string) => {
    editFrame(frameId, titleValue);
    setActionMode(StoryActionMode.WAITING);
    setFrameIdCurrent('');
  };

  const handlePageMode = (value: string) => {
    setPageMode(value);
  };

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <MyStoryHeader
          story={storyData}
          pageMode={pageMode}
          handlePageMode={handlePageMode}
          createFrame={createFrame}
        />
        <MyStoryFramesList
          pageMode={pageMode}
          actionMode={actionMode}
          framesList={framesList}
          frameIdCurrent={frameIdCurrent}
          deleteFrame={deleteFrame}
          deleteRecord={deleteRecord}
          reorderFrames={reorderFrames}
          handleStartRecording={handleStartRecording}
          handleStopRecording={handleStopRecording}
          handleStartPlaying={handleStartPlaying}
          handleStopPlaying={handleStopPlaying}
          handleStartEditing={handleStartEditing}
          handleStopEditing={handleStopEditing}
        />
      </Container>
      <audio ref={recordRef} style={{ display: 'none' }} />
    </>
  );
};

export default MyStoryContainer;
