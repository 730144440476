import React from 'react';
import { Pagination as PaginationOriginal } from '@material-ui/lab';
import { useStyles } from './styles';

interface Props {
  count: number;
  page: number;
  onChange: (event: React.ChangeEvent<any>, pageNew: number) => void;
}

const Pagination = ({ count, page, onChange }: Props) => {
  const classes = useStyles();

  return (
    <PaginationOriginal count={count} page={page} onChange={onChange} className={classes.root} />
  );
};

export default Pagination;
