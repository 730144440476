import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';

export const usePostUrlCheck = (id: string) => {
  const checkUrl = useCallback(
    async (uid: string) => {
      const details = { id, uid };
      const results: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/blog/check_post_url',
        details,
      );

      if (results?.data?.isChecked) {
        return results?.data?.isUnique;
      }

      return false;
    },
    [id],
  );

  return {
    checkUrl,
  };
};
