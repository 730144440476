import React from 'react';
import { Typography } from '@material-ui/core';
import Button from 'common/core/Button';
import { PlacesPageMode } from 'types/places';
import { useStyles } from './styles';

interface Props {
  handleEditArea: () => void;
}

const PlacesAreaAction = ({ handleEditArea }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button
        onClick={handleEditArea}
        // disabled={numPoints < 2}
        // className={classes.buttonSubmit}
      >
        Зберегти
      </Button>
    </div>
  );
};

export default PlacesAreaAction;
