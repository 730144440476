import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useLoadScript } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Typography, TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/CloseTwoTone';
import { useStyles } from './styles';

interface Props {
  valueInitial: string;
  handleChangeCity: (cityName: string, position: [number, number] | null) => void;
  className?: string;
}

const PlaceAutocomplete = ({ valueInitial, handleChangeCity, className = '' }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_TOKEN as string,
    libraries: ['places'],
  });
  const classes = useStyles();

  useEffect(() => {
    setSearchValue(valueInitial);
  }, [valueInitial]);

  const handleSelect = async (value: string) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    handleChangeCity(value, [latLng.lat, latLng.lng]);
  };

  const handleClear = () => {
    handleChangeCity('', null);
    setSearchValue('');
  };

  return (
    <>
      {isLoaded && (
        <div className={classes.container}>
          <div className={classes.autocompleteBlock}>
            <PlacesAutocomplete
              value={searchValue}
              onChange={setSearchValue}
              onSelect={handleSelect}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                return (
                  <>
                    <div>
                      <TextField
                        {...getInputProps()}
                        autoComplete="off"
                        placeholder="Шукати місце"
                        variant="outlined"
                        type="text"
                        className={classnames(
                          classes.input,
                          className,
                          // valueInitial && classes.inputChosen,
                        )}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {searchValue !== '' ? (
                                <CloseIcon onClick={handleClear} className={classes.inputIcon} />
                              ) : (
                                <SearchIcon className={classes.inputIcon} />
                              )}
                            </InputAdornment>
                          ),
                          className: classnames(classes.input, className),
                          classes: {
                            root: classnames(classes.input, className),
                            focused: classes.inputFocused,
                            notchedOutline: classes.inputNotchedOutline,
                          },
                        }}
                        FormHelperTextProps={{ className: classes.inputHelper }}
                      />
                      {/* {loading && <div>Loading...</div>} */}
                      {!!suggestions?.length && (
                        <div className={classes.selectBlock}>
                          {suggestions.map((suggestion, index) => (
                            <div
                              {...getSuggestionItemProps(suggestion)}
                              key={suggestion.placeId}
                              className={classnames(
                                classes.selectElementBlock,
                                index + 1 !== suggestions.length && classes.bottomBorderedBlock,
                              )}
                            >
                              <div className={classes.elementTextBlock}>
                                <Typography className={classes.selectElementText}>
                                  {suggestion.description}
                                </Typography>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </>
                );
              }}
            </PlacesAutocomplete>
          </div>
        </div>
      )}
    </>
  );
};

export default PlaceAutocomplete;
