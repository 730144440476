import { useEffect, useContext } from 'react';
import { Context } from 'context/Context';

export const useAuthCheck = () => {
  const { setIsUserAuthed, setUserData } = useContext(Context);

  useEffect(() => {
    const userDataLocal = localStorage.getItem('userData') ?? null;
    setUserData(userDataLocal ? JSON.parse(userDataLocal) : null);
    setIsUserAuthed(userDataLocal ? true : false);
  }, []);
};
