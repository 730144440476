import React, { useState } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import { useForgotPassword } from 'hooks/auth/useForgotPassword';

const ForgotPasswordContainer = () => {
  const { forgotPassword } = useForgotPassword();

  return (
    <>
      <ForgotPasswordForm forgotPassword={forgotPassword} />
    </>
  );
};

export default ForgotPasswordContainer;
