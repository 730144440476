import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import { useStyles } from './styles';

const ProfilePasswordForm = ({ answerError, editPassword }: any) => {
  const [password, setPassword] = useState('');
  const [passwordNew, setPasswordNew] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordNewError, setPasswordNewError] = useState('');
  const [passwordRepeatError, setPasswordRepeatError] = useState('');
  const classes = useStyles();

  useEffect(() => {
    setPasswordError('');
    setPasswordNewError('');
    setPasswordRepeatError('');
  }, []);

  const handleSubmit = () => {
    let isError: boolean = false;
    let passwordErrorNew = '';
    let passwordNewErrorNew = '';
    let passwordRepeatErrorNew = '';

    if (!password) {
      isError = true;
      passwordErrorNew = 'Заповніть старий пароль';
    }

    if (!passwordNew) {
      isError = true;
      passwordNewErrorNew = 'Заповніть новий пароль';
    }

    if (!passwordRepeat) {
      isError = true;
      passwordRepeatErrorNew = 'Заповніть ще раз новий пароль';
    }

    if (passwordNew && passwordRepeat && passwordNew !== passwordRepeat) {
      isError = true;
      passwordRepeatErrorNew = 'Повторіть новий пароль правильно';
    }

    setPasswordError(passwordErrorNew);
    setPasswordNewError(passwordNewErrorNew);
    setPasswordRepeatError(passwordRepeatErrorNew);

    if (!isError) {
      editPassword(password, passwordNew);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleBlock}>
        <Typography className={classes.titleText}>Змінити пароль</Typography>
      </div>
      {answerError !== '' && (
        <div className={classes.errorBlock}>
          <Typography className={classes.errorText}>
            Ваші дані мають якусь помилку. Перевірте та спробуйте зберегти ще раз.
          </Typography>
        </div>
      )}
      <div className={classes.inputBlock}>
        <Input
          label="Старий пароль"
          size="medium"
          type="password"
          className={classes.input}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          helperText={passwordError}
          fullWidth
          required
        />
      </div>
      <div className={classes.inputBlock}>
        <Input
          label="Новий пароль"
          size="medium"
          type="password"
          className={classes.input}
          onChange={(e) => setPasswordNew(e.target.value)}
          value={passwordNew}
          helperText={passwordNewError}
          fullWidth
          required
        />
      </div>
      <div className={classes.inputBlock}>
        <Input
          label="Ще раз новий пароль"
          size="medium"
          type="password"
          className={classes.input}
          onChange={(e) => setPasswordRepeat(e.target.value)}
          value={passwordRepeat}
          helperText={passwordRepeatError}
          fullWidth
          required
        />
      </div>
      <div className={classes.buttonBlock}>
        <Button onClick={handleSubmit}>Зберегти</Button>
      </div>
    </div>
  );
};

export default ProfilePasswordForm;
