export enum ShopProductsPageMode {
  LIST = 'list',
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
}

export interface ShopProduct {
  id: string;
  uid: string;
  name: string;
  descriptionBrief: string;
  description: string;
  materials: string;
  brand: string;
  audience?: string;
  benefit?: string;
  price: number;
  pricePrevious?: number;
  categories: string[];
  skills: string[];
  pictureUrl: string;
  thumbnailUrl: string;
  pictures: string[];
  isAvailable: boolean;
  isPublic: boolean;
}

export interface ShopProductInput {
  productId?: string;
  uid: string;
  name: string;
  descriptionBrief: string;
  description: string;
  materials: string;
  brand: string;
  audience?: string;
  benefit?: string;
  price: number;
  pricePrevious?: number;
  categories: string[];
  skills: string[];
  pictureUrl?: string;
  thumbnailUrl?: string;
  pictures?: string[];
  isAvailable: boolean;
  isPublic: boolean;
}

export interface ShopCategory {
  id: string;
  uid?: string;
  name: string;
  pictureUrl?: string;
  thumbnailUrl?: string;
  isPublic?: boolean;
}

export interface ShopCategoryInputCreate extends ShopCategoryInput {}

export interface ShopCategoryInputEdit extends ShopCategoryInput {
  categoryId: string;
}

export interface ShopCategoryInput {
  name: string;
  uid: string;
  description?: string;
  isPublic: boolean;
}
