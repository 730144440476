import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { Place, PlaceCategory, Region, City } from 'types/places';

export const useAdminPlaces = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [categoriesList, setCategoriesList] = useState<PlaceCategory[]>([]);
  const [regionsList, setRegionsList] = useState<Region[]>([]);
  const [citiesList, setCitiesList] = useState<City[]>([]);
  const [placesList, setPlacesList] = useState<Place[]>([]);

  useEffect(() => {
    const getRegions = async () => {
      const results: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/places/get_regions',
        {},
      );
      if (results?.data) {
        setRegionsList(results.data.regions);
      }
    };

    getRegions();
  }, []);

  useEffect(() => {
    const getCities = async () => {
      const results: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/places/get_cities',
        {},
      );
      if (results?.data) {
        setCitiesList(results.data.cities);
      }
    };

    getCities();
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      const results: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/places/get_places_categories',
        {},
      );
      if (results?.data) {
        setCategoriesList(results.data.categories);
      }
    };

    getCategories();
  }, []);

  useEffect(() => {
    getPlaces();
  }, []);

  const getPlaces = async () => {
    setIsLoading(true);
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/places/get_admin_places',
      {},
    );
    if (results?.data) {
      setIsLoading(false);
      setPlacesList(results.data.places);
    }
  };

  const createPlace = useCallback(
    async (
      categoryId: string,
      name: string,
      description: string,
      latitude: number,
      longitude: number,
    ) => {
      const details = {
        categoryId,
        name,
        description,
        latitude,
        longitude,
      };
      await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/places/create_place', details);
      getPlaces();
    },
    [],
  );

  const editPlace = useCallback(
    async (
      placeId: string,
      categoryId: string,
      name: string,
      description: string,
      latitude: number,
      longitude: number,
    ) => {
      const details = {
        placeId,
        categoryId,
        name,
        description,
        latitude,
        longitude,
      };
      await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/places/edit_place', details);
      getPlaces();
    },
    [],
  );

  const deletePlace = useCallback(async (placeId: string) => {
    const details = { placeId };
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/places/delete_place', details);
    getPlaces();
  }, []);

  return {
    isLoading,
    regionsList,
    citiesList,
    categoriesList,
    placesList,
    createPlace,
    editPlace,
    deletePlace,
  };
};
