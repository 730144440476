import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { prepareField } from 'utils/objects';
import { Route, RouteEditInput } from 'types/places';

export const useAdminRoutes = (position: [number, number] | null) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [routesList, setRoutesList] = useState<Route[]>([]);

  const getRoutes = async () => {
    setIsLoading(true);
    const details = {
      ...prepareField({ position }),
    };
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/places/get_admin_routes',
      details,
    );
    if (results?.data?.routes) {
      setIsLoading(false);
      setRoutesList(results.data.routes);
    }
  };

  useEffect(() => {
    getRoutes();
  }, [position]);

  const editRoute = useCallback(async (details: RouteEditInput) => {
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/places/edit_admin_route',
      details,
    );
    if (results?.data.routeId) {
      const { routeId } = results?.data;
      setRoutesList((prev) =>
        prev.map((item: Route) => {
          if (item.id === routeId) {
            return {
              ...item,
              ...results?.data,
            };
          }
          return item;
        }),
      );
    }
  }, []);

  const deleteRoute = useCallback(async (routeId: string) => {
    const details = { routeId };
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/places/delete_route',
      details,
    );
    if (results?.data.routeId) {
      const { routeId } = results?.data;
      setRoutesList((prev) => prev.filter((item: Route) => item.id !== routeId));
    }
  }, []);

  return {
    isLoading,
    routesList,
    editRoute,
    deleteRoute,
  };
};
