import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { PodcastsEpisode } from 'types/podcasts';

export const usePodcastsEpisodesCommon = () => {
  return useQuery(
    ['podcasts-episodes-common'],
    async () => {
      const result: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/podcasts/get_podcast_episodes',
        {},
      );
      const recordsList: PodcastsEpisode[] = result?.data?.episodes ?? [];

      return recordsList;
    },
    {
      refetchInterval: 10 * 60 * 60 * 6,
    },
  );
};
