import { useState } from 'react';
import Container from 'common/core/Container';
import PageTitle from 'common/core/PageTitle';
import TasksActions from './TasksActions';
import TasksList from './TasksList';
import TasksEdit from './TasksEdit';
import { useTasks } from 'hooks/tasks/useTasks';
import { NatureTask, NatureTaskInputCreate, NatureTasksPageMode } from 'types/projects/nature';

const TasksContainer = () => {
  const [pageMode, setPageMode] = useState<NatureTasksPageMode>(NatureTasksPageMode.LIST);
  const [taskIdCurrent, setTaskIdCurrent] = useState<string>('');
  const {
    categoriesList,
    agesList,
    tasksList,
    createTask,
    editTask,
    editTaskPicture,
    deleteTaskPicture,
    createGalleryPicture,
    deleteGalleryPicture,
    deleteTask,
  } = useTasks();

  const handleCreateTask = async (details: NatureTaskInputCreate) => {
    const taskId = await createTask(details);
    if (taskId) {
      setTaskIdCurrent(taskId);
      setPageMode(NatureTasksPageMode.EDIT);
    }
  };

  const handleEditMode = (taskId: string) => {
    setPageMode(NatureTasksPageMode.EDIT);
    setTaskIdCurrent(taskId);
  };

  const handleListMode = () => {
    setPageMode(NatureTasksPageMode.LIST);
    setTaskIdCurrent('');
  };

  const handleAddMode = () => {
    setPageMode(NatureTasksPageMode.ADD);
    setTaskIdCurrent('');
  };

  return (
    <>
      <Container>
        <PageTitle
          title={
            pageMode === NatureTasksPageMode.LIST
              ? 'Завдання'
              : pageMode === NatureTasksPageMode.ADD
              ? 'Завдання: нове'
              : 'Завдання: редагування'
          }
        />
        <TasksActions
          pageMode={pageMode}
          handleAddMode={handleAddMode}
          handleListMode={handleListMode}
        />
        {pageMode === NatureTasksPageMode.LIST && (
          <TasksList
            tasksList={tasksList}
            handleEditMode={handleEditMode}
            deleteTask={deleteTask}
          />
        )}
        {pageMode !== NatureTasksPageMode.LIST && (
          <TasksEdit
            task={tasksList.find((task: NatureTask) => task.id === taskIdCurrent) ?? null}
            createTask={handleCreateTask}
            categoriesList={categoriesList}
            agesList={agesList}
            editTask={editTask}
            editTaskPicture={editTaskPicture}
            deleteTaskPicture={deleteTaskPicture}
            createGalleryPicture={createGalleryPicture}
            deleteGalleryPicture={deleteGalleryPicture}
            handleListMode={handleListMode}
          />
        )}
      </Container>
    </>
  );
};

export default TasksContainer;
