import { useState, useEffect } from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import Modal from 'common/core/Modal';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import { Podcast, PodcastInput } from 'types/podcasts';
import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
  podcast: Podcast | null;
  createPodcast: (details: PodcastInput) => void;
  editPodcast: (details: PodcastInput) => void;
}

const PodcastsEdit = ({ isOpen, toggleIsOpen, podcast, createPodcast, editPodcast }: Props) => {
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const [title, setTitle] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [titleError, setTitleError] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    setTitle(podcast?.title ?? '');
    setBody(podcast?.body ?? '');
    setIsPublic(podcast?.isPublic ?? false);
  }, [podcast, isOpen]);

  const handleSubmit = () => {
    setTitleError(title ? '' : 'Fill title of podcast');

    if (title) {
      if (!podcast?.id) {
        createPodcast({ title, body, isPublic });
      } else {
        editPodcast({
          podcastId: podcast?.id ?? '',
          title,
          body,
          isPublic,
        });
      }
      toggleIsOpen();
    }
  };

  return (
    <Modal
      title={podcast?.id ? 'Редагування подкасту' : 'Новий подкаст'}
      widthMax={450}
      open={isOpen}
      handleClose={toggleIsOpen}
    >
      <div className={classes.container}>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Назва"
            type="text"
            className={classes.input}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            helperText={titleError}
            fullWidth
            required
          />
        </div>
        <div className={classes.inputBlock}>
          <Input
            label="Опис"
            type="text"
            className={classes.input}
            onChange={(e) => setBody(e.target.value)}
            value={body}
            fullWidth
            multiline
            rows={8}
          />
        </div>
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Доступ</InputLabel>
            <Select
              label="Доступ"
              native
              onChange={(e: any) => setIsPublic(e.target.value === 'true' ? true : false)}
              value={isPublic ? 'true' : 'false'}
              className={classes.select}
            >
              <option value="false" key="private">
                Закритий
              </option>
              <option value="true" key="public">
                Відкритий
              </option>
            </Select>
          </FormControl>
        </div>
        <div className={classes.buttonBlock}>
          <Button onClick={handleSubmit}>Зберегти</Button>
        </div>
      </div>
    </Modal>
  );
};

export default PodcastsEdit;
