import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  imgBlock: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 200,
    aspectRatio: '1/1',
    border: '1px solid #EBEBEB',
    backgroundColor: '#009999',
    borderRadius: '50%',
    [theme.breakpoints.down('md')]: {
      width: 100,
    },
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: 20,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 5,
    },
  },
  nameBlock: {
    display: 'flex',
    flexDirection: 'row',
  },
  dateBlock: {
    display: 'flex',
    flexDirection: 'row',
  },
  img: {
    width: 200,
    aspectRatio: '1/1',
    objectFit: 'cover',
    borderRadius: '50%',
    [theme.breakpoints.down('md')]: {
      width: 100,
    },
  },
  imgEmptyIcon: {
    color: '#fff',
    fontSize: 96,
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
    },
  },
  editImgIconButton: {
    position: 'absolute',
    bottom: 0,
    padding: 8,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    [theme.breakpoints.down('md')]: {
      bottom: 0,
      padding: 3,
    },
  },
  editImgIcon: {
    fontSize: 18,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  nameText: {
    fontSize: 18,
    fontWeight: 400,
  },
  dateText: {
    fontSize: 14,
    fontWeight: 300,
    color: '#999999',
  },
}));
