import React, { useState } from 'react';
import classnames from 'classnames';
import { Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import { useStyles } from './styles';

const radiusesList = [
  {
    id: '1000',
    distance: 1000,
    label: '1 км',
  },
  {
    id: '2000',
    distance: 2000,
    label: '2 км',
  },
  {
    id: '5000',
    distance: 5000,
    label: '5 км',
  },
  {
    id: '10000',
    distance: 10000,
    label: '10 км',
  },
];

interface Props {
  radius: number;
  handleChangeRadius: (value: number) => void;
}

const RadiusFilter = ({ radius, handleChangeRadius }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleChangeValue = (value: number) => {
    handleChangeRadius(value);
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.introBlock}>
          <Typography className={classes.introText}>Радіус пошуку:</Typography>
        </div>
        <div onClick={toggleMenu} className={classes.selectBlock}>
          <Typography className={classes.selectChosenText}>
            {radiusesList.find(({ distance }) => distance === radius)?.label}
          </Typography>
          <ArrowDropDownIcon className={classes.menuIcon} />
        </div>
      </div>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        {radiusesList.map(({ id, distance, label }) => (
          <MenuItem
            onClick={() => handleChangeValue(distance)}
            key={id}
            className={classnames(classes.menuItem, distance === radius && classes.menuItemChosen)}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default RadiusFilter;
