import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { Typography, IconButton, Grid } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  EventNoteTwoTone as DateIcon,
  EditTwoTone as EditIcon,
  DeleteTwoTone as DeleteIcon,
} from '@material-ui/icons';
import BadgeIcon from 'common/core/Badge/BadgeIcon';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import { formatDateBrief } from 'utils/datetime';
import { BlogPost } from 'types/blog';
import { useStyles } from './styles';

interface Props {
  post: BlogPost;
  handleEditMode: (placeId: string) => void;
  deletePost: (placeId: string) => void;
}

const BlogListItem = ({ post, handleEditMode, deletePost }: Props) => {
  const { id: postId, title, datePost, thumbnailUrl, isPublic } = post;
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const fileRef = useRef<any>();
  const classes = useStyles();

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEditAction = () => {
    handleEditMode(postId);
    setAnchorEl(null);
  };

  const handleDeleteAction = () => {
    toggleOpenConfirmModal();
    setAnchorEl(null);
  };

  const toggleOpenConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  const handleDeleteApproved = () => {
    deletePost(postId);
    toggleOpenConfirmModal();
  };

  return (
    <>
      <Grid item md={4} sm={6} xs={12}>
        <div className={classes.container}>
          <div className={classes.pictureBlock}>
            {thumbnailUrl && <img src={thumbnailUrl} className={classes.picture} alt="" />}
            {!isPublic && <BadgeIcon type="private" />}
          </div>
          <div className={classes.infoBlock}>
            <div className={classes.leftBlock}>
              <div className={classes.titleBlock}>
                <Typography className={classes.titleText}>{title}</Typography>
              </div>
              <div className={classes.detailsBlock}>
                <DateIcon className={classes.infoIcon} />
                <Typography className={classes.infoText}>
                  {formatDateBrief(datePost ?? 0)}
                </Typography>
              </div>
            </div>
            <div className={classes.actionsBlock}>
              <IconButton onClick={toggleMenu} className={classes.menuButton}>
                <MoreVertIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </Grid>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <MenuItem onClick={handleEditAction} className={classes.subMenu} key="edit">
          Редагувати
          <EditIcon />
        </MenuItem>
        <MenuItem
          onClick={handleDeleteAction}
          className={classnames(classes.subMenu, classes.subMenuDelete)}
          key="delete"
        >
          Видалити
          <DeleteIcon />
        </MenuItem>
      </Menu>
      <ModalConfirm
        title="Дійсно хочете видалити?"
        note="Ви не зможете повернути назад."
        isOpenConfirmModal={isOpenConfirmModal}
        toggleOpenConfirmModal={toggleOpenConfirmModal}
        handleSumbit={handleDeleteApproved}
      />
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={() => {}}
        multiple
        accept="image/*"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default BlogListItem;
