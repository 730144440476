import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { EmailTemplate } from 'types/general';
import { useAlert } from '../useAlert';

export const useContentPages = () => {
  const [pagesList, setTemplatesList] = useState<EmailTemplate[]>([]);
  const showAlert = useAlert();

  useEffect(() => {
    getPages();
  }, []);

  const getPages = useCallback(async () => {
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/general/get_admin_content_pages',
      {},
    );
    if (results.data?.pages) {
      setTemplatesList(results.data?.pages);
    }
  }, []);

  const createPage = useCallback(async (name: string, label: string, body: string) => {
    const details = {
      name,
      label,
      body,
    };
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/general/create_content_page',
      details,
    );
    showAlert('Page was created');
    getPages();
  }, []);

  const editPage = useCallback(
    async (pageId: string, name: string, label: string, body: string) => {
      const details = {
        pageId,
        name,
        label,
        body,
      };
      await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/general/edit_content_page',
        details,
      );
      showAlert('Page was edited');
      getPages();
    },
    [],
  );

  const deletePage = useCallback(async (pageId: string) => {
    const details = { pageId };
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/general/delete_content_page',
      details,
    );
    getPages();
    showAlert('Page was deleted');
  }, []);

  return {
    pagesList,
    createPage,
    editPage,
    deletePage,
  };
};
