import React from 'react';
import { Typography } from '@material-ui/core';
import Button from 'common/core/Button';
import { UsersPageMode } from 'types/auth';
import { useStyles } from './styles';

interface Props {
  pageMode: string;
  handlePageMode: (value: string) => void;
}

const PlacesHeader = ({ pageMode, handlePageMode }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.titleText}>
          Користувачі
          {pageMode === UsersPageMode.EDIT && <>: редагування користувача</>}
        </Typography>
      </div>
      <div>
        {pageMode === UsersPageMode.EDIT && (
          <Button onClick={() => handlePageMode(UsersPageMode.LIST)} variant="outlined">
            Скасувати
          </Button>
        )}
      </div>
    </div>
  );
};

export default PlacesHeader;
