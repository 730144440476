import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Typography, Checkbox, Switch } from '@material-ui/core';
// @ts-ignore
import { slugify } from 'transliter';
import ProductEditPictures from './ProductEditPictures';
import Input from 'common/core/Input';
import InputEditor from 'common/core/InputEditor';
import Button from 'common/core/Button';
import { ShopProductsPageMode, ShopProduct, ShopCategory, ShopProductInput } from 'types/shop';
import { SkillCategory } from 'types/general';
import { useStyles } from './styles';

interface Props {
  product: ShopProduct | null;
  categoriesList: ShopCategory[];
  skillsList: SkillCategory[];
  createProduct: (details: ShopProductInput) => void;
  editProduct: (details: ShopProductInput) => void;
  editProductPicture: (productId: string, file: any) => void;
  createGalleryPicture: (productId: string, file: any) => void;
  deleteGalleryPicture: (productId: string, id: string) => void;
  handlePageMode: (value: string) => void;
}

const ProductsEdit = ({
  product,
  categoriesList,
  skillsList,
  createProduct,
  editProduct,
  editProductPicture,
  createGalleryPicture,
  deleteGalleryPicture,
  handlePageMode,
}: Props) => {
  const [name, setName] = useState<string>('');
  const [descriptionBrief, setDescriptionBrief] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [materials, setMaterials] = useState<string>('');
  const [brand, setBrand] = useState<string>('');
  const [price, setPrice] = useState<string>('0');
  const [uid, setUid] = useState<string>('');
  const [skills, setSkills] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [isAvailable, setIsAvailable] = useState<boolean>(true);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [nameError, setNameError] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    if (product) {
      setName(product?.name ?? '');
      setDescription(product?.description ?? '');
      setMaterials(product?.materials ?? '');
      setBrand(product?.brand ?? '');
      setDescriptionBrief(product?.descriptionBrief ?? '');
      setPrice(product?.price ? product?.price.toString() : '');
      setUid(product?.uid ?? '');
      setIsAvailable(product?.isAvailable ?? false);
      setIsPublic(product?.isPublic ?? false);
      setSkills(product?.skills ? product?.skills : []);
      setCategories(product?.categories ? product?.categories : []);
    }
  }, [product]);

  useEffect(() => {
    if (!product?.id) {
      setUid(slugify(name, '_'));
    }
  }, [name, product]);

  const handlePrice = (e: any) => {
    const value = e.target.value;
    if (value === '0' || value === '' || Number(value)) {
      setPrice(value);
    }
  };

  const handleChangeSkills = (e: React.ChangeEvent<HTMLInputElement>) => {
    const skillsNew =
      skills.findIndex((categoryId) => categoryId === e.target.name) !== -1
        ? skills.filter((categoryId) => categoryId !== e.target.name)
        : [...skills, e.target.name];

    setSkills(skillsNew);
  };

  const handleChangeCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    const categoriesNew =
      categories.findIndex((categoryId) => categoryId === e.target.name) !== -1
        ? categories.filter((categoryId) => categoryId !== e.target.name)
        : [...categories, e.target.name];

    setCategories(categoriesNew);
  };

  const handleSubmit = () => {
    setNameError(name ? '' : 'Заповніть заголовок поста');
    if (name) {
      if (!product?.id) {
        createProduct({
          name,
          descriptionBrief,
          description,
          materials,
          brand,
          price: Number(price) ?? 0,
          uid,
          categories,
          skills,
          isAvailable,
          isPublic,
        });
        handlePageMode(ShopProductsPageMode.LIST);
      } else {
        editProduct({
          productId: product?.id ?? '',
          name,
          descriptionBrief,
          description,
          materials,
          brand,
          price: Number(price) ?? 0,
          uid,
          categories,
          skills,
          isAvailable,
          isPublic,
        });
      }
    }
  };

  return (
    <>
      <div className={classes.container}>
        {!!product && (
          <ProductEditPictures
            product={product}
            editProductPicture={editProductPicture}
            createGalleryPicture={createGalleryPicture}
            deleteGalleryPicture={deleteGalleryPicture}
          />
        )}

        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Назва товару"
            type="text"
            className={classes.input}
            onChange={(e) => setName(e.target.value)}
            value={name}
            helperText={nameError}
            fullWidth
            required
          />
        </div>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="URL"
            type="text"
            className={classnames(classes.input, classes.inputLimited)}
            onChange={(e) => setUid(e.target.value)}
            value={uid}
            // helperText={nameError}
            // required
          />
        </div>
        {/* <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Короткий опис"
            type="text"
            className={classes.input}
            onChange={(e) => setDescriptionBrief(e.target.value)}
            value={descriptionBrief}
            fullWidth
          />
        </div> */}
        <div className={classes.inputHeaderBlock}>
          <Typography className={classes.inputHeaderText}>Опис товару:</Typography>
        </div>
        <div className={classes.richTextareaBlock}>
          <InputEditor
            valueInitial={description}
            handleChangeValue={(value: string) => setDescription(value)}
          />
        </div>
        <div className={classes.inputHeaderBlock}>
          <Typography className={classes.inputHeaderText}>Матеріали:</Typography>
        </div>
        <div className={classes.richTextareaBlock}>
          <InputEditor
            valueInitial={materials}
            handleChangeValue={(value: string) => setMaterials(value)}
          />
        </div>
        <div className={classes.inputHeaderBlock}>
          <Typography className={classes.inputHeaderText}>Про бренд:</Typography>
        </div>
        <div className={classes.richTextareaBlock}>
          <InputEditor
            valueInitial={brand}
            handleChangeValue={(value: string) => setBrand(value)}
          />
        </div>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Ціна (UAH)"
            type="text"
            className={classnames(classes.input, classes.inputPrice)}
            onChange={handlePrice}
            value={price}
            fullWidth
          />
        </div>
        <div className={classes.optionBlock}>
          <div className={classes.optionsListBlock}>
            <div className={classes.optionsListHeaderBlock}>
              <Typography className={classes.optionsListHeaderText}>Навички:</Typography>
            </div>
            {skillsList.map(({ id, name }: SkillCategory) => {
              const isChecked = skills.findIndex((item: string) => item === id) !== -1;
              return (
                <div className={classes.optionElementBlock}>
                  <div className={classes.optionCheckboxBlock}>
                    <Checkbox
                      name={id}
                      checked={isChecked}
                      onChange={handleChangeSkills}
                      color="primary"
                      className={classes.optionCheckbox}
                    />
                  </div>
                  <div className={classes.optionNameBlock}>
                    <Typography className={classes.optionNameText}>{name}</Typography>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.inputBlock}>
          <div className={classes.optionsListBlock}>
            <div className={classes.optionsListHeaderBlock}>
              <Typography className={classes.optionsListHeaderText}>Категорії товару:</Typography>
            </div>
            {categoriesList.map((item: any) => {
              const isChecked = categories.findIndex((categoryId) => categoryId === item.id) !== -1;
              return (
                <div className={classes.optionElementBlock}>
                  <div className={classes.optionCheckboxBlock}>
                    <Checkbox
                      name={item.id}
                      checked={isChecked}
                      onChange={handleChangeCategory}
                      color="primary"
                      className={classes.optionCheckbox}
                    />
                  </div>
                  <div className={classes.optionNameBlock}>
                    <Typography className={classes.optionNameText}>{item?.name}</Typography>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.inputBlock}>
          <Switch
            checked={isAvailable}
            onChange={() => setIsAvailable(!isAvailable)}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography className={classes.switchText}>
            {isAvailable ? 'Товар доступний у магазині' : 'Товар відсутній'}{' '}
          </Typography>
        </div>
        <div className={classes.inputBlock}>
          <Switch
            checked={isPublic}
            onChange={() => setIsPublic(!isPublic)}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Typography className={classes.switchText}>
            {isPublic ? 'Відкритий доступ' : 'Закритий доступ'}{' '}
          </Typography>
        </div>
        <div className={classes.buttonBlock}>
          <Button onClick={handleSubmit} className={classes.button}>
            Зберегти
          </Button>
          <Button
            onClick={() => handlePageMode(ShopProductsPageMode.LIST)}
            variant="outlined"
            className={classes.button}
          >
            Назад
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProductsEdit;
