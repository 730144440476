import React, { useState } from 'react';
import Container from 'common/core/Container';
import PageTitle from 'common/core/PageTitle';
import PoetryActions from './PoetryActions';
import PoetryList from './PoetryList';
import PoetryEdit from './PoetryEdit';
import { usePoetry } from 'hooks/projects/poetry/usePoetry';
import { useAuthors } from 'hooks/projects/poetry/useAuthors';
import { PoetryWalksItem } from 'types/projects/poetry';

const PoetryContainer = () => {
  const [authorIdCurrent, setAuthorIdCurrent] = useState<string>('');
  const [poetryIdCurrent, setPoetryIdCurrent] = useState<string>('');
  const [isOpenEditModal, setIsEditOpenModal] = useState<boolean>(false);
  const {
    isLoading: isLoadingPoetry,
    poetryList,
    createPoetry,
    editPoetry,
    editPoetryAudio,
    deletePoetryAudio,
    deletePoetry,
  } = usePoetry(authorIdCurrent);
  const { isLoading: isLoadingAuthorsList, authorsList } = useAuthors();

  const handleAuthorIdCurrent = (value: string) => {
    setAuthorIdCurrent(value);
  };

  const handleEditMode = (poetryId: string) => {
    setPoetryIdCurrent(poetryId);
    setIsEditOpenModal(true);
  };

  const handleAddMode = () => {
    setPoetryIdCurrent('');
    setIsEditOpenModal(true);
  };

  return (
    <>
      <Container>
        <PageTitle title="Poetry Walks : Вірши" />
        <PoetryActions
          handleAddMode={handleAddMode}
          authorIdCurrent={authorIdCurrent}
          handleAuthorIdCurrent={handleAuthorIdCurrent}
        />
        <PoetryList
          isLoadingPoetry={isLoadingPoetry}
          poetryList={poetryList}
          handleEditMode={handleEditMode}
          editPoetryAudio={editPoetryAudio}
          deletePoetryAudio={deletePoetryAudio}
          deletePoetry={deletePoetry}
        />
      </Container>
      <PoetryEdit
        isOpen={isOpenEditModal}
        toggleIsOpen={() => setIsEditOpenModal(false)}
        authorsList={authorsList}
        poetry={poetryList.find((item: PoetryWalksItem) => item.id === poetryIdCurrent) ?? null}
        createPoetry={createPoetry}
        editPoetry={editPoetry}
      />
    </>
  );
};

export default PoetryContainer;
