import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, IconButton } from '@material-ui/core';
import Button from 'common/core/Button';
import { Story } from 'types/projects/tvorytelling';
import { useStyles } from './styles';

interface Props {
  story: Story;
}

const StoryHeader = ({ story }: Props) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.titleBlock}>
          <Typography className={classes.titleText}>{story?.title ?? ''}</Typography>
        </div>
        <div className={classes.actionBlock}>
          <Button onClick={() => history.push(`/tvorytelling/mystory/${story?.id}`)}>
            Create Mode
          </Button>
        </div>
      </div>
    </>
  );
};

export default StoryHeader;
