import React, { useContext } from 'react';
import ProfileEditForm from './ProfileEditForm';
import { useProfileEdit } from 'hooks/auth/useProfileEdit';
import { Context } from 'context/Context';

const ProfilePassword = () => {
  const { userData } = useContext(Context);
  const { regionsList, citiesList, answerError, editProfile } = useProfileEdit();

  return (
    <ProfileEditForm
      user={userData}
      regionsList={regionsList}
      citiesList={citiesList}
      answerError={answerError}
      editProfile={editProfile}
    />
  );
};

export default ProfilePassword;
