import React from 'react';
import { Container } from '@material-ui/core';
import StoriesHeader from './StoriesHeader';
import StoriesList from './StoriesList';
import { useStories } from 'hooks/projects/tvorytelling/useStories';
import { useStyles } from './styles';

const StoriesContainer = () => {
  const { storiesList } = useStories();
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <StoriesHeader />
      <StoriesList storiesList={storiesList} />
    </Container>
  );
};

export default StoriesContainer;
