import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    menuLink: {
        color: 'rgba(256,256,256,0.7)',
        marginRight: 5,
    },
    menuLinkActive: {
        color: '#fff',
    },
}));
