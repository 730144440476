import { makeStyles, Theme } from '@material-ui/core/styles';
import { IMAGE_BACKGROUND_COLOR } from 'config';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '1px solid #EBEBEB',
    borderRadius: 6,
  },
  pictureBlock: {
    display: 'flex',
    borderRadius: '6px 6px 0 0',
    cursor: 'pointer',
  },
  pictureEmptyBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: '16/9',
    backgroundColor: IMAGE_BACKGROUND_COLOR,
    borderRadius: '6px 6px 0 0',
    cursor: 'pointer',
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
  },
  leftBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: 10,
  },
  titleBlock: {
    display: 'flex',
    paddingBottom: 3,
  },
  locationBlock: {
    display: 'flex',
  },
  dateBlock: {
    display: 'flex',
  },
  actionsBlock: { paddingRight: 5 },
  picture: {
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '16/9',
    borderRadius: '6px 6px 0 0',
  },
  imgEmptyIcon: {
    width: 75,
    [theme.breakpoints.down('sm')]: {
      width: 75,
    },
  },
  titleText: {
    fontSize: 16,
    fontWeight: 400,
  },
  infoText: {
    fontSize: 14,
    fontWeight: 350,
    color: '#666666',
    lineHeight: '14px',
  },
  infoIcon: {
    fontSize: 14,
    color: '#666666',
    marginRight: 4,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginRight: 2,
    },
  },
  menuButton: {
    padding: 7,
  },
  subMenu: {
    minWidth: 170,
  },
  subMenuDelete: {
    color: '#FF0000 !important',
  },
  subMenuIcon: {},
}));
