import React, { useState } from 'react';
import { Container } from '@material-ui/core';
import MyStoriesHeader from './MyStoriesHeader';
import MyStoriesList from './MyStoriesList';
import MyStoriesEdit from './MyStoriesEdit';
import { useTemplates } from 'hooks/projects/tvorytelling/useTemplates';
import { StoriesPageMode, Story } from 'types/projects/tvorytelling';
import { useStyles } from './styles';

const TemplatesContainer = () => {
  const [pageMode, setPageMode] = useState<string>(StoriesPageMode.LIST);
  const [storyIdCurrent, setStoryIdCurrent] = useState<string>('');
  const { storiesList, createStory, editStory, editStoryPicture, deleteStory } = useTemplates();
  const classes = useStyles();

  const handlePageMode = (value: string) => {
    setPageMode(value);
  };

  const handleEditMode = (placeId: string) => {
    setStoryIdCurrent(placeId);
    setPageMode(StoriesPageMode.EDIT);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <MyStoriesHeader pageMode={pageMode} handlePageMode={handlePageMode} />
      {pageMode === StoriesPageMode.LIST && (
        <MyStoriesList
          storiesList={storiesList}
          handleEditMode={handleEditMode}
          deleteStory={deleteStory}
          editStoryPicture={editStoryPicture}
        />
      )}
      {(pageMode === StoriesPageMode.EDIT || pageMode === StoriesPageMode.ADD) && (
        <MyStoriesEdit
          story={storiesList.find((story: Story) => story.id === storyIdCurrent) ?? null}
          createStory={createStory}
          categoriesList={[]}
          editStory={editStory}
          handlePageMode={handlePageMode}
        />
      )}
    </Container>
  );
};

export default TemplatesContainer;
