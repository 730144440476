import { makeStyles, Theme } from '@material-ui/core/styles';
import { IMPORTANT_COLOR } from 'config';

export const useStyles = makeStyles((theme: Theme) => ({
  isPrivateBlock: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 5,
    left: 5,
    width: 22,
    height: 22,
    backgroundColor: IMPORTANT_COLOR,
  },
  isPrivateIcon: {
    fontSize: 16,
    color: '#fff',
    padding: 0,
    margin: 0,
  },
}));
