import React, { useState, useEffect } from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import Modal from 'common/core/Modal';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import { AlphabetLetter, AlphabetLetterInputEdit } from 'types/projects/alphabet';
import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
  item: AlphabetLetter | null;
  editLetter: (details: AlphabetLetterInputEdit) => void;
}

const AlphabetLettersEdit = ({ isOpen, toggleIsOpen, item, editLetter }: Props) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const classes = useStyles();

  useEffect(() => {
    if (item) {
      setName(item?.name ?? '');
      setDescription(item?.description ?? '');
      setIsPublic(item?.isPublic ?? true);
    } else {
      setName('');
      setDescription('');
      setIsPublic(true);
    }
    return () => {
      setName('');
      setDescription('');
      setIsPublic(true);
    };
  }, [item, isOpen]);

  const handleSubmit = () => {
    // setNameError(name ? '' : 'Заповніть назву історії');
    // setWordError(word ? '' : 'Заповніть назву історії');

    // if (name && word) {
    if (item?.id) {
      editLetter({
        letterId: item?.id ?? '',
        setId: item?.setId ?? '',
        name,
        description,
        isPublic,
      });
    }
    toggleIsOpen();
  };
  // };

  return (
    <Modal
      title={item?.id ? 'Редагування літери ' + item?.letter.toLocaleUpperCase() : 'Нове літера'}
      widthMax={500}
      open={isOpen}
      handleClose={toggleIsOpen}
    >
      <div className={classes.container}>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Назва"
            type="text"
            className={classes.input}
            onChange={(e) => setName(e.target.value)}
            value={name}
            fullWidth
          />
        </div>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Вірш"
            type="text"
            className={classes.input}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            fullWidth
            multiline={true}
            rows={10}
          />
        </div>
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Доступ</InputLabel>
            <Select
              label="Доступ"
              native
              onChange={(e: any) => setIsPublic(e.target.value === 'true' ? true : false)}
              value={isPublic ? 'true' : 'false'}
              className={classes.select}
            >
              <option value="false" key="private">
                Закритий
              </option>
              <option value="true" key="public">
                Відкритий
              </option>
            </Select>
          </FormControl>
        </div>
        <div className={classes.buttonBlock}>
          <Button onClick={handleSubmit}>Зберегти</Button>
        </div>
      </div>
    </Modal>
  );
};

export default AlphabetLettersEdit;
