import React, { useRef } from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import markerIcon from 'assets/icons/markerDraggable.svg';
import { markerDraggableWidth, markerDraggableHeight } from 'config';

const MapMarkerDraggable = ({ position, handleChangePosition }: any) => {
  const markerRef = useRef<any>(null);

  const handleDragEnd = (e: any) => {
    const marker = markerRef.current;
    if (marker) {
      handleChangePosition([e?.target._latlng.lat, e?.target._latlng.lng]);
    }
  };

  const customMarkerIcon = new L.Icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon,
    iconSize: new L.Point(markerDraggableWidth, markerDraggableHeight),
    iconAnchor: [markerDraggableWidth / 2, markerDraggableHeight],
  });

  return (
    <Marker
      icon={customMarkerIcon}
      onDragEnd={handleDragEnd}
      draggable={true}
      position={position}
      ref={markerRef}
    />
  );
};

export default MapMarkerDraggable;
