import { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import Input from 'common/core/Input';
import InputEditor from 'common/core/InputEditor';
import Button from 'common/core/Button';
import { PageMode, EmailTemplate } from 'types/general';
import { useStyles } from './styles';

interface Props {
  template: EmailTemplate | null;
  createTemplate: (name: string, label: string, subject: string, body: string) => void;
  editTemplate: (
    templateId: string,
    name: string,
    label: string,
    subject: string,
    body: string,
  ) => void;
  handlePageMode: (value: string) => void;
}

const EmailTemplatesEdit = ({ template, createTemplate, editTemplate, handlePageMode }: Props) => {
  const [name, setName] = useState<string>('');
  const [label, setLabel] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [labelError, setLabelError] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    setName(template?.name ?? '');
    setLabel(template?.label ?? '');
    setSubject(template?.subject ?? '');
    setBody(template?.body ?? '');
  }, [template]);

  const handleChangeBody = (value: string) => {
    setBody(value);
  };

  const handleSubmit = () => {
    setNameError(name ? '' : 'Будь ласка, заповніть це поле');
    setLabelError(label ? '' : 'Будь ласка, заповніть це поле');

    if (name && label) {
      if (!template) {
        createTemplate(name, label, subject, body);
        handlePageMode(PageMode.LIST);
      } else {
        editTemplate(template?.id ?? '', name, label, subject, body);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.inputBlock}>
        <Input
          label="Назва шаблону"
          type="text"
          size="medium"
          className={classes.input}
          onChange={(e) => setName(e.target.value)}
          value={name}
          helperText={nameError}
          fullWidth
          required
        />
      </div>
      <div className={classes.inputBlock}>
        <Input
          label="Мітка шаблону"
          type="text"
          size="medium"
          className={classes.input}
          onChange={(e) => setLabel(e.target.value)}
          value={label}
          helperText={labelError}
          fullWidth
          required
        />
      </div>
      <div className={classes.inputBlock}>
        <Input
          label="Тема листа"
          size="medium"
          type="text"
          className={classes.input}
          onChange={(e) => setSubject(e.target.value)}
          value={subject}
          fullWidth
          required
        />
      </div>
      <div className={classes.inputHeaderBlock}>
        <Typography className={classes.inputHeaderText}>Текст поста:</Typography>
      </div>
      <div className={classes.inputEditorBlock}>
        <InputEditor valueInitial={body} handleChangeValue={handleChangeBody} />
      </div>
      <div className={classes.buttonBlock}>
        <Button
          onClick={() => handlePageMode(PageMode.LIST)}
          variant="outlined"
          className={classes.button}
        >
          Назад
        </Button>
        <Button onClick={handleSubmit} className={classes.button}>
          Зберегти
        </Button>
      </div>
    </div>
  );
};

export default EmailTemplatesEdit;
