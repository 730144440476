import React from 'react';
import { Container } from '@material-ui/core';
import MainPagesList from './MainPagesList';
import { useStyles } from './styles';

const MainContainer = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <MainPagesList />
    </Container>
  );
};

export default MainContainer;
