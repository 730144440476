import React, { useState } from 'react';
import classnames from 'classnames';
import { Typography, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  CardMembershipTwoTone as ActivityIcon,
  BusinessCenterTwoTone as RoleIcon,
  EditTwoTone as EditIcon,
  DeleteTwoTone as DeleteIcon,
} from '@material-ui/icons';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import { formatDateBrief, formatDateAdvanced } from 'utils/datetime';
import { Place } from 'types/places';
import { useStyles } from './styles';

interface Props {
  place: Place;
  isLastItem: boolean;
  handleEditMode: (placerId: string) => void;
  deletePlace: (placeId: string) => void;
}

const AdminPlacesListItem = ({ place, isLastItem, handleEditMode, deletePlace }: Props) => {
  const { id: placeId, name, createdAt } = place;
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEditAction = () => {
    handleEditMode(placeId);
    setAnchorEl(null);
  };

  const handleDeleteAction = () => {
    toggleOpenConfirmModal();
    setAnchorEl(null);
  };

  const toggleOpenConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  const handleDeleteApproved = () => {
    deletePlace(placeId);
    toggleOpenConfirmModal();
  };

  return (
    <>
      <div className={classnames(classes.container, isLastItem ? classes.containerBottom : null)}>
        <div className={classes.nameBlock}>
          <div className={classes.nameTopBlock}>
            <Typography className={classes.nameText}>{name}</Typography>
          </div>
          <div className={classes.nameBottomBlock}>
            <Typography className={classes.emailText}>...</Typography>
          </div>
        </div>
        <div className={classes.locationBlock}>
          <Typography className={classes.text}>{formatDateAdvanced(createdAt)}</Typography>
        </div>
        <div className={classes.categoryBlock}>
          <Typography className={classes.text}>....</Typography>
        </div>
        <div className={classes.categoryBlock}>
          <Typography className={classes.text}>...</Typography>
        </div>
        <div className={classes.categoryBlock}>
          <Typography className={classes.text}>{'Admin'}</Typography>
        </div>
        {/* <div className={classes.categoryBlock}>
          <Typography className={classes.contentText}>{isActive ? 'true' : 'false'}</Typography>
        </div> */}
        <div className={classes.actionsBlock}>
          <IconButton onClick={toggleMenu}>
            <MoreVertIcon className={classes.menuButton} />
          </IconButton>
        </div>
      </div>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <MenuItem onClick={handleEditAction} className={classes.subMenu} key="edit_user">
          Edit
          <EditIcon />
        </MenuItem>
        <MenuItem
          onClick={handleDeleteAction}
          className={classnames(classes.subMenu, classes.subMenuDelete)}
          key="delete_user"
        >
          Delete
          <DeleteIcon />
        </MenuItem>
      </Menu>
      <ModalConfirm
        title="Дійсно хочете видалити?"
        note="Ви не зможете повернути назад."
        isOpenConfirmModal={isOpenConfirmModal}
        toggleOpenConfirmModal={toggleOpenConfirmModal}
        handleSumbit={handleDeleteApproved}
      />
    </>
  );
};

export default AdminPlacesListItem;
