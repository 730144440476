import React from 'react';
import { Typography } from '@material-ui/core';
import Button from 'common/core/Button';
import { ShopProductsPageMode } from 'types/shop';
import { useStyles } from './styles';

interface Props {
  pageMode: string;
  handlePageMode: (value: string) => void;
}

const ProductsHeader = ({ pageMode, handlePageMode }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.titleText}>
          Товари
          {pageMode === ShopProductsPageMode.ADD && <>: новий товар</>}
          {pageMode === ShopProductsPageMode.EDIT && <>: редагування товару</>}
        </Typography>
      </div>
      <div>
        {pageMode === ShopProductsPageMode.LIST ? (
          <Button onClick={() => handlePageMode(ShopProductsPageMode.ADD)}>Додати товар</Button>
        ) : (
          <Button onClick={() => handlePageMode(ShopProductsPageMode.LIST)} variant="outlined">
            Назад
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProductsHeader;
