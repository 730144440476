import React from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import { useLetterSets } from 'hooks/projects/alphabet/useLetterSets';
import { useStyles } from './styles';

interface Props {
  setIdCurrent: string;
  handleSetIdCurrent: (value: string) => void;
}

const AlphabetLettersActions = ({ setIdCurrent, handleSetIdCurrent }: Props) => {
  const { setsList } = useLetterSets();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.filterBlock}>
        {!!setsList?.length && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Абетка</InputLabel>
            <Select
              label="Автор"
              native
              onChange={(e: any) =>
                handleSetIdCurrent((e.target.value === 'all' ? '' : e.target.value) as string)
              }
              value={setIdCurrent}
              className={classes.select}
            >
              {setsList.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
    </div>
  );
};

export default AlphabetLettersActions;
