import { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import Input from 'common/core/Input';
import InputEditor from 'common/core/InputEditor';
import Button from 'common/core/Button';
import { PageMode, ContentPage } from 'types/general';
import { useStyles } from './styles';

interface Props {
  page: ContentPage | null;
  createPage: (name: string, label: string, body: string) => void;
  editPage: (pageId: string, name: string, label: string, body: string) => void;
  handlePageMode: (value: string) => void;
}

const СontentPagesEdit = ({ page, createPage, editPage, handlePageMode }: Props) => {
  const [name, setName] = useState<string>('');
  const [label, setLabel] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [labelError, setLabelError] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    setName(page?.name ?? '');
    setLabel(page?.label ?? '');
    setBody(page?.body ?? '');
  }, [page]);

  const handleChangeBody = (value: string) => {
    setBody(value);
  };

  const handleSubmit = () => {
    setNameError(name ? '' : 'Будь ласка, заповніть це поле');
    setLabelError(label ? '' : 'Будь ласка, заповніть це поле');

    if (name && label) {
      if (!page) {
        createPage(name, label, body);
        handlePageMode(PageMode.LIST);
      } else {
        editPage(page?.id ?? '', name, label, body);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.inputBlock}>
        <Input
          label="Назва сторінки або блоку тексту"
          type="text"
          size="medium"
          className={classes.input}
          onChange={(e) => setName(e.target.value)}
          value={name}
          helperText={nameError}
          fullWidth
          required
        />
      </div>
      <div className={classes.inputBlock}>
        <Input
          label="Мітка"
          type="text"
          size="medium"
          className={classes.input}
          onChange={(e) => setLabel(e.target.value)}
          value={label}
          helperText={labelError}
          fullWidth
          required
        />
      </div>
      <div className={classes.inputHeaderBlock}>
        <Typography className={classes.inputHeaderText}>Текст поста:</Typography>
      </div>
      <div className={classes.inputEditorBlock}>
        <InputEditor valueInitial={body} handleChangeValue={handleChangeBody} />
      </div>
      <div className={classes.buttonBlock}>
        <Button
          onClick={() => handlePageMode(PageMode.LIST)}
          variant="outlined"
          className={classes.button}
        >
          Назад
        </Button>
        <Button onClick={handleSubmit} className={classes.button}>
          Зберегти
        </Button>
      </div>
    </div>
  );
};

export default СontentPagesEdit;
