import React from 'react';
import { Typography } from '@material-ui/core';
import Button from 'common/core/Button';
import { StoriesPageMode } from 'types/projects/tvorytelling';
import { useStyles } from './styles';

interface Props {
  pageMode: string;
  handlePageMode: (value: string) => void;
}

const MyStoriesHeader = ({ pageMode, handlePageMode }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.titleBlock}>
        <Typography className={classes.titleText}>
          {pageMode === StoriesPageMode.LIST && 'Мої шаблони  '}
          {pageMode === StoriesPageMode.ADD && 'Новий шаблон'}
          {pageMode === StoriesPageMode.EDIT && 'Редагування шаблону'}
        </Typography>
      </div>
      <div className={classes.actionBlock}>
        {pageMode === StoriesPageMode.LIST ? (
          <Button onClick={() => handlePageMode(StoriesPageMode.ADD)} className={classes.addButton}>
            Додати шаблон
          </Button>
        ) : (
          <Button onClick={() => handlePageMode(StoriesPageMode.LIST)} variant="outlined">
            Скасувати
          </Button>
        )}
      </div>
    </div>
  );
};

export default MyStoriesHeader;
