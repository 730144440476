import { makeStyles, Theme } from '@material-ui/core/styles';
import { IMAGE_BACKGROUND_COLOR, IMPORTANT_COLOR, IMPORTANT_COLOR_2 } from 'config';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '1px solid #EBEBEB',
    borderRadius: 6,
  },
  pictureBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: '1/1',
    backgroundColor: IMAGE_BACKGROUND_COLOR,
    borderRadius: '6px 6px 0 0',
  },
  isSpecialBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 5,
    right: 5,
    width: 22,
    height: 22,
    backgroundColor: IMPORTANT_COLOR_2,
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
  },
  leftBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 4,
    paddingLeft: 10,
  },
  titleBlock: {
    display: 'flex',
    paddingBottom: 3,
  },
  priceBlock: {
    display: 'flex',
  },
  actionsBlock: { paddingRight: 5 },
  picture: {
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '1/1',
    borderRadius: '6px 6px 0 0',
  },
  emptyPictureIcon: {
    fontSize: 120,
    color: '#fff',
    opacity: 0.7,
  },
  isSpecialIcon: {
    fontSize: 16,
    color: '#000',
  },
  titleText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '130%',
  },
  priceText: {
    fontSize: 16,
    fontWeight: 400,
    color: IMPORTANT_COLOR,
    lineHeight: '130%',
  },
  infoIcon: {
    fontSize: 14,
    color: '#666666',
    marginRight: 4,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginRight: 2,
    },
  },
  menuButton: {
    padding: 7,
  },
  subMenu: {
    minWidth: 170,
  },
  subMenuDelete: {
    color: '#FF0000 !important',
  },
  subMenuIcon: {},
}));
