import DateFnsAdapter from '@date-io/date-fns';
const dateFns = new DateFnsAdapter();

export const checkIsToday = (value: Date) => {
  const date = new Date(value);
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const checkIsYesterday = (value: Date) => {
  const date = new Date(value);
  const yesterday = new Date(Date.now() - 3600 * 24 * 1000);

  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
};

export const formatDateToList = (value: number) => {
  const date = new Date(value);
  const isToday = checkIsToday(date);
  const isYesterday = checkIsYesterday(date);

  if (isToday) {
    return 'today';
  }

  if (isYesterday) {
    return 'yesterday';
  }

  return date.toLocaleString('en-US', { month: 'long', day: 'numeric' });
};

export const formatDateBrief = (value: number) => {
  const date = new Date(value);

  return date
    .toLocaleString('en-GB', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      // hour: 'numeric',
      // minute: 'numeric',
    })
    .split('/')
    .join('.');
};

export const formatDateAdvanced = (value: number) => {
  const date = new Date(value);

  return date
    .toLocaleString('en-GB', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
    .split('/')
    .join('.');
};

export const formatTime = (time: number) => {
  let minutes = Math.floor(time / 60).toString();
  let seconds = Math.floor(time % 60).toString();
  if (seconds.toString().length === 1) {
    seconds = '0' + seconds;
  }
  return `${minutes}:${seconds}`;
};

export const formatTimeFromDate = (value: number) => {
  const date = new Date(value);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  const hoursStr = hours ? hours : 12;
  const minutesStr = minutes < 10 ? '0' + minutes : minutes;

  return hoursStr + ':' + minutesStr + ' ' + ampm;
};

export const dateConvertFromDatePicker = (value: string) => {
  if (typeof new Date(dateFns.date(value)).getTime() !== 'number') {
    return 0;
  }
  return new Date(dateFns.date(value)).getTime();
};

export const dateConvertToDatePicker = (value: number) => {
  return new Date(value + 3600000 * 2).toISOString().substring(0, 16);
};

export const dateStartDefault = () => {
  const dateNow = Date.now();
  return new Date(dateNow).toISOString().substring(0, 16);
};

export const dateEndDefault = () => {
  const dateNow = Date.now();
  return new Date(dateNow + 3600 * 1000 * 24 * 30).toISOString().substring(0, 16);
};
