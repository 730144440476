import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import MyStoryFramesListItem from './MyStoryFramesListItem';
import InfoBox from 'common/core/InfoBox';
import { StoryFrame } from 'types/projects/tvorytelling';
import { useStyles } from './styles';

interface Props {
  pageMode: string;
  actionMode: string;
  framesList: StoryFrame[];
  frameIdCurrent: string;
  deleteFrame: (frameId: string) => void;
  deleteRecord: (frameId: string) => void;
  reorderFrames: (orderFrom: number, orderTo: number) => void;
  handleStartRecording: (frameId: string) => void;
  handleStopRecording: (frameId: string) => void;
  handleStartPlaying: (frameId: string) => void;
  handleStopPlaying: (frameId: string) => void;
  handleStartEditing: (frameId: string) => void;
  handleStopEditing: (frameId: string, title: string) => void;
}

const MyStoryFramesList = ({
  pageMode,
  actionMode,
  framesList,
  frameIdCurrent,
  deleteFrame,
  deleteRecord,
  reorderFrames,
  handleStartRecording,
  handleStopRecording,
  handleStartPlaying,
  handleStopPlaying,
  handleStartEditing,
  handleStopEditing,
}: Props) => {
  const classes = useStyles();

  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    reorderFrames(result.source.index, result.destination.index);
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classes.droppableBlock}
            >
              <Grid container spacing={1}>
                {!framesList.length && (
                  <InfoBox
                    label="У цьому шаблоні поки немає жодного кадру."
                    className={classes.infoBox}
                  />
                )}
                {framesList.map((frame: StoryFrame, index: number) => (
                  <MyStoryFramesListItem
                    key={frame.id}
                    index={index}
                    pageMode={pageMode}
                    actionMode={actionMode}
                    frame={frame}
                    frameIdCurrent={frameIdCurrent}
                    deleteFrame={deleteFrame}
                    deleteRecord={deleteRecord}
                    handleStartRecording={handleStartRecording}
                    handleStopRecording={handleStopRecording}
                    handleStartPlaying={handleStartPlaying}
                    handleStopPlaying={handleStopPlaying}
                    handleStartEditing={handleStartEditing}
                    handleStopEditing={handleStopEditing}
                  />
                ))}
              </Grid>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default MyStoryFramesList;
