import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr 70px',
  },
  nameBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start !important',
    paddingLeft: '12px',
  },
  namePartBlock: {
    display: 'flex',
  },
  nameText: {
    fontSize: 16,
    fontWeight: 400,
  },
  emailText: {
    fontSize: 12,
    fontWeight: 400,
    color: 'rgba(0,0,0,0.5)',
  },
  text: {
    fontSize: 14,
    fontWeight: 350,
  },
  menuButton: {
    cursor: 'pointer',
  },
  subMenu: {
    minWidth: 180,
  },
  subMenuDelete: {
    color: '#FF0000 !important',
  },
  subMenuIcon: {},
}));
