import React, { useEffect, useState } from 'react';
import Table from 'common/core/Table';
import СontentPagesListItem from './СontentPagesListItem';
import Pagination from 'common/core/Pagination';
import { ContentPage } from 'types/general';
import { useStyles } from './styles';

interface Props {
  pagesList: ContentPage[];
  handleEditMode: (pageId: string) => void;
  deletePage: (pageId: string) => void;
}

const NUM_VALUES_ON_PAGE = 40;

const СontentPagesList = ({ pagesList, handleEditMode, deletePage }: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (pagesList.length) {
      const pageCountNew = Math.ceil(pagesList.length / NUM_VALUES_ON_PAGE);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [pagesList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Table>
        {pagesList
          .filter(
            (_: ContentPage, index: number) =>
              index >= (page - 1) * NUM_VALUES_ON_PAGE && index < page * NUM_VALUES_ON_PAGE,
          )
          .map((page: ContentPage) => (
            <СontentPagesListItem
              key={page.id}
              page={page}
              handleEditMode={handleEditMode}
              deletePage={deletePage}
            />
          ))}
      </Table>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default СontentPagesList;
