import React, { useRef } from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForeverTwoTone';
import Button from 'common/core/Button';
import { useStyles } from './styles';

interface Props {
  projectId: string;
  title: string;
  pictures: string[];
  createGalleryPicture: (projectId: string, file: any) => void;
  deleteGalleryPicture: (projectId: string, url: string) => void;
}

const ProjectsGallery = ({
  projectId,
  title,
  pictures,
  createGalleryPicture,
  deleteGalleryPicture,
}: Props) => {
  const fileRef = useRef<any>();
  const classes = useStyles();

  const handleUpoloadImage = () => {
    fileRef.current.click();
  };

  const chooseFileInUploader = (e: any) => {
    if (e.target.files.length) {
      let files: any[] = [];
      for (let x = 0; x < e.target.files.length; x++) {
        files.push(e.target.files[x]);
      }
      fileRef.current.value = '';
      createGalleryPicture(projectId, files);
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.titleBlock}>
          <Typography className={classes.titleText}>{title}</Typography>
        </div>
        <Grid container spacing={2}>
          {pictures.map((item) => (
            <Grid item md={4} sm={6} xs={12}>
              <div className={classes.elementBlock}>
                <div className={classes.pictureBlock}>
                  <img src={item} className={classes.picture} alt="" />
                </div>
                <div className={classes.deleteBlock}>
                  <IconButton
                    onClick={() => deleteGalleryPicture(projectId, item)}
                    className={classes.deleteButton}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
        <div className={classes.buttonBlock}>
          <Button onClick={handleUpoloadImage}>Додати фото</Button>
        </div>
      </div>
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={chooseFileInUploader}
        multiple
        accept="image/*"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default ProjectsGallery;
