import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { Place } from 'types/places';

export const usePlace = (placeId: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [placeData, setPlaceData] = useState<Place | null>(null);

  useEffect(() => {
    const getPlaceData = async (placeId: string) => {
      setIsLoading(true);
      const details = {
        placeId,
      };
      const results: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/places/get_place',
        details,
      );
      if (results?.data) {
        setPlaceData(results?.data);
        setIsLoading(false);
      }
    };

    if (placeId) {
      getPlaceData(placeId);
    }
  }, [placeId]);

  return {
    isLoading,
    placeData,
  };
};
