import React, { useState, useEffect } from 'react';
import PlacesAreaMap from './PlacesAreaMap';
import PlacesAreaAction from './PlacesAreaAction';
import PlacesAreaInfo from './PlacesAreaInfo';
import { usePlaceArea } from 'hooks/places/usePlaceArea';
import { Place } from 'types/places';
import { AreaInput } from 'types/places';

interface Props {
  placeId: string;
  place: Place;
  handlePageMode: (value: string) => void;
}

const PlacesArea = ({ placeId, place, handlePageMode }: Props) => {
  const [placePosition, setPlacePosition] = useState<[number, number]>();
  //   const router = useRouter();
  //   const routeId = typeof router?.query?.id === 'string' ? router.query.id : '';
  const { areaData, pointsList, addPointer, deletePointer, editArea } = usePlaceArea(placeId);

  useEffect(() => {
    if (place?.position) {
      setPlacePosition(place?.position);
    }
  }, [place]);

  const handleEditArea = () => {
    editArea();
  };

  return (
    <>
      <PlacesAreaInfo place={place} />
      {!!placePosition && (
        <PlacesAreaMap
          placePosition={placePosition}
          pointsList={pointsList}
          addPointer={addPointer}
          deletePointer={deletePointer}
        />
      )}
      <PlacesAreaAction handleEditArea={handleEditArea} />
    </>
  );
};

export default PlacesArea;
