import React, { useState } from 'react';
import {} from '@material-ui/core';
import { useStyles } from './styles';

const PlaceInfo = ({}: any) => {
  const classes = useStyles();

  return <div></div>;
};

export default PlaceInfo;
