import { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { Project } from 'types/projects';

export const useProjectCategories = () => {
  const [categoriesList, setCategoriesList] = useState<Project[]>([]);

  useEffect(() => {
    const getProjectCategories = async () => {
      const results: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/projects/get_project_categories',
        {},
      );

      if (results?.data?.categories) {
        setCategoriesList(results?.data?.categories);
      }
    };

    getProjectCategories();
  }, []);

  return {
    categoriesList,
  };
};
