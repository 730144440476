import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { timeout } from 'utils/timeout';
import { StoryCategory, Story, StoryFrame } from 'types/projects/tvorytelling';

export const useMyStory = (storyId: string) => {
  const [storyData, setStoryData] = useState<any | null>(null);
  const [framesList, setFramesList] = useState<StoryFrame[]>([]);

  useEffect(() => {
    getStory();
    getFrames();
  }, [storyId]);

  const getStory = useCallback(async () => {
    const details = {
      storyId,
    };
    const results: AxiosResponse = await fetchAPI(
      'post',
      // 'http://localhost:4000/v1/tvorytelling/get_my_story',
      process.env.REACT_APP_REST_API_URL + '/tvorytelling/get_my_story',
      details,
    );
    if (results?.data) {
      // console.log(results.data);
      setStoryData(results.data);
    }
  }, [storyId]);

  const getFrames = useCallback(async () => {
    const details = {
      storyId,
    };
    const results: AxiosResponse = await fetchAPI(
      'post',
      // 'http://localhost:4000/v1/tvorytelling/get_my_frames',
      process.env.REACT_APP_REST_API_URL + '/tvorytelling/get_my_frames',
      details,
    );
    if (results?.data) {
      setFramesList(results.data.frames);
    }
  }, [storyId]);

  const createFrame = useCallback(
    async (file: any) => {
      let formData = new FormData();
      formData.append('file', file);
      formData.append('storyId', storyId);
      await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/tvorytelling/create_frame',
        formData,
      );
      await timeout(1500);
      getFrames();
    },
    [storyId],
  );

  const editFrame = useCallback(
    async (frameId: string, title: string) => {
      const details = {
        frameId,
        title,
      };
      await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/tvorytelling/edit_frame',
        details,
      );
      const framesListNew = framesList.map((item: StoryFrame) => {
        if (item.id === frameId) {
          return {
            ...item,
            title,
          };
        }
        return item;
      });
      setFramesList(framesListNew);
    },
    [framesList],
  );

  const deleteFrame = useCallback(
    async (frameId: string) => {
      const details = { frameId };
      await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/tvorytelling/delete_frame',
        details,
      );
      const framesListNew = framesList.filter((item: StoryFrame) => item.id !== frameId);
      setFramesList(framesListNew);
    },
    [framesList],
  );

  const reorderFrames = useCallback(
    async (indexFrom: number, indexTo: number) => {
      let framesListNew = framesList.filter((item: StoryFrame, index) => index !== indexFrom);
      const frameChanged = framesList.find((item: StoryFrame, index) => index === indexFrom);

      if (frameChanged) {
        framesListNew.splice(indexTo, 0, frameChanged);

        setFramesList(framesListNew);

        const framesListForAPI = framesListNew.map((item: StoryFrame, index: number) => ({
          frameId: item?.id,
          index,
        }));

        const details = {
          list: framesListForAPI,
        };
        await fetchAPI(
          'post',
          process.env.REACT_APP_REST_API_URL + '/tvorytelling/reorder_frames',
          details,
        );
      }
    },
    [framesList],
  );

  const createRecord = useCallback(async (frameId: string, file: any) => {
    let formData = new FormData();
    formData.append('frameId', frameId);
    formData.append('file', file);

    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/tvorytelling/create_record',
      formData,
    );
    // if (results?.data?.recordUrl) {
    //   const framesListNew = framesList.map((item: StoryFrame) => {
    //     if (item.id === frameId) {
    //       return {
    //         ...item,
    //         recordUrl: results.data.recordUrl,
    //       };
    //     }
    //     return item;
    //   });
    //   setFramesList(framesListNew);
    // }
    getFrames();
  }, []);

  const deleteRecord = useCallback(async (frameId: string) => {
    const details = {
      frameId,
    };
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/tvorytelling/delete_record',
      details,
    );
    // const framesListNew = framesList.map((item: StoryFrame) => {
    //   if (item.id === frameId) {
    //     return {
    //       ...item,
    //       recordUrl: '',
    //     };
    //   }
    //   return item;
    // });
    // setFramesList(framesListNew);
    getFrames();
  }, []);

  return {
    storyData,
    framesList,
    createFrame,
    editFrame,
    deleteFrame,
    reorderFrames,
    createRecord,
    deleteRecord,
  };
};
