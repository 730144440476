import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { PoetryWalksItem, PoetryInputCreate, PoetryInputEdit } from 'types/projects/poetry';

export const usePoetry = (authorId: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [poetryList, setPoetryList] = useState<PoetryWalksItem[]>([]);
  const [poetryListFormated, setPoetryListFormated] = useState<PoetryWalksItem[]>([]);

  useEffect(() => {
    getPoetry();
  }, []);

  useEffect(() => {
    if (authorId) {
      const poetryListNew = poetryList.filter((item) => item.authorId === authorId);
      setPoetryListFormated(poetryListNew);
    } else {
      setPoetryListFormated(poetryList);
    }
  }, [poetryList, authorId]);

  const getPoetry = async () => {
    setIsLoading(true);
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/poetry/get_admin_poetry',
      {},
    );
    if (results?.data?.poetry) {
      setPoetryList(results.data.poetry);
      setIsLoading(false);
    }
  };

  const createPoetry = useCallback(async (details: PoetryInputCreate) => {
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/poetry/create_poetry', details);
    getPoetry();
  }, []);

  const editPoetry = useCallback(async (details: PoetryInputEdit) => {
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/poetry/edit_poetry', details);
    getPoetry();
  }, []);

  const editPoetryAudio = useCallback(async (poetryId: string, file: any) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('poetryId', poetryId);

    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/poetry/edit_poetry_audio',
      formData,
    );
    getPoetry();
  }, []);

  const deletePoetryAudio = useCallback(async (poetryId: string) => {
    const details = { poetryId };
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/poetry/delete_poetry_audio',
      details,
    );
    getPoetry();
  }, []);

  const deletePoetry = useCallback(async (poetryId: string) => {
    const details = { poetryId };
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/poetry/delete_poetry', details);
    getPoetry();
  }, []);

  return {
    isLoading,
    poetryList: poetryListFormated,
    createPoetry,
    editPoetry,
    editPoetryAudio,
    deletePoetryAudio,
    deletePoetry,
  };
};
