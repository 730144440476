import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #EBEBEB',
    borderRadius: 6,
  },
  pictureBlock: {
    position: 'relative',
    display: 'flex',
    borderRadius: '6px 6px 0 0',
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingTop: 6,
    paddingBottom: 6,
  },
  infoBlockWithoutText: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    minHeight: 0,
  },
  leftBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: 10,
  },
  titleBlock: {
    display: 'flex',
  },
  editBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  inputBlock: {
    display: 'flex',
    width: '100%',
  },
  editButtonBlock: {
    display: 'flex',
    marginLeft: 7,
  },
  actionsBlock: { display: 'flex', paddingRight: 3 },
  picture: {
    width: '100%',
    borderRadius: 4,
  },
  titleText: {
    fontSize: 16,
    fontWeight: 350,
  },
  titleTextDefault: {
    fontSize: 14,
    fontWeight: 350,
    fontStyle: 'italic',
    color: '#999',
  },
  input: {
    backgroundColor: '#fff',
    margin: 0,
    fontSize: 16,
    fontWeight: 350,
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '&:hover .MuiInputLabel-outlined': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  inputNotchedOutline: {
    border: '1px solid rgba(0, 0, 0, 0.38) !important',
  },
  inputFocused: {
    borderWidth: '1px ',
    borderColor: 'rgba(0, 0, 0, 0.38) !important',
  },

  editButton: {
    border: `2px solid ${theme.palette.secondary.main}`,
    width: 30,
    height: 30,
  },
  editIcon: {
    fontSize: 20,
    color: theme.palette.secondary.main,
  },
  draggableButton: {
    position: 'absolute',
    top: 5,
    left: 5,
    // border: `2px solid ${theme.palette.secondary.main}`,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    width: 45,
    height: 45,
    marginRight: 2,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  draggableIcon: {
    fontSize: 30,
    color: theme.palette.secondary.main,
  },
  actionViewButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    margin: 'auto',
    // border: `2px solid ${theme.palette.secondary.main}`,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    width: 45,
    height: 45,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  actionViewIcon: {
    fontSize: 80,
    color: theme.palette.secondary.main,
  },
  actionButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    // border: `2px solid ${theme.palette.secondary.main}`,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    width: 45,
    height: 45,
    marginRight: 2,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  actionIcon: {
    fontSize: 30,
    color: theme.palette.secondary.main,
  },
  actionButtonRed: {
    border: '2px solid red !important',
  },
  actionIconRed: {
    color: 'red !important',
  },
  menuButton: {
    width: 45,
    height: 45,
  },
  subMenu: {
    minWidth: 200,
  },
  subMenuDelete: {
    color: '#FF0000 !important',
  },
  subMenuIcon: {},
}));