import React, { useState, useEffect } from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import Map from 'common/core/Map';
import MapMarkerDraggable from 'common/core/Map/MapMarkerDraggable';
import { centerMapDefault, zoomCoutryDefault, zoomCityDefault } from 'config';
import { PlacesPageMode, Place, PlaceCategory } from 'types/places';
import { useStyles } from './styles';

interface Props {
  place: Place | null;
  categoriesList: PlaceCategory[];
  createPlace: (
    categoryId: string,
    name: string,
    description: string,
    latitude: number,
    longitude: number,
  ) => void;
  editPlace: (
    placeId: string,
    categoryId: string,
    name: string,
    description: string,
    latitude: number,
    longitude: number,
  ) => void;
  handlePageMode: (value: string) => void;
}

const PlacesEdit = ({ place, categoriesList, createPlace, editPlace, handlePageMode }: Props) => {
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [location, setLocation] = useState<string>('');
  const [categoryId, setCategoryId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  const [centerMap, setCenterMap] = useState<[number, number]>([0, 0]);
  const [zoomMap, setZoomMap] = useState<number>(place ? zoomCityDefault : zoomCoutryDefault);
  const [nameError, setNameError] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    if (place) {
      setCategoryId(place?.categoryId ?? '');
      setName(place?.name ?? '');
      setDescription(place?.description ?? '');
      setLatitude(place?.latitude ?? 0);
      setLongitude(place?.longitude ?? 0);
      setCenterMap([place?.latitude ?? 0, place?.longitude ?? 0]);
    } else {
      setLatitude(centerMapDefault[0]);
      setLongitude(centerMapDefault[1]);
      setCenterMap([centerMapDefault[0], centerMapDefault[1]]);
    }
  }, [place]);

  // useEffect(() => {
  //   if (isChanged) {
  //     if (cityId) {
  //       const city: any = citiesList.find((city: City) => city.id === cityId);
  //       if (city?.latitude && city?.longitude) {
  //         setZoomMap(zoomCityDefault);
  //         if (isChanged) {
  //           setLatitude(city?.latitude);
  //           setLongitude(city?.longitude);
  //           setCenterMap([city?.latitude, city?.longitude]);
  //         }
  //       }
  //     } else {
  //       setLatitude(centerMapDefault[0]);
  //       setLongitude(centerMapDefault[1]);
  //       setCenterMap([centerMapDefault[0], centerMapDefault[1]]);
  //       setZoomMap(zoomCoutryDefault);
  //     }
  //   }
  // }, [cityId, citiesList]);

  const handleChangePosition = (value: [number, number]) => {
    setLatitude(value[0]);
    setLongitude(value[1]);
  };

  const handleSubmit = () => {
    setNameError(name ? '' : 'Заповніть назву місця');

    if (name) {
      if (place) {
        editPlace(place?.id ?? '', categoryId, name, description, latitude, longitude);
      } else {
        createPlace(categoryId, name, description, latitude, longitude);
      }
      handlePageMode(PlacesPageMode.LIST);
    }
  };

  return (
    <div className={classes.container}>
      {/* <div className={classes.inputBlock}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Категорія</InputLabel>
          <Select
            label="Категорія"
            native
            onChange={(e: any) => setCategoryId(e.target.value)}
            value={categoryId}
            className={classes.select}
          >
            <option aria-label="None" value="" key={0}></option>
            {categoriesList
              .sort((a: PlaceCategory, b: PlaceCategory) => a.name.localeCompare(b.name))
              .map((item: PlaceCategory) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
          </Select>
        </FormControl>
      </div> */}
      <div className={classes.inputBlock}>
        <Input
          label="Назва місця"
          type="text"
          className={classes.input}
          onChange={(e) => setName(e.target.value)}
          value={name}
          helperText={nameError}
          fullWidth
          required
        />
      </div>
      <div className={classes.inputBlock}>
        <Input
          label="Опис"
          type="text"
          className={classes.input}
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          fullWidth
          multiline
          rows={4}
          required
        />
      </div>
      <div className={classes.inputBlock}>
        <Input
          label="Latitude"
          type="text"
          className={classes.input}
          onChange={(e) => setLatitude(+e.target.value)}
          value={latitude.toString()}
          fullWidth
        />
      </div>
      <div className={classes.inputBlock}>
        <Input
          label="Долгота"
          type="text"
          className={classes.input}
          onChange={(e) => setLongitude(+e.target.value)}
          value={longitude.toString()}
          fullWidth
        />
      </div>
      <div className={classes.mapBlock}>
        <Map center={centerMap} zoom={zoomMap}>
          <MapMarkerDraggable
            position={[latitude, longitude]}
            handleChangePosition={handleChangePosition}
          />
        </Map>
      </div>
      <div className={classes.buttonBlock}>
        <Button onClick={handleSubmit}>Зберегти</Button>
      </div>
    </div>
  );
};

export default PlacesEdit;
