import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { timeout } from 'utils/timeout';
import { ShopCategory, ShopProduct, ShopProductInput } from 'types/shop';
import { SkillCategory } from 'types/general';
import { useAlert } from '../useAlert';

export const useShopProducts = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productsList, setProductsList] = useState<ShopProduct[]>([]);
  const [categoriesList, setCategoriesList] = useState<ShopCategory[]>([]);
  const [skillsList, setSkillsList] = useState<SkillCategory[]>([]);
  const showAlert = useAlert();

  useEffect(() => {
    const getCategories = async () => {
      const results: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/shop/get_product_categories',
        {},
      );
      if (results?.data?.categories) {
        setCategoriesList(results?.data?.categories);
      }
    };

    getCategories();
  }, []);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = useCallback(async () => {
    setIsLoading(true);
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/shop/get_admin_products',
      {},
    );
    if (results?.data?.products) {
      setProductsList(results?.data?.products);
    }
    if (results?.data?.skills) {
      setSkillsList(results?.data?.skills);
    }
    setIsLoading(false);
  }, []);

  const createProduct = useCallback(async (details: ShopProductInput) => {
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/shop/create_product',
      details,
    );
    if (results?.data?.productId) {
      showAlert('New product was created.');
      getProducts();
      return results?.data?.productId;
    }
    return '';
  }, []);

  const editProduct = useCallback(async (details: ShopProductInput) => {
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/shop/edit_product', details);
    showAlert('Product was updated.');
    getProducts();
  }, []);

  const editProductPicture = useCallback(async (productId: string, file: any) => {
    let formData = new FormData();
    formData.append('productId', productId);
    formData.append('file', file);
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/shop/edit_product_picture',
      formData,
    );
    showAlert('Picture for product was updated.');
    getProducts();
  }, []);

  const createGalleryPicture = useCallback(async (productId: string, file: any) => {
    let formData = new FormData();
    formData.append('productId', productId);
    formData.append('file', file);
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/shop/create_product_gallery_picture',
      formData,
    );
    await timeout(2000);
    getProducts();
  }, []);

  const deleteGalleryPicture = useCallback(async (productId: string, id: string) => {
    const details = { productId, id };
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/shop/delete_product_gallery_picture',
      details,
    );

    getProducts();
  }, []);

  const deleteProduct = useCallback(async (productId: string) => {
    const details = { productId };
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/shop/delete_product', details);
    showAlert('Product was deleted.');
    getProducts();
  }, []);

  return {
    isLoading,
    categoriesList,
    skillsList,
    productsList,
    createProduct,
    editProduct,
    editProductPicture,
    createGalleryPicture,
    deleteGalleryPicture,
    deleteProduct,
  };
};
