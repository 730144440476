import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { CircularProgress } from '@material-ui/core';
import QuestionIcon from '@material-ui/icons/Help';
import RightIcon from '@material-ui/icons/DoneAll';
import WrongIcon from '@material-ui/icons/Close';
import Input from 'common/core/Input';
import { useStyles } from './styles';

interface Props {
  label: string;
  value: string;
  handleChangeValue: (value: string) => void;
  handleCheckValue: (value: string) => Promise<boolean>;
}

const InputCheck = ({ label, value, handleChangeValue, handleCheckValue }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRight, setIsRight] = useState<boolean | null>(null);
  const classes = useStyles();

  useEffect(() => {
    setIsRight(null);
    setIsLoading(false);
  }, [value]);

  const submitCheck = async () => {
    setIsLoading(true);
    const isRightNew = await handleCheckValue(value);
    setIsRight(isRightNew);
    setIsLoading(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.inputBlock}>
        <Input
          size="medium"
          label={label}
          type="text"
          className={classes.input}
          onChange={(e) => handleChangeValue(e.target.value)}
          value={value}
          fullWidth
          required
        />
      </div>
      <div className={classes.actionBlock}>
        {!!value && (
          <>
            {isLoading ? (
              <CircularProgress size={32} />
            ) : (
              <>
                {isRight === null && (
                  <QuestionIcon
                    onClick={submitCheck}
                    className={classnames(classes.icon, classes.questionIcon)}
                  />
                )}
                {isRight === true && (
                  <RightIcon className={classnames(classes.icon, classes.rightIcon)} />
                )}
                {isRight === false && (
                  <WrongIcon className={classnames(classes.icon, classes.wrongIcon)} />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default InputCheck;
