import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  buttonContained: {
    backgroundColor: '#ff6b00',
  },
  buttonOutlined: {
    color: 'rgba(15, 15, 33, 0.6)',
    borderColor: 'rgba(15, 15, 33, 0.3)',
    backgroundColor: 'rgba(15, 15, 33, 0.1)',
  },
  root: {
    fontSize: 14,
    fontWeight: 400,
    // minWidth: 142,
    // marginLeft: 4,
    // marginRight: 4,
    textTransform: 'none',
  },
}));
