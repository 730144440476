import { useState, useEffect, useRef } from 'react';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import PublishIcon from '@material-ui/icons/Publish';
import { NatureTask } from 'types/projects/nature';
import { useStyles } from './styles';

const MAX_GALLERY_AMOUNT = 4;

interface Props {
  task: NatureTask;
  editTaskPicture: (taskId: string, file: any) => void;
  deleteTaskPicture: (taskId: string) => void;
  createGalleryPicture: (taskId: string, file: any) => void;
  deleteGalleryPicture: (taskId: string, id: string) => void;
}

const TaskEditPictures = ({
  task,
  editTaskPicture,
  deleteTaskPicture,
  createGalleryPicture,
  deleteGalleryPicture,
}: Props) => {
  const [pictureUrl, setPictureUrl] = useState<string>('');
  const [pictures, setPictures] = useState<any[]>([]);
  const fileRef = useRef<any>();
  const fileGalleryRef = useRef<any>();
  const classes = useStyles();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    timer = setTimeout(() => {
      setPictureUrl(task?.pictureUrl);
      setPictures(task?.pictures ?? []);
    }, 1000);
    return () => clearTimeout(timer);
  }, [task]);

  const handleUpoloadImage = () => {
    fileRef.current.click();
  };

  const chooseFileInUploader = (e: any) => {
    if (e.target.files.length) {
      editTaskPicture(task?.id ?? '', e.target.files[0]);
      fileRef.current.value = '';
    }
  };

  const handleGalleryUpoloadImage = () => {
    fileGalleryRef.current.click();
  };

  const chooseGalleryFileInUploader = (e: any) => {
    if (e.target.files.length) {
      createGalleryPicture(task?.id ?? '', e.target.files[0]);
      fileGalleryRef.current.value = '';
    }
  };

  return (
    <>
      {!!task && (
        <div className={classes.picturesBlock}>
          <div className={classes.pictureBlock}>
            {!!pictureUrl && <img src={pictureUrl} className={classes.picture} alt="" />}
            {!pictureUrl ? (
              <PublishIcon onClick={handleUpoloadImage} className={classes.editIcon} />
            ) : (
              <DeleteIcon onClick={() => deleteTaskPicture(task.id)} className={classes.editIcon} />
            )}
          </div>
          {pictures?.map(({ thumbnailUrl, pictureUrl, id }: any) => (
            <div className={classes.galleryItemBlock}>
              <img src={thumbnailUrl} className={classes.picture} alt="" />
              <DeleteIcon
                onClick={() => deleteGalleryPicture(task.id, id)}
                className={classes.editIcon}
              />
            </div>
          ))}
          {MAX_GALLERY_AMOUNT > pictures.length && (
            <div className={classes.galleryItemBlock}>
              <PublishIcon onClick={handleGalleryUpoloadImage} className={classes.editIcon} />
            </div>
          )}
        </div>
      )}
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={chooseFileInUploader}
        multiple
        accept="image/*"
        style={{ display: 'none' }}
      />
      <input
        type="file"
        id="file"
        ref={fileGalleryRef}
        onChange={chooseGalleryFileInUploader}
        multiple
        accept="image/*"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default TaskEditPictures;
