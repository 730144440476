import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { Grid, Typography, IconButton, TextField } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PlayIcon from '@material-ui/icons/PlayArrowTwoTone';
import PauseIcon from '@material-ui/icons/PauseTwoTone';
import MicIcon from '@material-ui/icons/MicNoneTwoTone';
import StopIcon from '@material-ui/icons/StopTwoTone';
import DeleteRecordIcon from '@material-ui/icons/HighlightOffTwoTone';
import { DeleteTwoTone as DeleteIcon, ImageTwoTone as ImageIcon } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import { StoryFrame, StoryPageMode, StoryActionMode } from 'types/projects/tvorytelling';
import { useStyles } from './styles';

interface Props {
  index: number;
  actionMode: string;
  frame: StoryFrame;
  frameIdCurrent: string;
  handleStartPlaying: (frameId: string) => void;
  handleStopPlaying: (frameId: string) => void;
}

const StoryFramesListItem = ({
  index,
  actionMode,
  frame,
  frameIdCurrent,
  handleStartPlaying,
  handleStopPlaying,
}: Props) => {
  const { id: frameId, title, pictureUrl, recordUrl } = frame;
  const audioRef = useRef<any>();
  const classes = useStyles();

  useEffect(() => {
    audioRef.current.src = recordUrl;
    audioRef.current.currentTime = 0;
  }, [recordUrl]);

  useEffect(() => {
    if (actionMode === StoryActionMode.PLAYING && frameId === frameIdCurrent) {
      audioRef.current.play();
      audioRef.current.addEventListener('ended', function () {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      });
    }
    if (
      (actionMode === StoryActionMode.PLAYING && frameId !== frameIdCurrent) ||
      actionMode !== StoryActionMode.PLAYING
    ) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [frameIdCurrent, frameId]);

  const handleStartPlayingAction = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    handleStartPlaying(frameId);
  };

  const handleStopPlayingAction = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    handleStopPlaying(frameId);
  };

  return (
    <>
      <Grid item lg={4} md={6} sm={6} xs={12}>
        <div>
          <div className={classes.container}>
            <div className={classes.pictureBlock}>
              <img src={pictureUrl} className={classes.picture} alt="" />
              {(actionMode !== StoryActionMode.PLAYING ||
                (actionMode === StoryActionMode.PLAYING && frameId !== frameIdCurrent)) &&
                frameId !== frameIdCurrent &&
                recordUrl !== '' && (
                  <IconButton
                    onClick={handleStartPlayingAction}
                    className={classes.actionViewButton}
                  >
                    <PlayIcon className={classes.actionViewIcon} />
                  </IconButton>
                )}
              {actionMode === StoryActionMode.PLAYING &&
                frameId === frameIdCurrent &&
                recordUrl !== '' && (
                  <IconButton
                    onClick={handleStopPlayingAction}
                    className={classes.actionViewButton}
                  >
                    <PauseIcon className={classes.actionViewIcon} />
                  </IconButton>
                )}
            </div>

            <div
              className={classnames(
                classes.infoBlock,
                title === '' && classes.infoBlockWithoutText,
              )}
            >
              <div className={classes.leftBlock}>
                <div className={classes.titleBlock}>
                  {/* <Typography
                    onClick={handleStartEditingAction}
                    className={title ? classes.titleText : classes.titleTextDefault}
                  >
                    {title ? `${title}` : `Додати текст...`}
                  </Typography> */}

                  <Typography className={title ? classes.titleText : classes.titleTextDefault}>
                    {title}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <audio ref={audioRef} />
    </>
  );
};

export default StoryFramesListItem;
