import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 5px 20px rgba(23, 33, 48, 0.09)',
    borderRadius: 12,
    padding: 10,
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 20,
  },
  titleBlock: {
    display: 'flex',
    marginBottom: 10,
  },
  errorBlock: {
    display: 'flex',
    marginBottom: 10,
  },
  pictureBlock: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '16/9',
    backgroundColor: '#336699',
    marginBottom: 25,
  },
  inputBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    marginBottom: 15,
  },
  inputEditorBlock: {
    display: 'flex',
    height: 500,
    marginBottom: 15,
  },
  inputHeaderBlock: {
    marginBottom: 4,
  },
  optionsListBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  optionsListHeaderBlock: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 7,
  },
  optionBlock: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  optionCheckboxBlock: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 0,
    padding: 3,
    paddingRight: 5,
  },
  optionNameBlock: {
    display: 'flex',
    flexGrow: 1,
  },
  buttonBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    marginTop: 20,
  },
  picture: {
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '16/9',
  },
  editIcon: {
    fontSize: 24,
    position: 'absolute',
    top: 5,
    right: 5,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },

  titleText: {
    fontSize: 18,
    fontWeight: 450,
  },
  errorText: {
    fontSize: 16,
    fontWeight: 300,
    color: '#ff0000',
  },
  inputHeaderText: {
    fontSize: 14,
    fontWeight: 450,
  },
  select: {
    width: 315,
    maxWidth: '100%',
  },
  selectHelper: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    color: '#000',
    paddingBottom: 8,
  },
  input: {
    maxWidth: 1000,
    width: '100%',
  },
  inputDatePicker: {
    maxWidth: 200,
  },
  inputFileUpload: {
    maxWidth: 400,
    cursor: 'pointer',
  },
  inputLimited: {
    maxWidth: 550,
  },
  inputTextarea: {
    width: 315,
    height: 100,
  },
  closeIcon: {
    color: '#999',
    cursor: 'pointer',
  },
  optionsListHeaderText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '100%',
    color: '#000',
  },
  optionCheckbox: {
    padding: 0,
  },
  optionNameText: {
    fontSize: 14,
    fontWeight: 350,
    lineHeight: '100%',
    color: '#333',
  },
  switchText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '100%',
    color: '#000',
  },
  button: {
    minWidth: 130,
  },
}));
