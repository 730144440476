export interface SkillCategory {
  id: string;
  name: string;
}

export enum PageMode {
  LIST = 'list',
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
}

export enum EmailTemplatesPageMode {
  LIST = 'list',
  ADD = 'add',
  EDIT = 'edit',
}

export interface EmailTemplate {
  id: string;
  name: string;
  label: string;
  subject?: string;
  body: string;
  userId?: string;
  createdAt?: number;
  isPublic?: boolean;
}

export interface ContentPage {
  id: string;
  name: string;
  label: string;
  body: string;
  isPublic?: boolean;
}

export interface TeamMember {
  id: string;
  name: string;
  position?: string;
  email?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  pictureUrl?: string;
  isPublic: boolean;
}

export interface TeamMemberInput {
  memberId?: string;
  name: string;
  position?: string;
  email?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  isPublic: boolean;
}
