import { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { AlphabetSet } from 'types/projects/alphabet';

export const useLetterSets = () => {
  const [setsList, setSetsList] = useState<AlphabetSet[]>([]);

  const getLetterSets = async () => {
    const details = {};
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/alphabet/get_admin_letter_sets',
      details,
    );
    if (results?.data?.sets) {
      setSetsList(results?.data?.sets);
    }
  };

  useEffect(() => {
    getLetterSets();
  }, []);

  return {
    setsList,
  };
};
