import { useEffect, useContext, useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { Context } from 'context/Context';

export const useConfirmLink = (confirmCode: String) => {
  const [confirmStatus, setConfirmStatus] = useState<boolean | null>(null);

  useEffect(() => {
    const confirmLink = async () => {
      if (!confirmCode) {
        setConfirmStatus(false);
      } else {
        const details = {
          confirmCode,
        };

        const results: AxiosResponse = await fetchAPI(
          'post',
          // 'http://localhost:4000/v1/auth/confirm_link',
          process.env.REACT_APP_REST_API_URL + '/auth/confirm_link',
          details,
        );

        if (results?.data) {
          if (results.data?.error) {
            setConfirmStatus(false);
          } else {
            setConfirmStatus(true);
          }
        }
      }
    };

    confirmLink();
  }, [confirmCode]);

  return { confirmStatus };
};
