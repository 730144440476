import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Grid, Typography, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Person as PersonIcon } from '@material-ui/icons';
import StoryBigIcon from 'assets/icons/story2.svg';
import { formatDateBrief } from 'utils/datetime';
import { Story } from 'types/projects/tvorytelling';
import { useStyles } from './styles';

interface Props {
  story: Story;
}

const StoriesListItem = ({ story }: Props) => {
  const { id: storyId, title, pictureUrl, userName, numFrames, createdAt } = story;
  const history = useHistory();
  const pictureRef = useRef<any>();
  const classes = useStyles();

  useEffect(() => {
    if (pictureUrl) {
      pictureRef.current.src = pictureUrl;
    }
  }, [pictureUrl]);

  return (
    <>
      <Grid item md={4} sm={6} xs={12}>
        <div className={classes.container}>
          <div
            className={pictureUrl ? classes.pictureBlock : classes.pictureEmptyBlock}
            onClick={() => history.push(`/tvorytelling/story/${storyId}`)}
          >
            <img
              src={pictureUrl ? pictureUrl : StoryBigIcon}
              className={pictureUrl ? classes.picture : classes.imgEmptyIcon}
              ref={pictureRef}
              alt=""
            />
          </div>
          <div className={classes.infoBlock}>
            <div className={classes.leftBlock}>
              <div className={classes.titleBlock}>
                <Typography
                  className={classnames(classes.titleText, !title && classes.titleNoText)}
                >
                  {!!title ? title : 'Немає назви'}
                </Typography>
              </div>
              <div className={classes.dateBlock}>
                <PersonIcon className={classes.infoIcon} />
                <Typography className={classes.infoText}>{userName}</Typography>
              </div>
              <div className={classes.dateBlock}>
                <Typography className={classes.infoText}>Кадрів: {numFrames}</Typography>
              </div>
              <div className={classes.dateBlock}>
                <Typography className={classes.infoText}>
                  Створено: {formatDateBrief(createdAt)}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default StoriesListItem;
