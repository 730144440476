import { useCallback } from 'react';
import { useSnackbar, VariantType } from 'notistack';

export const useAlert = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showAlert = useCallback((message: string, variant: VariantType | undefined = 'default') => {
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: 3000,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    });
  }, []);

  return showAlert;
};
