import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { Podcast } from 'types/podcasts';

export const usePodcasts = () => {
  return useQuery(
    ['podcasts'],
    async () => {
      const result: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/podcasts/get_admin_podcasts',
        {},
      );
      const recordsList: Podcast[] = result?.data?.podcasts ?? [];

      return recordsList;
    },
    {
      refetchInterval: 10 * 60 * 60 * 6,
    },
  );
};
