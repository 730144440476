import React, { useState, useEffect } from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import Modal from 'common/core/Modal';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import { Podcast, PodcastsEpisode, PodcastsEpisodeInput } from 'types/podcasts';
import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
  podcastsList: Podcast[];
  episode: PodcastsEpisode | null;
  createEpisode: (details: PodcastsEpisodeInput) => void;
  editEpisode: (details: PodcastsEpisodeInput) => void;
}

const PoetryEdit = ({
  isOpen,
  toggleIsOpen,
  podcastsList,
  episode,
  createEpisode,
  editEpisode,
}: Props) => {
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const [title, setTitle] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [podcastId, setPodcastId] = useState<string>('');
  const [recordAuthorName, setRecordAuthorName] = useState<string>('');
  // const [nameError, setNameError] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    setTitle(episode?.title ?? '');
    setPodcastId(episode?.podcastId ?? ''); //   setRecordAuthorName(poetry?.recordAuthorName ?? '');
    setIsPublic(episode?.isPublic ?? false);
  }, [episode, isOpen]);

  const handleSubmit = () => {
    // setNameError(name ? '' : 'Заповніть назву вірша');

    // if (name) {
    if (!episode?.id) {
      createEpisode({
        title,
        podcastId,
        isPublic,
      });
    } else {
      editEpisode({
        episodeId: episode?.id ?? '',
        title,
        podcastId,
        isPublic,
      });
    }
    toggleIsOpen();
    // }
  };

  return (
    <Modal
      title={episode?.id ? 'Редагування eпізода подкаста' : 'Новий eпізод подкаста'}
      widthMax={550}
      open={isOpen}
      handleClose={toggleIsOpen}
    >
      <div className={classes.container}>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Podcast episode title"
            type="text"
            className={classes.input}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            // helperText={nameError}
            fullWidth
            required
          />
        </div>
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Podcast name</InputLabel>
            <Select
              label="Podcast name"
              native
              onChange={(e: any) => setPodcastId(e.target.value)}
              value={podcastId}
              className={classes.select}
            >
              <option aria-label="None" value="" key={0}></option>
              {podcastsList.map(({ id, title }) => (
                <option value={id} key={id}>
                  {title}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.inputBlock}>
          <Input
            label="Опис"
            type="text"
            className={classes.input}
            onChange={(e) => setBody(e.target.value)}
            value={body}
            fullWidth
            multiline
            rows={8}
          />
        </div>
        {/* {!!episode?.recordUrl && (
          <div className={classes.inputBlock}>
            <Input
              size="medium"
              label="Хто озвучував"
              type="text"
              className={classes.input}
              onChange={(e) => setRecordAuthorName(e.target.value)}
              value={recordAuthorName}
              fullWidth
            />
          </div>
        )} */}
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Доступ</InputLabel>
            <Select
              label="Доступ"
              native
              onChange={(e: any) => setIsPublic(e.target.value === 'true' ? true : false)}
              value={isPublic ? 'true' : 'false'}
              className={classes.select}
            >
              <option value="false" key="private">
                Сховати
              </option>
              <option value="true" key="public">
                Показувати
              </option>
            </Select>
          </FormControl>
        </div>
        <div className={classes.buttonBlock}>
          <Button onClick={handleSubmit}>Зберегти</Button>
        </div>
      </div>
    </Modal>
  );
};

export default PoetryEdit;
