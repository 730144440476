export const googleClientId =
  '783272358398-fgs4jahh9pc71m7p71clvhujurbkpa1t.apps.googleusercontent.com';
export const numValuesOnPage = 24;

export const IMAGE_BACKGROUND_COLOR = '#006699';
export const IMAGE_BACKGROUND_SECONDARY_COLOR = '#006699';
export const IMPORTANT_COLOR = '#b8144c';
export const IMPORTANT_COLOR_2 = '#f4c700';

export const NUM_VALUES_ON_PAGE = 40;

// export const centerMapDefault: [number, number] = [49.499912, 31.215407];
export const centerMapDefault: [number, number] = [50.449856, 30.522996];
export const zoomMapDefault = 17;
export const zoomCoutryDefault = 6;
export const zoomCityDefault = 17;
export const markerDraggableWidth = 65;
export const markerDraggableHeight = 65;
export const markerUsualWidth = 40;
export const markerUsualHeight = 40;

export const markerRouteSizeSmall = [25, 25];

export const recordTimeLimit = 60; // Limit time for recording

export const SEASONS_UKR_LIST = [
  {
    id: 'winter',
    name: 'Зима',
  },
  {
    id: 'spring',
    name: 'Весна',
  },
  {
    id: 'summer',
    name: 'Літо',
  },
  {
    id: 'autumn',
    name: 'Осінь',
  },
];

export const AGES_UKR_LIST = [
  {
    id: '0-2',
    name: '0-2',
  },
  {
    id: '3-5',
    name: '3-5',
  },
  {
    id: '6-8',
    name: '6-8',
  },
  {
    id: '9-12',
    name: '9-12',
  },
  {
    id: '13-16',
    name: '13-16',
  },
  {
    id: '16+',
    name: '16+',
  },
  {
    id: 'all',
    name: 'Всі',
  },
];

export const EXECUTE_TIME_UKR_LIST = [
  {
    id: 'small',
    name: 'менше 30 хвилин',
  },
  {
    id: 'medium',
    name: 'менше 1 години',
  },
  {
    id: 'high',
    name: 'більше 1 години',
  },
];

export const MONTHS_UKR_LIST = [
  'Січень',
  'Лютий',
  'Березень',
  'Квітень',
  'Травень',
  'Червень',
  'Липень',
  'Серпень',
  'Вересень',
  'Жовтень',
  'Листопад',
  'Грудень',
];

export const recordTypesList = [
  {
    id: '1',
    title: 'Керовано',
    // title: 'Керування',
    description:
      'Що є предметом обговорення? Про що ми розмірковуємо? Яка наша мета? Може бачити загальну картину.',
    color: '#333',
    bgColor: '#78A6F7',
  },
  {
    id: '2',
    title: 'Інформаційно',
    // title: 'Інформація',
    description: 'Беручи до уваги виключно доступну нам інформацію, які факти в нас є?',
    color: '#333',
    bgColor: '#fff',
  },
  {
    id: '3',
    title: 'Емоційно',
    // title: 'Емоції',
    description:
      'Інтуїтивна чи інстинктивна внутрішня реакція або констатація емоційних почуттів (проте жодного виправдання).',
    color: '#333',
    bgColor: '#FB7D67',
  },
  {
    id: '4',
    title: 'Проникливо',
    // title: 'Проникливість',
    description:
      'Застосування логіки для визначення причин бути обережними та консервативними. Практичний, реалістичний.',
    color: '#333',
    bgColor: '#C9C9C6',
  },
  {
    id: '5',
    // title: 'Оптимістична реакція',
    title: 'Оптимістично',
    description:
      'Застосування логіки для визначення переваг, пошук гармонії. Бачить позитивний, сонячний бік ситуацій.',
    color: '#333',
    bgColor: '#F6F86D',
  },
  {
    id: '6',
    title: 'Творчо',
    // title: 'Творчість',
    description:
      'Провокативні та дослідницькі судження, дати волю думці. Мислить творчо, не за шаблоном.',
    color: '#333',
    bgColor: '#94E471',
  },
];
