import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';

export const useUsers = () => {
  const [usersList, setUsers] = useState<any[]>([]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/auth/get_users',
      {},
    );
    if (results.data.users) {
      setUsers(results.data.users);
    }
  };

  const editUserActivity = useCallback(async (userId: string, activityValue: boolean) => {
    const details = { userId, activityValue };
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/auth/edit_user_activity',
      details,
    );
    // getUsers();
  }, []);

  const editUserRole = useCallback(async (userId: string, isAdmin: boolean) => {
    const details = { userId, isAdmin };
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/auth/edit_user_role', details);
    getUsers();
  }, []);

  const deleteUser = useCallback(async (userId: string) => {
    const details = { userId };
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/auth/delete_user', details);
    getUsers();
  }, []);

  return {
    usersList,
    editUserActivity,
    editUserRole,
    deleteUser,
  };
};
