import React from 'react';
import { Grid } from '@material-ui/core';
import ProjectsListItem from './ProjectsListItem';
import { Project } from 'types/projects';

interface Props {
  projectsList: Project[];
  handleEditMode: (value: string) => void;
  handleEditGalleryMode: (value: string) => void;
  deleteProject: (value: string) => void;
  editProjectPicture: (projectId: string, file: any) => void;
}

const ProjectsList = ({
  projectsList,
  handleEditMode,
  handleEditGalleryMode,
  deleteProject,
  editProjectPicture,
}: Props) => {
  return (
    <>
      <Grid container spacing={1}>
        {projectsList.map((project: Project) => (
          <ProjectsListItem
            key={project.id}
            project={project}
            handleEditMode={handleEditMode}
            handleEditGalleryMode={handleEditGalleryMode}
            deleteProject={deleteProject}
            editProjectPicture={editProjectPicture}
          />
        ))}
      </Grid>
    </>
  );
};

export default ProjectsList;
