export enum StoriesPageMode {
  LIST = 'list',
  ADD = 'add',
  EDIT = 'edit',
}

export interface StoryCategory {
  id: string;
  name: string;
}

export interface Story {
  id: string;
  categoryId?: string;
  categoryName?: string;
  title: string;
  author: string;
  description?: string;
  pictureUrl?: string;
  userId?: string;
  userName?: string;
  numFrames: number;
  createdAt: number;
  isActive?: boolean;
  isPublic?: boolean;
  isCommonContent?: boolean;
}

export interface StoryFrame {
  id: string;
  title?: string;
  description?: string;
  pictureUrl: string;
  recordUrl?: string;
  userId?: string;
  userName?: string;
  createdAt?: number;
  order: number;
  isActive?: boolean;
}

export enum StoryPageMode {
  LIST_CREATE = 'list_create',
  LIST_VIEW = 'list_view',
}

export enum StoryActionMode {
  WAITING = 'waiting',
  RECORDING = 'recording',
  PLAYING = 'playing',
  EDITING = 'editing',
}
