import React, { useState, useEffect, useRef } from 'react';
import { Typography, FormControl, Select, InputLabel, Checkbox, Switch } from '@material-ui/core';
// @ts-ignore
import { slugify } from 'transliter';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import { SEASONS_UKR_LIST, EXECUTE_TIME_UKR_LIST } from 'config';
import InputEditor from 'common/core/InputEditor';
import TaskEditPictures from './TaskEditPictures';
import InputCheck from 'common/InputCheck';
import { useTaskUrlCheck } from 'hooks/tasks/useTaskUrlCheck';
import {
  NatureTask,
  NatureTaskInputCreate,
  NatureTaskInputEdit,
  NatureCategory,
  NatureExecuteTime,
  NatureSeason,
  NatureAge,
} from 'types/projects/nature';
import { SkillCategory } from 'types/general';
import { useStyles } from './styles';

const pointsList = [1, 2, 3, 4, 5];

interface Props {
  task: NatureTask | null;
  categoriesList: NatureCategory[];
  agesList: NatureAge[];
  createTask: (details: NatureTaskInputCreate) => void;
  editTask: (details: NatureTaskInputEdit) => void;
  editTaskPicture: (taskId: string, file: any) => void;
  deleteTaskPicture: (taskId: string) => void;
  createGalleryPicture: (taskId: string, file: any) => void;
  deleteGalleryPicture: (taskId: string, id: string) => void;
  handleListMode: () => void;
}

const TasksEdit = ({
  task,
  categoriesList,
  agesList,
  createTask,
  editTask,
  editTaskPicture,
  deleteTaskPicture,
  createGalleryPicture,
  deleteGalleryPicture,
  handleListMode,
}: Props) => {
  const [uid, setUid] = useState<string>('');
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [isDiaryAccessible, setIsDiaryAccessible] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [materials, setMaterials] = useState<string>('');
  const [points, setPoints] = useState<number>(1);
  const [category, setCategory] = useState<string[]>([]);
  const [executeTime, setExecuteTime] = useState<string[]>([]);
  const [season, setSeason] = useState<string[]>([]);
  const [age, setAge] = useState<string[]>([]);
  const [nameError, setNameError] = useState<string>('');
  const { checkUrl } = useTaskUrlCheck(task?.id ?? '');
  const formRef = useRef<any>();
  const classes = useStyles();

  useEffect(() => {
    if (task) {
      setUid(task?.uid ?? '');
      setName(task?.name ?? '');
      setDescription(task?.description ?? '');
      setMaterials(task?.materials ?? '');
      setPoints(!!task?.points ? task?.points : 1);
      setSeason(task?.season ?? []);
      setExecuteTime(task?.executeTime ?? []);
      setCategory(task?.category ?? []);
      setAge(task?.age ?? []);
      setIsDiaryAccessible(task?.isDiaryAccessible ?? false);
      setIsPublic(task?.isPublic ?? false);
    } else {
      setUid('');
      setName('');
      setDescription('');
      setMaterials('');
      setPoints(1);
      setSeason([]);
      setExecuteTime([]);
      setCategory([]);
      setAge([]);
      setIsPublic(false);
    }
  }, [task]);

  useEffect(() => {
    if (!task?.id) {
      setUid(slugify(name, '-'));
    }
  }, [name, task]);

  const handleSubmit = () => {
    setNameError(name ? '' : 'Заповніть назву історії');

    if (name) {
      if (!task?.id) {
        createTask({
          uid,
          name,
          description,
          materials,
          points,
          category,
          executeTime,
          season,
          age,
          isDiaryAccessible,
          isPublic,
        });
        // handleListMode();
      } else {
        editTask({
          taskId: task?.id ?? '',
          uid,
          name,
          description,
          materials,
          points,
          category,
          executeTime,
          season,
          age,
          isDiaryAccessible,
          isPublic,
        });
      }
    }
  };

  const handleChangeCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    const categoryNew =
      category.findIndex((item) => item === e.target.name) !== -1
        ? category.filter((item) => item !== e.target.name)
        : [...category, e.target.name];
    setCategory(categoryNew);
  };

  const handleChangeExecuteTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const executeTimeNew =
      executeTime.findIndex((item) => item === e.target.name) !== -1
        ? executeTime.filter((item) => item !== e.target.name)
        : [...executeTime, e.target.name];
    setExecuteTime(executeTimeNew);
  };

  const handleChangeSeason = (e: React.ChangeEvent<HTMLInputElement>) => {
    const seasonNew =
      season.findIndex((item) => item === e.target.name) !== -1
        ? season.filter((item) => item !== e.target.name)
        : [...season, e.target.name];
    setSeason(seasonNew);
  };

  const handleChangeSeasonsAll = () => {
    setSeason(
      SEASONS_UKR_LIST.length !== season.length ? SEASONS_UKR_LIST.map(({ id }) => id) : [],
    );
  };

  const handleChangeAge = (e: React.ChangeEvent<HTMLInputElement>) => {
    const ageNew =
      age.findIndex((item) => item === e.target.name) !== -1
        ? age.filter((item) => item !== e.target.name)
        : [...age, e.target.name];
    setAge(ageNew);
  };

  const handleChangeAgesAll = () => {
    setAge(agesList.length !== age.length ? agesList.map(({ id }) => id) : []);
  };

  return (
    <div ref={formRef} className={classes.container}>
      {!!task && (
        <TaskEditPictures
          task={task}
          editTaskPicture={editTaskPicture}
          deleteTaskPicture={deleteTaskPicture}
          createGalleryPicture={createGalleryPicture}
          deleteGalleryPicture={deleteGalleryPicture}
        />
      )}
      <div className={classes.inputBlock}>
        <Input
          size="medium"
          label="Назва завдання"
          type="text"
          className={classes.input}
          onChange={(e) => setName(e.target.value)}
          value={name}
          helperText={nameError}
          fullWidth
          required
        />
      </div>
      <div className={classes.inputBlock}>
        <InputCheck
          label="UID"
          value={uid}
          handleChangeValue={(value) => setUid(value)}
          handleCheckValue={checkUrl}
        />
      </div>
      <div className={classes.inputHeaderBlock}>
        <Typography className={classes.inputHeaderText}>Опис завдання:</Typography>
      </div>
      <div className={classes.richTextareaBlock}>
        <InputEditor
          valueInitial={description}
          handleChangeValue={(value: string) => setDescription(value)}
        />
      </div>
      <div className={classes.inputBlock}>
        <Input
          size="medium"
          label="Матеріали"
          type="text"
          className={classes.input}
          onChange={(e) => setMaterials(e.target.value)}
          value={materials}
          fullWidth
        />
      </div>
      <div className={classes.optionBlock}>
        <div className={classes.optionsListHeaderBlock}>
          <Typography className={classes.optionsListHeaderText}>Навички:</Typography>
        </div>
        <div className={classes.optionsListBlock}>
          {categoriesList.map(({ id, name }: SkillCategory) => {
            const isChecked = category.findIndex((item: string) => item === id) !== -1;
            return (
              <div className={classes.optionElementBlock}>
                <div className={classes.optionCheckboxBlock}>
                  <Checkbox
                    name={id}
                    checked={isChecked}
                    onChange={handleChangeCategory}
                    color="primary"
                    className={classes.optionCheckbox}
                  />
                </div>
                <div className={classes.optionNameBlock}>
                  <Typography className={classes.optionNameText}>{name}</Typography>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.optionBlock}>
        <div className={classes.optionsListHeaderBlock}>
          <Typography className={classes.optionsListHeaderText}>Час виконання:</Typography>
        </div>
        <div className={classes.optionsListBlock}>
          {EXECUTE_TIME_UKR_LIST.map(({ id, name }: NatureExecuteTime) => {
            const isChecked = executeTime.findIndex((item: string) => item === id) !== -1;
            return (
              <div className={classes.optionElementBlock}>
                <div className={classes.optionCheckboxBlock}>
                  <Checkbox
                    name={id}
                    checked={isChecked}
                    onChange={handleChangeExecuteTime}
                    color="primary"
                    className={classes.optionCheckbox}
                  />
                </div>
                <div className={classes.optionNameBlock}>
                  <Typography className={classes.optionNameText}>{name}</Typography>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.optionBlock}>
        <div className={classes.optionsListHeaderBlock}>
          <Typography className={classes.optionsListHeaderText}>Сезони для виконання:</Typography>
        </div>
        <div className={classes.optionsListBlock}>
          <div className={classes.optionElementBlock}>
            <div className={classes.optionCheckboxBlock}>
              <Checkbox
                name="all"
                checked={SEASONS_UKR_LIST.length === season.length}
                onChange={handleChangeSeasonsAll}
                color="secondary"
                className={classes.optionCheckbox}
              />
            </div>
            <div className={classes.optionNameBlock}>
              <Typography className={classes.optionNameText}>Всі сезони</Typography>
            </div>
          </div>
          {SEASONS_UKR_LIST.map(({ id, name }: NatureSeason) => {
            const isChecked = season.findIndex((item: string) => item === id) !== -1;
            return (
              <div className={classes.optionElementBlock}>
                <div className={classes.optionCheckboxBlock}>
                  <Checkbox
                    name={id}
                    checked={isChecked}
                    onChange={handleChangeSeason}
                    color="primary"
                    className={classes.optionCheckbox}
                  />
                </div>
                <div className={classes.optionNameBlock}>
                  <Typography className={classes.optionNameText}>{name}</Typography>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.optionBlock}>
        <div className={classes.optionsListHeaderBlock}>
          <Typography className={classes.optionsListHeaderText}>Вік для виконання:</Typography>
        </div>
        <div className={classes.optionsListBlock}>
          <div className={classes.optionElementBlock}>
            <div className={classes.optionCheckboxBlock}>
              <Checkbox
                name="all"
                checked={agesList.length === age.length}
                onChange={handleChangeAgesAll}
                color="secondary"
                className={classes.optionCheckbox}
              />
            </div>
            <div className={classes.optionNameBlock}>
              <Typography className={classes.optionNameText}>Всі віки</Typography>
            </div>
          </div>
          {agesList.map(({ id, name }: NatureAge) => {
            const isChecked = age.findIndex((item: string) => item === id) !== -1;
            return (
              <div className={classes.optionElementBlock}>
                <div className={classes.optionCheckboxBlock}>
                  <Checkbox
                    name={id}
                    checked={isChecked}
                    onChange={handleChangeAge}
                    color="primary"
                    className={classes.optionCheckbox}
                  />
                </div>
                <div className={classes.optionNameBlock}>
                  <Typography className={classes.optionNameText}>{name}</Typography>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.inputBlock}>
        <Switch
          checked={isDiaryAccessible}
          onChange={() => setIsDiaryAccessible(!isDiaryAccessible)}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <Typography className={classes.switchText}>
          {isDiaryAccessible
            ? 'Для щоденника Natural Neighbors'
            : 'Не для щоденника Natural Neighbors'}
        </Typography>
      </div>
      {isDiaryAccessible && (
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Балів за виконання</InputLabel>
            <Select
              label="Балів за виконання"
              native
              onChange={(e: any) => setPoints(+e.target.value)}
              value={points.toString()}
              className={classes.select}
            >
              {pointsList.map((item: number) => (
                <option value={item} key={item.toString()}>
                  {item}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      <div className={classes.inputBlock}>
        <Switch
          checked={isPublic}
          onChange={() => setIsPublic(!isPublic)}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <Typography className={classes.switchText}>
          {isPublic ? 'Відкритий доступ' : 'Закритий доступ'}{' '}
        </Typography>
      </div>
      <div className={classes.buttonBlock}>
        <Button onClick={handleSubmit} className={classes.button}>
          Зберегти
        </Button>
        <Button onClick={handleListMode} variant="outlined" className={classes.button}>
          Назад
        </Button>
      </div>
    </div>
  );
};

export default TasksEdit;
