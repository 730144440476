import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { Grid, Typography, IconButton, TextField } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PlayIcon from '@material-ui/icons/PlayArrowTwoTone';
import PauseIcon from '@material-ui/icons/PauseTwoTone';
import MicIcon from '@material-ui/icons/MicNoneTwoTone';
import StopIcon from '@material-ui/icons/StopTwoTone';
import DeleteRecordIcon from '@material-ui/icons/HighlightOffTwoTone';
import { DeleteTwoTone as DeleteIcon, ImageTwoTone as ImageIcon } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import { StoryFrame, StoryPageMode, StoryActionMode } from 'types/projects/tvorytelling';
import { useStyles } from './styles';

interface Props {
  index: number;
  pageMode: string;
  actionMode: string;
  frame: StoryFrame;
  frameIdCurrent: string;
  deleteFrame: (storyId: string) => void;
  deleteRecord: (frameId: string) => void;
  handleStartRecording: (frameId: string) => void;
  handleStopRecording: (frameId: string) => void;
  handleStartPlaying: (frameId: string) => void;
  handleStopPlaying: (frameId: string) => void;
  handleStartEditing: (frameId: string) => void;
  handleStopEditing: (frameId: string, title: string) => void;
}

const MyStoryFramesListItem = ({
  index,
  pageMode,
  actionMode,
  frame,
  frameIdCurrent,
  deleteFrame,
  deleteRecord,
  handleStartRecording,
  handleStopRecording,
  handleStartPlaying,
  handleStopPlaying,
  handleStartEditing,
  handleStopEditing,
}: Props) => {
  const { id: frameId, title, pictureUrl, recordUrl } = frame;
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [titleValue, setTitleValue] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const audioRef = useRef<any>();
  const classes = useStyles();

  useEffect(() => {
    if (actionMode === StoryActionMode.EDITING && frameId === frameIdCurrent) {
      setTitleValue(title ?? '');
    }
  }, [pageMode, frameIdCurrent, title]);

  useEffect(() => {
    audioRef.current.src = recordUrl;
    audioRef.current.currentTime = 0;
  }, [recordUrl]);

  useEffect(() => {
    if (actionMode === StoryActionMode.PLAYING && frameId === frameIdCurrent) {
      audioRef.current.play();
      audioRef.current.addEventListener('ended', function () {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        handleStopRecording(frameId);
      });
    }
    if (
      (actionMode === StoryActionMode.PLAYING && frameId !== frameIdCurrent) ||
      actionMode !== StoryActionMode.PLAYING
    ) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [pageMode, frameIdCurrent, frameId]);

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleStartEditingAction = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    handleStartEditing(frameId);
  };

  const handleStopEditingAction = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    handleStopEditing(frameId, titleValue);
  };

  const handleStartRecordingAction = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    handleStartRecording(frameId);
  };

  const handleStopRecordingAction = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    handleStopRecording(frameId);
  };

  const handleStartPlayingAction = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    handleStartPlaying(frameId);
  };

  const handleStopPlayingAction = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    handleStopPlaying(frameId);
  };

  const handleDeleteAction = () => {
    setAnchorEl(null);
    toggleOpenConfirmModal();
  };

  const handleDeleteRecordAction = () => {
    deleteRecord(frameId);
    setAnchorEl(null);
  };

  const toggleOpenConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  const handleDeleteApproved = () => {
    deleteFrame(frameId);
    toggleOpenConfirmModal();
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    // border: isDragging ? '3px solid red' : 0,
    // borderRadius: 6,
    ...draggableStyle,
  });

  return (
    <>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Draggable key={frameId} draggableId={frameId} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              // {...provided.dragHandleProps}
              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            >
              <div className={classes.container}>
                <div className={classes.pictureBlock}>
                  <img src={pictureUrl} className={classes.picture} alt="" />
                  {/* {pageMode === StoryPageMode.LIST_VIEW &&
                    (actionMode !== StoryActionMode.PLAYING ||
                      (actionMode === StoryActionMode.PLAYING && frameId !== frameIdCurrent)) &&
                    frameId !== frameIdCurrent &&
                    recordUrl !== '' && (
                      <IconButton
                        onClick={handleStartPlayingAction}
                        className={classes.actionViewButton}
                      >
                        <PlayIcon className={classes.actionViewIcon} />
                      </IconButton>
                    )} */}
                  {/* {pageMode === StoryPageMode.LIST_VIEW &&
                    actionMode === StoryActionMode.PLAYING &&
                    frameId === frameIdCurrent &&
                    recordUrl !== '' && (
                      <IconButton
                        onClick={handleStopPlayingAction}
                        className={classes.actionViewButton}
                      >
                        <PauseIcon className={classes.actionViewIcon} />
                      </IconButton>
                    )} */}
                </div>

                <div
                  className={classnames(
                    classes.infoBlock,
                    pageMode === StoryPageMode.LIST_VIEW &&
                      title === '' &&
                      classes.infoBlockWithoutText,
                  )}
                >
                  <div className={classes.leftBlock}>
                    <div className={classes.titleBlock}>
                      {pageMode === StoryPageMode.LIST_CREATE &&
                        actionMode === StoryActionMode.EDITING &&
                        frameId === frameIdCurrent && (
                          <div className={classes.editBlock}>
                            <div className={classes.inputBlock}>
                              <TextField
                                color="primary"
                                variant="outlined"
                                margin="normal"
                                size="small"
                                placeholder="Додати текст..."
                                type="text"
                                className={classes.input}
                                onChange={(e) => setTitleValue(e.target.value)}
                                value={titleValue}
                                multiline
                                minRows={2}
                                InputProps={{
                                  className: classes.input,
                                  classes: {
                                    root: classes.input,
                                    focused: classes.inputFocused,
                                    notchedOutline: classes.inputNotchedOutline,
                                  },
                                }}
                                fullWidth={true}
                              />
                            </div>
                            <div className={classes.editButtonBlock}>
                              <IconButton
                                onClick={handleStopEditingAction}
                                className={classes.editButton}
                              >
                                <DoneAllIcon className={classes.editIcon} />
                              </IconButton>
                            </div>
                          </div>
                        )}

                      {pageMode === StoryPageMode.LIST_CREATE &&
                        (actionMode !== StoryActionMode.EDITING ||
                          (actionMode === StoryActionMode.EDITING &&
                            frameId !== frameIdCurrent)) && (
                          <Typography
                            onClick={handleStartEditingAction}
                            className={title ? classes.titleText : classes.titleTextDefault}
                          >
                            {title ? `${title}` : `Додати текст...`}
                          </Typography>
                        )}

                      {pageMode === StoryPageMode.LIST_VIEW && (
                        <Typography
                          className={title ? classes.titleText : classes.titleTextDefault}
                        >
                          {title}
                        </Typography>
                      )}
                    </div>
                  </div>
                  {pageMode === StoryPageMode.LIST_CREATE && (
                    <IconButton {...provided.dragHandleProps} className={classes.draggableButton}>
                      <DragIndicatorIcon className={classes.draggableIcon} />
                    </IconButton>
                  )}
                  <div className={classes.actionsBlock}>
                    {/* {((actionMode !== StoryActionMode.RECORDING &&
                      actionMode !== StoryActionMode.PLAYING) ||
                      (actionMode === StoryActionMode.PLAYING && frameId !== frameIdCurrent)) &&
                      recordUrl !== '' && (
                        <IconButton
                          onClick={handleStartPlayingAction}
                          className={classes.actionButton}
                        >
                          <PlayIcon className={classes.actionIcon} />
                        </IconButton>
                      )}
                    {actionMode === StoryActionMode.PLAYING &&
                      recordUrl !== '' &&
                      frameId === frameIdCurrent && (
                        <IconButton
                          onClick={handleStopPlayingAction}
                          className={classes.actionButton}
                        >
                          <PauseIcon className={classes.actionIcon} />
                        </IconButton>
                      )}

                    {actionMode !== StoryActionMode.RECORDING && recordUrl === '' && (
                      <IconButton
                        onClick={handleStartRecordingAction}
                        className={classes.actionButton}
                      >
                        <MicIcon className={classes.actionIcon} />
                      </IconButton>
                    )}
                    {actionMode === StoryActionMode.RECORDING &&
                      recordUrl === '' &&
                      frameId === frameIdCurrent && (
                        <IconButton
                          onClick={handleStopRecordingAction}
                          className={classnames(classes.actionButton, classes.actionButtonRed)}
                        >
                          <StopIcon
                            className={classnames(classes.actionIcon, classes.actionIconRed)}
                          />
                        </IconButton>
                      )} */}
                    <IconButton onClick={toggleMenu} className={classes.menuButton}>
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Draggable>
      </Grid>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        {recordUrl !== '' && (
          <MenuItem
            onClick={handleDeleteRecordAction}
            className={classes.subMenu}
            key="delete_record"
          >
            Видалити запис
            <DeleteRecordIcon />
          </MenuItem>
        )}
        {/* <MenuItem onClick={() => {}} className={classes.subMenu} key="change_frame">
          Змінити малюнок
          <ImageIcon />
        </MenuItem> */}
        <MenuItem
          onClick={handleDeleteAction}
          className={classnames(classes.subMenu, classes.subMenuDelete)}
          key="delete_frame"
        >
          Видалити малюнок
          <DeleteIcon />
        </MenuItem>
      </Menu>
      <ModalConfirm
        title="Дійсно хочете видалити?"
        note="Ви не зможете повернути назад."
        isOpenConfirmModal={isOpenConfirmModal}
        toggleOpenConfirmModal={toggleOpenConfirmModal}
        handleSumbit={handleDeleteApproved}
      />
      <audio ref={audioRef} />
    </>
  );
};

export default MyStoryFramesListItem;
