import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mapBlock: {
    position: 'relative',
    width: '100%',
    height: 300,
    border: '1px solid #EBEBEB',
  },
}));
