import React, { forwardRef } from 'react';
import MenuOriginal from '@material-ui/core/Menu';
import { useStyles } from './styles';

interface Props {
  anchorEl: null | HTMLElement;
  toggleMenu: (e: React.MouseEvent<any>) => void;
  vertical?: string;
  horizontal?: string;
  children: React.ReactNode;
}

const Menu = (
  { anchorEl, toggleMenu, vertical = 'top', horizontal = 'right', children }: Props,
  ref: React.ForwardedRef<HTMLUListElement>,
) => {
  const classes = useStyles();

  return (
    <MenuOriginal
      ref={ref}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={toggleMenu}
      anchorOrigin={{
        vertical: vertical === 'top' ? 'top' : 'bottom',
        horizontal: horizontal === 'right' ? 'right' : 'left',
      }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      MenuListProps={{ className: classes.root }}
    >
      {children}
    </MenuOriginal>
  );
};

export default forwardRef(Menu);
