import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import Button from 'common/core/Button';
// import { RoutesPageMode } from 'types/places';
import { useStyles } from './styles';

interface Props {
  //   pageMode: string;
  //   handlePageMode: (value: string) => void;
}

const PlaceHeader = ({ place }: any) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.titleText}>{place?.name}</Typography>
      </div>
      <div onClick={() => history.push('/places')}>Back</div>
    </div>
  );
};

export default PlaceHeader;
