import React, { useState } from 'react';
import Container from 'common/core/Container';
import PageTitle from 'common/core/PageTitle';
import AlphabetLettersActions from './AlphabetLettersActions';
import AlphabetLettersList from './AlphabetLettersList';
import AlphabetLettersEdit from './AlphabetLettersEdit';
import { useLetters } from 'hooks/projects/alphabet/useLetters';
import { AlphabetLetter } from 'types/projects/alphabet';

const AlphabetLettersContainer = () => {
  const [setIdCurrent, setSetIdCurrent] = useState<string>('');
  const [letterIdCurrent, setLetterIdCurrent] = useState<string>('');
  const {
    isLoading: isLoadingLettersList,
    lettersList,
    editLetter,
    editLetterPicture,
    deleteLetterPicture,
  } = useLetters(setIdCurrent);
  const [isOpenEditModal, setIsEditOpenModal] = useState<boolean>(false);

  const handleSetIdCurrent = (value: string) => {
    setSetIdCurrent(value);
  };

  const handleEditMode = (letterId: string) => {
    setLetterIdCurrent(letterId);
    setIsEditOpenModal(true);
  };

  const closeIsEditOpenModal = () => {
    setIsEditOpenModal(false);
    // setLetterIdCurrent('');
  };

  return (
    <>
      <Container>
        <PageTitle title="Alphabet: Літери" />
        <AlphabetLettersActions
          setIdCurrent={setIdCurrent}
          handleSetIdCurrent={handleSetIdCurrent}
        />
        <AlphabetLettersList
          lettersList={lettersList}
          handleEditMode={handleEditMode}
          editLetterPicture={editLetterPicture}
          deleteLetterPicture={deleteLetterPicture}
        />
      </Container>
      {!!letterIdCurrent && (
        <AlphabetLettersEdit
          isOpen={isOpenEditModal}
          toggleIsOpen={closeIsEditOpenModal}
          item={
            lettersList.find(
              (item: AlphabetLetter) => item.id === letterIdCurrent && item.setId === setIdCurrent,
            ) ?? null
          }
          editLetter={editLetter}
        />
      )}
    </>
  );
};

export default AlphabetLettersContainer;
