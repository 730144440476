import React from 'react';
import { Typography } from '@material-ui/core';
import Button from 'common/core/Button';
import { Place } from 'types/places';
import { useStyles } from './styles';

interface Props {
  place: Place;
}

const PlacesAreaInfo = ({ place }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography>
        {place?.name} || {place?.location}
      </Typography>
    </div>
  );
};

export default PlacesAreaInfo;
