import React, { useState, useEffect } from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import Modal from 'common/core/Modal';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import {
  PoetryWalksAuthor,
  PoetryWalksAuthorInputCreate,
  PoetryWalksAuthorInputEdit,
} from 'types/projects/poetry';
import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
  author: PoetryWalksAuthor | null;
  createAuthor: (details: PoetryWalksAuthorInputCreate) => void;
  editAuthor: (details: PoetryWalksAuthorInputEdit) => void;
}

const AuthorsEdit = ({ isOpen, toggleIsOpen, author, createAuthor, editAuthor }: Props) => {
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    if (author) {
      setName(author?.name ?? '');
      setIsPublic(author?.isPublic ?? true);
    } else {
      setName('');
      setIsPublic(true);
    }
    return () => {
      setName('');
      setIsPublic(true);
    };
  }, [author, isOpen]);

  const handleSubmit = () => {
    setNameError(name ? '' : 'Заповніть назву історії');

    if (name) {
      if (!author?.id) {
        createAuthor({ name, isPublic });
      } else {
        editAuthor({
          authorId: author?.id ?? '',
          name,
          isPublic,
        });
      }
      toggleIsOpen();
    }
  };

  return (
    <Modal
      title={author?.id ? 'Редагування автора' : 'Новий автор'}
      widthMax={450}
      open={isOpen}
      handleClose={toggleIsOpen}
    >
      <div className={classes.container}>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Ім'я автора"
            type="text"
            className={classes.input}
            onChange={(e) => setName(e.target.value)}
            value={name}
            helperText={nameError}
            fullWidth
            required
          />
        </div>
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Доступ</InputLabel>
            <Select
              label="Доступ"
              native
              onChange={(e: any) => setIsPublic(e.target.value === 'true' ? true : false)}
              value={isPublic ? 'true' : 'false'}
              className={classes.select}
            >
              <option value="false" key="private">
                Закритий
              </option>
              <option value="true" key="public">
                Відкритий
              </option>
            </Select>
          </FormControl>
        </div>
        <div className={classes.buttonBlock}>
          <Button onClick={handleSubmit}>Зберегти</Button>
        </div>
      </div>
    </Modal>
  );
};

export default AuthorsEdit;
