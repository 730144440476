import React from 'react';
import ResetPasswordForm from './ResetPasswordForm';
import { useResetPassword } from 'hooks/auth/useResetPassword';

const ResetPasswordContainer = () => {
  const { resetPassword, answerError } = useResetPassword();
  return (
    <>
      <ResetPasswordForm resetPassword={resetPassword} answerError={answerError} />
    </>
  );
};

export default ResetPasswordContainer;
