import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: 62,
    alignItems: 'center',
    padding: 0,
    backgroundColor: theme.palette.primary.main,
  },
  titleBlock: {
    display: 'flex',
    paddingLeft: 10,
  },
  menuBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    paddingRight: 5,
  },
  menuButton: {
    marginRight: 0,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  titleText: {
    fontSize: 24,
    fontWeight: 500,
    color: '#fff',
    cursor: 'pointer',
  },
  menuLink: {
    color: 'rgba(256,256,256,0.7)',
    marginRight: 5,
  },
  menuLinkActive: {
    color: '#fff',
  },
  menuButtonIcon: {
    fontSize: 22,
    marginLeft: 2,
  },
  menuIcon: {
    color: 'rgba(256,256,256,0.7)',
    fontSize: 30,
    [theme.breakpoints.up('md')]: {
      fontSize: 30,
    },
  },
  menuIconActive: {
    color: '#fff !important',
  },
  submenuIcon: {
    marginRight: 10,
  },
  subMenu: {
    fontSize: 16,
    fontWeight: 300,
    minWidth: 180,
  },
}));
