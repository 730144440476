import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 70px',
  },
  text: {
    fontSize: 16,
    fontWeight: 350,
  },
  menuButton: {
    cursor: 'pointer',
  },
  subMenu: {
    minWidth: 190,
  },
  subMenuDelete: {
    color: '#FF0000 !important',
  },
  subMenuIcon: {},
}));
