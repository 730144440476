import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 10,
    },
  },
  titleBlock: {},
  actionBlock: {},
  titleText: {
    fontSize: 20,
    fontWeight: 500,
  },
  addButton: {
    borderRadius: 18,
  },
}));