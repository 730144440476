import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import RadiusFilter from './RadiusFilter';

interface Props {
  radius: number;
  handleChangeRadius: (radius: number) => void;
}

const PlaceRoutesHeader = ({ radius, handleChangeRadius }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.titleBlock}>
        <Typography className={classes.titleText}>Місця</Typography>
      </div>
      <div className={classes.filterBlock}>
        <RadiusFilter radius={radius} handleChangeRadius={handleChangeRadius} />
      </div>
    </div>
  );
};

export default PlaceRoutesHeader;
