import React, { useState } from 'react';
import Container from 'common/core/Container';
import PageTitle from 'common/core/PageTitle';
import AuthorsActions from './AuthorsActions';
import AuthorsList from './AuthorsList';
import AuthorsEdit from './AuthorsEdit';
import { useAuthors } from 'hooks/projects/poetry/useAuthors';
import { PoetryWalksAuthor } from 'types/projects/poetry';

const AuthorsContainer = () => {
  const [authorIdCurrent, setAuthorIdCurrent] = useState<string>('');
  const { isLoading, authorsList, createAuthor, editAuthor, editAuthorPicture } = useAuthors();
  const [isOpenEditModal, setIsEditOpenModal] = useState<boolean>(false);

  const handleEditMode = (authorId: string) => {
    setAuthorIdCurrent(authorId);
    setIsEditOpenModal(true);
  };

  const handleAddMode = () => {
    setAuthorIdCurrent('');
    setIsEditOpenModal(true);
  };

  return (
    <>
      <Container>
        <PageTitle title="Poetry Walks : Автори" />
        <AuthorsActions handleAddMode={handleAddMode} />
        <AuthorsList
          authorsList={authorsList}
          handleEditMode={handleEditMode}
          editAuthorPicture={editAuthorPicture}
        />
      </Container>
      <AuthorsEdit
        isOpen={isOpenEditModal}
        toggleIsOpen={() => setIsEditOpenModal(false)}
        author={
          authorsList.find((author: PoetryWalksAuthor) => author.id === authorIdCurrent) ?? null
        }
        createAuthor={createAuthor}
        editAuthor={editAuthor}
      />
    </>
  );
};

export default AuthorsContainer;
