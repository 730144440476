import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { BlogAuthor, BlogCategory, BlogPost, BlogPostInput } from 'types/blog';
import { useAlert } from 'hooks/useAlert';

export const useBlogPosts = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [postsList, setPostsList] = useState<BlogPost[]>([]);
  const [authorsList, setAuthorsList] = useState<BlogAuthor[]>([]);
  const [categoriesList, setCategoriesList] = useState<BlogCategory[]>([]);
  const showAlert = useAlert();

  useEffect(() => {
    const getAuthors = async () => {
      const results: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/blog/get_blog_authors',
        {},
      );
      if (results?.data?.authors) {
        setAuthorsList(results?.data?.authors);
      }
    };

    getAuthors();
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      const results: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/blog/get_blog_categories',
        {},
      );
      if (results?.data?.categories) {
        setCategoriesList(results?.data?.categories);
      }
    };

    getCategories();
  }, []);

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = useCallback(async () => {
    setIsLoading(true);
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/blog/get_admin_blog_posts',
      {},
    );
    if (results?.data?.posts) {
      setPostsList(results?.data?.posts);
    }
    setIsLoading(false);
  }, []);

  const createPost = useCallback(async (details: BlogPostInput) => {
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/blog/create_blog_post',
      details,
    );

    if (results?.data?.postId) {
      showAlert('Пост був доданий');
      getPosts();
      return results?.data?.postId;
    }
  }, []);

  const editPost = useCallback(async (details: BlogPostInput) => {
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/blog/edit_blog_post', details);
    showAlert('Пост було змінено');
    getPosts();
  }, []);

  const editPostPicture = useCallback(async (postId: string, file: any) => {
    let formData = new FormData();
    formData.append('postId', postId);
    formData.append('file', file);
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/blog/edit_blog_post_picture',
      formData,
    );
    showAlert('Ілюстрацію посту було змінено');
    getPosts();
  }, []);

  const deletePost = useCallback(async (postId: string) => {
    const details = { postId };
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/blog/delete_blog_post', details);
    showAlert('Пост був видалений');
    getPosts();
  }, []);

  return {
    isLoading,
    authorsList,
    categoriesList,
    postsList,
    createPost,
    editPost,
    editPostPicture,
    deletePost,
  };
};
