import { useState } from 'react';
import Container from 'common/core/Container';
import EmailTemplatesHeader from './EmailTemplatesHeader';
import EmailTemplatesEdit from './EmailTemplatesEdit';
import EmailTemplatesList from './EmailTemplatesList';
import { useEmailTemplates } from 'hooks/general/useEmailTemplates';
import { PageMode, EmailTemplate } from 'types/general';

const EmailTemplatesContainer = () => {
  const [pageMode, setPageMode] = useState<string>(PageMode.LIST);
  const [templateIdCurrent, setTemplateIdCurrent] = useState<string>('');
  const { templatesList, createTemplate, editTemplate, testTemplate, deleteTemplate } =
    useEmailTemplates();

  const handlePageMode = (value: string) => {
    if (value === PageMode.LIST) {
      setTemplateIdCurrent('');
    }
    setPageMode(value);
  };

  const handleEditMode = (templateId: string) => {
    setTemplateIdCurrent(templateId);
    setPageMode(PageMode.EDIT);
  };

  return (
    <Container>
      <EmailTemplatesHeader pageMode={pageMode} handlePageMode={handlePageMode} />

      {pageMode === PageMode.LIST && (
        <EmailTemplatesList
          templatesList={templatesList}
          handleEditMode={handleEditMode}
          testTemplate={testTemplate}
          deleteTemplate={deleteTemplate}
        />
      )}
      {(pageMode === PageMode.EDIT || pageMode === PageMode.ADD) && (
        <EmailTemplatesEdit
          template={
            templatesList.find((template: EmailTemplate) => template.id === templateIdCurrent) ??
            null
          }
          createTemplate={createTemplate}
          editTemplate={editTemplate}
          handlePageMode={handlePageMode}
        />
      )}
    </Container>
  );
};

export default EmailTemplatesContainer;
