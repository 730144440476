import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;',
        border: '1px solid #E4E7ED',
        borderRadius: 4,
    },
    pictureBlock: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        aspectRatio: '16/9',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '4px 4px 0 0',
        cursor: 'pointer',
    },
    icon: {
        fontSize: 72,
        color: 'rgba(256, 256, 256, 0.8)',
    },
    infoBlock: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: 7,
    },
    titleBlock: {
        display: 'flex',
    },
    titleText: {
        fontSize: 16,
        fontWeight: 450,
        // textTransform: 'uppercase'
    },
}));
