import axios from 'axios';

export const fetchAPI = async (method: string, url: string, body = {}, headers = {}) => {
  const accessToken = localStorage.getItem('accessToken') || '';
  if (method === 'get') {
    const results = await axios
      .get(url, {
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          localStorage.removeItem('userData');
          localStorage.removeItem('accessToken');
        }
        return e.response;
      });
    return results;
  } else {
    const results = await axios
      .post(url, body, {
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          localStorage.removeItem('userData');
          localStorage.removeItem('accessToken');
        }
        return e.response;
      });
    return results;
  }
};
