export const getDistance = (origin: [number, number], destination: [number, number]) => {
  const longitude1 = toRadian(origin[1]);
  const latitude1 = toRadian(origin[0]);
  const longitude2 = toRadian(destination[1]);
  const latitude2 = toRadian(destination[0]);

  const deltaLatitude = latitude2 - latitude1;
  const deltaLongitude = longitude2 - longitude1;

  const a =
    Math.pow(Math.sin(deltaLatitude / 2), 2) +
    Math.cos(latitude1) * Math.cos(latitude2) * Math.pow(Math.sin(deltaLongitude / 2), 2);
  const c = 2 * Math.asin(Math.sqrt(a));
  const EARTH_RADIUS = 6371;
  return c * EARTH_RADIUS * 1000;
};

export const toRadian = (degree: number) => {
  return (degree * Math.PI) / 180;
};

export const formatDistance = (distance: number): string => {
  if (distance >= 1000) {
    return (distance / 1000).toFixed(2) + ' км';
  }
  return distance.toFixed(0) + ' метрів';
};
