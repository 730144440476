import { useLocation, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Button } from '@material-ui/core';

import { useStyles } from './styles';

interface Props {
  title: string;
  route?: string;
  routesList: string[];
}

const HeaderButton = ({ title, route, routesList }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const appPath = location.pathname;
  const classes = useStyles();

  const handleClick = () => {
    if (route) {
      history.push(route);
    }
  };

  return (
    <Button
      onClick={handleClick}
      className={classnames(
        classes.menuLink,
        routesList.includes(appPath) && classes.menuLinkActive,
      )}
      color="inherit"
    >
      {title}
    </Button>
  );
};

export default HeaderButton;
