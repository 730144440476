import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import {
  AlphabetWord,
  AlphabetWordInputCreate,
  AlphabetWordInputEdit,
} from 'types/projects/alphabet';

export const useWords = () => {
  const [wordsList, setWordsList] = useState<AlphabetWord[]>([]);

  const getWords = async () => {
    const details = {};
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/alphabet/get_admin_words',
      details,
    );
    if (results?.data?.words) {
      const wordsListNew = results?.data?.words.sort((a: AlphabetWord, b: AlphabetWord) =>
        a.letter.localeCompare(b.letter),
      );
      setWordsList(wordsListNew);
    }
  };

  useEffect(() => {
    getWords();
  }, []);

  const createWord = useCallback(async (details: AlphabetWordInputCreate) => {
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/alphabet/create_word', details);
    getWords();
  }, []);

  const editWord = useCallback(async (details: AlphabetWordInputEdit) => {
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/alphabet/edit_word', details);
    getWords();
  }, []);

  const editWordPicture = useCallback(async (wordId: string, file: any) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('wordId', wordId);
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/alphabet/edit_word_picture',
      formData,
    );
    await new Promise((resolve) => {
      return setTimeout(resolve, 1500);
    });
    getWords();
  }, []);

  const deleteWord = useCallback(async (wordId: string) => {
    const details = { wordId };
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/alphabet/delete_word', details);
    getWords();
  }, []);

  return {
    wordsList,
    createWord,
    editWord,
    editWordPicture,
    deleteWord,
  };
};
