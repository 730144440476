import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
    zIndex: 3000,
  },
  headerBlock: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#fff',
  },
  headerTitle: {
    color: '#000',
  },
  headerTitleText: {
    fontSize: 20,
    fontWeight: 600,
    color: '#0F0F21',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeIcon: {
    color: '#000',
    fontSize: 22,
    fontWeight: 600,
  },
  contentBlock: {
    padding: 0,
  },
}));
