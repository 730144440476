import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
    },
  },
  titleText: {
    fontSize: 22,
    fontWeight: 450,
    color: '#000',
    textTransform: 'uppercase',

    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
}));
