import React, { useEffect, useState } from 'react';
import Table from 'common/core/Table';
import PodcastsEpisodesListItem from './PodcastsEpisodesListItem';
import Pagination from 'common/core/Pagination';
import { PodcastsEpisode } from 'types/podcasts';
import { useStyles } from './styles';

interface Props {
  episodesList: PodcastsEpisode[];
  handleEditMode: (episodeId: string) => void;
  editEpisodeAudio: (episodeId: string, file: any) => void;
  deleteEpisode: (episodeId: string) => void;
}

const NUM_VALUES_ON_PAGE = 40;

const PodcastsEpisodesList = ({
  episodesList,
  handleEditMode,
  editEpisodeAudio,
  deleteEpisode,
}: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (episodesList.length) {
      const pageCountNew = Math.ceil(episodesList.length / NUM_VALUES_ON_PAGE);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [episodesList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Table>
        {episodesList
          .filter(
            (_: PodcastsEpisode, index: number) =>
              index >= (page - 1) * NUM_VALUES_ON_PAGE && index < page * NUM_VALUES_ON_PAGE,
          )
          .map((template: PodcastsEpisode) => (
            <PodcastsEpisodesListItem
              key={template.id}
              template={template}
              handleEditMode={handleEditMode}
              editEpisodeAudio={editEpisodeAudio}
              deleteEpisode={deleteEpisode}
            />
          ))}
      </Table>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default PodcastsEpisodesList;
