import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 60,
    borderBottom: '1px solid #E4E7ED',
  },
  containerBottom: {
    borderBottom: '0px !important',
  },
  nameBlock: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    width: '100%',
  },
  nameTopBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  nameBottomBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  categoryBlock: {
    paddingLeft: 10,
    width: 300,
  },
  locationBlock: {
    paddingLeft: 10,
    width: 400,
  },
  actionsBlock: {
    width: 100,
  },
  nameText: {
    fontSize: 16,
    fontWeight: 400,
  },
  emailText: {
    fontSize: 12,
    fontWeight: 400,
    color: '#666666',
  },
  text: {
    fontSize: 14,
    fontWeight: 350,
  },
  menuButton: {
    cursor: 'pointer',
  },
  subMenu: {
    minWidth: 180,
  },
  subMenuDelete: {
    color: '#FF0000 !important',
  },
  subMenuIcon: {},
}));
