import { useState, useEffect } from 'react';
import { RoutePathCoords, RoutePoint } from 'types/places';

export const useRouteView = (pointsList: RoutePoint[]) => {
  const [pathsList, setPathsList] = useState<RoutePathCoords[]>([]);

  useEffect(() => {
    let latitudePrev: number;
    let longitudePrev: number;
    let coords: RoutePathCoords[] = [];

    pointsList.forEach((item: any, index: number) => {
      const { latitude, longitude } = item;
      if (index) {
        coords.push({
          position1: [latitudePrev, longitudePrev],
          position2: [latitude, longitude],
        });
      }
      latitudePrev = latitude;
      longitudePrev = longitude;
    });

    setPathsList(coords);
  }, [pointsList]);

  return {
    pathsList,
  };
};
