import { Typography } from '@material-ui/core';
import { useStyles } from './styles';

interface Props {
  title: string;
}

const PageTitle = ({ title }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.titleText}>{title}</Typography>
    </div>
  );
};

export default PageTitle;
