import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  introBlock: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 5,
  },
  selectBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: 8,
    border: '1px solid #999999',
    backgroundColor: '#E4E7ED',
    minWidth: 100,
  },
  introText: {
    fontSize: 12,
    fontWeight: 350,
  },
  selectChosenText: {
    fontSize: 14,
    fontWeight: 350,
  },
  menuIcon: {
    color: '#999999',
    fontSize: 20,
  },
  menuItem: {
    height: 38,
    width: 140,
  },
  menuItemChosen: {
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
}));
