import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: 10,
    paddingBottom: 5,
  },
  headerLeftBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexGrow: 1,
    paddingLeft: 20,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
    },
  },
  titleBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    // paddingBottom: 5,
    [theme.breakpoints.down('sm')]: {
      // paddingBottom: 5,
    },
  },
  detailsBlock: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  detailsLeftBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  detailsRightBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  infoBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 2,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 1,
    },
  },
  closeBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: 50,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
  },
  mapBlock: {
    position: 'relative',
    width: '100%',
    height: 400,
    [theme.breakpoints.down('sm')]: {
      height: 400,
    },
    [theme.breakpoints.down('xs')]: {
      height: 300,
    },
  },
  footerBlock: {
    minHeight: 40,
  },

  titleText: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '14px',
    marginBottom: 3,
  },
  titleNoText: {
    fontWeight: 350,
    color: '#999',
    fontStyle: 'italic',
    lineHeight: '14px',
  },
  infoText: {
    fontSize: 12,
    fontWeight: 350,
    color: '#666666',
    lineHeight: '14px',
  },
  circleRouteIcon: {
    color: '#660708 !important',
    marginLeft: 6,
  },
  circleRouteText: {
    color: '#660708',
  },
  locationText: {
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 250,
    },
  },
  infoIcon: {
    fontSize: 14,
    color: '#666666',
    marginRight: 4,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginRight: 2,
    },
  },
  closeButton: {
    padding: 5,
    margin: 3,
  },
  closeIcon: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
  },
}));
