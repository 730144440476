import React from 'react';
import classnames from 'classnames';
import { useStyles } from './styles';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const TableRow = ({ children, className = '' }: Props) => {
  const classes = useStyles();

  return <div className={classnames(classes.container, className)}>{children}</div>;
};

export default TableRow;
