import React, { useState } from 'react';
import Container from 'common/core/Container';
import PageTitle from 'common/core/PageTitle';
import ShopCategoriesActions from './ShopCategoriesActions';
import ShopCategoriesList from './ShopCategoriesList';
import ShopCategoriesEdit from './ShopCategoriesEdit';
import { useShopCategories } from 'hooks/shop/useShopCategories';
import { ShopCategory } from 'types/shop';

const ShopCategoriesContainer = () => {
  const [categoryIdCurrent, setCategoryIdCurrent] = useState<string>('');
  const { isLoading, catgoriesList, createCategory, editCategory, editCategoryPicture } =
    useShopCategories();
  const [isOpenEditModal, setIsEditOpenModal] = useState<boolean>(false);

  const handleEditMode = (authorId: string) => {
    setCategoryIdCurrent(authorId);
    setIsEditOpenModal(true);
  };

  const handleAddMode = () => {
    setCategoryIdCurrent('');
    setIsEditOpenModal(true);
  };

  return (
    <>
      <Container>
        <PageTitle title="Категорії магазину" />
        <ShopCategoriesActions handleAddMode={handleAddMode} />
        <ShopCategoriesList
          catgoriesList={catgoriesList}
          handleEditMode={handleEditMode}
          editCategoryPicture={editCategoryPicture}
        />
      </Container>
      <ShopCategoriesEdit
        isOpen={isOpenEditModal}
        toggleIsOpen={() => setIsEditOpenModal(false)}
        category={
          catgoriesList.find((category: ShopCategory) => category.id === categoryIdCurrent) ?? null
        }
        createCategory={createCategory}
        editCategory={editCategory}
      />
    </>
  );
};

export default ShopCategoriesContainer;
