import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import MainContainer from 'components/general/main/MainContainer';

import AuthContainer from 'components/general/auth/AuthContainer';
import ConfirmLinkContainer from 'components/general/auth/ConfirmLinkContainer';
import ForgotPasswordContainer from 'components/general/auth/ForgotPasswordContainer';
import ResetPasswordContainer from 'components/general/auth/ResetPasswordContainer';
import ProfileContainer from 'components/general/profile/ProfileContainer';

import BlogContainer from 'components/general/blog/BlogContainer';

import ShopMainContainer from 'components/shop/ShopMainContainer';
import ProductsContainer from 'components/shop/ProductsContainer';
import ShopCategoriesContainer from 'components/shop/ShopCategoriesContainer';

import TemplatesContainer from 'components/projects/tvorytelling/TemplatesContainer';
import TemplateContainer from 'components/projects/tvorytelling/TemplateContainer';
import StoriesContainer from 'components/projects/tvorytelling/StoriesContainer';
import StoryContainer from 'components/projects/tvorytelling/StoryContainer';

import ProjectsMainContainer from 'components/projects/ProjectsMainContainer';
import ProjectsContainer from 'components/projects/ProjectsContainer';
import TasksContainer from 'components/tasks/TasksContainer';
import NatAchievementsContainer from 'components/projects/nature/AchievementsContainer';
import PoetryContainer from 'components/projects/poetry/PoetryContainer';
import AuthorsContainer from 'components/projects/poetry/AuthorsContainer';
import AlphabetLettersContainer from 'components/projects/alphabet/AlphabetLettersContainer';
import AlphabetWordsContainer from 'components/projects/alphabet/AlphabetWordsContainer';

import LocationsMainContainer from 'components/locations/LocationsMainContainer';
import PlacesContainer from 'components/locations/PlacesContainer';
import AdminPlacesContainer from 'components/locations/AdminPlacesContainer';
import PlaceContainer from 'components/locations/PlaceContainer';
import RoutesContainer from 'components/locations/RoutesContainer';

import OtherMainContainer from 'components/other/OtherMainContainer';
import PeopleMainContainer from 'components/general/users/PeopleMainContainer';
import UsersContainer from 'components/general/users/UsersContainer';
import TeamContainer from 'components/other/TeamContainer';
import PodcastsContainer from 'components/podcasts/PodcastsContainer';
import PodcastsEpisodesContainer from 'components/podcasts/PodcastsEpisodesContainer/inde';
import EmailTemplatesContainer from 'components/general/users/EmailTemplatesContainer';
import СontentPagesContainer from 'components/other/СontentPagesContainer';

import { Context } from 'context/Context';

const Router = () => {
  const { isUserAuthed } = useContext(Context);

  if (isUserAuthed === null) {
    return <></>;
  }

  return (
    <Switch>
      {isUserAuthed ? (
        <>
          <Route exact path={'/'} component={MainContainer} />

          <Route exact path={'/auth'} component={AuthContainer} />
          <Route exact path={'/auth/confirm'} component={ConfirmLinkContainer} />
          <Route exact path={'/profile/edit'} component={ProfileContainer} />

          <Route exact path={'/blog'} component={BlogContainer} />

          <Route exact path={'/tasks'} component={TasksContainer} />

          <Route exact path={'/shop'} component={ShopMainContainer} />
          <Route exact path={'/shop/products'} component={ProductsContainer} />
          <Route exact path={'/shop/categories'} component={ShopCategoriesContainer} />

          <Route exact path={'/projects'} component={ProjectsMainContainer} />
          <Route exact path={'/apps'} component={ProjectsContainer} />
          <Route exact path={'/projects/poetry/poetry'} component={PoetryContainer} />
          <Route exact path={'/projects/poetry/authors'} component={AuthorsContainer} />
          <Route
            exact
            path={'/projects/nature/achievements'}
            component={NatAchievementsContainer}
          />
          <Route exact path={'/projects/alphabet/letters'} component={AlphabetLettersContainer} />
          <Route exact path={'/projects/alphabet/words'} component={AlphabetWordsContainer} />
          <Route exact path={'/tvorytelling/templates'} component={TemplatesContainer} />
          <Route path={'/tvorytelling/template/:id'} component={TemplateContainer} />
          <Route exact path={'/tvorytelling/stories'} component={StoriesContainer} />
          <Route path={'/tvorytelling/story/:id'} component={StoryContainer} />

          <Route exact path={'/locations'} component={LocationsMainContainer} />
          <Route exact path={'/places'} component={PlacesContainer} />
          <Route exact path={'/places/adminplaces'} component={AdminPlacesContainer} />
          <Route exact path={'/places/myplaces'} component={PlacesContainer} />
          <Route path={'/places/place/:id'} component={PlaceContainer} />
          <Route exact path={'/routes'} component={RoutesContainer} />

          <Route exact path={'/other'} component={OtherMainContainer} />
          <Route exact path={'/people'} component={PeopleMainContainer} />
          <Route exact path={'/users'} component={UsersContainer} />
          <Route exact path={'/general/team'} component={TeamContainer} />
          <Route exact path={'/podcasts'} component={PodcastsContainer} />
          <Route exact path={'/podcasts-episodes'} component={PodcastsEpisodesContainer} />
          <Route exact path={'/email-templates'} component={EmailTemplatesContainer} />
          <Route exact path={'/content-pages'} component={СontentPagesContainer} />
        </>
      ) : (
        <>
          <Route exact path={'/'} component={AuthContainer} />
          <Route path={'/auth/confirm/:id'} component={ConfirmLinkContainer} />
          <Route exact path={'/auth/forgotpassword'} component={ForgotPasswordContainer} />
          <Route path={'/auth/resetpassword/:id'} component={ResetPasswordContainer} />
          {/* <Route path="*" component={AuthContainer} /> */}
          {/* <Redirect to={'/'} /> */}
        </>
      )}
    </Switch>
  );
};

export default Router;
