import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '1px solid #EBEBEB',
    borderRadius: 6,
  },
  pictureBlock: {
    display: 'flex',
    borderRadius: '6px 6px 0 0',
    aspectRatio: '16/9',
    backgroundColor: '#336699',
  },
  infoBlock: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: 4,
    paddingBottom: 4,
  },
  leftBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: 10,
  },
  titleBlock: {
    display: 'flex',
  },
  actionsBlock: { paddingRight: 3 },
  picture: {
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '16/9',
    borderRadius: '6px 6px 0 0',
  },
  imgEmptyIcon: {
    width: 90,
    [theme.breakpoints.down('sm')]: {
      width: 90,
    },
  },
  titleText: {
    fontSize: 16,
    fontWeight: 400,
  },
  infoText: {
    fontSize: 12,
    fontWeight: 350,
    color: '#666666',
  },
  infoIcon: {
    fontSize: 16,
    color: '#333',
    marginRight: 5,
  },
  menuButton: {},
  subMenu: {
    minWidth: 200,
  },
  subMenuDelete: {
    color: '#FF0000 !important',
  },
  subMenuIcon: {},
}));
