import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Avatar, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import { useStyles } from './styles';

interface Props {
  forgotPassword: (email: string) => void;
}

const ForgotPasswordForm = ({ forgotPassword }: Props) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const history = useHistory();
  const classes = useStyles();

  const handleSubmit = () => {
    setEmailError(email ? '' : 'Заповніть свій email');

    if (email) {
      forgotPassword(email);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Container maxWidth="xs" className={classes.container}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Забули пароль?
        </Typography>
        <form className={classes.form} noValidate>
          <div className={classes.formBlock}>
            <div className={classes.element}>
              <Input
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                helperText={emailError}
                fullWidth
                required
              />
            </div>
            <div className={classes.element}>
              <Button fullWidth onClick={handleSubmit}>
                Надіслати
              </Button>
            </div>
            <div className={classes.element}>
              <div className={classes.linkBlock}>
                <div className={classes.linkBlockLeft}>
                  <Typography className={classes.linkText} onClick={() => history.push('/')}>
                    Назад до входу
                  </Typography>
                </div>
                <div className={classes.linkBlockRight}></div>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default ForgotPasswordForm;
