import React, { useState, useEffect } from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import Modal from 'common/core/Modal';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import {
  AlphabetWord,
  AlphabetWordInputCreate,
  AlphabetWordInputEdit,
} from 'types/projects/alphabet';
import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
  item: AlphabetWord | null;
  createWord: (details: AlphabetWordInputCreate) => void;
  editWord: (details: AlphabetWordInputEdit) => void;
}

const AlphabetWordsEdit = ({ isOpen, toggleIsOpen, item, createWord, editWord }: Props) => {
  const [letter, setLetter] = useState<string>('');
  const [word, setWord] = useState<string>('');
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [letterError, setLetterError] = useState<string>('');
  const [wordError, setWordError] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    if (item) {
      setLetter(item?.letter ?? '');
      setWord(item?.word ?? '');
      setIsPublic(item?.isPublic ?? true);
    } else {
      setLetter('');
      setWord('');
      setIsPublic(false);
    }
    return () => {
      setLetter('');
      setWord('');
      setIsPublic(false);
    };
  }, [item, isOpen]);

  const handleSubmit = () => {
    setLetterError(letter ? '' : 'Заповніть назву історії');
    setWordError(word ? '' : 'Заповніть назву історії');

    if (letter && word) {
      if (!item?.id) {
        createWord({ letter, word, isPublic });
      } else {
        editWord({
          wordId: item?.id ?? '',
          letter,
          word,
          isPublic,
        });
      }
      toggleIsOpen();
    }
  };

  return (
    <Modal
      title={item?.id ? 'Редагування слова' : 'Нове слово'}
      widthMax={450}
      open={isOpen}
      handleClose={toggleIsOpen}
    >
      <div className={classes.container}>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Літера"
            type="text"
            className={classes.input}
            onChange={(e) => setLetter(e.target.value)}
            value={letter}
            helperText={letterError}
            fullWidth
            required
          />
        </div>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Слово"
            type="text"
            className={classes.input}
            onChange={(e) => setWord(e.target.value)}
            value={word}
            helperText={wordError}
            fullWidth
            required
          />
        </div>
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Доступ</InputLabel>
            <Select
              label="Доступ"
              native
              onChange={(e: any) => setIsPublic(e.target.value === 'true' ? true : false)}
              value={isPublic ? 'true' : 'false'}
              className={classes.select}
            >
              <option value="false" key="private">
                Закритий
              </option>
              <option value="true" key="public">
                Відкритий
              </option>
            </Select>
          </FormControl>
        </div>
        <div className={classes.buttonBlock}>
          <Button onClick={handleSubmit}>Зберегти</Button>
        </div>
      </div>
    </Modal>
  );
};

export default AlphabetWordsEdit;
