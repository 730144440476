import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 20,
  },
  titleBlock: {
    display: 'flex',
    paddingLeft: 10,
  },
  actionBlock: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 10,
  },
  titleText: {
    fontSize: 20,
    fontWeight: 500,
  },
  backLinkText: {
    fontSize: 16,
    fontWeight: 350,
    cursor: 'pointer',
    marginRight: 10,
  },
  actionButton: {
    border: `2px solid ${theme.palette.secondary.main}`,
    // backgroundColor: '#ccc',
    padding: 13,
    marginRight: 6,
  },
  actionIcon: {
    fontSize: 40,
    color: theme.palette.secondary.main,
  },
}));