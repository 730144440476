import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { AlphabetLetter, AlphabetWord, AlphabetLetterInputEdit } from 'types/projects/alphabet';

const SET_ID_DEFAULT = '646217fcc9595cffa30137f9';

export const useLetters = (setIdCurrent: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lettersList, setLettersList] = useState<AlphabetLetter[]>([]);

  const getLetters = async (setIdCurrent: string) => {
    setIsLoading(true);
    const details = {
      setId: setIdCurrent ? setIdCurrent : SET_ID_DEFAULT,
    };
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/alphabet/get_admin_letters',
      details,
    );
    if (results?.data?.letters) {
      const lettersListNew = results?.data?.letters.sort((a: AlphabetWord, b: AlphabetWord) =>
        a.letter.localeCompare(b.letter),
      );
      setLettersList(lettersListNew);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getLetters(setIdCurrent);
  }, [setIdCurrent]);

  const editLetter = useCallback(async (details: AlphabetLetterInputEdit) => {
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/alphabet/edit_letter_object',
      details,
    );
    getLetters(details?.setId ?? '');
  }, []);

  const editLetterPicture = useCallback(async (setId: string, letterId: string, file: any) => {
    let formData = new FormData();
    formData.append('setId', setId);
    formData.append('letterId', letterId);
    formData.append('file', file);
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/alphabet/edit_letter_picture',
      formData,
    );
    await new Promise((resolve) => {
      return setTimeout(resolve, 1500);
    });
    getLetters(setId);
  }, []);

  const deleteLetterPicture = useCallback(async (setId: string, letterId: string) => {
    let formData = new FormData();
    formData.append('setId', setId);
    formData.append('letterId', letterId);
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/alphabet/delete_letter_picture',
      formData,
    );
    await new Promise((resolve) => {
      return setTimeout(resolve, 1500);
    });
    getLetters(setId);
  }, []);

  return {
    isLoading,
    lettersList,
    editLetter,
    editLetterPicture,
    deleteLetterPicture,
  };
};
