import React, { useState, useEffect } from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import Modal from 'common/core/Modal';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import {
  NatureAchievement,
  NatureAchievementInputCreate,
  NatureAchievementInputEdit,
} from 'types/projects/nature';
import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
  achievement: NatureAchievement | null;
  createAchievement: (value: NatureAchievementInputCreate) => void;
  editAchievement: (value: NatureAchievementInputEdit) => void;
}

const AchievementsEditModal = ({
  isOpen,
  toggleIsOpen,
  achievement,
  createAchievement,
  editAchievement,
}: Props) => {
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const [name, setName] = useState<string>('');
  const [points, setPoints] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    if (achievement) {
      setName(achievement?.name ?? '');
      setPoints(achievement?.points ? achievement?.points.toString() : '');
      setIsPublic(achievement?.isPublic ?? true);
    } else {
      setName('');
      setPoints('');
      setIsPublic(true);
    }
  }, [achievement, isOpen]);

  const handleSubmit = () => {
    setNameError(name ? '' : 'Заповніть назву досягнення');

    if (name) {
      if (!achievement?.id) {
        createAchievement({
          name,
          points: +points,
          isPublic,
        });
      } else {
        editAchievement({
          achievementId: achievement?.id,
          name,
          points: +points,
          isPublic,
        });
      }
      toggleIsOpen();
    }
  };

  return (
    <Modal
      title={achievement?.id ? 'Редагування досягнення' : 'Нове досягнення'}
      widthMax={650}
      open={isOpen}
      handleClose={toggleIsOpen}
    >
      <div className={classes.container}>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Назва досягнення"
            type="text"
            className={classes.input}
            onChange={(e) => setName(e.target.value)}
            value={name}
            helperText={nameError}
            fullWidth
            required
          />
        </div>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Num points"
            type="text"
            className={classes.input}
            onChange={(e) => setPoints(e.target.value)}
            value={points}
            fullWidth
          />
        </div>
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Доступ</InputLabel>
            <Select
              label="Доступ"
              native
              onChange={(e: any) => setIsPublic(e.target.value === 'true' ? true : false)}
              value={isPublic ? 'true' : 'false'}
              className={classes.select}
            >
              <option value="false" key="private">
                Закритий
              </option>
              <option value="true" key="public">
                Відкритий
              </option>
            </Select>
          </FormControl>
        </div>
        <div className={classes.buttonBlock}>
          <Button onClick={handleSubmit}>Зберегти</Button>
        </div>
      </div>
    </Modal>
  );
};

export default AchievementsEditModal;
