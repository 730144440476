import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { ShopCategory, ShopCategoryInputCreate, ShopCategoryInputEdit } from 'types/shop';

export const useShopCategories = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [catgoriesList, setCatgoriesList] = useState<ShopCategory[]>([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    setIsLoading(true);
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/shop/get_admin_categories',
      {},
    );
    if (results?.data?.categories) {
      setCatgoriesList(results?.data?.categories);
    }
    setIsLoading(false);
  };

  const createCategory = useCallback(async (details: ShopCategoryInputCreate) => {
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/shop/create_category', details);
    getCategories();
  }, []);

  const editCategory = useCallback(async (details: ShopCategoryInputEdit) => {
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/shop/edit_category', details);
    getCategories();
  }, []);

  const editCategoryPicture = useCallback(async (categoryId: string, file: any) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('categoryId', categoryId);

    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/shop/edit_category_picture',
      formData,
    );
    await new Promise((resolve) => {
      return setTimeout(resolve, 2500);
    });
    getCategories();
  }, []);

  return {
    isLoading,
    catgoriesList,
    createCategory,
    editCategory,
    editCategoryPicture,
  };
};
