import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: 20,
    marginBottom: 5,
  },
  titleBlock: {
    display: 'flex',
    flexDirection: 'row',
  },
  filterBlock: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleText: {
    fontSize: 20,
    fontWeight: 350,
    lineHeight: '16px',
  },
  filterSelect: {
    height: 36,
    width: 200,
    padding: 0,
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      width: 160,
    },
    '& .MuiMenuItem-root': {
      padding: 0,
      margin: 0,
      height: 36,
    },
  },
}));
