import React, { useEffect, useState } from 'react';
import PoetryListItem from './PoetryListItem';
import Pagination from 'common/core/Pagination';
import { NUM_VALUES_ON_PAGE } from 'config';
import { PoetryWalksItem } from 'types/projects/poetry';
import { useStyles } from './styles';

interface Props {
  isLoadingPoetry: boolean;
  poetryList: PoetryWalksItem[];
  handleEditMode: (userId: string) => void;
  editPoetryAudio: (poetryId: string, file: any) => void;
  deletePoetryAudio: (poetryId: string) => void;
  deletePoetry: (poetryId: string) => void;
}

const PoetryList = ({
  isLoadingPoetry,
  poetryList,
  handleEditMode,
  editPoetryAudio,
  deletePoetryAudio,
  deletePoetry,
}: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (poetryList.length) {
      const pageCountNew = Math.ceil(poetryList.length / NUM_VALUES_ON_PAGE);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [poetryList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <div className={classes.container}>
        {poetryList
          .sort((a: PoetryWalksItem, b: PoetryWalksItem) => b.createdAt - a.createdAt)
          .filter(
            (_: PoetryWalksItem, index: number) =>
              index >= (page - 1) * NUM_VALUES_ON_PAGE && index < page * NUM_VALUES_ON_PAGE,
          )
          .map((poetry: PoetryWalksItem, index: number) => (
            <PoetryListItem
              key={poetry.id}
              poetry={poetry}
              isLastItem={index === poetryList.length - 1}
              handleEditMode={handleEditMode}
              editPoetryAudio={editPoetryAudio}
              deletePoetryAudio={deletePoetryAudio}
              deletePoetry={deletePoetry}
            />
          ))}
      </div>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default PoetryList;
