import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: 60,
    borderBottom: '1px solid #E4E7ED',
  },
  containerBottom: {
    borderBottom: '0px !important',
  },
  titleBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: 10,
    paddingBottom: 4,
  },
  statusBlock: {
    paddingLeft: 10,
    width: 400,
  },
  actionsBlock: {
    width: 100,
  },
  contentText: {
    fontSize: 16,
    fontWeight: 350,
  },
  titleText: {
    fontSize: 16,
    fontWeight: 400,
  },
  authorText: {
    fontSize: 14,
    fontWeight: 350,
    color: theme.palette.primary.main,
  },
  poemText: {
    fontSize: 14,
    fontWeight: 350,
    color: 'rgba(0,0,0,0.5)',
    lineHeight: '110%',
  },
  audioText: {
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
  notPublicText: {
    color: 'red',
    fontWeight: 400,
  },
  menuButton: {
    cursor: 'pointer',
  },
  subMenu: {
    minWidth: 190,
  },
  subMenuDelete: {
    color: '#FF0000 !important',
  },
  subMenuIcon: {},
}));
