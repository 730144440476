import { useMutation, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { Podcast, PodcastInput } from 'types/podcasts';

export const useEditPodcast = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (details: PodcastInput) => {
      const result: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/podcasts/edit_podcast',
        details,
      );

      return result;
    },
    onSuccess: (data, variables) => {
      const results = data?.data;

      if (results?.id) {
        const podcastsList: Podcast[] = queryClient.getQueryData('podcasts') ?? [];
        const podcastsListNew = podcastsList.map((item) =>
          item.id !== results?.id
            ? item
            : {
                ...item,
                ...results,
              },
        );
        queryClient.setQueryData(['podcasts'], podcastsListNew);
      }
    },
  });
};
