import { useEffect, useState } from 'react';
import Container from 'common/core/Container';
import PageTitle from 'common/core/PageTitle';
import PodcastsEpisodesList from './PodcastsEpisodesList';
import { usePodcasts } from 'hooks/podcasts/usePodcasts';
import { usePodcastsEpisodes } from 'hooks/podcasts/usePodcastsEpisodes';
import { PodcastsEpisode } from 'types/podcasts';
import { PageMode } from 'types/general';
import PodcastsEpisodesActions from './PodcastsEpisodesActions';
import PodcastsEpisodesEdit from './PodcastsEpisodesEdit';

const PodcastsEpisodesContainer = () => {
  const [pageMode, setPageMode] = useState<PageMode>(PageMode.LIST);
  const [episodeIdCurrent, setEpisodeIdCurrent] = useState<string>('');
  const [isOpenEditModal, setIsEditOpenModal] = useState<boolean>(false);
  const { data: podcastsList } = usePodcasts();
  const { isLoading, episodesList, createEpisode, deleteEpisode, editEpisode, editEpisodeAudio } =
    usePodcastsEpisodes();

  useEffect(() => {
    if (pageMode !== PageMode.EDIT && pageMode !== PageMode.VIEW) {
      setEpisodeIdCurrent('');
    }
  }, [pageMode]);

  const handleAuthorIdCurrent = (value: string) => {
    setEpisodeIdCurrent(value);
  };

  const handleEditMode = (poetryId: string) => {
    setEpisodeIdCurrent(poetryId);
    setIsEditOpenModal(true);
  };

  const handleAddMode = () => {
    setEpisodeIdCurrent('');
    setIsEditOpenModal(true);
  };

  return (
    <>
      <Container>
        <PageTitle title="Епізоди подкастів" />
        <PodcastsEpisodesActions
          handleAddMode={handleAddMode}
          episodeIdCurrent={episodeIdCurrent}
          handleAuthorIdCurrent={handleAuthorIdCurrent}
        />
        {pageMode === PageMode.LIST && (
          <PodcastsEpisodesList
            episodesList={episodesList}
            handleEditMode={handleEditMode}
            editEpisodeAudio={editEpisodeAudio}
            deleteEpisode={deleteEpisode}
          />
        )}
      </Container>
      {!!podcastsList && (
        <PodcastsEpisodesEdit
          isOpen={isOpenEditModal}
          toggleIsOpen={() => setIsEditOpenModal(false)}
          podcastsList={podcastsList}
          episode={
            episodesList.find((item: PodcastsEpisode) => item.id === episodeIdCurrent) ?? null
          }
          createEpisode={createEpisode}
          editEpisode={editEpisode}
        />
      )}
    </>
  );
};

export default PodcastsEpisodesContainer;
