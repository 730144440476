import { useMutation, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { TeamMember } from 'types/general';

interface InputData {
  memberId: string;
  file: any;
}

export const useEditTeamMemberPicture = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (details: InputData) => {
      const { memberId, file } = details;
      let formData = new FormData();
      formData.append('file', file);
      formData.append('memberId', memberId);

      const result: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/general/edit_team_member_picture',
        formData,
      );

      await new Promise((resolve) => setTimeout(resolve, 1500));

      return result;
    },
    onSuccess: (data, variables) => {
      const results = data?.data;

      if (results?.id) {
        const recordsList: TeamMember[] = queryClient.getQueryData('team-members') ?? [];
        const recordsListNew = recordsList.map((item) =>
          item.id !== results?.id
            ? item
            : {
                ...item,
                ...results,
              },
        );
        queryClient.setQueryData(['team-members'], recordsListNew);
      }
    },
  });
};
