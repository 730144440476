import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddToPhotosTwoTone';
import MicIcon from '@material-ui/icons/MicNoneTwoTone';
import Button from 'common/core/Button';
import { StoryPageMode } from 'types/projects/tvorytelling';
import { useStyles } from './styles';

// interface Props {
//   pageMode: string;
//   handlePageMode: (value: string) => void;
// }

const MyStoryHeader = ({ story, pageMode, handlePageMode, createFrame }: any) => {
  const history = useHistory();
  const fileRef = useRef<any>();
  const classes = useStyles();

  const handleUpoloadImage = () => {
    fileRef.current.click();
  };

  const chooseFileInUploader = (e: any) => {
    if (e.target.files.length) {
      createFrame(e.target.files[0]);
      fileRef.current.value = null;
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.titleBlock}>
          <Typography className={classes.titleText}>{story?.title ?? ''}</Typography>
        </div>
        <div className={classes.actionBlock}>
          {/* {pageMode === StoryPageMode.LIST_CREATE ? (
            <Button onClick={() => history.push(`/tvorytelling/story/${story?.id}`)}>
              View Mode
            </Button>
          ) : (
            <Button onClick={() => handlePageMode(StoryPageMode.LIST_CREATE)}>Create Mode</Button>
          )} */}
          {/* &nbsp;
          <Button variant="outlined" onClick={() => history.push(`/tvorytelling/mystories`)}>
            Back to list
          </Button> */}
          &nbsp;
          {pageMode === StoryPageMode.LIST_CREATE && (
            <>
              <Button onClick={handleUpoloadImage}>Додати слайд</Button>
              {/* <IconButton onClick={handleUpoloadImage} className={classes.actionButton}>
                <AddIcon className={classes.actionIcon} />
              </IconButton> */}
            </>
          )}
        </div>
      </div>
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={chooseFileInUploader}
        multiple
        accept="image/*"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default MyStoryHeader;
