import React, { useState } from 'react';
import classnames from 'classnames';
import { Typography, IconButton, Grid } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  ShoppingCart as AvailableIcon,
  EditTwoTone as EditIcon,
  DeleteTwoTone as DeleteIcon,
} from '@material-ui/icons';
import BadgeIcon from 'common/core/Badge/BadgeIcon';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import { ShopProduct } from 'types/shop';
import { useStyles } from './styles';

interface Props {
  product: ShopProduct;
  handleEditMode: (productId: string) => void;
  deleteProduct: (productId: string) => void;
}

const ProductsListItem = ({ product, handleEditMode, deleteProduct }: Props) => {
  const { id: productId, name, price, pictureUrl, pictures, isPublic, isAvailable } = product;
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEditAction = () => {
    handleEditMode(productId);
    setAnchorEl(null);
  };

  const handleDeleteAction = () => {
    toggleOpenConfirmModal();
    setAnchorEl(null);
  };

  const toggleOpenConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  const handleDeleteApproved = () => {
    deleteProduct(productId);
    toggleOpenConfirmModal();
  };

  return (
    <>
      <Grid item md={3} sm={4} xs={12}>
        <div className={classes.container}>
          <div className={classes.pictureBlock}>
            {!!pictureUrl && <img src={pictureUrl} className={classes.picture} alt="" />}
            {!isPublic && <BadgeIcon type="private" />}
            {isAvailable && (
              <div className={classes.isSpecialBlock}>
                <AvailableIcon className={classes.isSpecialIcon} />
              </div>
            )}
          </div>
          <div className={classes.infoBlock}>
            <div className={classes.leftBlock}>
              <div className={classes.titleBlock}>
                <Typography className={classes.titleText}>{name}</Typography>
              </div>
              <div className={classes.priceBlock}>
                <Typography className={classes.priceText}>{price ?? '---'} UAH</Typography>
              </div>
            </div>
            <div className={classes.actionsBlock}>
              <IconButton onClick={toggleMenu} className={classes.menuButton}>
                <MoreVertIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </Grid>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <MenuItem onClick={handleEditAction} className={classes.subMenu} key="edit">
          Редагувати
          <EditIcon />
        </MenuItem>
        {!pictureUrl && !pictures?.length && (
          <MenuItem
            onClick={handleDeleteAction}
            className={classnames(classes.subMenu, classes.subMenuDelete)}
            key="delete"
          >
            Видалити
            <DeleteIcon />
          </MenuItem>
        )}
      </Menu>
      <ModalConfirm
        title="Дійсно хочете видалити?"
        note="Ви не зможете повернути назад."
        isOpenConfirmModal={isOpenConfirmModal}
        toggleOpenConfirmModal={toggleOpenConfirmModal}
        handleSumbit={handleDeleteApproved}
      />
    </>
  );
};

export default ProductsListItem;
