import { useState, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { useAlert } from '../useAlert';

export const usePasswordEdit = () => {
  const [answerError, setAnswerError] = useState<string>('');
  const showAlert = useAlert();

  const editPassword = useCallback(async (password: string, passwordNew: string) => {
    const details = {
      password,
      passwordNew,
    };

    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/auth/edit_password',
      details,
    );

    if (results?.data) {
      if (results.data?.error) {
        setAnswerError(results.data.error);
      } else {
        setAnswerError('');
        showAlert('Ваш пароль був змінений.');
      }
    }
  }, []);

  return { editPassword, answerError };
};
