import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import InfoBox from 'common/core/InfoBox';
import ProductsListItem from './ProductsListItem';
import Pagination from 'common/core/Pagination';
import { ShopProduct } from 'types/shop';
import { useStyles } from './styles';

const NUM_VALUES_ON_PAGE = 15;

interface Props {
  isLoadingProducts: boolean;
  productsList: ShopProduct[];
  handleEditMode: (productId: string) => void;
  handleViewMode: (productId: string) => void;
  deleteProduct: (productId: string) => void;
}

const ProductsList = ({
  isLoadingProducts,
  productsList,
  handleEditMode,
  handleViewMode,
  deleteProduct,
}: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (productsList.length) {
      const pageCountNew = Math.ceil(productsList.length / NUM_VALUES_ON_PAGE);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [productsList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Grid container spacing={1}>
        {!productsList.length && !isLoadingProducts && (
          <InfoBox label="Ви поки що не створили жодного поста." className={classes.infoBox} />
        )}
        {productsList
          .filter(
            (_: ShopProduct, index: number) =>
              index >= (page - 1) * NUM_VALUES_ON_PAGE && index < page * NUM_VALUES_ON_PAGE,
          )
          .map((product: ShopProduct, index: number) => (
            <ProductsListItem
              key={product.id}
              product={product}
              handleEditMode={handleEditMode}
              deleteProduct={deleteProduct}
            />
          ))}
      </Grid>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default ProductsList;
