import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Grid, Typography, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  EditTwoTone as EditIcon,
  DeleteTwoTone as DeleteIcon,
  ImageTwoTone as ImageIcon,
} from '@material-ui/icons';
import BadgeIcon from 'common/core/Badge/BadgeIcon';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import StoryBigIcon from 'assets/icons/story2.svg';
import { PoetryWalksAuthor } from 'types/projects/poetry';
import { useStyles } from './styles';

interface Props {
  author: PoetryWalksAuthor;
  handleEditMode: (authorId: string) => void;
  editAuthorPicture: (authorId: string, file: any) => void;
}

const AuthorsListItem = ({ author, handleEditMode, editAuthorPicture }: Props) => {
  const { id: authorId, name, pictureUrl, isPublic } = author;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const pictureRef = useRef<any>();
  const fileRef = useRef<any>();
  const classes = useStyles();

  useEffect(() => {
    if (pictureUrl) {
      pictureRef.current.src = pictureUrl;
    }
  }, [pictureUrl]);

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEditAction = () => {
    handleEditMode(authorId);
    setAnchorEl(null);
  };

  const handleUpoloadImage = () => {
    setAnchorEl(null);
    fileRef.current.click();
  };

  const chooseFileInUploader = (e: any) => {
    if (e.target.files.length) {
      editAuthorPicture(authorId, e.target.files[0]);
    }
    fileRef.current.value = null;
  };

  return (
    <>
      <Grid item md={3} sm={6} xs={12}>
        <div className={classes.container}>
          <div className={classes.pictureBlock}>
            {!!pictureUrl && (
              <img
                src={pictureUrl ? pictureUrl : StoryBigIcon}
                className={pictureUrl ? classes.picture : classes.imgEmptyIcon}
                ref={pictureRef}
                alt=""
              />
            )}
            {!isPublic && <BadgeIcon type="private" />}
          </div>
          <div className={classes.infoBlock}>
            <div className={classes.leftBlock}>
              <div className={classes.titleBlock}>
                <Typography className={classes.titleText}>{name}</Typography>
              </div>
            </div>
            <div className={classes.actionsBlock}>
              <IconButton onClick={toggleMenu} className={classes.menuButton}>
                <MoreVertIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </Grid>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <MenuItem onClick={handleUpoloadImage} className={classes.subMenu} key="edit_picture">
          Нове зображення
          <ImageIcon />
        </MenuItem>
        <MenuItem onClick={handleEditAction} className={classes.subMenu} key="edit">
          Редагувати
          <EditIcon />
        </MenuItem>
      </Menu>
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={chooseFileInUploader}
        multiple
        accept="image/*"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default AuthorsListItem;
