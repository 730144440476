import React from 'react';
import PlaceAutocomplete from 'common/places/PlaceAutocomplete';
import { useStyles } from './styles';

interface Props {
  cityFilter: string;
  handleChangeCity: (cityFilter: string, position: [number, number] | null) => void;
}

const RoutesFilter = ({ cityFilter, handleChangeCity }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <PlaceAutocomplete
        valueInitial={cityFilter}
        handleChangeCity={handleChangeCity}
        className={classes.filterSelect}
      />
    </div>
  );
};

export default RoutesFilter;
