import React, { useState, useEffect } from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import { StoriesPageMode, StoryCategory } from 'types/projects/tvorytelling';
import { useStyles } from './styles';

interface Props {
  story: any | null;
  categoriesList: any[];
  createStory: (categoryId: string, name: string, description: string, isPublic: boolean) => void;
  editStory: (
    storyId: string,
    categoryId: string,
    name: string,
    description: string,
    isPublic: boolean,
  ) => void;
  handlePageMode: (value: string) => void;
}

const MyStoryEdit = ({ story, categoriesList, createStory, editStory, handlePageMode }: Props) => {
  const [categoryId, setCategoryId] = useState<string>('');
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    if (story) {
      // console.log(story?.isTemplate);
      setCategoryId(story?.categoryId ?? '');
      setName(story?.title ?? '');
      setDescription(story?.description ?? '');
      setIsTemplate(story?.isTemplate ?? false);
      setIsPublic(story?.isPublic ?? false);
    }
  }, [story]);

  const handleSubmit = () => {
    setNameError(name ? '' : 'Заповніть назву історії');

    if (name) {
      if (!story?.id) {
        createStory(categoryId, name, description, isPublic);
      } else {
        editStory(story?.id ?? '', categoryId, name, description, isPublic);
      }
      handlePageMode(StoriesPageMode.LIST);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.inputBlock}>
        <Input
          size="medium"
          label="Назва історії"
          type="text"
          className={classes.input}
          onChange={(e) => setName(e.target.value)}
          value={name}
          helperText={nameError}
          fullWidth
          required
        />
      </div>
      <div className={classes.inputBlock}>
        <Input
          label="Опис"
          type="text"
          className={classes.input}
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          fullWidth
          multiline
          rows={8}
          required
        />
      </div>
      <div className={classes.inputBlock}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Категорія</InputLabel>
          <Select
            label="Категорія"
            native
            onChange={(e: any) => setCategoryId(e.target.value)}
            value={categoryId}
            className={classes.select}
          >
            <option aria-label="None" value="" key={0}></option>
            {categoriesList
              .sort((a: StoryCategory, b: StoryCategory) => a.name.localeCompare(b.name))
              .map((item: StoryCategory) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.inputBlock}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Шаблон</InputLabel>
          <Select
            label="Шаблон"
            native
            onChange={(e: any) => setIsPublic(e.target.value === 'true' ? true : false)}
            value={isTemplate ? 'true' : 'false'}
            className={classes.select}
          >
            <option value="false" key="private">
              Ні
            </option>
            <option value="true" key="public">
              Так
            </option>
          </Select>
        </FormControl>
      </div>

      <div className={classes.inputBlock}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Публікувати</InputLabel>
          <Select
            label="Публікувати"
            native
            onChange={(e: any) => setIsPublic(e.target.value === 'true' ? true : false)}
            value={isPublic ? 'true' : 'false'}
            className={classes.select}
          >
            <option value="false" key="private">
              Ні
            </option>
            <option value="true" key="public">
              Так
            </option>
          </Select>
        </FormControl>
      </div>
      <div className={classes.buttonBlock}>
        <Button onClick={handleSubmit}>Зберегти</Button>
      </div>
    </div>
  );
};

export default MyStoryEdit;
