import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Grid, Typography, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  Lock as LockIcon,
  Person as PersonIcon,
  EditTwoTone as EditIcon,
  DeleteTwoTone as DeleteIcon,
  ImageTwoTone as ImageIcon,
} from '@material-ui/icons';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import StoryBigIcon from 'assets/icons/story2.svg';
import { formatDateBrief } from 'utils/datetime';
import { Story } from 'types/projects/tvorytelling';
import { useStyles } from './styles';

interface Props {
  story: Story;
  handleEditMode: (placeId: string) => void;
  deleteStory: (storyId: string) => void;
  editStoryPicture: (storyId: string, file: any) => void;
}

const MyStoriesListItem = ({ story, handleEditMode, deleteStory, editStoryPicture }: Props) => {
  const {
    id: storyId,
    title,
    author,
    pictureUrl,
    userName,
    numFrames,
    isPublic,
    createdAt,
  } = story;
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const pictureRef = useRef<any>();
  const fileRef = useRef<any>();
  const classes = useStyles();

  useEffect(() => {
    if (pictureUrl) {
      pictureRef.current.src = pictureUrl;
    }
  }, [pictureUrl]);

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEditAction = () => {
    handleEditMode(storyId);
    setAnchorEl(null);
  };

  const handleDeleteAction = () => {
    toggleOpenConfirmModal();
    setAnchorEl(null);
  };

  const toggleOpenConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  const handleDeleteApproved = () => {
    deleteStory(storyId);
    toggleOpenConfirmModal();
  };

  const handleUpoloadImage = () => {
    setAnchorEl(null);
    fileRef.current.click();
  };

  const chooseFileInUploader = (e: any) => {
    if (e.target.files.length) {
      editStoryPicture(storyId, e.target.files[0]);
    }
    fileRef.current.value = null;
  };

  return (
    <>
      <Grid item md={4} sm={6} xs={12}>
        <div className={classes.container}>
          <div
            className={pictureUrl ? classes.pictureBlock : classes.pictureEmptyBlock}
            onClick={() => history.push(`/tvorytelling/template/${storyId}`)}
          >
            <img
              src={pictureUrl ? pictureUrl : StoryBigIcon}
              className={pictureUrl ? classes.picture : classes.imgEmptyIcon}
              ref={pictureRef}
              alt=""
            />
            {!isPublic && (
              <div className={classes.isPrivateBlock}>
                <LockIcon className={classes.isPrivateIcon} />
              </div>
            )}
          </div>
          <div className={classes.infoBlock}>
            <div className={classes.leftBlock}>
              <div className={classes.titleBlock}>
                <Typography
                  className={classnames(classes.titleText, !title && classes.titleNoText)}
                >
                  {!!title ? title : 'Немає назви'}
                </Typography>
              </div>
              {/* <div className={classes.dateBlock}>
                <PersonIcon className={classes.infoIcon} />
                <Typography className={classes.dateText}>{userName}</Typography>
              </div> */}
              <div className={classes.dateBlock}>
                <Typography className={classes.infoText}>{author}</Typography>
              </div>
              <div className={classes.dateBlock}>
                <Typography className={classes.infoText}>
                  Створено: {formatDateBrief(createdAt)}
                </Typography>
              </div>
            </div>
            <div className={classes.actionsBlock}>
              <IconButton onClick={toggleMenu} className={classes.menuButton}>
                <MoreVertIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </Grid>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <MenuItem onClick={handleUpoloadImage} className={classes.subMenu} key="edit_picture">
          Нова обкладинка
          <ImageIcon />
        </MenuItem>
        <MenuItem onClick={handleEditAction} className={classes.subMenu} key="edit">
          Редагувати
          <EditIcon />
        </MenuItem>
        {!numFrames && (
          <MenuItem
            onClick={handleDeleteAction}
            className={classnames(classes.subMenu, classes.subMenuDelete)}
            key="delete"
          >
            Видалити
            <DeleteIcon />
          </MenuItem>
        )}
      </Menu>
      <ModalConfirm
        title="Дійсно хочете видалити?"
        note="Ви не зможете повернути назад."
        isOpenConfirmModal={isOpenConfirmModal}
        toggleOpenConfirmModal={toggleOpenConfirmModal}
        handleSumbit={handleDeleteApproved}
      />
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={chooseFileInUploader}
        multiple
        accept="image/*"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default MyStoriesListItem;
