import React, { useState, useEffect } from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import Modal from 'common/core/Modal';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import { ShopCategory, ShopCategoryInputCreate, ShopCategoryInputEdit } from 'types/shop';
import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
  category: ShopCategory | null;
  createCategory: (details: ShopCategoryInputCreate) => void;
  editCategory: (details: ShopCategoryInputEdit) => void;
}

const ShopCategoriesEdit = ({
  isOpen,
  toggleIsOpen,
  category,
  createCategory,
  editCategory,
}: Props) => {
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [uid, setUid] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    setName(category?.name ?? '');
    setUid(category?.uid ?? '');
    setIsPublic(category?.isPublic ?? true);

    return () => {
      setName('');
      setUid('');
      setIsPublic(true);
    };
  }, [category, isOpen]);

  const handleSubmit = () => {
    setNameError(name ? '' : 'Заповніть назву історії');

    if (name) {
      if (!category?.id) {
        createCategory({ name, uid, isPublic });
      } else {
        editCategory({
          categoryId: category?.id ?? '',
          name,
          uid,
          isPublic,
        });
      }
      toggleIsOpen();
    }
  };

  return (
    <Modal
      title={category?.id ? 'Редагування автора' : 'Новий автор'}
      widthMax={450}
      open={isOpen}
      handleClose={toggleIsOpen}
    >
      <div className={classes.container}>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Назва"
            type="text"
            className={classes.input}
            onChange={(e) => setName(e.target.value)}
            value={name}
            helperText={nameError}
            fullWidth
            required
          />
        </div>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="UID"
            type="text"
            className={classes.input}
            onChange={(e) => setUid(e.target.value)}
            value={uid}
            helperText={nameError}
            fullWidth
            required
          />
        </div>
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Доступ</InputLabel>
            <Select
              label="Доступ"
              native
              onChange={(e: any) => setIsPublic(e.target.value === 'true' ? true : false)}
              value={isPublic ? 'true' : 'false'}
              className={classes.select}
            >
              <option value="false" key="private">
                Закритий
              </option>
              <option value="true" key="public">
                Відкритий
              </option>
            </Select>
          </FormControl>
        </div>
        <div className={classes.buttonBlock}>
          <Button onClick={handleSubmit}>Зберегти</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShopCategoriesEdit;
