import { Typography, Grid } from '@material-ui/core';
import { useStyles } from './styles';

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container spacing={0} className={classes.infoBlock}>
        <Grid item md={6} sm={8} xs={8}>
          <Typography noWrap className={classes.textSmall}>
            {new Date().getFullYear()} &copy; Всі права захищені.
          </Typography>
        </Grid>
        <Grid item md={6} sm={4} xs={4} className={classes.iconBlock}></Grid>
      </Grid>
    </div>
  );
};

export default Footer;
