import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { MenuItem as MenuItemOriginal } from '@material-ui/core';
import { useStyles } from './styles';

interface Props {
  className?: string;
  onClick?: (event: React.MouseEvent<any>) => void;
  children: React.ReactNode;
}

const MenuItem = (
  { className = '', onClick, children }: Props,
  ref: React.ForwardedRef<HTMLUListElement>,
) => {
  const classes = useStyles();

  return (
    <MenuItemOriginal className={classnames(classes.root, className)} onClick={onClick}>
      {children}
    </MenuItemOriginal>
  );
};

export default forwardRef(MenuItem);
