import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { useConfirmLink } from 'hooks/auth/useConfirmLink';
import { useStyles } from './styles';

const ConfirmLinkContainer = () => {
  const location = useLocation();
  const pagePath = location.pathname.split('/');
  const confirmCode = pagePath[3] ?? '';
  const { confirmStatus } = useConfirmLink(confirmCode);
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      ConfirmLinkContainer {confirmCode} {confirmStatus}
    </Container>
  );
};

export default ConfirmLinkContainer;
