import React, { useState, useEffect } from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import Modal from 'common/core/Modal';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import { Route, RouteEditInput } from 'types/places';
import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
  route: Route;
  editRoute: (value: RouteEditInput) => void;
}

const RouteItemEditModal = ({ isOpen, toggleIsOpen, route, editRoute }: Props) => {
  const [name, setName] = useState<string>('');
  const [isRecommended, setIsRecommended] = useState<boolean>(false);
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const classes = useStyles();

  useEffect(() => {
    if (route) {
      setName(route?.name ?? '');
      setIsRecommended(route?.isRecommended ?? true);
      setIsPublic(route?.isPublic ?? true);
    } else {
      setName('');
      setIsRecommended(false);
      setIsPublic(true);
    }
  }, [route, isOpen]);

  const handleSubmit = () => {
    editRoute({
      routeId: route?.id,
      name,
      isRecommended,
      isPublic,
    });
    toggleIsOpen();
  };

  return (
    <Modal title={'Редагування маршруту'} widthMax={650} open={isOpen} handleClose={toggleIsOpen}>
      <div className={classes.container}>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Назва маршруту"
            type="text"
            className={classes.input}
            onChange={(e) => setName(e.target.value)}
            value={name}
            fullWidth
          />
        </div>
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Рекомендований</InputLabel>
            <Select
              label="Рекомендований"
              native
              onChange={(e: any) => setIsRecommended(e.target.value === 'true' ? true : false)}
              value={isRecommended ? 'true' : 'false'}
              className={classes.select}
            >
              <option value="false" key="no">
                Ні
              </option>
              <option value="true" key="yes">
                Так
              </option>
            </Select>
          </FormControl>
        </div>
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Доступ</InputLabel>
            <Select
              label="Доступ"
              native
              onChange={(e: any) => setIsPublic(e.target.value === 'true' ? true : false)}
              value={isPublic ? 'true' : 'false'}
              className={classes.select}
            >
              <option value="false" key="private">
                Закритий
              </option>
              <option value="true" key="public">
                Відкритий
              </option>
            </Select>
          </FormControl>
        </div>
        <div className={classes.buttonBlock}>
          <Button onClick={handleSubmit}>Зберегти</Button>
        </div>
      </div>
    </Modal>
  );
};

export default RouteItemEditModal;
