import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { Grid, Typography, IconButton, Chip } from '@material-ui/core';
import {
  Lock as LockIcon,
  EditTwoTone as EditIcon,
  PhotoLibraryTwoTone as GalleryIcon,
  DeleteTwoTone as DeleteIcon,
  ImageTwoTone as ImageIcon,
} from '@material-ui/icons';
import BadgeIcon from 'common/core/Badge/BadgeIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import { Project } from 'types/projects';
import { useStyles } from './styles';

interface Props {
  project: Project;
  handleEditMode: (value: string) => void;
  handleEditGalleryMode: (value: string) => void;
  deleteProject: (value: string) => void;
  editProjectPicture: (projectId: string, file: any) => void;
}

const ProjectsListItem = ({
  project,
  handleEditMode,
  handleEditGalleryMode,
  deleteProject,
  editProjectPicture,
}: Props) => {
  const { id: projectId, name, descriptionBrief, isPublic, isTesting, pictureUrl } = project;
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const fileRef = useRef<any>();
  const classes = useStyles();

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEditAction = () => {
    handleEditMode(projectId);
    setAnchorEl(null);
  };

  const handleEditGalleryAction = () => {
    handleEditGalleryMode(projectId);
    setAnchorEl(null);
  };

  const handleDeleteAction = () => {
    toggleOpenConfirmModal();
    setAnchorEl(null);
  };

  const toggleOpenConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  const handleDeleteApproved = () => {
    deleteProject(projectId);
    toggleOpenConfirmModal();
  };

  const handleUpoloadImage = () => {
    setAnchorEl(null);
    fileRef.current.click();
  };

  const chooseFileInUploader = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      fileRef.current.value = '';
      editProjectPicture(projectId, file);
    }
  };

  return (
    <>
      <Grid item md={4} sm={6} xs={12}>
        <div className={classes.container}>
          {!!pictureUrl && (
            <div className={classes.pictureBlock}>
              <img src={pictureUrl} className={classes.picture} alt="" />
            </div>
          )}
          {!isPublic && <BadgeIcon type="private" />}
          <div className={classes.infoBlock}>
            <div className={classes.leftBlock}>
              <div className={classes.titleBlock}>
                <Typography className={classes.titleText}>{name}</Typography>
              </div>
            </div>
            <div className={classes.actionsBlock}>
              <IconButton onClick={toggleMenu} className={classes.menuButton}>
                <MoreVertIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </Grid>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <MenuItem onClick={handleEditAction} className={classes.subMenu} key="edit">
          Редагувати
          <EditIcon />
        </MenuItem>
        <MenuItem onClick={handleUpoloadImage} className={classes.subMenu} key="edit_picture">
          Змінити ілюстрацію
          <ImageIcon />
        </MenuItem>
        <MenuItem onClick={handleEditGalleryAction} className={classes.subMenu} key="edit_gallery">
          Галерея
          <GalleryIcon />
        </MenuItem>
        <MenuItem
          onClick={handleDeleteAction}
          className={classnames(classes.subMenu, classes.subMenuDelete)}
          key="delete"
        >
          Видалити
          <DeleteIcon />
        </MenuItem>
      </Menu>
      <ModalConfirm
        title="Дійсно хочете видалити?"
        note="Ви не зможете повернути назад."
        isOpenConfirmModal={isOpenConfirmModal}
        toggleOpenConfirmModal={toggleOpenConfirmModal}
        handleSumbit={handleDeleteApproved}
      />
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={chooseFileInUploader}
        multiple
        accept="image/*"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default ProjectsListItem;
