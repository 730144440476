import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },

  formBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  element: {
    display: 'flex',
    paddingTop: 8,
    paddingBottom: 8,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  errorText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    color: 'red',
    cursor: 'pointer',
  },
  linkBlock: {
    display: 'flex',
    width: '100%',
    paddingTop: 5,
  },
  linkBlockLeft: {
    display: 'flex',
    width: '50%',
  },
  linkBlockRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '50%',
  },
  linkText: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
}));
