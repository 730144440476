import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import NatureTasksListItem from './TasksListItem';
import Pagination from 'common/core/Pagination';
import { NatureTask } from 'types/projects/nature';
import { useStyles } from './styles';

interface Props {
  tasksList: NatureTask[];
  handleEditMode: (taskId: string) => void;
  deleteTask: (taskId: string) => void;
}

const NUM_VALUES_ON_PAGE = 15;

const TasksList = ({ tasksList, handleEditMode, deleteTask }: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (tasksList.length) {
      const pageCountNew = Math.ceil(tasksList.length / NUM_VALUES_ON_PAGE);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [tasksList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Grid container spacing={1}>
        {tasksList
          .filter(
            (_: NatureTask, index: number) =>
              index >= (page - 1) * NUM_VALUES_ON_PAGE && index < page * NUM_VALUES_ON_PAGE,
          )
          .map((task: NatureTask) => (
            <NatureTasksListItem
              key={task.id}
              task={task}
              handleEditMode={handleEditMode}
              deleteTask={deleteTask}
            />
          ))}
      </Grid>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default TasksList;
