import React, { cloneElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import SubjectIcon from '@material-ui/icons/Subject';
import { useStyles } from './styles';

interface Props {
  title: string;
  url: string;
  children?: React.ReactElement;
}

const MenuMainItem = ({ title, url, children = <SubjectIcon /> }: Props) => {
  const history = useHistory();
  const classes = useStyles();

  const clonedElement = cloneElement(children, { className: classes.icon });

  return (
    <Grid item md={3} sm={6} xs={12}>
      <div className={classes.container}>
        <div className={classes.pictureBlock} onClick={url ? () => history.push(url) : () => {}}>
          {clonedElement}
        </div>

        <div className={classes.infoBlock}>
          <div className={classes.titleBlock}>
            <Typography className={classes.titleText}>{title}</Typography>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default MenuMainItem;
