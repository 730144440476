import { makeStyles, Theme } from '@material-ui/core/styles';
import { IMAGE_BACKGROUND_COLOR, IMPORTANT_COLOR } from 'config';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '1px solid #EBEBEB',
    borderRadius: 6,
  },
  pictureBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: '16/9',
    backgroundColor: IMAGE_BACKGROUND_COLOR,
    borderRadius: '6px 6px 0 0',
  },
  isPrivateBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 5,
    left: 5,
    width: 22,
    height: 22,
    backgroundColor: IMPORTANT_COLOR,
  },
  infoBlock: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
  },
  leftBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 5,
    paddingLeft: 10,
  },
  titleBlock: {
    display: 'flex',
    paddingBottom: 3,
  },
  detailsBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
  },
  actionsBlock: { paddingRight: 5 },
  picture: {
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '16/9',
    borderRadius: '6px 6px 0 0',
  },
  isPrivateIcon: {
    fontSize: 16,
    color: '#fff',
  },
  titleText: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '120%',
  },
  infoText: {
    fontSize: 14,
    fontWeight: 400,
    color: 'rgba(0,0,0,0.6)',
    lineHeight: '120%',
  },
  infoIcon: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.6)',
  },
  menuButton: {
    padding: 7,
  },
  subMenu: {
    minWidth: 170,
  },
  subMenuDelete: {
    color: '#FF0000 !important',
  },
  subMenuIcon: {},
}));
