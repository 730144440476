import React, { useEffect, useState } from 'react';
import AdminPlacesListItem from './AdminPlacesListItem';
import Pagination from 'common/core/Pagination';
import { numValuesOnPage } from 'config';
import { Place } from 'types/places';
import { useStyles } from './styles';

interface Props {
  isLoadingPlaces: boolean;
  placesList: Place[];
  handleEditMode: (placeId: string) => void;
  deletePlace: (placeId: string) => void;
}

const AdminPlacesList = ({ isLoadingPlaces, placesList, handleEditMode, deletePlace }: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  // console.log(usersList);

  useEffect(() => {
    if (placesList.length) {
      const pageCountNew = Math.ceil(placesList.length / numValuesOnPage);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [placesList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <div className={classes.container}>
        {placesList
          .sort((a: Place, b: Place) => b.createdAt - a.createdAt)
          .filter(
            (_: Place, index: number) =>
              index >= (page - 1) * numValuesOnPage && index < page * numValuesOnPage,
          )
          .map((item: any, index: number) => (
            <AdminPlacesListItem
              key={item.id}
              place={item}
              isLastItem={index === placesList.length - 1}
              handleEditMode={handleEditMode}
              deletePlace={deletePlace}
            />
          ))}
      </div>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default AdminPlacesList;
