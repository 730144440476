import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Avatar, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import { useStyles } from './styles';

interface Props {
  resetPassword: (password: string, confirmCode: string) => void;
  answerError: string;
}

const ResetPasswordForm = ({ resetPassword, answerError }: Props) => {
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordRepeatError, setPasswordRepeatError] = useState('');
  const location = useLocation();
  const pagePath = location.pathname.split('/');
  const confirmCode = pagePath[3] ?? '';
  const classes = useStyles();

  const handleSubmit = () => {
    setPasswordError('');
    setPasswordRepeatError('');

    if (password && passwordRepeat) {
      resetPassword(password, confirmCode);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Container maxWidth="xs" className={classes.container}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Зміна пароля
        </Typography>
        <form className={classes.form} noValidate>
          <div className={classes.formBlock}>
            <div className={classes.element}>
              {answerError && <Typography className={classes.errorText}>{answerError}</Typography>}
            </div>
            <div className={classes.element}>
              <Input
                label="Пароль"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                helperText={passwordError}
                fullWidth
                required
              />
            </div>
            <div className={classes.element}>
              <Input
                label="Пароль ще раз"
                type="password"
                onChange={(e) => setPasswordRepeat(e.target.value)}
                value={passwordRepeat}
                helperText={passwordRepeatError}
                fullWidth
                required
              />
            </div>
            <div className={classes.element}>
              <Button fullWidth onClick={handleSubmit}>
                Зберегти
              </Button>
            </div>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default ResetPasswordForm;
