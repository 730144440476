import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { timeout } from 'utils/timeout';
import { useAlert } from 'hooks/useAlert';
import { Project, ProjectInput } from 'types/projects';

export const useProjects = () => {
  const [projectsList, setProjectsList] = useState<Project[]>([]);
  const showAlert = useAlert();

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = useCallback(async () => {
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/projects/get_my_projects',
      {},
    );

    if (results?.data?.projects) {
      setProjectsList(results?.data?.projects);
    }
  }, []);

  const createProject = useCallback(async (value: ProjectInput) => {
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/projects/create_project', value);
    getProjects();
    showAlert('Застосунок було створено');
  }, []);

  const editProject = useCallback(async (value: ProjectInput) => {
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/projects/edit_project', value);
    getProjects();
    showAlert('Застосунок бул змінено');
  }, []);

  const editProjectPicture = useCallback(async (projectId: string, file: any) => {
    let formData = new FormData();
    formData.append('projectId', projectId);
    formData.append('file', file);
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/projects/edit_project_picture',
      formData,
    );
    await timeout(2000);
    getProjects();
    showAlert('Ілюстрація була додана');
  }, []);

  const deleteProject = useCallback(async (projectId: string) => {
    const details = { projectId };
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/projects/delete_project',
      details,
    );
    getProjects();
    showAlert('Застосунок бул видалено');
  }, []);

  const createGalleryPicture = useCallback(async (projectId: string, files: any[]) => {
    let formData = new FormData();
    formData.append('projectId', projectId);
    files.forEach((file: any) => {
      formData.append('files', file);
    });
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/projects/create_project_picture',
      formData,
    );
    await timeout(2000);
    getProjects();
    showAlert('Зображення галереї були додані');
  }, []);

  const deleteGalleryPicture = useCallback(async (projectId: string, url: string) => {
    const details = { projectId, url };
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/projects/delete_project_picture',
      details,
    );

    getProjects();
    showAlert('Зображення галереї видалено');
  }, []);

  return {
    projectsList,
    createProject,
    editProject,
    editProjectPicture,
    deleteProject,
    createGalleryPicture,
    deleteGalleryPicture,
  };
};
