import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import ShopCategoriesListItem from './ShopCategoriesListItem';
import Pagination from 'common/core/Pagination';
import { numValuesOnPage } from 'config';
import { PoetryWalksAuthor } from 'types/projects/poetry';
import { useStyles } from './styles';

interface Props {
  catgoriesList: PoetryWalksAuthor[];
  handleEditMode: (categoryId: string) => void;
  editCategoryPicture: (categoryId: string, file: any) => void;
}

const ShopCategoriesList = ({ catgoriesList, handleEditMode, editCategoryPicture }: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (catgoriesList.length) {
      const pageCountNew = Math.ceil(catgoriesList.length / numValuesOnPage);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [catgoriesList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Grid container spacing={1}>
        {catgoriesList
          .filter(
            (_: PoetryWalksAuthor, index: number) =>
              index >= (page - 1) * numValuesOnPage && index < page * numValuesOnPage,
          )
          .map((category: PoetryWalksAuthor) => (
            <ShopCategoriesListItem
              key={category.id}
              category={category}
              handleEditMode={handleEditMode}
              editCategoryPicture={editCategoryPicture}
            />
          ))}
      </Grid>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default ShopCategoriesList;
