import { useState } from 'react';
import Container from 'common/core/Container';
import СontentPagesHeader from './СontentPagesHeader';
import СontentPagesEdit from './СontentPagesEdit';
import СontentPagesList from './СontentPagesList';
import { useContentPages } from 'hooks/general/useContentPages';
import { PageMode, ContentPage } from 'types/general';

const EmailTemplatesContainer = () => {
  const [pageMode, setPageMode] = useState<string>(PageMode.LIST);
  const [pageIdCurrent, setPageIdCurrent] = useState<string>('');
  const { pagesList, createPage, editPage, deletePage } = useContentPages();

  const handlePageMode = (value: string) => {
    if (value === PageMode.LIST) {
      setPageIdCurrent('');
    }
    setPageMode(value);
  };

  const handleEditMode = (templateId: string) => {
    setPageIdCurrent(templateId);
    setPageMode(PageMode.EDIT);
  };

  return (
    <Container>
      <СontentPagesHeader pageMode={pageMode} handlePageMode={handlePageMode} />

      {pageMode === PageMode.LIST && (
        <СontentPagesList
          pagesList={pagesList}
          handleEditMode={handleEditMode}
          deletePage={deletePage}
        />
      )}
      {(pageMode === PageMode.EDIT || pageMode === PageMode.ADD) && (
        <СontentPagesEdit
          page={pagesList.find((template: ContentPage) => template.id === pageIdCurrent) ?? null}
          createPage={createPage}
          editPage={editPage}
          handlePageMode={handlePageMode}
        />
      )}
    </Container>
  );
};

export default EmailTemplatesContainer;
