import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Grid, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CircleRouteIcon from '@material-ui/icons/FlipCameraAndroidTwoTone';
import CommentsIcon from '@material-ui/icons/CommentOutlined';
import PlaceIcon from '@material-ui/icons/PlaceTwoTone';
import RouteIcon from '@material-ui/icons/TimelineTwoTone';
import PersonIcon from '@material-ui/icons/PersonOutlineTwoTone';
import DateIcon from '@material-ui/icons/DateRangeTwoTone';
import ModalContent from 'common/core/Modal/ModalContent';
import Map from 'common/core/Map';
import MapMarker from 'common/core/Map/MapMarker';
import MapPath from 'common/core/Map/MapPath';
import { useRouteView } from 'hooks/places/useRouteView';
import { formatDistance } from 'utils/geometry';
import { formatTime, formatDateAdvanced } from 'utils/datetime';
import { centerMapDefault } from 'config';
import { Route, RoutePathCoords, RoutePoint } from 'types/places';
import { useStyles } from './styles';

interface Props {
  route: Route;
  isOpenViewModal: boolean;
  toggleOpenViewModal: () => void;
}

const RouteItemViewModal = ({ route, isOpenViewModal, toggleOpenViewModal }: Props) => {
  const {
    id: routeId,
    points,
    numPoints,
    distance,
    name,
    userName,
    location,
    pictureUrl,
    isPublic,
    isLoopRoute,
    rating,
    numRatingVotes,
    isRatingVoted,
    numComments,
    createdAt,
  } = route;
  const [pointsList, setPointsList] = useState<RoutePoint[]>([]);
  const [positionCenter, setPositionCenter] = useState<[number, number]>(centerMapDefault);
  const { pathsList } = useRouteView(pointsList);
  const classes = useStyles();

  useEffect(() => {
    if (points) {
      setPointsList(JSON.parse(points));
    }
  }, [route]);

  return (
    <ModalContent widthMax={750} open={isOpenViewModal} handleClose={toggleOpenViewModal}>
      <div className={classes.container}>
        <div className={classes.headerBlock}>
          <div className={classes.headerLeftBlock}>
            <div className={classes.titleBlock}>
              <Typography
                className={classnames(classes.titleText, name === '' && classes.titleNoText)}
              >
                {name !== '' ? name : 'Назви немає'}
              </Typography>
              <div className={classes.closeBlock}>
                <IconButton className={classes.closeButton} onClick={toggleOpenViewModal}>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
            </div>
            <Grid container spacing={0}>
              <Grid item md={6} sm={6} xs={12}>
                <div className={classes.detailsLeftBlock}>
                  <div className={classes.infoBlock}>
                    <RouteIcon className={classes.infoIcon} />
                    <Typography className={classes.infoText}>
                      {formatDistance(distance)} (точок: {numPoints})
                    </Typography>
                    {isLoopRoute && (
                      <>
                        <CircleRouteIcon
                          className={classnames(classes.infoIcon, classes.circleRouteIcon)}
                        />
                        <Typography
                          className={classnames(classes.infoText, classes.circleRouteText)}
                        >
                          круговий
                        </Typography>
                      </>
                    )}
                  </div>
                  <div className={classes.infoBlock}>
                    <PlaceIcon className={classes.infoIcon} />
                    <Typography className={classnames(classes.infoText, classes.locationText)}>
                      {location !== '' ? location : '--  --'}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className={classes.detailsRightBlock}>
                  <div className={classes.infoBlock}>
                    <PersonIcon className={classes.infoIcon} />
                    <Typography className={classes.infoText}>{userName}</Typography>
                  </div>
                  {/* <div className={classes.infoBlock}>
                    <DateIcon className={classes.infoIcon} />
                    <Typography className={classes.infoText}>
                      {formatDateAdvanced(createdAt)}
                    </Typography>
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </div>
          {/* <div className={classes.closeBlock}>
            <IconButton className={classes.closeButton} onClick={toggleOpenViewModal}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div> */}
        </div>
        <div className={classes.mapBlock}>
          <Map center={positionCenter} pointsList={pointsList} zoom={15}>
            {pointsList.map((point: RoutePoint, index: number) =>
              !index ? (
                <>
                  <MapMarker
                    key={point.id}
                    position={[point.latitude, point.longitude]}
                    type="route_example"
                  />
                  <MapMarker
                    key={point.id + '_start'}
                    position={[point.latitude, point.longitude]}
                    type="route_example"
                    isStart={true}
                  />
                </>
              ) : (
                <MapMarker
                  key={point.id}
                  position={[point.latitude, point.longitude]}
                  type="route_example"
                />
              ),
            )}
            {pathsList.map((path: RoutePathCoords, index: number) => (
              <MapPath
                key={index.toString()}
                position1={path.position1}
                position2={path.position2}
                weight={2.5}
              />
            ))}
          </Map>
        </div>
        {/* <div className={classes.footerBlock}>..</div> */}
      </div>
    </ModalContent>
  );
};

export default RouteItemViewModal;
