import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import AchievementsListItem from './AchievementsListItem';
import Pagination from 'common/core/Pagination';
import { numValuesOnPage } from 'config';
import { NatureAchievement } from 'types/projects/nature';
import { useStyles } from './styles';

interface Props {
  achievementsList: NatureAchievement[];
  handleEditMode: (achievementId: string) => void;
  editAchievementPicture: (achievementId: string, file: any) => void;
}

const AchievementsList = ({ achievementsList, handleEditMode, editAchievementPicture }: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (achievementsList.length) {
      const pageCountNew = Math.ceil(achievementsList.length / numValuesOnPage);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [achievementsList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Grid container spacing={1}>
        {achievementsList
          .filter(
            (_: NatureAchievement, index: number) =>
              index >= (page - 1) * numValuesOnPage && index < page * numValuesOnPage,
          )
          .map((achievement: NatureAchievement) => (
            <AchievementsListItem
              key={achievement.id}
              achievement={achievement}
              handleEditMode={handleEditMode}
              editAchievementPicture={editAchievementPicture}
            />
          ))}
      </Grid>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default AchievementsList;
