import { useMutation, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { Podcast } from 'types/podcasts';

interface InputData {
  podcastId: string;
  file: any;
}

export const useEditPodcastPicture = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (details: InputData) => {
      const { podcastId, file } = details;
      let formData = new FormData();
      formData.append('file', file);
      formData.append('podcastId', podcastId);

      const result: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/podcasts/edit_podcast_picture',
        formData,
      );

      await new Promise((resolve) => setTimeout(resolve, 1500));

      return result;
    },
    onSuccess: (data, variables) => {
      const results = data?.data;

      if (results?.id) {
        const podcastsList: Podcast[] = queryClient.getQueryData('podcasts') ?? [];
        const podcastsListNew = podcastsList.map((item) =>
          item.id !== results?.id
            ? item
            : {
                ...item,
                ...results,
              },
        );
        queryClient.setQueryData(['podcasts'], podcastsListNew);
      }
    },
  });
};
