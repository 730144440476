import React from 'react';
import PlaceRoutesHeader from './PlaceRoutesHeader';
// import PlaceRoutesList from './PlaceRoutesList';
import { Place, Route } from 'types/places';

interface Props {
  isLoadingRoutes: boolean;
  routesList: Route[];
  radius: number;
  handleChangeRadius: (radius: number) => void;
}

const PlaceRoutes = ({ isLoadingRoutes, routesList, radius, handleChangeRadius }: Props) => {
  return (
    <div>
      <PlaceRoutesHeader radius={radius} handleChangeRadius={handleChangeRadius} />
      {/* <PlaceRoutesList isLoadingRoutes={isLoadingRoutes} routesList={routesList} /> */}
    </div>
  );
};

export default PlaceRoutes;
