import { useState, useEffect } from 'react';
import Container from 'common/core/Container';
import MyPlacesHeader from './MyPlacesHeader';
import MyPlacesList from './PlacesList';
import MyPlacesEdit from './PlacesEdit';
import PlacesArea from './PlacesArea';
import { useAdminPlaces } from 'hooks/places/useAdminPlaces';
import { PlacesPageMode, Place } from 'types/places';

const MyPlacesContainer = () => {
  const [pageMode, setPageMode] = useState<string>(PlacesPageMode.LIST);
  const [placeIdCurrent, setPlaceIdCurrent] = useState<string>('');
  const [placeCurrent, setPlaceCurrent] = useState<Place | null>(null);
  const {
    isLoading: isLoadingPlaces,
    categoriesList,
    placesList,
    createPlace,
    editPlace,
    deletePlace,
  } = useAdminPlaces();

  useEffect(() => {
    if (pageMode === PlacesPageMode.AREA && placesList.length && placeIdCurrent) {
      const place = placesList.find((item) => item.id === placeIdCurrent);
      setPlaceCurrent(place ?? null);
    } else {
      setPlaceCurrent(null);
    }
  }, [pageMode, placeIdCurrent]);

  const handlePageMode = (value: string) => {
    setPageMode(value);
  };

  const handleEditMode = (placeId: string) => {
    setPlaceIdCurrent(placeId);
    setPageMode(PlacesPageMode.EDIT);
  };

  const handleAreaMode = (placeId: string) => {
    setPlaceIdCurrent(placeId);
    setPageMode(PlacesPageMode.AREA);
  };

  return (
    <Container>
      <MyPlacesHeader pageMode={pageMode} handlePageMode={handlePageMode} />
      {pageMode === PlacesPageMode.LIST && (
        <MyPlacesList
          isLoadingPlaces={isLoadingPlaces}
          placesList={placesList}
          handleEditMode={handleEditMode}
          handleAreaMode={handleAreaMode}
          deletePlace={deletePlace}
        />
      )}
      {(pageMode === PlacesPageMode.EDIT || pageMode === PlacesPageMode.ADD) && (
        <MyPlacesEdit
          place={placesList.find((place: Place) => place.id === placeIdCurrent) ?? null}
          categoriesList={categoriesList}
          createPlace={createPlace}
          editPlace={editPlace}
          handlePageMode={handlePageMode}
        />
      )}
      {pageMode === PlacesPageMode.AREA && !!placeCurrent && (
        <PlacesArea placeId={placeIdCurrent} place={placeCurrent} handlePageMode={handlePageMode} />
      )}
    </Container>
  );
};

export default MyPlacesContainer;
