import { makeStyles, Theme } from '@material-ui/core/styles';
import { IMAGE_BACKGROUND_COLOR } from 'config';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 5px 20px rgba(23, 33, 48, 0.09)',
    borderRadius: 12,
    padding: 10,
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 20,
  },
  picturesBlock: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridTemplateRows: 'repeat(4, 1fr)',
    gap: 5,
    marginBottom: 20,
  },
  pictureBlock: {
    gridColumn: '1 / span 4',
    gridRow: '1 / span 4',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '3/2',
    borderRadius: 4,
    backgroundColor: IMAGE_BACKGROUND_COLOR,
  },
  galleryItemBlock: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    aspectRatio: '3/2',
    backgroundColor: IMAGE_BACKGROUND_COLOR,
  },
  picture: {
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '3/2',
    borderRadius: 4,
  },
  editIcon: {
    fontSize: 30,
    position: 'absolute',
    top: 5,
    right: 5,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
}));
