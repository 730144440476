import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 5px 20px rgba(23, 33, 48, 0.09)',
    borderRadius: 12,
    padding: 10,
    paddingLeft: 12,
    marginBottom: 20,
  },
  titleBlock: { marginBottom: 20 },
  contentBlock: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  elementBlock: {
    marginBottom: 15,
  },
  pictureBlock: {},
  deleteBlock: {},
  buttonBlock: {
    display: 'flex',
    marginTop: 20,
  },

  titleText: {
    fontSize: 20,
    fontWeight: 450,
  },
  picture: {
    width: '100%',
    border: '1px solid rgba(0,0,0,0.4)',
  },
  deleteButton: {
    padding: 2,
  },
  deleteIcon: {
    fontSize: 30,
    color: theme.palette.secondary.main,
  },
}));
