import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';

const RoutesHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.titleText}>Маршрути</Typography>
      </div>
      <div></div>
    </div>
  );
};

export default RoutesHeader;
