import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import AlphabetWordsListItem from './AlphabetWordsListItem';
import Pagination from 'common/core/Pagination';
import { numValuesOnPage } from 'config';
import { AlphabetWord } from 'types/projects/alphabet';
import { useStyles } from './styles';

interface Props {
  wordsList: AlphabetWord[];
  handleEditMode: (authorId: string) => void;
  editWordPicture: (wordId: string, file: any) => void;
  deleteWord: (wordId: string) => void;
}

const AlphabetWordsList = ({ wordsList, handleEditMode, editWordPicture, deleteWord }: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (wordsList.length) {
      const pageCountNew = Math.ceil(wordsList.length / numValuesOnPage);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [wordsList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Grid container spacing={1}>
        {wordsList
          .filter(
            (_: AlphabetWord, index: number) =>
              index >= (page - 1) * numValuesOnPage && index < page * numValuesOnPage,
          )
          .map((item: AlphabetWord) => (
            <AlphabetWordsListItem
              key={item.id}
              item={item}
              handleEditMode={handleEditMode}
              editWordPicture={editWordPicture}
              deleteWord={deleteWord}
            />
          ))}
      </Grid>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default AlphabetWordsList;
