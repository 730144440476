import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import MyStoriesListItem from './MyStoriesListItem';
import Pagination from 'common/core/Pagination';
import { numValuesOnPage } from 'config';
import { Story } from 'types/projects/tvorytelling';
import { useStyles } from './styles';

interface Props {
  storiesList: Story[];
  handleEditMode: (storyId: string) => void;
  deleteStory: (value: string) => void;
  editStoryPicture: (storyId: string, file: any) => void;
}

const MyStoriesList = ({ storiesList, handleEditMode, deleteStory, editStoryPicture }: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (storiesList.length) {
      const pageCountNew = Math.ceil(storiesList.length / numValuesOnPage);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [storiesList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Grid container spacing={2}>
        {storiesList
          .sort((a: Story, b: Story) => b.createdAt - a.createdAt)
          .filter(
            (_: Story, index: number) =>
              index >= (page - 1) * numValuesOnPage && index < page * numValuesOnPage,
          )
          .map((story: Story) => (
            <MyStoriesListItem
              key={story.id}
              story={story}
              handleEditMode={handleEditMode}
              deleteStory={deleteStory}
              editStoryPicture={editStoryPicture}
            />
          ))}
      </Grid>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default MyStoriesList;
