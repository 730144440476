import React, { useState } from 'react';
import classnames from 'classnames';
import { Typography, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  EditTwoTone as EditIcon,
  Send as SendIcon,
  DeleteTwoTone as DeleteIcon,
} from '@material-ui/icons';
import TableRow from 'common/core/Table/TableRow';
import TableCell from 'common/core/Table/TableCell';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import { EmailTemplate } from 'types/general';
import { useStyles } from './styles';

interface Props {
  template: EmailTemplate;
  handleEditMode: (templateId: string) => void;
  testTemplate: (templateId: string) => void;
  deleteTemplate: (templateId: string) => void;
}

const EmailTemplatesListItem = ({
  template,
  handleEditMode,
  testTemplate,
  deleteTemplate,
}: Props) => {
  const { id: templateId, name, label } = template;
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEditAction = () => {
    handleEditMode(templateId);
    setAnchorEl(null);
  };

  const handleTestAction = () => {
    testTemplate(templateId);
    setAnchorEl(null);
  };

  const handleDeleteAction = () => {
    toggleOpenConfirmModal();
    setAnchorEl(null);
  };

  const toggleOpenConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  const handleDeleteApproved = () => {
    deleteTemplate(templateId);
    toggleOpenConfirmModal();
  };

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell>
          <Typography className={classes.text}>{name}</Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.text}>{label}</Typography>
        </TableCell>
        <TableCell>
          <IconButton onClick={toggleMenu}>
            <MoreVertIcon className={classes.menuButton} />
          </IconButton>
        </TableCell>
      </TableRow>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <MenuItem onClick={handleEditAction} className={classes.subMenu} key="edit">
          Редагувати
          <EditIcon />
        </MenuItem>
        <MenuItem onClick={handleTestAction} className={classes.subMenu} key="test">
          Тестувати
          <SendIcon />
        </MenuItem>
        <MenuItem
          onClick={handleDeleteAction}
          className={classnames(classes.subMenu, classes.subMenuDelete)}
          key="delete"
        >
          Видалити
          <DeleteIcon />
        </MenuItem>
      </Menu>
      <ModalConfirm
        title="Дійсно хочете видалити?"
        note="Ви не зможете повернути назад."
        isOpenConfirmModal={isOpenConfirmModal}
        toggleOpenConfirmModal={toggleOpenConfirmModal}
        handleSumbit={handleDeleteApproved}
      />
    </>
  );
};

export default EmailTemplatesListItem;
