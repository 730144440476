import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import InfoBox from 'common/core/InfoBox';
import Pagination from 'common/core/Pagination';
import RouteItem from 'components/locations/RouteItem';
import { numValuesOnPage } from 'config';
import { Route, RouteEditInput } from 'types/places';
import { useStyles } from './styles';

interface Props {
  isLoadingRoutes: boolean;
  routesList: Route[];
  editRoute: (value: RouteEditInput) => void;
  deleteRoute: (routeId: string) => void;
}

const RoutesList = ({ isLoadingRoutes, routesList, editRoute, deleteRoute }: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (routesList.length) {
      const pageCountNew = Math.ceil(routesList.length / numValuesOnPage);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [routesList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Grid container spacing={1}>
        {!routesList.length && !isLoadingRoutes && (
          <InfoBox
            label="Цій умові пошуку не відповідає жоден маршрут."
            className={classes.infoBox}
          />
        )}
        {routesList
          .sort((a: Route, b: Route) => b.createdAt - a.createdAt)
          .filter(
            (_: Route, index: number) =>
              index >= (page - 1) * numValuesOnPage && index < page * numValuesOnPage,
          )
          .map((route: Route) => (
            <RouteItem
              key={route.id}
              route={route}
              editRoute={editRoute}
              deleteRoute={deleteRoute}
            />
          ))}
      </Grid>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default RoutesList;
