import React from 'react';
import { Dialog } from '@material-ui/core';
import { useStyles } from './styles';

interface Props {
  widthMax?: number;
  open: boolean;
  handleClose?: () => void;
  children?: React.ReactNode;
}

const ModalContent = ({ widthMax = 0, open = false, handleClose = () => {}, children }: Props) => {
  const classes = useStyles();

  const paperProps = widthMax ? { style: { width: widthMax } } : {};

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      className={classes.root}
      PaperProps={paperProps}
    >
      <div className={classes.contentBlock}>{children}</div>
    </Dialog>
  );
};

export default ModalContent;
