import { useEffect, useState } from 'react';
import Container from 'common/core/Container';
import BlogHeader from './BlogHeader';
import BlogList from './BlogList';
import BlogEdit from './BlogEdit';
import { useBlogPosts } from 'hooks/blog/useBlogPosts';
import { BlogPageMode, BlogPost, BlogPostInput } from 'types/blog';

const BlogContainer = () => {
  const [pageMode, setPageMode] = useState<string>(BlogPageMode.LIST);
  const [postIdCurrent, setPostIdCurrent] = useState<string>('');
  const {
    isLoading: isLoadingPosts,
    authorsList,
    categoriesList,
    postsList,
    createPost,
    editPost,
    editPostPicture,
    deletePost,
  } = useBlogPosts();

  const handlePageMode = (value: string) => {
    setPageMode(value);
  };

  useEffect(() => {
    if (pageMode !== BlogPageMode.EDIT && pageMode !== BlogPageMode.VIEW) {
      setPostIdCurrent('');
    }
  }, [pageMode]);

  const handleCreatePost = async (details: BlogPostInput) => {
    const postId = await createPost(details);
    if (postId) {
      setPostIdCurrent(postId);
      setPageMode(BlogPageMode.EDIT);
    }
  };

  const handleEditMode = (postId: string) => {
    setPostIdCurrent(postId);
    setPageMode(BlogPageMode.EDIT);
  };

  return (
    <Container>
      <BlogHeader pageMode={pageMode} handlePageMode={handlePageMode} />
      {pageMode === BlogPageMode.LIST && (
        <BlogList
          isLoadingPosts={isLoadingPosts}
          postsList={postsList}
          handleEditMode={handleEditMode}
          deletePost={deletePost}
        />
      )}

      {(pageMode === BlogPageMode.EDIT || pageMode === BlogPageMode.ADD) && (
        <BlogEdit
          post={postsList.find((place: BlogPost) => place.id === postIdCurrent) ?? null}
          authorsList={authorsList}
          categoriesList={categoriesList}
          createPost={handleCreatePost}
          editPost={editPost}
          editPostPicture={editPostPicture}
          handlePageMode={handlePageMode}
        />
      )}
    </Container>
  );
};

export default BlogContainer;
