import React, { useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Typography, IconButton, Grid } from '@material-ui/core';
// import Rating from '@material-ui/lab/Rating';
import {
  Lock as LockIcon,
  EditTwoTone as EditIcon,
  DeleteTwoTone as DeleteIcon,
  MoreVert as MoreVertIcon,
  FavoriteBorderOutlined as LoveIcon,
  FavoriteOutlined as LoveChosenIcon,
  FlipCameraAndroidTwoTone as CircleRouteIcon,
  CommentOutlined as CommentsIcon,
  PlaceTwoTone as PlaceIcon,
  TimelineTwoTone as RouteIcon,
} from '@material-ui/icons';
import RecommendIcon from '@material-ui/icons/Stars';
import RouteItemViewModal from './RouteItemViewModal';
import RouteItemEditModal from './RouteItemEditModal';
import RouteBigIcon from 'assets/icons/route3.svg';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
// import { useAlert } from 'hooks/useAlert';
import { formatDistance } from 'utils/geometry';
import { Route, RouteEditInput } from 'types/places';
import { useStyles } from './styles';

interface Props {
  route: Route;
  deleteRoute?: (value: string) => void;
  editRoute: (value: RouteEditInput) => void;
}

const RouteItem = ({ route, deleteRoute, editRoute }: Props) => {
  const {
    id: routeId,
    numPoints,
    distance,
    name,
    location,
    pictureUrl,
    isRecommended,
    isPublic,
    isLoopRoute,
    // rating,
    // numRatingVotes,
    // isRatingVoted,
    // numComments,
    // createdAt,
  } = route;
  const [isOpenViewModal, setIsOpenViewModal] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const pictureRef = useRef<any>();
  const history = useHistory();
  // const showAlert = useAlert();
  const classes = useStyles();

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const toggleOpenViewModal = () => {
    setIsOpenViewModal((prev) => !prev);
  };

  const toggleOpenEditModal = () => {
    setIsOpenEditModal((prev) => !prev);
  };

  const handleEditAction = () => {
    setIsOpenEditModal(true);
    setAnchorEl(null);
  };

  const handleDeleteAction = () => {
    toggleOpenConfirmModal();
    setAnchorEl(null);
  };

  const toggleOpenConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  const handleDeleteApproved = () => {
    if (deleteRoute) {
      deleteRoute(routeId);
    }
    toggleOpenConfirmModal();
  };

  return (
    <>
      <Grid item md={4} sm={6} xs={12}>
        <div className={classes.container}>
          <div className={classes.leftBlock} onClick={toggleOpenViewModal}>
            <img
              src={pictureUrl ? pictureUrl : RouteBigIcon}
              className={pictureUrl ? classes.picture : classes.imgEmptyIcon}
              ref={pictureRef}
              alt=""
            />
            {!isPublic && (
              <div className={classes.isPrivateBlock}>
                <LockIcon className={classes.isPrivateIcon} />
              </div>
            )}
            {isRecommended && (
              <div className={classes.isRecommendBlock}>
                <RecommendIcon className={classes.isRecommendIcon} />
              </div>
            )}
          </div>
          <div className={classes.contentBlock}>
            <div className={classes.contentTopBlock}>
              <div className={classes.infoBlock}>
                <Typography
                  className={classnames(classes.titleText, name === '' && classes.titleNoText)}
                >
                  {name !== '' ? name : 'Назви немає'}
                </Typography>
              </div>
            </div>
            <div className={classes.contentBottomBlock}>
              <div className={classes.infoBlock}>
                <RouteIcon className={classes.infoIcon} />
                <Typography className={classes.infoText}>
                  {formatDistance(distance)} (точок: {numPoints})
                </Typography>
                {isLoopRoute && (
                  <>
                    <CircleRouteIcon
                      className={classnames(classes.infoIcon, classes.circleRouteIcon)}
                    />
                    <Typography className={classnames(classes.infoText, classes.circleRouteText)}>
                      круговий
                    </Typography>
                  </>
                )}
              </div>
              {!!location && (
                <div className={classes.infoBlock}>
                  <PlaceIcon className={classes.infoIcon} />
                  <Typography className={classnames(classes.infoText, classes.locationText)}>
                    {location !== '' ? location : '--  --'}
                  </Typography>
                </div>
              )}
              {/* <div className={classes.actionsBlock}>
                <Rating
                  value={2.5}
                  precision={0.5}
                  size="small"
                  readOnly
                  className={classes.rating}
                />
                <Typography className={classes.ratingText}>({numRatingVotes} голосів)</Typography>
                <CommentsIcon className={classes.commentsIcon} />
                <Typography className={classes.commentsText}>{numComments}</Typography>
              </div> */}
            </div>
            <IconButton onClick={toggleMenu} className={classes.menuButton}>
              <MoreVertIcon className={classes.menuIcon} />
            </IconButton>
          </div>
          {/* <LoveIcon className={classes.loveIcon} /> */}
        </div>
      </Grid>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <MenuItem onClick={handleEditAction} className={classes.subMenu} key="2">
          Редагувати
          <EditIcon />
        </MenuItem>
        <MenuItem
          onClick={handleDeleteAction}
          className={classnames(classes.subMenu, classes.subMenuDelete)}
          key="3"
        >
          Видалити
          <DeleteIcon />
        </MenuItem>
      </Menu>
      <ModalConfirm
        title="Дійсно хочете видалити?"
        note="Ви не зможете повернути назад."
        isOpenConfirmModal={isOpenConfirmModal}
        toggleOpenConfirmModal={toggleOpenConfirmModal}
        handleSumbit={handleDeleteApproved}
      />
      <RouteItemViewModal
        route={route}
        isOpenViewModal={isOpenViewModal}
        toggleOpenViewModal={toggleOpenViewModal}
      />
      <RouteItemEditModal
        isOpen={isOpenEditModal}
        toggleIsOpen={toggleOpenEditModal}
        route={route}
        editRoute={editRoute}
      />
    </>
  );
};

export default RouteItem;
