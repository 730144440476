import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import AlphabetLettersListItem from './AlphabetLettersListItem';
import Pagination from 'common/core/Pagination';
import { NUM_VALUES_ON_PAGE } from 'config';
import { AlphabetLetter } from 'types/projects/alphabet';
import { useStyles } from './styles';

interface Props {
  lettersList: AlphabetLetter[];
  handleEditMode: (authorId: string) => void;
  editLetterPicture: (setId: string, letterId: string, file: any) => void;
  deleteLetterPicture: (setId: string, letterId: string) => void;
}

const AlphabetLettersList = ({
  lettersList,
  handleEditMode,
  editLetterPicture,
  deleteLetterPicture,
}: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (lettersList.length) {
      const pageCountNew = Math.ceil(lettersList.length / NUM_VALUES_ON_PAGE);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [lettersList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Grid container spacing={1}>
        {lettersList
          .filter(
            (_: AlphabetLetter, index: number) =>
              index >= (page - 1) * NUM_VALUES_ON_PAGE && index < page * NUM_VALUES_ON_PAGE,
          )
          .map((item: AlphabetLetter) => (
            <AlphabetLettersListItem
              key={item.id}
              item={item}
              handleEditMode={handleEditMode}
              editLetterPicture={editLetterPicture}
              deleteLetterPicture={deleteLetterPicture}
            />
          ))}
      </Grid>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default AlphabetLettersList;
