import { useMutation, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { TeamMember, TeamMemberInput } from 'types/general';

export const useCreateTeamMember = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (details: TeamMemberInput) => {
      const result: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/general/create_team_member',
        details,
      );

      return result;
    },
    onSuccess: (data, variables) => {
      const results = data?.data;

      if (results?.id) {
        const recordsList: TeamMember[] = queryClient.getQueryData('team-members') ?? [];
        queryClient.setQueryData(['team-members'], [...recordsList, results]);
      }
    },
  });
};
