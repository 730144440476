import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import {
  Editor,
  ContentState,
  EditorState,
  RichUtils,
  Modifier,
  DraftHandleValue,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import BoldIcon from '@material-ui/icons/FormatBold';
import ItalicIcon from '@material-ui/icons/FormatItalic';
import UnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import StrikethroughIcon from '@material-ui/icons/StrikethroughS';
import './Draft.css';
import { useStyles } from './styles';

enum EditorAction {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  UNDERLINE = 'UNDERLINE',
  STRIKETHROUGH = 'STRIKETHROUGH',
}

interface Props {
  placeholder?: string;
  valueInitial: string;
  handleChangeValue: (value: string) => void;
}

const InputEditor = ({ placeholder = '', valueInitial, handleChangeValue }: Props) => {
  const [value, setValue] = useState<string>(' ');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const endValue = useRef(0);
  const wrapperRef = useRef<any>(null);
  const editor = useRef<any>(null);
  const classes = useStyles();

  const removeSelectedBlocksStyle = (editorState: EditorState) => {
    const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);
    if (newContentState) {
      return EditorState.push(editorState, newContentState, 'change-block-type');
    }
    return editorState;
  };

  const getResetEditorState = (editorState: EditorState) => {
    const blocks = editorState.getCurrentContent().getBlockMap().toList();
    const updatedSelection = editorState.getSelection().merge({
      anchorKey: blocks.first().get('key'),
      anchorOffset: 0,
      focusKey: blocks.last().get('key'),
      focusOffset: blocks.last().getLength(),
    });
    const newContentState = Modifier.removeRange(
      editorState.getCurrentContent(),
      updatedSelection,
      'forward',
    );

    const newState = EditorState.push(editorState, newContentState, 'remove-range');
    return removeSelectedBlocksStyle(newState);
  };

  const clearState = () => {
    setEditorState(getResetEditorState(editorState));
    setValue('');
    handleChangeValue('');
  };

  useEffect(() => {
    if (valueInitial !== value) {
      const blocksFromHtml = htmlToDraft(valueInitial);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setEditorState(EditorState.createWithContent(contentState));
    }
    if (valueInitial.length === 0) {
      clearState();
    }
  }, [valueInitial]);

  const handleClickWrapper = () => {
    if (editor.current) {
      editor.current.focus();
    }
    // if (valueInitial.length > 0) {
    //   const selectionState = editorState.getSelection();
    //   endValue.current = selectionState.getEndOffset();
    // } else {
    //   editor.current.focus();
    // }
  };

  const handleKeyCommand = (command: string): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    handleChangeValue(markup);
    setValue(markup);
  }, [editorState]);

  const handleMenuClick = (e: React.MouseEvent, command: string) => {
    e.stopPropagation();
    const newState = RichUtils.toggleInlineStyle(editorState, command);
    setEditorState(newState);
  };

  // const focus = () => {
  //   if (editor.current) {
  //     editor.current.focus();
  //   }
  // };

  return (
    <>
      <div ref={wrapperRef} className={classes.wrapper}>
        <div className={classnames(classes.inputBlock)} onClick={handleClickWrapper}>
          <Editor
            handleKeyCommand={handleKeyCommand}
            ref={editor}
            editorState={editorState}
            onChange={setEditorState}
            placeholder={placeholder}
            // onFocus={() => console.log(1111)}
          />
        </div>
        <div className={classes.controlsBlock} onClick={(e: any) => e.stopPropagation()}>
          <div className={classes.controlsLeftBlock}>
            <BoldIcon
              onClick={(e: React.MouseEvent) => handleMenuClick(e, EditorAction.BOLD)}
              className={classes.actionIcon}
            />
            <ItalicIcon
              onClick={(e: React.MouseEvent) => handleMenuClick(e, EditorAction.ITALIC)}
              className={classes.actionIcon}
            />
            <UnderlinedIcon
              onClick={(e: React.MouseEvent) => handleMenuClick(e, EditorAction.UNDERLINE)}
              className={classes.actionIcon}
            />
            <StrikethroughIcon
              onClick={(e: React.MouseEvent) => handleMenuClick(e, EditorAction.STRIKETHROUGH)}
              className={classes.actionIcon}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InputEditor;
