import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';

const StoriesHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.titleBlock}>
        <Typography className={classes.titleText}>Всі історії</Typography>
      </div>
      <div className={classes.actionBlock}></div>
    </div>
  );
};

export default StoriesHeader;
