import { SnackbarProvider } from 'notistack';
import { useStyles } from './styles';

interface Props {
  children: React.ReactNode;
}

const Alerts = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      maxSnack={3}
      preventDuplicate
    >
      {children}
    </SnackbarProvider>
  );
};

export default Alerts;
