import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  container: {
    display: 'flex',
    alignItems: 'center',
    height: 36,
    backgroundColor: theme.palette.primary.main,
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      height: 32,
    },
  },
  infoBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  textSmall: {
    color: 'white',
    fontSize: 14,
    fontWeight: 350,
    paddingLeft: 10,
  },
  iconBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 5,
  },
  iconButton: {
    padding: 0,
    paddingLeft: 6,
  },
  icon: {
    color: '#008500',
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
  },
}));
