import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import InfoBox from 'common/core/InfoBox';
import MyPlacesListItem from './PlacesListItem';
import Pagination from 'common/core/Pagination';
import {} from 'config';
import { Place } from 'types/places';
import { useStyles } from './styles';

const numValuesOnPage = 40;

interface Props {
  isLoadingPlaces: boolean;
  placesList: Place[];
  handleEditMode: (placeId: string) => void;
  handleAreaMode: (placeId: string) => void;
  deletePlace: (placeId: string) => void;
}

const PlacesList = ({
  isLoadingPlaces,
  placesList,
  handleEditMode,
  handleAreaMode,
  deletePlace,
}: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (placesList.length) {
      const pageCountNew = Math.ceil(placesList.length / numValuesOnPage);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [placesList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Grid container spacing={1}>
        {!placesList.length && !isLoadingPlaces && (
          <InfoBox label="Ви поки що не створили жодного місця." className={classes.infoBox} />
        )}
        {placesList
          .sort((a: Place, b: Place) => b.createdAt - a.createdAt)
          .filter(
            (_: Place, index: number) =>
              index >= (page - 1) * numValuesOnPage && index < page * numValuesOnPage,
          )
          .map((place: Place, index: number) => (
            <MyPlacesListItem
              key={place.id}
              place={place}
              isLastItem={index === placesList.length - 1}
              handleEditMode={handleEditMode}
              handleAreaMode={handleAreaMode}
              deletePlace={deletePlace}
            />
          ))}
      </Grid>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default PlacesList;
