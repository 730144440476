import React from 'react';
import classnames from 'classnames';
import { Alert } from '@material-ui/lab';
import { useStyles } from './styles';

interface Props {
  label: string;
  className?: string;
}

const InfoBox = ({ label, className = '' }: Props) => {
  const classes = useStyles();

  return (
    <Alert severity="info" className={classnames(classes.root, className)}>
      {label}
    </Alert>
  );
};

export default InfoBox;
