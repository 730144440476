import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  header: {
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: 'column',
  },
  centerBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'auto'
  },
  content: {
    flexGrow: 1,
    display: 'flex',
  },
  footer: {
    flexGrow: 0,
    flexShrink: 0,
  },
}));