import React from 'react';
import { Typography } from '@material-ui/core';
import Button from 'common/core/Button';
import { ProjectsPageMode } from 'types/projects';
import { useStyles } from './styles';

interface Props {
  pageMode: string;
  handlePageMode: (value: string) => void;
}

const ProjectsHeader = ({ pageMode, handlePageMode }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.titleText}>Всі застосунки</Typography>
      {pageMode === ProjectsPageMode.LIST ? (
        <Button onClick={() => handlePageMode(ProjectsPageMode.ADD)} className={classes.button}>
          Додати застосунок
        </Button>
      ) : (
        <Button
          onClick={() => handlePageMode(ProjectsPageMode.LIST)}
          variant="outlined"
          className={classes.button}
        >
          Назад
        </Button>
      )}
    </div>
  );
};

export default ProjectsHeader;
