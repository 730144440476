import { Grid } from '@material-ui/core';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ViewListIcon from '@material-ui/icons/ViewList';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CategoryIcon from '@material-ui/icons/Category';
import TeamIcon from '@material-ui/icons/PeopleAltTwoTone';
import Container from 'common/core/Container';
import PageTitle from 'common/core/PageTitle';
import MenuMainItem from 'common/core/MenuMainItem';

const pagesList = [
  {
    id: 'users',
    title: 'Всі користувачі',
    url: '/users',
    icon: <PermContactCalendarIcon />,
  },
  {
    id: 'podcasts',
    title: 'Подкасти',
    url: '/podcasts',
    icon: <ViewListIcon />,
  },
  {
    id: 'podcasts-episodes',
    title: 'Епізоди подкастів',
    url: '/podcasts-episodes',
    icon: <ViewListIcon />,
  },
  {
    id: 'email-templates',
    title: 'Шаблони листів',
    url: '/email-templates',
    icon: <ViewListIcon />,
  },
  {
    id: 'content-pages',
    title: 'Контент сторінки',
    url: '/content-pages',
    icon: <FileCopyIcon />,
  },
  {
    id: 'skills',
    title: 'Всі навички',
    url: '/general/skills',
    icon: <CategoryIcon />,
  },
  {
    id: 'team',
    title: 'Команда',
    url: '/general/team',
    icon: <TeamIcon />,
  },
];

const OtherMainContainer = () => {
  return (
    <Container>
      <PageTitle title="Інше" />
      <Grid container spacing={2}>
        {pagesList.map(({ id, title, url, icon }) => (
          <MenuMainItem key={id} title={title} url={url}>
            {icon}
          </MenuMainItem>
        ))}
      </Grid>
    </Container>
  );
};

export default OtherMainContainer;
