import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 3,
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.26)',
    borderRadius: '4px 4px  0 0',
    width: '100%',
    // height: 'calc(100% - 40px)',
  },
  inputBlock: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 40,
    height: '100%',
    overflowY: 'auto',
    wordWrap: 'break-word',
    paddingLeft: 4,
    paddingRight: 10,
  },
  controlsBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 0,
    width: '100%',
    height: 40,
    borderTop: '1px solid #999',
    borderColor: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: '#EBEBEB',
  },
  controlsLeftBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 5,
  },
  controlsRightBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
  },
  actionIcon: {
    color: 'rgba(15, 15, 33, 0.4)',
    cursor: 'pointer',
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.26)',
    padding: 0,
    margin: 0,
    marginRight: 10,
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
  },
}));
