import React from 'react';
import classnames from 'classnames';
import { Container as ContainerOriginal } from '@material-ui/core';
import { useStyles } from './styles';

interface Props {
  children: NonNullable<React.ReactNode>;
  className?: string;
}

const Container = ({ children, className = '' }: Props) => {
  const classes = useStyles();

  return (
    <ContainerOriginal maxWidth="lg" className={classnames(classes.root, className)}>
      {children}
    </ContainerOriginal>
  );
};

export default Container;
