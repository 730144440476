import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    width: '100%',
  },
  inputBlock: {
    display: 'flex',
    flexGrow: 1,
  },
  actionBlock: {
    display: 'flex',
    flexGrow: 1,
    padding: 10,
  },
  input: {},
  icon: {
    fontSize: 36,
  },
  questionIcon: {
    color: 'gray',
    cursor: 'pointer',
  },
  rightIcon: {
    color: 'green',
  },
  wrongIcon: {
    color: 'red',
  },
}));
