import React from 'react';
import { Container } from '@material-ui/core';
import PageTitle from 'common/core/PageTitle';
import ProfileAvatar from './ProfileAvatar';
import ProfileEdit from './ProfileEdit';
import ProfilePassword from './ProfilePassword';
import { useStyles } from './styles';

const ProfileContainer = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <PageTitle title="Мій профайл" />
      <ProfileAvatar />
      <ProfileEdit />
      <ProfilePassword />
    </Container>
  );
};

export default ProfileContainer;
