import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { useAlert } from '../useAlert';

export const useForgotPassword = () => {
  //   const [confirmStatus, setConfirmStatus] = useState<boolean | null>(null);
  const [isSendingActive, setIsSendingActive] = useState<boolean>(true);
  const showAlert = useAlert();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (!isSendingActive) {
      timer = setTimeout(() => setIsSendingActive(true), 10000);
    }
    return () => clearTimeout(timer);
  }, [isSendingActive]);

  const forgotPassword = useCallback(
    async (email: string) => {
      if (isSendingActive) {
        const details = {
          email,
        };

        const results: AxiosResponse = await fetchAPI(
          'post',
          // 'http://localhost:4000/v1/auth/forgot_password',
          process.env.REACT_APP_REST_API_URL + '/auth/forgot_password',
          details,
        );

        setIsSendingActive(false);
      }
      showAlert('Вам на емайл надіслано листа.');
    },
    [isSendingActive],
  );

  return { forgotPassword };
};
