import React, { useState, useEffect } from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';

const marker = new L.Icon({
  iconUrl: '/icons/circle7.svg',
  iconSize: new L.Point(8, 8),
  iconAnchor: [8 / 2, 8 / 2],
});

const markerStart = L.icon({
  iconUrl: '/icons/flag_point3.svg',
  iconSize: new L.Point(40, 40),
  iconAnchor: [(40 - 24) / 2, 40],
});

const markerDelete = new L.Icon({
  iconUrl: '/icons/delete.svg',
  iconSize: new L.Point(40, 40),
  iconAnchor: [40 / 2, (40 + 5) / 2],
});

interface Props {
  position: [number, number];
  handleClick: (e: React.SyntheticEvent) => void;
  isStart?: boolean;
}

const PlacesAreaMapMarker = ({ position, handleClick, isStart }: Props) => {
  const [isMarkerOver, setIsMarkerOver] = useState<boolean>(false);
  const [isReadyToEdited, setIsReadyToEdited] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsReadyToEdited(true), 500);
    return () => clearTimeout(timer);
  }, []);

  const handleMouseOver = () => {
    if (isReadyToEdited) {
      setIsMarkerOver(true);
    }
  };

  const handleMouseOut = () => {
    setIsMarkerOver(false);
  };
  return (
    <>
      <Marker
        icon={!isMarkerOver ? marker : markerDelete}
        position={position}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      />
      {isStart && (
        <Marker
          icon={!isMarkerOver ? markerStart : markerDelete}
          position={position}
          onClick={handleClick}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />
      )}
    </>
  );
};

export default PlacesAreaMapMarker;
