import { useContext, useCallback } from 'react';
import { useGoogleLogout } from 'react-google-login';
import { googleClientId } from 'config';
import { Context } from 'context/Context';
import { useAlert } from '../useAlert';

export const useLogout = () => {
  const { setIsUserAuthed, setUserData } = useContext(Context);
  const showAlert = useAlert();

  const onLogoutSuccess = () => {
    // console.log('Logout success');
  };

  const onFailure = () => {
    // console.log('Logout failed');
  };

  const { signOut } = useGoogleLogout({
    clientId: googleClientId,
    onLogoutSuccess,
    onFailure: onFailure,
  });

  const logout = useCallback(() => {
    setIsUserAuthed(false);
    setUserData(null);
    localStorage.removeItem('userData');
    localStorage.removeItem('accessToken');
    signOut();
    showAlert('Ви успішно вийшли.');
  }, []);

  return { logout };
};
