import React, { useState, useEffect, useRef } from 'react';
import { Map as MapContainer, TileLayer } from 'react-leaflet';
import { LatLngBounds } from 'leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { centerMapDefault, zoomMapDefault } from 'config';
import { RoutePoint } from 'types/places';

interface Props {
  pointsList?: RoutePoint[];
  center?: [number, number];
  zoom?: number;
  handleClick?: (e: any) => void;
  children?: React.ReactNode;
}

const Map = ({
  pointsList = [],
  center = centerMapDefault,
  zoom = zoomMapDefault,
  handleClick = () => {},
  children,
}: Props) => {
  const [bounds, setBounds] = useState<LatLngBounds>();
  const mapRef = useRef<any>();

  useEffect(() => {
    if (pointsList?.length) {
      let boundsList: any[] = [];
      pointsList.map((point: any) => boundsList.push([point.latitude, point.longitude]));
      if (boundsList.length) {
        const bounds = L.latLngBounds(boundsList);
        setBounds(bounds);
      }
    }
  }, [pointsList]);

  return (
    <MapContainer
      ref={mapRef}
      center={center}
      bounds={bounds}
      zoom={zoom}
      maxZoom={19}
      attributionControl={false}
      zoomControl={true}
      doubleClickZoom={true}
      scrollWheelZoom={true}
      dragging={true}
      animate={true}
      easeLinearity={0.35}
      onClick={handleClick}
    >
      <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
      {children}
    </MapContainer>
  );
};

export default Map;
