import { useState, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { useAlert } from '../useAlert';

export const useResetPassword = () => {
  const [answerError, setAnswerError] = useState<string>('');
  const showAlert = useAlert();

  const resetPassword = useCallback(async (password: string, confirmCode: string) => {
    const details = {
      password,
      confirmCode,
    };

    const results: AxiosResponse = await fetchAPI(
      'post',
      // 'http://localhost:4000/v1/auth/reset_password',
      process.env.REACT_APP_REST_API_URL + '/auth/reset_password',
      details,
    );

    console.log(results?.data);

    if (results?.data) {
      if (results.data?.error) {
        console.log(11111111112222222);
        // setAnswerError(results.data.error);
      } else {
        showAlert('Ваш пароль був змінений.');
      }
    }
  }, []);

  return { resetPassword, answerError };
};
