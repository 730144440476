import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import {
  NatureAchievement,
  NatureAchievementInputCreate,
  NatureAchievementInputEdit,
} from 'types/projects/nature';

export const useAchievements = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [achievementsList, setAchievementsList] = useState<NatureAchievement[]>([]);

  useEffect(() => {
    getAchievements();
  }, []);

  const getAchievements = async () => {
    setIsLoading(true);
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/nature/get_admin_achievements',
      {},
    );
    if (results?.data?.achievements) {
      setAchievementsList(results?.data?.achievements);
    }
    setIsLoading(false);
  };

  const createAchievement = useCallback(async (details: NatureAchievementInputCreate) => {
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/nature/create_achievement',
      details,
    );
    getAchievements();
  }, []);

  const editAchievement = useCallback(async (details: NatureAchievementInputEdit) => {
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/nature/edit_achievement',
      details,
    );
    getAchievements();
  }, []);

  const editAchievementPicture = useCallback(async (achievementId: string, file: any) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('achievementId', achievementId);
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/nature/edit_achievement_picture',
      formData,
    );
    await new Promise((resolve) => {
      return setTimeout(resolve, 1500);
    });
    getAchievements();
  }, []);

  return {
    isLoading,
    achievementsList,
    createAchievement,
    editAchievement,
    editAchievementPicture,
  };
};
