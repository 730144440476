import React, { useState, useEffect } from 'react';
import { Checkbox, Typography, Switch } from '@material-ui/core';
import Input from 'common/core/Input';
import InputEditor from 'common/core/InputEditor';
import Button from 'common/core/Button';
import { ProjectsPageMode, Project, ProjectInput } from 'types/projects';
import { useStyles } from './styles';
import { SkillCategory } from 'types/general';

interface Props {
  project: Project | null;
  skillsList: any[];
  createProject: (value: ProjectInput) => void;
  editProject: (value: ProjectInput) => void;
  handlePageMode: (value: string) => void;
}

const ProjectsEdit = ({
  project,
  skillsList,
  createProject,
  editProject,
  handlePageMode,
}: Props) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [rules, setRules] = useState<string>('');
  const [audience, setAudience] = useState<string>('');
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [isTesting, setIsTesting] = useState<boolean>(false);
  const [skills, setSkills] = useState<string[]>([]);
  const [appStoreUrl, setAppStoreUrl] = useState<string>('');
  const [googlePlayUrl, setGooglePlayUrl] = useState<string>('');
  const [webUrl, setWebUrl] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [versionIos, setVersionIos] = useState<number>(0);
  const [versionAndroid, setVersionAndroid] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (project) {
      setName(project?.name ?? '');
      setDescription(project?.description ?? '');
      setRules(project?.rules ?? '');
      setAudience(project?.audience ?? '');
      setIsPublic(project?.isPublic ?? false);
      setIsTesting(project?.isTesting ?? false);
      setSkills(project?.skills ? project?.skills : []);
      setAppStoreUrl(project?.linksData?.appStoreUrl ?? '');
      setGooglePlayUrl(project?.linksData?.googlePlayUrl ?? '');
      setWebUrl(project?.linksData?.webUrl ?? '');

      setVersionIos(project?.versionData?.versionIos ?? 0);
      setVersionAndroid(project?.versionData?.versionAndroid ?? 0);
    }
  }, [project]);

  const handleSubmit = () => {
    setNameError(name ? '' : 'Заповніть назву проекту');

    if (name) {
      if (!project?.id) {
        createProject({
          name,
          description,
          rules,
          audience,
          isPublic,
          isTesting,
          skills,
          linksData: {
            appStoreUrl,
            googlePlayUrl,
            webUrl,
          },
          versionData: {
            versionIos,
            versionAndroid,
          },
        });
      } else {
        editProject({
          projectId: project?.id ?? '',
          name,
          description,
          rules,
          audience,
          isPublic,
          isTesting,
          skills,
          linksData: {
            appStoreUrl,
            googlePlayUrl,
            webUrl,
          },
          versionData: {
            versionIos,
            versionAndroid,
          },
        });
      }
    }
  };

  const handleChangeSkills = (e: React.ChangeEvent<HTMLInputElement>) => {
    const skillsNew =
      skills.findIndex((categoryId) => categoryId === e.target.name) !== -1
        ? skills.filter((categoryId) => categoryId !== e.target.name)
        : [...skills, e.target.name];

    setSkills(skillsNew);
  };

  const handleChangeDescription = (value: string) => {
    setDescription(value);
  };

  const handleChangeRules = (value: string) => {
    setRules(value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.inputBlock}>
        <Input
          size="medium"
          label="Назва проекту"
          type="text"
          className={classes.input}
          onChange={(e) => setName(e.target.value)}
          value={name}
          helperText={nameError}
          fullWidth
          required
        />
      </div>
      {/* <div className={classes.inputBlock}>
        <Input
          size="medium"
          label="Короткий опис"
          type="text"
          className={classes.input}
          onChange={(e) => setDescriptionBrief(e.target.value)}
          value={descriptionBrief}
          fullWidth
        />
      </div> */}

      <div className={classes.inputHeaderBlock}>
        <Typography className={classes.inputHeaderText}>Опис:</Typography>
      </div>
      <div className={classes.richTextareaBlock}>
        <InputEditor
          placeholder="..."
          valueInitial={description}
          handleChangeValue={handleChangeDescription}
        />
      </div>

      <div className={classes.inputHeaderBlock}>
        <Typography className={classes.inputHeaderText}>Правила:</Typography>
      </div>
      <div className={classes.richTextareaBlock}>
        <InputEditor valueInitial={rules} handleChangeValue={handleChangeRules} />
      </div>
      <div className={classes.inputBlock}>
        <Input
          size="medium"
          label="Аудиторія"
          type="text"
          className={classes.input}
          onChange={(e) => setAudience(e.target.value)}
          value={audience}
          fullWidth
        />
      </div>
      <div className={classes.inputBlock}>
        <Input
          size="medium"
          label="AppStore Url"
          type="text"
          className={classes.input}
          onChange={(e) => setAppStoreUrl(e.target.value)}
          value={appStoreUrl}
          fullWidth
        />
      </div>
      <div className={classes.inputBlock}>
        <Input
          size="medium"
          label="GooglePlay Url"
          type="text"
          className={classes.input}
          onChange={(e) => setGooglePlayUrl(e.target.value)}
          value={googlePlayUrl}
          fullWidth
        />
      </div>
      <div className={classes.inputBlock}>
        <Input
          size="medium"
          label="Web Url"
          type="text"
          className={classes.input}
          onChange={(e) => setWebUrl(e.target.value)}
          value={webUrl}
          fullWidth
        />
      </div>

      <div className={classes.inputBlock}>
        <Input
          size="medium"
          label="Версія iOS"
          type="text"
          className={classes.input}
          onChange={(e) => setVersionIos(+e.target.value)}
          value={versionIos.toString()}
          fullWidth
        />
      </div>
      <div className={classes.inputBlock}>
        <Input
          size="medium"
          label="Версія Android"
          type="text"
          className={classes.input}
          onChange={(e) => setVersionAndroid(+e.target.value)}
          value={versionAndroid.toString()}
          fullWidth
        />
      </div>

      <div className={classes.optionBlock}>
        <div className={classes.optionsListBlock}>
          <div className={classes.optionsListHeaderBlock}>
            <Typography className={classes.optionsListHeaderText}>Навички:</Typography>
          </div>
          {skillsList.map(({ id, name }: SkillCategory) => {
            const isChecked = skills.findIndex((item: string) => item === id) !== -1;
            return (
              <div className={classes.optionElementBlock}>
                <div className={classes.optionCheckboxBlock}>
                  <Checkbox
                    name={id}
                    checked={isChecked}
                    onChange={handleChangeSkills}
                    color="primary"
                    className={classes.optionCheckbox}
                  />
                </div>
                <div className={classes.optionNameBlock}>
                  <Typography className={classes.optionNameText}>{name}</Typography>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className={classes.inputBlock}>
        <Switch
          checked={isTesting}
          onChange={() => setIsTesting(!isTesting)}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <Typography className={classes.switchText}>
          {isTesting ? 'Тестуємо' : 'Не тестуємо'}
        </Typography>
      </div>

      <div className={classes.inputBlock}>
        <Switch
          checked={isPublic}
          onChange={() => setIsPublic(!isPublic)}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <Typography className={classes.switchText}>
          {isPublic ? 'Показувати на сайті' : 'Не показувати на сайті'}
        </Typography>
      </div>

      <div className={classes.buttonBlock}>
        <Button
          onClick={() => handlePageMode(ProjectsPageMode.LIST)}
          variant="outlined"
          className={classes.button}
        >
          Назад
        </Button>
        <Button onClick={handleSubmit} className={classes.button}>
          Зберегти
        </Button>
      </div>
    </div>
  );
};

export default ProjectsEdit;
