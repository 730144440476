import React, { useState, useEffect } from 'react';
import Container from 'common/core/Container';
import PageTitle from 'common/core/PageTitle';
import AchievementsActions from './AchievementsActions';
import AchievementsList from './AchievementsList';
import AchievementsEditModal from './AchievementsEditModal';
import { useAchievements } from 'hooks/projects/nature/useAchievements';
import { NatureAchievement } from 'types/projects/nature';

const AchievementsContainer = () => {
  const [achievementIdCurrent, setAchievementIdCurrent] = useState<string>('');
  const {
    isLoading,
    achievementsList,
    createAchievement,
    editAchievement,
    editAchievementPicture,
  } = useAchievements();
  const [isOpenEditModal, setIsEditOpenModal] = useState<boolean>(false);

  const handleEditMode = (authorId: string) => {
    setAchievementIdCurrent(authorId);
    setIsEditOpenModal(true);
  };

  const handleAddMode = () => {
    setAchievementIdCurrent('');
    setIsEditOpenModal(true);
  };

  return (
    <>
      <Container>
        <PageTitle title="Nature Neighbors : Досягнення" />
        <AchievementsActions handleAddMode={handleAddMode} />
        <AchievementsList
          achievementsList={achievementsList}
          handleEditMode={handleEditMode}
          editAchievementPicture={editAchievementPicture}
        />
      </Container>
      <AchievementsEditModal
        isOpen={isOpenEditModal}
        toggleIsOpen={() => setIsEditOpenModal(false)}
        achievement={
          achievementsList.find(
            (author: NatureAchievement) => author.id === achievementIdCurrent,
          ) ?? null
        }
        createAchievement={createAchievement}
        editAchievement={editAchievement}
      />
    </>
  );
};

export default AchievementsContainer;
