import { Typography } from '@material-ui/core';
import Button from 'common/core/Button';
import { PageMode } from 'types/general';
import { useStyles } from './styles';

interface Props {
  pageMode: string;
  handlePageMode: (value: string) => void;
}

const EmailTemplatesHeader = ({ pageMode, handlePageMode }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.titleText}>
        Шаблони листів
        {pageMode === PageMode.ADD && <>: Новий шаблон</>}
        {pageMode === PageMode.EDIT && <>: Редагування</>}
      </Typography>

      {pageMode === PageMode.LIST ? (
        <Button onClick={() => handlePageMode(PageMode.ADD)} className={classes.button}>
          Додати
        </Button>
      ) : (
        <Button
          onClick={() => handlePageMode(PageMode.LIST)}
          variant="outlined"
          className={classes.button}
        >
          Назад
        </Button>
      )}
    </div>
  );
};

export default EmailTemplatesHeader;
