import { Grid } from '@material-ui/core';
import PlaceIcon from '@material-ui/icons/Place';
import TimelineIcon from '@material-ui/icons/Timeline';
import Container from 'common/core/Container';
import PageTitle from 'common/core/PageTitle';
import MenuMainItem from 'common/core/MenuMainItem';

const pagesList = [
  {
    id: 'places',
    title: 'Місця',
    url: '/places',
    icon: <PlaceIcon />,
  },
  {
    id: 'routes',
    title: 'Маршрути',
    url: '/routes',
    icon: <TimelineIcon />,
  },
];

const LocationsMainContainer = () => {
  return (
    <Container>
      <PageTitle title="Локації" />
      <Grid container spacing={2}>
        {pagesList.map(({ id, title, url, icon }) => (
          <MenuMainItem key={id} title={title} url={url}>
            {icon}
          </MenuMainItem>
        ))}
      </Grid>
    </Container>
  );
};

export default LocationsMainContainer;
