import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { PodcastsEpisode, PodcastsEpisodeInput } from 'types/podcasts';
import { useAlert } from 'hooks/useAlert';

export const usePodcastsEpisodes = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [episodesList, setEpisodesList] = useState<PodcastsEpisode[]>([]);
  const showAlert = useAlert();

  useEffect(() => {
    getEpisodes();
  }, []);

  const getEpisodes = useCallback(async () => {
    setIsLoading(true);
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/podcasts/get_admin_podcast_episodes',
      {},
    );
    if (results?.data?.episodes) {
      setEpisodesList(results?.data?.episodes);
    }
    setIsLoading(false);
  }, []);

  const createEpisode = useCallback(async (details: PodcastsEpisodeInput) => {
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/podcasts/create_podcast_episode',
      details,
    );

    if (results?.data?.episodeId) {
      showAlert('Episode was added');
      getEpisodes();
      return results?.data?.episodeId;
    }
  }, []);

  const editEpisode = useCallback(async (details: PodcastsEpisodeInput) => {
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/podcasts/edit_podcast_episode',
      details,
    );
    showAlert('Episode was changed');
    getEpisodes();
  }, []);

  const editEpisodeAudio = useCallback(async (episodeId: string, file: any) => {
    let formData = new FormData();
    formData.append('episodeId', episodeId);
    formData.append('file', file);
    await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/podcasts/edit_podcast_episode_audio',
      formData,
    );
    showAlert('Audio for podcast episode was changed');
    getEpisodes();
  }, []);

  const deleteEpisode = useCallback(async (postId: string) => {
    const details = { postId };
    await fetchAPI('post', process.env.REACT_APP_REST_API_URL + '/blog/delete_blog_post', details);
    showAlert('Episode was deleted');
    getEpisodes();
  }, []);

  return {
    isLoading,
    episodesList,
    createEpisode,
    editEpisode,
    editEpisodeAudio,
    deleteEpisode,
  };
};
