import React from 'react';
import { Polyline } from 'react-leaflet';

const MapPath = ({ position1, position2, weight = 1.5, type = 'example' }: any) => {
  if (type === 'example') {
    return (
      <Polyline
        positions={[position1, position2]}
        color="#000066"
        weight={2.5}
        pathOptions={{ color: '#000066' }}
      />
    );
  }

  return (
    <Polyline
      positions={[position1, position2]}
      color="#000066"
      weight={weight}
      dashArray="2, 6"
      pathOptions={{ color: '#000066' }}
    />
  );
};

export default MapPath;
