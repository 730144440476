import React, { useContext, useRef } from 'react';
import { Typography, IconButton } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/FaceTwoTone';
import EditIcon from '@material-ui/icons/Edit';
import { useAvatarEdit } from 'hooks/auth/useAvatarEdit';
import { formatDateBrief } from 'utils/datetime';
import { useStyles } from './styles';
import { Context } from 'context/Context';

const ProfileAvatar = () => {
  const { userData } = useContext(Context);
  const { createdAt } = userData;
  const { editAvatar } = useAvatarEdit();
  const fileRef = useRef<any>(null);
  const classes = useStyles();

  const handleClickFileUploader = () => {
    fileRef.current.click();
  };

  const chooseFileInUploader = async (e: any) => {
    if (e.target.files.length) {
      editAvatar(e.target.files[0]);
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.imgBlock}>
          {userData?.avatarUrl && <img src={userData?.avatarUrl} className={classes.img} alt="" />}
          <IconButton
            color="inherit"
            className={classes.editImgIconButton}
            onClick={handleClickFileUploader}
          >
            <EditIcon className={classes.editImgIcon} />
          </IconButton>
        </div>
        <div className={classes.infoBlock}>
          <div className={classes.infoBlock}>
            <Typography className={classes.nameText}>{userData?.userName}</Typography>
          </div>
          <div className={classes.infoBlock}>
            <Typography className={classes.dateText}>
              на сайті з {formatDateBrief(createdAt)}
            </Typography>
          </div>
        </div>
      </div>
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={chooseFileInUploader}
        multiple
        accept="image/*"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default ProfileAvatar;
