import { useState, useEffect } from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import Modal from 'common/core/Modal';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import { TeamMember, TeamMemberInput } from 'types/general';
import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
  member: TeamMember | null;
  createMember: (details: TeamMemberInput) => void;
  editMember: (details: TeamMemberInput) => void;
}

const TeamEdit = ({ isOpen, toggleIsOpen, member, createMember, editMember }: Props) => {
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const [name, setName] = useState<string>('');
  const [position, setPosition] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [facebookUrl, setFacebookUrl] = useState<string>('');
  const [instagramUrl, setInstagramUrl] = useState<string>('');
  const [titleError, setTitleError] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    setName(member?.name ?? '');
    setPosition(member?.position ?? '');
    setEmail(member?.email ?? '');
    setFacebookUrl(member?.facebookUrl ?? '');
    setInstagramUrl(member?.instagramUrl ?? '');
    setIsPublic(member?.isPublic ?? false);
  }, [member, isOpen]);

  const handleSubmit = () => {
    setTitleError(name ? '' : 'Fill name of team member');

    if (name) {
      if (!member?.id) {
        createMember({ name, position, email, facebookUrl, instagramUrl, isPublic });
      } else {
        editMember({
          memberId: member?.id ?? '',
          name,
          position,
          email,
          facebookUrl,
          instagramUrl,
          isPublic,
        });
      }
      toggleIsOpen();
    }
  };

  return (
    <Modal
      title={member?.id ? 'Редагування члена командиу' : 'Новий член команди'}
      widthMax={450}
      open={isOpen}
      handleClose={toggleIsOpen}
    >
      <div className={classes.container}>
        <div className={classes.inputBlock}>
          <Input
            size="medium"
            label="Ім'я"
            type="text"
            className={classes.input}
            onChange={(e) => setName(e.target.value)}
            value={name}
            helperText={titleError}
            fullWidth
            required
          />
        </div>
        <div className={classes.inputBlock}>
          <Input
            label="Посада"
            type="text"
            className={classes.input}
            onChange={(e) => setPosition(e.target.value)}
            value={position}
            fullWidth
          />
        </div>
        <div className={classes.inputBlock}>
          <Input
            label="Email"
            type="text"
            className={classes.input}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            fullWidth
          />
        </div>
        <div className={classes.inputBlock}>
          <Input
            label="Facebook Url"
            type="text"
            className={classes.input}
            onChange={(e) => setFacebookUrl(e.target.value)}
            value={facebookUrl}
            fullWidth
          />
        </div>
        <div className={classes.inputBlock}>
          <Input
            label="Instagram Url"
            type="text"
            className={classes.input}
            onChange={(e) => setInstagramUrl(e.target.value)}
            value={instagramUrl}
            fullWidth
          />
        </div>
        <div className={classes.inputBlock}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Доступ</InputLabel>
            <Select
              label="Доступ"
              native
              onChange={(e: any) => setIsPublic(e.target.value === 'true' ? true : false)}
              value={isPublic ? 'true' : 'false'}
              className={classes.select}
            >
              <option value="false" key="private">
                Закритий
              </option>
              <option value="true" key="public">
                Відкритий
              </option>
            </Select>
          </FormControl>
        </div>
        <div className={classes.buttonBlock}>
          <Button onClick={handleSubmit}>Зберегти</Button>
        </div>
      </div>
    </Modal>
  );
};

export default TeamEdit;
