import { makeStyles, Theme } from '@material-ui/core/styles';
import { IMAGE_BACKGROUND_COLOR } from 'config';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '1px solid #EBEBEB',
    borderRadius: 6,
  },
  pictureBlock: {
    display: 'flex',
    borderRadius: '6px 6px 0 0',
    aspectRatio: '3/2',
    backgroundColor: IMAGE_BACKGROUND_COLOR,
  },
  isPrivateBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 5,
    left: 5,
    width: 22,
    height: 22,
    backgroundColor: '#660708',
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingTop: 4,
    paddingBottom: 4,
  },
  leftBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: 10,
  },
  titleBlock: {
    display: 'flex',
  },
  bodyBlock: {
    display: 'flex',
  },
  actionsBlock: { paddingRight: 3 },
  picture: {
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '3/2',
    borderRadius: 4,
  },
  imgEmptyIcon: {
    width: 90,
    [theme.breakpoints.down('sm')]: {
      width: 90,
    },
  },
  isPrivateIcon: {
    fontSize: 16,
    color: '#fff',
    padding: 0,
    margin: 0,
  },
  titleText: {
    fontSize: 18,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  bodyText: {
    fontSize: 12,
    fontWeight: 300,
  },
  infoText: {
    fontSize: 12,
    fontWeight: 350,
    color: '#666666',
  },
  infoIcon: {
    fontSize: 16,
    color: '#333',
    marginRight: 5,
  },
  menuButton: {},
  subMenu: {
    minWidth: 220,
  },
  subMenuDelete: {
    color: '#FF0000 !important',
  },
  subMenuIcon: {},
}));
