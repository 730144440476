import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px',
    marginTop: 10,
    marginBottom: 20,
  },
  titleBlock: {
    display: 'flex',
    marginBottom: 10,
  },
  errorBlock: {
    display: 'flex',
    marginBottom: 10,
  },
  inputBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    marginBottom: 20,
  },
  inputHeaderBlock: {
    marginBottom: 4,
  },
  richTextareaBlock: {
    display: 'flex',
    height: 450,
    marginBottom: 20,
  },
  optionBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
  },
  optionsListBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  optionsListHeaderBlock: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 7,
  },
  optionElementBlock: {
    display: 'flex',
    alignItems: 'center',
    width: '25%',
    maxWidth: '25%',
    flexGrow: 1,
  },
  optionCheckboxBlock: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 0,
    padding: 2,
    paddingRight: 5,
  },
  optionNameBlock: {
    display: 'flex',
    flexGrow: 1,
  },

  buttonBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    marginTop: 10,
  },
  titleText: {
    fontSize: 18,
    fontWeight: 450,
  },
  errorText: {
    fontSize: 16,
    fontWeight: 300,
    color: '#ff0000',
  },
  inputHeaderText: {
    fontSize: 16,
    fontWeight: 400,
    color: '#000',
  },
  select: {
    width: 250,
  },
  selectHelper: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    color: '#000',
    paddingBottom: 8,
  },
  input: {},
  inputTextarea: {},
  optionsListHeaderText: {
    fontSize: 16,
    fontWeight: 400,
    color: '#000',
  },
  optionCheckbox: {
    padding: 0,
  },
  optionNameText: {
    fontSize: 14,
    fontWeight: 350,
    lineHeight: '100%',
    color: '#333',
  },
  switchText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '100%',
    color: '#000',
  },

  button: {
    minWidth: 130,
  },
}));
