import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: 'red',
    '& .MuiPagination-ul': {
      '& li:not(:first-child):not(:last-child)': {
        '& button': {
          fontSize: 14,
          color: '#000000',
        },
      },
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff !important',
    },
    '& :hover .Mui-selected': {
      backgroundColor: '#666666',
      color: '#fff !important',
    },
  },
}));
