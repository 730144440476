import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import { useStyles } from './styles';

interface Props {
  togglePage: () => void;
  answerError: string;
  login: (email: string, password: string) => void;
}

const LoginForm = ({ togglePage, answerError, login }: Props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const history = useHistory();
  const classes = useStyles();

  const handleSubmit = () => {
    if (email.length === 0) {
      setEmailError('Заповніть свій email');
    } else {
      setEmailError('');
      if (password.length === 0) {
        setPasswordError('Заповніть свій пароль');
      } else {
        setPasswordError('');
        login(email, password);
      }
    }
  };

  return (
    <div className={classes.wrapper}>
      <Container maxWidth="xs" className={classes.container}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Вхід
        </Typography>
        <form className={classes.form} noValidate>
          <div className={classes.formBlock}>
            <div className={classes.element}>
              {answerError && <Typography className={classes.errorText}>{answerError}</Typography>}
            </div>
            <div className={classes.element}>
              <Input
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                helperText={emailError}
                fullWidth
                required
              />
            </div>
            <div className={classes.element}>
              <Input
                label="Пароль"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                helperText={passwordError}
                fullWidth
                required
              />
            </div>
            <div className={classes.element}>
              <Button fullWidth onClick={handleSubmit}>
                Увійти
              </Button>
            </div>
            {/* <div className={classes.element}>
              <div className={classes.linkBlock}>
                <div className={classes.linkBlockLeft}>
                  <Typography
                    className={classes.linkText}
                    onClick={() => history.push('/auth/forgotpassword')}
                  >
                    Забули пароль?
                  </Typography>
                </div>
                <div className={classes.linkBlockRight}>
                  <Typography className={classes.linkText} onClick={togglePage}>
                    Ще не зареєстровані?
                  </Typography>
                </div>
              </div>
            </div> */}
          </div>
        </form>
      </Container>
    </div>
  );
};

export default LoginForm;
