import React from 'react';
import Header from 'common/Header';
import Footer from 'common/Footer';
import { useAuthCheck } from 'hooks/auth/useAuthCheck';
import { useStyles } from './styles';

interface Props {
  children: React.ReactNode;
}

const App = ({ children }: Props) => {
  useAuthCheck();
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Header />
      </div>
      <div className={classes.centerBlock}>
        <div className={classes.content}>
          <div id="top" />
          {children}
        </div>
        <div className={classes.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default App;
