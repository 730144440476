import React from 'react';
import classnames from 'classnames';
import ButtonOriginal from '@material-ui/core/Button';
import { useStyles } from './styles';

interface Props {
  variant?: string;
  onClick?: () => void;
  fullWidth?: boolean;
  className?: any;
  disabled?: boolean;
  children?: any;
}

const Button = ({
  variant = 'contained',
  onClick,
  fullWidth,
  className = null,
  disabled = false,
  children,
}: Props) => {
  const classes = useStyles();

  return (
    <ButtonOriginal
      onClick={onClick}
      variant={variant === 'contained' ? 'contained' : 'outlined'}
      size="medium"
      color="secondary"
      fullWidth={fullWidth}
      disabled={disabled}
      className={classnames(
        classes.root,
        variant === 'contained' ? classes.buttonContained : classes.buttonOutlined,
        className,
      )}
    >
      {children}
    </ButtonOriginal>
  );
};

export default Button;
