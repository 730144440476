import React, { useState } from 'react';
import classnames from 'classnames';
import { Typography, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  CardMembershipTwoTone as ActivityIcon,
  BusinessCenterTwoTone as RoleIcon,
  EditTwoTone as EditIcon,
  DeleteTwoTone as DeleteIcon,
} from '@material-ui/icons';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import ModalConfirm from 'common/core/Modal/ModalConfirm';
import { formatDateBrief, formatDateAdvanced } from 'utils/datetime';
import { User } from 'types/auth';
import { useStyles } from './styles';
import TableRow from 'common/core/Table/TableRow';
import TableCell from 'common/core/Table/TableCell';

interface Props {
  user: User;
  handleEditMode: (userId: string) => void;
  editUserActivity: (userId: string, activityValue: boolean) => void;
  editUserRole: (userId: string, isActive: boolean) => void;
  deleteUser: (userId: string) => void;
}

const PlacesListItem = ({
  user,
  handleEditMode,
  editUserActivity,
  editUserRole,
  deleteUser,
}: Props) => {
  const {
    id: userId,
    name,
    email,
    isActive,
    isAdmin,
    createdAt,
    registerMethod,
    registerOrigin,
  } = user;
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEditUserActivity = () => {
    editUserActivity(userId, !isActive);
    setAnchorEl(null);
  };

  const handleEditUserRole = () => {
    editUserRole(userId, !isAdmin);
    setAnchorEl(null);
  };

  const handleEditAction = () => {
    handleEditMode(userId);
    setAnchorEl(null);
  };

  const handleDeleteAction = () => {
    toggleOpenConfirmModal();
    setAnchorEl(null);
  };

  const toggleOpenConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  const handleDeleteApproved = () => {
    deleteUser(userId);
    toggleOpenConfirmModal();
  };

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell className={classes.nameBlock}>
          <div className={classes.namePartBlock}>
            <Typography className={classes.nameText}>{name}</Typography>
          </div>
          <div className={classes.namePartBlock}>
            <Typography className={classes.emailText}>{email}</Typography>
          </div>
        </TableCell>
        <TableCell>
          <Typography className={classes.text}>{formatDateAdvanced(createdAt)}</Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.text}>{registerOrigin}</Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.text}>{registerMethod}</Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.text}>{isAdmin && 'Admin'}</Typography>
        </TableCell>
        <TableCell>
          <IconButton onClick={toggleMenu}>
            <MoreVertIcon className={classes.menuButton} />
          </IconButton>
        </TableCell>
      </TableRow>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <MenuItem
          onClick={handleEditUserActivity}
          className={classes.subMenu}
          key="edit_user_activity"
        >
          Change activity
          <ActivityIcon />
        </MenuItem>
        <MenuItem onClick={handleEditUserRole} className={classes.subMenu} key="edit_user_role">
          {isAdmin ? 'Revoke Admin' : 'Make Admin'}
          <RoleIcon />
        </MenuItem>
        <MenuItem onClick={handleEditAction} className={classes.subMenu} key="edit_user">
          Edit
          <EditIcon />
        </MenuItem>
        <MenuItem
          onClick={handleDeleteAction}
          className={classnames(classes.subMenu, classes.subMenuDelete)}
          key="delete_user"
        >
          Delete
          <DeleteIcon />
        </MenuItem>
      </Menu>
      <ModalConfirm
        title="Дійсно хочете видалити?"
        note="Ви не зможете повернути назад."
        isOpenConfirmModal={isOpenConfirmModal}
        toggleOpenConfirmModal={toggleOpenConfirmModal}
        handleSumbit={handleDeleteApproved}
      />
    </>
  );
};

export default PlacesListItem;
