export enum UsersPageMode {
  LIST = 'list',
  ADD = 'add',
  EDIT = 'edit',
}

export interface User {
  id: string;
  name: string;
  email: string;
  avatarUrl?: string;
  createdAt: number;
  role?: string;
  googleId?: string;
  googleAvatarUrl?: string;
  isAdmin?: boolean;
  isActive?: boolean;
  registerMethod: string;
  registerOrigin: string;
}
