import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    // display: 'flex',
    // justifyContent: 'space-between',
    width: '100%',
    minHeight: 55,
    '&:not(:first-child)': {
      borderTop: '1px solid #E4E7ED',
    },
  },
}));
