import { Grid } from '@material-ui/core';
import SubjectIcon from '@material-ui/icons/Subject';
import MenuMainItem from 'common/core/MenuMainItem';

const pagesList = [
  {
    id: 'tasks',
    title: 'Завдання',
    url: '/tasks',
    icon: <SubjectIcon />,
  },
  {
    id: 'blog',
    title: 'Блог',
    url: '/blog',
    icon: <SubjectIcon />,
  },
  {
    id: 'projects',
    title: 'Застосунки',
    url: '/projects',
    icon: <SubjectIcon />,
  },
  {
    id: 'shop',
    title: 'Магазин',
    url: '/shop',
    icon: <SubjectIcon />,
  },
  {
    id: 'people',
    title: 'Користувачі',
    url: '/people',
    icon: <SubjectIcon />,
  },
  {
    id: 'locations',
    title: 'Локації',
    url: '/locations',
    icon: <SubjectIcon />,
  },
];

const MainPagesList = () => {
  return (
    <Grid container spacing={2}>
      {pagesList.map((item) => (
        <MenuMainItem key={item.id} title={item.title} url={item.url}>
          {item?.icon}
        </MenuMainItem>
      ))}
    </Grid>
  );
};

export default MainPagesList;
