import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import ContextProvider from 'context/ContextProvider';
import App from './App';
import Router from './Router';
import Alerts from 'common/core/Alerts';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    primary: {
      main: '#87990d',
      contrastText: '#fff',
    },
    secondary: {
      main: '#e57c00',
      contrastText: '#fff',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ContextProvider>
          <ThemeProvider theme={theme}>
            <Alerts>
              <App>
                <Router />
              </App>
            </Alerts>
          </ThemeProvider>
        </ContextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
