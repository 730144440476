import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
  },
  autocompleteBlock: { position: 'relative', display: 'flex', flexDirection: 'row' },
  selectBlock: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: 35,
    right: 0,
    width: 250,
    border: '1px solid #E4E7ED',
    borderRadius: 8,
    backgroundColor: '#fff',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;',
    zIndex: 2500,
  },
  selectElementBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 7,
    paddingBottom: 7,
  },
  elementTextBlock: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  bottomBorderedBlock: {
    borderBottom: '1px solid #EBEBEB',
  },
  input: {
    width: '100%',
    fontSize: 14,
    fontWeight: 400,
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '&:hover .MuiInputLabel-outlined': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  inputNotchedOutline: {
    border: '1px solid rgba(0, 0, 0, 0.25) !important',
  },
  inputFocused: {
    borderWidth: '1px ',
    borderColor: 'rgba(0, 0, 0, 0.38) !important',
  },
  inputChosen: { backgroundColor: '#E4E7ED' },
  inputLabel: {
    fontSize: 14,
  },
  inputIcon: {
    fontSize: 20,
    color: '#666666',
    cursor: 'pointer',
  },
  inputHelper: {
    padding: 0,
    margin: 0,
    fontSize: 12,
    fontWeight: 400,
  },
  selectElementText: {
    fontSize: 14,
    fontWeight: 350,
    lineHeight: '16px',
    color: '#000',
    cursor: 'pointer',
    wordWrap: 'break-word',
  },
}));
