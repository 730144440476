import React, { useEffect, useState } from 'react';
import Table from 'common/core/Table';
import UsersListItem from './UsersListItem';
import Pagination from 'common/core/Pagination';
import { User } from 'types/auth';
import { useStyles } from './styles';

const NUM_VALUES_ON_PAGE = 40;

interface Props {
  usersList: User[];
  handleEditMode: (userId: string) => void;
  editUserActivity: (userId: string, activityValue: boolean) => void;
  editUserRole: (userId: string, isActive: boolean) => void;
  deleteUser: (userId: string) => void;
}

const PlacesList = ({
  usersList,
  handleEditMode,
  editUserActivity,
  editUserRole,
  deleteUser,
}: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  // console.log(usersList);

  useEffect(() => {
    if (usersList.length) {
      const pageCountNew = Math.ceil(usersList.length / NUM_VALUES_ON_PAGE);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [usersList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Table>
        {usersList
          .sort((a: User, b: User) => b.createdAt - a.createdAt)
          .filter(
            (_: User, index: number) =>
              index >= (page - 1) * NUM_VALUES_ON_PAGE && index < page * NUM_VALUES_ON_PAGE,
          )
          .map((user: any, index: number) => (
            <UsersListItem
              key={user.id}
              user={user}
              handleEditMode={handleEditMode}
              editUserActivity={editUserActivity}
              editUserRole={editUserRole}
              deleteUser={deleteUser}
            />
          ))}
      </Table>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default PlacesList;
