import React, { useState, useEffect, useRef } from 'react';
// import classnames from 'classnames';
import { Grid, Typography, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  Lock as LockIcon,
  EditTwoTone as EditIcon,
  ImageTwoTone as ImageIcon,
  DeleteTwoTone as DeleteIcon,
} from '@material-ui/icons';
import Menu from 'common/core/Menu';
import MenuItem from 'common/core/MenuItem';
import StoryBigIcon from 'assets/icons/story2.svg';
import { AlphabetLetter } from 'types/projects/alphabet';
import { useStyles } from './styles';

interface Props {
  item: AlphabetLetter;
  handleEditMode: (letterId: string) => void;
  editLetterPicture: (setId: string, letterId: string, file: any) => void;
  deleteLetterPicture: (setId: string, letterId: string) => void;
}

const AlphabetWordsListItem = ({
  item,
  handleEditMode,
  editLetterPicture,
  deleteLetterPicture,
}: Props) => {
  const { id: letterId, setId, letter, name, pictureUrl, isPublic, description } = item;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const pictureRef = useRef<any>();
  const fileRef = useRef<any>();
  const classes = useStyles();

  useEffect(() => {
    if (pictureUrl) {
      pictureRef.current.src = pictureUrl;
    }
  }, [pictureUrl]);

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEditAction = () => {
    handleEditMode(letterId);
    setAnchorEl(null);
  };

  const handleDeletePictureAction = () => {
    deleteLetterPicture(setId, letterId);
    setAnchorEl(null);
  };

  const handleUpoloadImage = () => {
    setAnchorEl(null);
    fileRef.current.click();
  };

  const chooseFileInUploader = (e: any) => {
    if (e.target.files.length) {
      editLetterPicture(setId, letterId, e.target.files[0]);
    }
    fileRef.current.value = null;
  };

  return (
    <>
      <Grid item md={3} sm={6} xs={12}>
        <div className={classes.container}>
          <div className={classes.pictureBlock}>
            {!!pictureUrl && (
              <img
                src={pictureUrl ? pictureUrl : StoryBigIcon}
                className={pictureUrl ? classes.picture : classes.imgEmptyIcon}
                ref={pictureRef}
                alt=""
              />
            )}
            {/* {!isPublic && (
              <div className={classes.isPrivateBlock}>
                <LockIcon className={classes.isPrivateIcon} />
              </div>
            )} */}
          </div>
          <div className={classes.infoBlock}>
            <div className={classes.leftBlock}>
              <div className={classes.titleBlock}>
                <Typography className={classes.titleText}>{letter}</Typography>
              </div>
              <div className={classes.bodyBlock}>
                <Typography className={classes.bodyText}>{description}</Typography>
              </div>
            </div>
            <div className={classes.actionsBlock}>
              <IconButton onClick={toggleMenu} className={classes.menuButton}>
                <MoreVertIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </Grid>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <MenuItem onClick={handleUpoloadImage} className={classes.subMenu} key="edit_picture">
          Нове зображення
          <ImageIcon />
        </MenuItem>
        {!!pictureUrl && (
          <MenuItem
            onClick={handleDeletePictureAction}
            className={classes.subMenu}
            key="delete_picture"
          >
            Видалити зображення
            <DeleteIcon />
          </MenuItem>
        )}
        <MenuItem onClick={handleEditAction} className={classes.subMenu} key="edit">
          Редагувати
          <EditIcon />
        </MenuItem>
      </Menu>
      <input
        type="file"
        id="file"
        ref={fileRef}
        onChange={chooseFileInUploader}
        multiple
        accept="image/*"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default AlphabetWordsListItem;
