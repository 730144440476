import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Container } from '@material-ui/core';
import RoutesHeader from './RoutesHeader';
import RoutesFilter from './RoutesFilter';
// import RoutesMap from './RoutesMap';
import RoutesList from './RoutesList';
import { useAdminRoutes } from 'hooks/places/useAdminRoutes';
import { useStyles } from './styles';

const RoutesContainer = () => {
  const [cityFilter, setCityFilter] = useState<string>('');
  const [positionCity, setPositionCity] = useState<[number, number] | null>(null);
  const location = useLocation();
  const history = useHistory();
  const {
    isLoading: isLoadingRoutes,
    routesList,
    editRoute,
    deleteRoute,
  } = useAdminRoutes(positionCity);
  const classes = useStyles();

  // useEffect(() => {
  //   if (location?.search) {
  //     const searchData = location.search.split('?')[1];
  //     const searchDataParts = searchData.split('&');
  //     const city = searchDataParts[0].split('=')[1];
  //     const lat = searchDataParts[1].split('=')[1];
  //     const lng = searchDataParts[2].split('=')[1];

  //     if (city && lat && lng) {
  //       setCityFilter(city as string);
  //       setPositionCity([+lat, +lng]);
  //     }
  //   }
  // }, [location]);

  const handleChangeCity = (cityName: string, position: [number, number] | null) => {
    setCityFilter(cityName);
    setPositionCity(position);

    if (cityName && position) {
      history.push(`/routes?city=${cityName}&lat=${position[0]}&lng=${position[1]}`);
    } else {
      history.push(`/routes`);
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <RoutesHeader />
      <RoutesFilter cityFilter={cityFilter} handleChangeCity={handleChangeCity} />
      {/* <RoutesMap positionCity={positionCity} positionCurrent={positionCurrent} /> */}
      <RoutesList
        isLoadingRoutes={isLoadingRoutes}
        routesList={routesList}
        editRoute={editRoute}
        deleteRoute={deleteRoute}
      />
    </Container>
  );
};

export default RoutesContainer;
