import React, { useState } from 'react';
import { Container } from '@material-ui/core';
import AdminPlacesHeader from './AdminPlacesHeader';
import AdminPlacesList from './AdminPlacesList';
// import MyPlacesEdit from './MyPlacesEdit';
import { useAdminPlaces } from 'hooks/places/useAdminPlaces';
import { PlacesPageMode, Place } from 'types/places';
import { useStyles } from './styles';

const AdminPlacesContainer = () => {
  const [pageMode, setPageMode] = useState<string>(PlacesPageMode.LIST);
  const [placeIdCurrent, setPlaceIdCurrent] = useState<string>('');
  const {
    isLoading: isLoadingPlaces,
    regionsList,
    citiesList,
    categoriesList,
    placesList,
    createPlace,
    editPlace,
    deletePlace,
  } = useAdminPlaces();
  const classes = useStyles();
       
  const handlePageMode = (value: string) => {
    setPageMode(value);
  };

  const handleEditMode = (placeId: string) => {
    setPlaceIdCurrent(placeId);
    setPageMode(PlacesPageMode.EDIT);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <AdminPlacesHeader pageMode={pageMode} handlePageMode={handlePageMode} />
      {pageMode === PlacesPageMode.LIST && (
        <AdminPlacesList
          isLoadingPlaces={isLoadingPlaces}
          placesList={placesList}
          handleEditMode={handleEditMode}
          deletePlace={deletePlace}
        />
      )}
      {/* {(pageMode === PlacesPageMode.EDIT || pageMode === PlacesPageMode.ADD) && (
        <MyPlacesEdit
          place={placesList.find((place: Place) => place.id === placeIdCurrent) ?? null}
          categoriesList={categoriesList}
          regionsList={regionsList}
          citiesList={citiesList}
          createPlace={createPlace}
          editPlace={editPlace}
          handlePageMode={handlePageMode}
        />
      )} */}
    </Container>
  );
};

export default AdminPlacesContainer;
