import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// @ts-ignore
import { RecordRTCPromisesHandler } from 'recordrtc';
import { Container } from '@material-ui/core';
import StoryHeader from './StoryHeader';
import StoryFramesList from './StoryFramesList';
import { useMyStory } from 'hooks/projects/tvorytelling/useMyStory';
import { StoryPageMode, StoryActionMode } from 'types/projects/tvorytelling';
import { useStyles } from './styles';

const MyStoryContainer = () => {
  const [pageMode, setPageMode] = useState<string>(StoryPageMode.LIST_VIEW);
  const [actionMode, setActionMode] = useState<string>(StoryActionMode.WAITING);
  const [frameIdCurrent, setFrameIdCurrent] = useState<string>('');
  const location = useLocation();
  const pagePath = location.pathname.split('/');
  const storyId = pagePath[3] ?? '';
  const { storyData, framesList, createFrame } = useMyStory(storyId);
  const recordRef = useRef<any>(null);
  const classes = useStyles();

  useEffect(() => {
    setActionMode(StoryActionMode.WAITING);
  }, [pageMode]);

  const handleStartPlaying = (frameId: string) => {
    setActionMode(StoryActionMode.PLAYING);
    setFrameIdCurrent(frameId);
  };

  const handleStopPlaying = (frameId: string) => {
    setActionMode(StoryActionMode.WAITING);
    setFrameIdCurrent('');
  };

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <StoryHeader story={storyData} />
        <StoryFramesList
          actionMode={actionMode}
          framesList={framesList}
          frameIdCurrent={frameIdCurrent}
          handleStartPlaying={handleStartPlaying}
          handleStopPlaying={handleStopPlaying}
        />
      </Container>
      <audio ref={recordRef} style={{ display: 'none' }} />
    </>
  );
};

export default MyStoryContainer;
