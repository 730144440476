import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 10,
  },
  filterSelect: {
    height: 32,
    width: 250,
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
  },
}));
