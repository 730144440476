import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 10,
    marginBottom: 20,
  },
  titleBlock: {
    display: 'flex',
    marginBottom: 10,
  },
  errorBlock: {
    display: 'flex',
    marginBottom: 10,
  },
  inputBlock: {
    display: 'flex',
    marginBottom: 15,
  },
  optionBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
  },
  optionsListBlock: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  optionsListHeaderBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginBottom: 7,
  },
  optionElementBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '33%',
    flexGrow: 1,
  },
  optionCheckboxBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexGrow: 0,
    padding: 3,
    paddingRight: 5,
  },
  optionNameBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  buttonBlock: {
    display: 'flex',
    marginTop: 10,
  },
  titleText: {
    fontSize: 18,
    fontWeight: 450,
  },
  errorText: {
    fontSize: 16,
    fontWeight: 300,
    color: '#ff0000',
  },
  select: {
    width: 315,
  },
  selectHelper: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    color: '#000',
    paddingBottom: 8,
  },
  input: {
    width: 550,
  },
  inputTextarea: {
    width: 315,
    height: 100,
  },
  optionsListHeaderText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '100%',
    color: '#000',
  },
  optionCheckbox: {
    padding: 0,
  },
  optionNameText: {
    fontSize: 14,
    fontWeight: 350,
    lineHeight: '100%',
    color: '#333',
  },
}));
