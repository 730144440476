import { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { LatLng } from 'leaflet';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { getDistance } from 'utils/geometry';
import { useAlert } from 'hooks/useAlert';
import { AreaInput, RoutePoint, Route } from 'types/places';

const MIN_ALLOWABLE_DISTANCE = 1;

export const usePlaceArea = (placeId: string) => {
  const [areaData, setAreaData] = useState<Route | null>(null);
  const [pointsList, setPointsList] = useState<RoutePoint[]>([]);
  const showAlert = useAlert();

  const getArea = async () => {
    const details = { placeId };
    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/places/get_admin_area',
      details,
    );
    if (results?.data?.pointsList) {
      const pointsListListNew = results?.data?.pointsList;
      setPointsList(pointsListListNew);
    }
  };

  useEffect(() => {
    if (placeId) {
      getArea();
    }
  }, [placeId]);

  const addPointer = useCallback(
    (point: LatLng) => {
      let isAllowable = true;
      if (pointsList.length) {
        const pointLast = pointsList[pointsList.length - 1];
        const { latitude, longitude } = pointLast;
        const distanceLast = getDistance([latitude, longitude], [point.lat, point.lng]);
        if (distanceLast <= MIN_ALLOWABLE_DISTANCE) {
          isAllowable = false;
        }
      }
      if (isAllowable) {
        setPointsList((prev) =>
          prev.concat({
            id: uuidv4(),
            latitude: point.lat,
            longitude: point.lng,
          }),
        );
      } else {
        showAlert('Not allowable distance.');
      }
    },
    [pointsList],
  );

  const deletePointer = useCallback(
    (pointId: string) => {
      setPointsList((prev) => prev.filter((item) => item.id !== pointId));
    },
    [pointsList],
  );

  const editArea = useCallback(async () => {
    const details = {
      placeId,
      pointsList,
      isPublic: true,
    };

    const results: AxiosResponse = await fetchAPI(
      'post',
      process.env.REACT_APP_REST_API_URL + '/places/edit_area',
      details,
    );

    if (results?.data?.placeId) {
      showAlert('Набір точок було змінено.');
    }
  }, [placeId, pointsList]);

  return {
    areaData,
    pointsList,
    addPointer,
    deletePointer,
    editArea,
  };
};
