import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    // height: 30,
    backgroundColor: '#fff',
    margin: 0,
  },
}));

interface Props {
  error?: boolean;
  size?: 'small' | 'medium';
  label?: string;
  placeholder?: string;
  value?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  required?: boolean;
  autoComplete?: string;
  fullWidth?: boolean;
  type?: string;
  multiline?: boolean;
  rows?: number;
  autoFocus?: boolean;
  helperText?: string;
  FormHelperTextProps?: any;
  inputProps?: any;
  className?: any;
}

const Input = ({
  error,
  size = 'small',
  label,
  placeholder,
  value,
  onChange,
  onClick = () => {},
  required,
  autoComplete,
  fullWidth,
  type,
  multiline,
  rows,
  autoFocus,
  helperText,
  FormHelperTextProps,
  inputProps = {},
  className = null,
}: Props) => {
  const classes = useStyles();

  return (
    <TextField
      className={classnames(classes.root, className)}
      color="primary"
      variant="outlined"
      margin="normal"
      size={size}
      required={required}
      fullWidth={fullWidth}
      value={value}
      label={label}
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      onClick={onClick}
      multiline={multiline}
      rows={rows}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      helperText={helperText}
      FormHelperTextProps={FormHelperTextProps ?? { style: { color: 'red' } }}
      InputProps={{
        ...inputProps,
        // classes: { input: classnames(classes.root, className) },
      }}
    />
  );
};

export default Input;
