import React, { useState, useEffect } from 'react';
import { FormControl, Select, InputLabel, Typography } from '@material-ui/core';
import Input from 'common/core/Input';
import Button from 'common/core/Button';
import { Region, City } from 'types/places';
import { useStyles } from './styles';

const ProfileEditForm = ({ user, regionsList, citiesList, answerError, editProfile }: any) => {
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [regionId, setRegionId] = useState<string>('');
  const [cityId, setCityId] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (user && regionsList.length && citiesList.length) {
      setFirstName(user?.firstName ?? '');
      setLastName(user?.lastName ?? '');
      setRegionId(user?.regionId ?? '');
      setCityId(user?.cityId ?? '');
    }
  }, [user, regionsList, citiesList]);

  useEffect(() => {
    if (isChanged) {
      setCityId('');
    }
  }, [regionId]);

  const handleChangeRegion = (e: React.ChangeEvent<{ value: string | unknown }>) => {
    setRegionId(e.target.value as string);
    setIsChanged(true);
  };

  const handleChangeCity = (e: React.ChangeEvent<{ value: string | unknown }>) => {
    setCityId(e.target.value as string);
    setIsChanged(true);
  };

  const handleSubmit = () => {
    setFirstNameError(!firstName ? "Заповніть своє ім'я" : '');
    setLastNameError(!lastName ? 'Заповніть своє прізвище' : '');

    if (firstName && lastName) {
      editProfile(firstName, lastName, regionId, cityId);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleBlock}>
        <Typography className={classes.titleText}>Редагувати профайл</Typography>
      </div>
      {answerError !== '' && (
        <div className={classes.errorBlock}>
          <Typography className={classes.errorText}>
            Ваші дані мають якусь помилку. Перевірте та спробуйте зберегти ще раз.
          </Typography>
        </div>
      )}
      <div className={classes.inputBlock}>
        <Input
          label="Ваше ім'я"
          size="medium"
          type="text"
          className={classes.input}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
          value={firstName}
          helperText={firstNameError}
          fullWidth
          required
        />
      </div>
      <div className={classes.inputBlock}>
        <Input
          label="Ваше ім'я"
          size="medium"
          type="text"
          className={classes.input}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
          value={lastName}
          helperText={lastNameError}
          fullWidth
          required
        />
      </div>
      <div className={classes.inputBlock}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Регіон</InputLabel>
          <Select
            label="Регіон"
            native
            onChange={handleChangeRegion}
            value={regionId}
            className={classes.select}
          >
            <option aria-label="None" value="" key={0}></option>
            {regionsList
              .sort((a: Region, b: Region) => a.name.localeCompare(b.name))
              .map((item: Region) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.inputBlock}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Місто</InputLabel>
          <Select
            label="Місто"
            native
            onChange={handleChangeCity}
            value={cityId}
            className={classes.select}
          >
            <option aria-label="None" value="" key={0}></option>
            {citiesList
              .filter((item: City) => item.regionId === regionId)
              .sort((a: City, b: City) => a.name.localeCompare(b.name))
              .map((item: City) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.buttonBlock}>
        <Button onClick={handleSubmit}>Зберегти</Button>
      </div>
    </div>
  );
};

export default ProfileEditForm;
