import React from 'react';
import { Dialog, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Button from 'common/core/Button';
import { useStyles } from './styles';

interface Props {
  widthMax?: number;
  title?: string;
  isCloseIcon?: boolean;
  open: boolean;
  handleClose?: () => void;
  handleSubmit?: () => void;
  textBtnClose?: string;
  textBtnSubmit?: string;
  children?: React.ReactNode;
}

const Modal = ({
  widthMax = 0,
  title,
  isCloseIcon = true,
  open = false,
  handleClose = () => {},
  handleSubmit,
  textBtnClose,
  textBtnSubmit,
  children,
}: Props) => {
  const classes = useStyles();

  const paperProps = widthMax ? { style: { width: widthMax } } : {};

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth={'lg'}
      PaperProps={paperProps}
    >
      <div className={classes.container}>
        {(title !== '' || isCloseIcon === true) && (
          <div className={classes.headerBlock}>
            <div className={classes.titleBlock}>
              {title && <Typography className={classes.titleText}>{title}</Typography>}
            </div>
            <div className={classes.closeBlock}>
              {isCloseIcon === true && (
                <IconButton className={classes.closeButton} onClick={handleClose}>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              )}
            </div>
          </div>
        )}
        <div className={classes.contentBlock}>{children}</div>
        {(handleSubmit || textBtnClose) && (
          <div className={classes.actionsBlock}>
            {textBtnClose && (
              <Button onClick={handleClose} variant="outlined" className={classes.button}>
                {textBtnClose}
              </Button>
            )}
            {handleSubmit && (
              <Button onClick={handleSubmit} className={classes.button}>
                {textBtnSubmit ? textBtnSubmit : 'Sumbit'}
              </Button>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default Modal;
