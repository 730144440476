import React from 'react';
import Button from 'common/core/Button';
import { useStyles } from './styles';

interface Props {
  handleAddMode: () => void;
}

const AchievementsActions = ({ handleAddMode }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.actionBlock}>
        <Button onClick={handleAddMode} className={classes.addButton}>
          Додати досягнення
        </Button>
      </div>
    </div>
  );
};

export default AchievementsActions;
