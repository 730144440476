// import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Lock as LockIcon } from '@material-ui/icons';

import { useStyles } from './styles';

interface Props {
  type?: string;
}

const BadgeIcon = ({ type = 'private' }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.isPrivateBlock}>
      <LockIcon className={classes.isPrivateIcon} />
    </div>
  );
};

export default BadgeIcon;
