import React, { useState } from 'react';
import { Container } from '@material-ui/core';
import UsersHeader from './UsersHeader';
import UsersList from './UsersList';
// import PlacesEdit from './PlacesEdit';
import { useUsers } from 'hooks/general/useUsers';
import { UsersPageMode, User } from 'types/auth';
import { useStyles } from './styles';

const UsersContainer = () => {
  const [pageMode, setPageMode] = useState<string>(UsersPageMode.LIST);
  const [userIdCurrent, setUserIdCurrent] = useState<string>('');
  const { usersList, editUserActivity, editUserRole, deleteUser } = useUsers();
  const classes = useStyles();

  const handlePageMode = (value: string) => {
    setPageMode(value);
  };

  const handleEditMode = (placeId: string) => {
    setUserIdCurrent(placeId);
    setPageMode(UsersPageMode.EDIT);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <UsersHeader pageMode={pageMode} handlePageMode={handlePageMode} />
      {pageMode === UsersPageMode.LIST && (
        <UsersList
          usersList={usersList}
          handleEditMode={handleEditMode}
          editUserActivity={editUserActivity}
          editUserRole={editUserRole}
          deleteUser={deleteUser}
        />
      )}
      {/* {pageMode === UsersPageMode.EDIT && (
        <PlacesEdit
          userIdCurrent={placesList.find((place: PlacesPlace) => place.id === placeIdCurrent) ?? null}
          editPlace={editPlace}
          handlePageMode={handlePageMode}
        />
      )} */}
    </Container>
  );
};

export default UsersContainer;
