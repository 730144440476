import React from 'react';
import { Typography } from '@material-ui/core';
import Modal from 'common/core/Modal';
import Button from 'common/core/Button';
import { useStyles } from './styles';

interface Props {
  isOpenConfirmModal: boolean;
  toggleOpenConfirmModal: () => void;
  title?: string;
  note?: string;
  handleSumbit: () => void;
  textCancel?: string;
  textSubmit?: string;
}

const ModalConfirm = ({
  title,
  note,
  isOpenConfirmModal,
  toggleOpenConfirmModal,
  handleSumbit,
  textCancel = 'Скасувати',
  textSubmit = 'Видалити',
}: Props) => {
  const classes = useStyles();

  return (
    <Modal
      widthMax={330}
      title={''}
      isCloseIcon={false}
      open={isOpenConfirmModal}
      handleClose={toggleOpenConfirmModal}
    >
      <div className={classes.container}>
        <div className={classes.titleBlock}>
          {title && (
            <Typography className={classes.titleText} align="center">
              {title}
            </Typography>
          )}
        </div>
        {note && (
          <div className={classes.noteBlock}>
            <Typography className={classes.noteText}>{note}</Typography>
          </div>
        )}
        <div className={classes.buttonBlock}>
          <Button
            onClick={toggleOpenConfirmModal}
            className={classes.buttonCancel}
            variant="outlined"
          >
            {textCancel}
          </Button>
          <Button onClick={handleSumbit} className={classes.buttonSumbit}>
            {textSubmit}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirm;
