import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 10,
    marginBottom: 20,
  },
  titleBlock: {
    display: 'flex',
    marginBottom: 10,
  },
  errorBlock: {
    display: 'flex',
    marginBottom: 10,
  },
  inputBlock: {
    display: 'flex',
    marginBottom: 15,
  },
  buttonBlock: {
    display: 'flex',
    marginTop: 10,
  },
  titleText: {
    fontSize: 18,
    fontWeight: 450,
  },
  errorText: {
    fontSize: 16,
    fontWeight: 300,
    color: '#ff0000',
  },
  select: {
    width: 250,
  },
  selectHelper: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    color: '#000',
    paddingBottom: 8,
  },
  input: {
    // width: 550,
  },
  inputTextarea: {
    // width: 315,
    // height: 100,
  },
}));
