import React, { useState } from 'react';
import Container from 'common/core/Container';
import ProjectsHeader from './ProjectsHeader';
import ProjectsList from './ProjectsList';
import ProjectsEdit from './ProjectsEdit';
import ProjectsGallery from './ProjectsGallery';
import { useProjects } from 'hooks/projects/useProjects';
import { useProjectCategories } from 'hooks/projects/useProjectCategories';
import { Project, ProjectsPageMode } from 'types/projects';

const ProjectsContainer = () => {
  const [pageMode, setPageMode] = useState<string>(ProjectsPageMode.LIST);
  const [projectIdCurrent, setProjectIdCurrent] = useState<string>('');
  const {
    projectsList,
    createProject,
    editProject,
    deleteProject,
    editProjectPicture,
    createGalleryPicture,
    deleteGalleryPicture,
  } = useProjects();
  const { categoriesList: skillsList } = useProjectCategories();

  const handlePageMode = (value: string) => {
    setPageMode(value);
  };

  const handleEditMode = (value: string) => {
    setProjectIdCurrent(value);
    setPageMode(ProjectsPageMode.EDIT);
  };

  const handleEditGalleryMode = (value: string) => {
    setProjectIdCurrent(value);
    setPageMode(ProjectsPageMode.EDIT_GALLERY);
  };

  return (
    <Container>
      <ProjectsHeader pageMode={pageMode} handlePageMode={handlePageMode} />
      {pageMode === ProjectsPageMode.LIST && (
        <ProjectsList
          projectsList={projectsList}
          handleEditMode={handleEditMode}
          handleEditGalleryMode={handleEditGalleryMode}
          deleteProject={deleteProject}
          editProjectPicture={editProjectPicture}
        />
      )}
      {(pageMode === ProjectsPageMode.EDIT || pageMode === ProjectsPageMode.ADD) && (
        <>
          <ProjectsEdit
            project={projectsList.find((item: Project) => item.id === projectIdCurrent) ?? null}
            skillsList={skillsList}
            createProject={createProject}
            editProject={editProject}
            handlePageMode={handlePageMode}
          />
        </>
      )}
      {pageMode === ProjectsPageMode.EDIT_GALLERY && (
        <ProjectsGallery
          projectId={projectIdCurrent}
          title={projectsList.find((item: Project) => item.id === projectIdCurrent)?.name ?? ''}
          pictures={
            projectsList.find((item: Project) => item.id === projectIdCurrent)?.pictures ?? []
          }
          createGalleryPicture={createGalleryPicture}
          deleteGalleryPicture={deleteGalleryPicture}
        />
      )}
    </Container>
  );
};

export default ProjectsContainer;
