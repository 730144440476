import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import AuthorsListItem from './AuthorsListItem';
import Pagination from 'common/core/Pagination';
import { numValuesOnPage } from 'config';
import { PoetryWalksAuthor } from 'types/projects/poetry';
import { useStyles } from './styles';

interface Props {
  authorsList: PoetryWalksAuthor[];
  handleEditMode: (authorId: string) => void;
  editAuthorPicture: (authorId: string, file: any) => void;
}

const AuthorsList = ({ authorsList, handleEditMode, editAuthorPicture }: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (authorsList.length) {
      const pageCountNew = Math.ceil(authorsList.length / numValuesOnPage);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [authorsList]);

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <Grid container spacing={1}>
        {authorsList
          .filter(
            (_: PoetryWalksAuthor, index: number) =>
              index >= (page - 1) * numValuesOnPage && index < page * numValuesOnPage,
          )
          .map((author: PoetryWalksAuthor) => (
            <AuthorsListItem
              key={author.id}
              author={author}
              handleEditMode={handleEditMode}
              editAuthorPicture={editAuthorPicture}
            />
          ))}
      </Grid>
      <div className={classes.pagginationBlock}>
        {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChangePage} />}
      </div>
    </>
  );
};

export default AuthorsList;
