import { useMutation, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { Podcast, PodcastInput } from 'types/podcasts';

export const useCreatePodcast = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (details: PodcastInput) => {
      const result: AxiosResponse = await fetchAPI(
        'post',
        process.env.REACT_APP_REST_API_URL + '/podcasts/create_podcast',
        details,
      );

      return result;
    },
    onSuccess: (data, variables) => {
      const results = data?.data;

      if (results?.id) {
        const podcastsList: Podcast[] = queryClient.getQueryData('podcasts') ?? [];
        queryClient.setQueryData(['podcasts'], [...podcastsList, results]);
      }
    },
  });
};
