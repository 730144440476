import { makeStyles, Theme } from '@material-ui/core/styles';
import { IMAGE_BACKGROUND_COLOR, IMPORTANT_COLOR } from 'config';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '1px solid #EBEBEB',
    borderRadius: 4,
  },
  pictureBlock: {
    display: 'flex',
    borderRadius: '4px 4px 0 0',
    backgroundColor: IMAGE_BACKGROUND_COLOR,
  },
  pictureEmptyBlock: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: '16/9',
    backgroundColor: IMAGE_BACKGROUND_COLOR,
    borderRadius: '4px 4px 0 0',
  },
  infoBlock: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: 6,
    paddingBottom: 6,
  },
  leftBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: 10,
  },
  titleBlock: {
    display: 'flex',
    marginBottom: 1,
  },
  elementBlock: {
    display: 'flex',
  },
  actionsBlock: { paddingRight: 5 },
  picture: {
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '16/9',
    borderRadius: '4px 4px 0 0',
  },
  imgEmptyCompass: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    margin: 'auto',
    width: 120,
    opacity: 0.7,
  },
  titleText: {
    fontSize: 16,
    fontWeight: 400,
  },
  infoText: {
    fontSize: 14,
    fontWeight: 350,
    color: '#666666',
  },
  infoIcon: {
    fontSize: 16,
    color: '#333',
    marginRight: 5,
  },
  menuButton: {},
  subMenu: {
    display: 'flex',
    minWidth: 200,
    gap: 25,
  },
  subMenuDelete: {
    color: '#FF0000 !important',
  },
  subMenuIcon: {},
}));
