export enum NatureTasksPageMode {
  LIST = 'list',
  ADD = 'add',
  EDIT = 'edit',
}

export enum NatureTasksUsageMode {
  COMMON = 'common',
  PAID = 'paid',
  DEMO = 'demo',
}

export interface NatureTask {
  id: string;
  uid: string;
  name: string;
  description: string;
  materials: string;
  points: number;
  complexity: number;
  pictureUrl: string;
  thumbnailUrl: string;
  pictures: string[];
  months?: number[];
  season: string[];
  age: string[];
  // usage: NatureTasksUsageMode;
  isDiaryAccessible: boolean;
  executeTime: string[];
  category: string[];
  level?: number;
  isPublic?: boolean;
}

export interface NatureCategory {
  id: string;
  name: string;
}

export interface NatureExecuteTime {
  id: string;
  name: string;
}

export interface NatureSeason {
  id: string;
  name: string;
}

export interface NatureAge {
  id: string;
  name: string;
}

export interface NatureTaskInputCreate extends NatureTaskInput {}

export interface NatureTaskInputEdit extends NatureTaskInput {
  taskId: string;
}

export interface NatureTaskInput {
  uid: string;
  name: string;
  description: string;
  materials: string;
  points: number;
  season: string[];
  executeTime: string[];
  category: string[];
  age: string[];
  // usage: string;
  isDiaryAccessible: boolean;
  isPublic: boolean;
}

export interface NatureAchievement {
  id: string;
  name: string;
  points: number;
  pictureUrl?: string;
  isPublic?: boolean;
}

export interface NatureAchievementInputCreate extends NatureAchievementInput {}

export interface NatureAchievementInputEdit extends NatureAchievementInput {
  achievementId: string;
}

export interface NatureAchievementInput {
  name: string;
  points: number;
  isPublic: boolean;
}
