import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { fetchAPI } from 'utils/api';
import { Story } from 'types/projects/tvorytelling';

export const useStories = () => {
  const [storiesList, setStoriesList] = useState<Story[]>([]);

  useEffect(() => {
    getStories();
  }, []);

  const getStories = useCallback(async () => {
    const results: AxiosResponse = await fetchAPI(
      'post',
      // 'http://localhost:4000/v1/tvorytelling/get_my_stories',
      process.env.REACT_APP_REST_API_URL + '/tvorytelling/get_stories',
      {},
    );

    if (results?.data) {
      setStoriesList(results.data.stories);
    }
  }, []);

  return {
    storiesList,
  };
};
