import React from 'react';
import { useLogin } from 'hooks/auth/useLogin';
import LoginForm from './LoginForm';

interface Props {
  togglePage: () => void;
}

const Login = ({ togglePage }: Props) => {
  const { login, answerError } = useLogin();

  return <LoginForm togglePage={togglePage} answerError={answerError} login={login} />;
};

export default Login;
