import React from 'react';
import { Typography } from '@material-ui/core';
import Button from 'common/core/Button';
import { PlacesPageMode } from 'types/places';
import { useStyles } from './styles';

interface Props {
  pageMode: string;
  handlePageMode: (value: string) => void;
}

const AdminPlacesHeader = ({ pageMode, handlePageMode }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.titleText}>
          Мої місця
          {pageMode === PlacesPageMode.ADD && <>: нове місце</>}
          {pageMode === PlacesPageMode.EDIT && <>: редагування місця</>}
        </Typography>
      </div>
      <div>
        {pageMode === PlacesPageMode.LIST ? (
          <Button onClick={() => handlePageMode(PlacesPageMode.ADD)}>Додати місце</Button>
        ) : (
          <Button onClick={() => handlePageMode(PlacesPageMode.LIST)} variant="outlined">
            Скасувати
          </Button>
        )}
      </div>
    </div>
  );
};

export default AdminPlacesHeader;
