import { makeStyles, Theme } from '@material-ui/core/styles';
import { IMAGE_BACKGROUND_COLOR, IMPORTANT_COLOR, IMPORTANT_COLOR_2 } from 'config';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '1px solid #EBEBEB',
    borderRadius: 6,
  },
  pictureBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: '16/9',
    backgroundColor: IMAGE_BACKGROUND_COLOR,
    borderRadius: '6px 6px 0 0',
  },
  isSpecialBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 5,
    right: 5,
    width: 22,
    height: 22,
    backgroundColor: IMPORTANT_COLOR_2,
  },
  infoBlock: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: 4,
    paddingBottom: 4,
  },
  leftBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: 10,
  },
  titleBlock: {
    display: 'flex',
  },
  actionsBlock: { paddingRight: 3 },
  picture: {
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '16/9',
    borderRadius: '6px 6px 0 0',
  },
  imgEmptyIcon: {
    width: 90,
  },
  isSpecialIcon: {
    fontSize: 16,
    color: '#000',
  },
  titleText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '120%',
  },
  infoText: {
    fontSize: 12,
    fontWeight: 350,
    color: '#666666',
    lineHeight: '130%',
  },
  infoIcon: {
    fontSize: 16,
    color: '#333',
    marginRight: 5,
  },
  menuButton: {},
  subMenu: {
    minWidth: 200,
  },
  subMenuDelete: {
    color: '#FF0000 !important',
  },
  subMenuIcon: {},
}));
